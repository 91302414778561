import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Widgets/Header";
import CommonField from "../../Widgets/CommonField";
import constants from "../../constants";
import { Context } from "../../Context";
import SidebarForm from "../lakehouse/SidebarForm";
import Status from "../../Widgets/Status";
import Table from "../../Widgets/Table";
import { filterDataBySearch } from "../../utils/utils";

function SchedulerQueryDetails() {
  const contextValues = useContext(Context);
  const [search, setSearch] = useState();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [lakehouseData, setLakehouseData] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(16);
  const navigate = useNavigate();

  // useEffect(() => {
  //   contextValues.GetLakehouse();
  // }, []);

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    setLakehouseData((values) => ({
      ...values,
      [name]: value,
      status: "active",
    }));
  };

  const handleSubmit = () => {
    contextValues.createLakehouse(lakehouseData);
    setOpenSidebar(false);
    setLakehouseData({});
  };

  const validate = () => {
    let isValidate = false;
    if (Object.keys(lakehouseData).length !== 0) {
      if (!lakehouseData?.name || lakehouseData?.name.trim() === "") {
        isValidate = false;
      } else if (
        /[A-Z]/.test(lakehouseData?.name) ||
        lakehouseData?.name?.includes(" ") ||
        lakehouseData?.name?.includes(" ") ||
        lakehouseData?.name?.includes("_")
      ) {
        isValidate = false;
      } else if (!lakehouseData?.source || lakehouseData?.source === "") {
        isValidate = false;
      } else if (lakehouseData?.source?.value === "local_fs") {
        if (!lakehouseData?.path || lakehouseData?.path === "") {
          isValidate = false;
        } else {
          isValidate = true;
        }
      } else {
        isValidate = true;
      }
    }
    return isValidate;
  };

  const scheduledQueriesHead = [
    { id: 1, label: "Run date", position: "left" },
    { id: 3, label: "Schedule Time", position: "center" },
    { id: 4, label: "Summary", position: "center" },
    { id: 4, label: "Status", position: "center" },
  ];

  const data = [
    {
      id: 1,
      run_date: "August 2 2023",
      schedule_time: "August 2 2023 at 6:30 AM UTC+5:30",
      summary: "Transfer run has completed successfully.",
      status: "success",
    },
    {
      id: 2,
      run_date: "August 4 2023",
      schedule_time: "August 4 2023 at 7:30 AM UTC+5:30",
      summary: "Transfer run has completed successfully.",
      status: "success",
    },
    {
      id: 3,
      run_date: "August 5 2023",
      schedule_time: "August 5 2023 at 8:00 AM UTC+5:30",
      summary: "Transfer run has failed.",
      status: "failed",
    },
  ];

  return (
    <div className="w-full h-full">
      <Header
        title="Scheduled Query Details"
        searchPlaceholder="Search..."
        search={search}
        setSearch={setSearch}
        showBackIcon
        onBack={() => navigate(-1)}
      />

      {openSidebar && (
        <SidebarForm
          disable={!validate()}
          onClose={() => {
            setOpenSidebar(false);
            setLakehouseData({});
          }}
          handleSubmit={handleSubmit}
        >
          <CommonField
            className="mb-8"
            titleText="Lakehouse name"
            name="name"
            value={lakehouseData.name || ""}
            onChange={handleChange}
            error="lowercase"
          />
          <CommonField
            className="mb-8"
            titleText="Description"
            rows="4"
            name="description"
            onChange={handleChange}
            value={lakehouseData.description}
            textarea
          />
          <CommonField
            className="mb-8"
            titleText="Source"
            value={lakehouseData?.sorce}
            options={constants.components.scheduledQuerieDetailsData}
            onChange={(source) => {
              setLakehouseData({
                ...lakehouseData,
                source,
              });
            }}
            error
            selectfield
          />

          {lakehouseData?.source?.value === "local_fs" && (
            <CommonField className="mb-8" titleText="Path" name="path" value={lakehouseData.path || ""} onChange={handleChange} error="field" />
          )}

          {lakehouseData?.source?.value === "s3" && contextValues?.configurationData?.data?.length == 0 && (
            <div className="p-12 bg-amber-50 bg-opacity-30 rounded-md">
              <i className="fa-solid fa-triangle-exclamation text-amber-600 text-lg mb-8" />
              <div className="text-xs text-amber-600">
                To customize your experience, please access the{" "}
                <span
                  className="hover:text-blue-500 hover:underline cursor-pointer font-semibold"
                  role="presentation"
                  onClick={() => navigate(constants.route.settings)}
                >
                  settings
                </span>{" "}
                and configure the desired options accordingly.
              </div>
            </div>
          )}
        </SidebarForm>
      )}

      <div className="p-10">
        <div className="mb-14 px-16 py-12 border-b bg-white">
          <div className="flex items-center">
            <h2 className="text-md font-semibold bg-buttonGradient inline-block text-transparent bg-clip-text  mr-10">
              daily_dashboard_base_kpi_metrics
            </h2>
            <Status title="success" />
          </div>
          <div className="flex items-center">
            <div className="mr-14">
              <h3 className="text-sm font-semibold mt-6 text-base text-neutral-600 dark:text-neutral-200 bg-buttonGradient inline-block text-transparent bg-clip-text">
                Schedule(UTC)
              </h3>
              <p className="text-xs mt-4">Every day 01:00</p>
            </div>
            <div className="">
              <h3 className="text-sm font-semibold mt-6 text-base text-neutral-600 dark:text-neutral-200 bg-buttonGradient inline-block text-transparent bg-clip-text">
                Target date for next run
              </h3>
              <p className="text-xs mt-4">August 3 2023 at 6:30 AM UTC+5:30</p>
            </div>
          </div>
        </div>
        <Table
          title="Scheduled Query Details"
          setPage={setPage}
          tableHeader={scheduledQueriesHead}
          rowsPerPage={rowsPerPage}
          totalPost={filterDataBySearch(data, search)?.length}
        >
          {filterDataBySearch(data, search)
            ?.slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
            ?.map((item, index) => {
              return (
                <tr key={index} className="border-b w-full hover:bg-tableHoverColor last:border-0">
                  <td className="px-16 py-5 cursor-pointer text-xs">{item?.run_date}</td>
                  <td className="px-16 py-5 text-center cursor-pointer text-xs">{item?.schedule_time}</td>
                  <td className="px-16 py-5 text-center cursor-pointer text-xs">{item?.summary}</td>
                  <td className="px-16 py-5 cursor-pointer flex items-center justify-center text-xs">
                    <Status title={item?.status} />
                  </td>
                </tr>
              );
            })}
        </Table>
      </div>
    </div>
  );
}

export default SchedulerQueryDetails;
