import { memo } from "react";

const Checkbox = ({ label, className, ...field }) => {
  return (
    <label htmlFor="checkbox" className={`flex items-center h-20 text-[14px] ${className}`}>
      <input type="checkbox" {...field} id="checkbox" className="mr-4 w-10 h-10 cursor-pointer" />
      {label}
    </label>
  );
};

export default memo(Checkbox);
