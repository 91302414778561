import NoData from "./NoData";
import Pagination from "./Pagination";

const Table = ({
  tableHeader,
  children,
  title,
  removeSidePadding,
  rowsPerPage,
  page,
  setPage,
  className,
  totalPost,
  showBackButton,
  onBackButton,
}) => {
  return (
    <div className={`w-full h-full ${className}`}>
      <div className="w-full rounded-sm overflow-x-auto">
        {title && (
          <div className={`${!removeSidePadding && "px-16 py-12"} w-full inline-block flex items-center`}>
            {showBackButton && (
              <i
                className="fa-solid fa-angle-left font-semibold flex-row mr-5 cursor-pointer hover:text-blue-600 h-20 w-20 flex items-center justify-center hover:bg-blue-100 hover:bg-opacity-30 rounded-full"
                role="presentation"
                onClick={onBackButton}
              />
            )}
            {title && <div className="text-sm font-normal w-full">{title}</div>}
          </div>
        )}
        <div className={`relative ${!title && "rounded-t-sm"}  bg-white rounded-sm overflow-x-auto`}>
          <table className="w-full">
            <thead className="w-full text-333333 text-xs">
              <tr>
                {tableHeader?.map((headerItem, index) => (
                  <th className={`px-16 py-8 text-${headerItem?.position}`} key={index}>
                    {headerItem?.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {children?.length === 0 || children == undefined ? (
                <tr>
                  <td colSpan={tableHeader?.length} className="text-center py-4">
                    <NoData />
                  </td>
                </tr>
              ) : (
                children
              )}
            </tbody>
          </table>
        </div>
      </div>
      {rowsPerPage && <Pagination className="px-12" rowsPerPage={rowsPerPage} totalPost={totalPost} setPage={setPage} page={page} />}
    </div>
  );
};

export default Table;
