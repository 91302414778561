import { useContext, useEffect, useState } from "react";
import { Context } from "../../Context";
import CommonField from "../../Widgets/CommonField";

const Lakehouse = ({ lakehouse, setLakehouse }) => {
  const [optionsData, setOptionsData] = useState();
  const contextValues = useContext(Context);

  useEffect(() => {
    contextValues.GetLakehouse({ limit: -1 });
  }, []);

  useEffect(() => {
    if (contextValues?.lakehouseData?.data !== undefined || null) {
      setOptionsData(
        contextValues?.lakehouseData?.data.map((item) => ({
          value: `${item?.id}`,
          label: `${item?.name}`,
        }))
      );
    }
  }, [contextValues?.lakehouseData]);

  return (
    <div className="w-full">
      <CommonField
        selectfield
        titleText="Select Lakhouse"
        value={lakehouse?.lakehouse}
        options={optionsData}
        error="field"
        onChange={(data) => setLakehouse({ ...lakehouse, lakehouse: data })}
      />
    </div>
  );
};

export default Lakehouse;
