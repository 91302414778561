import { useEffect, useState } from "react";
import Checkbox from "../../Widgets/Checkbox";

import CommonField from "../../Widgets/CommonField";
import Radio from "../../Widgets/Radio";

const PeopleCounter = ({ peopleCounter, setPeopleCounter, handleChange }) => {
  useEffect(() => {
    if (!peopleCounter.type) {
      setPeopleCounter({ type: "overallcount", inputType: "" });
    }
  }, []);

  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const handleMouseClick = (e) => {
    setStartPosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
  };
  const isVerticalChecked = peopleCounter.inputType === "vertical";
  const isHorizontalChecked = peopleCounter.inputType === "horizontal";

  return (
    <div className="grid gap-x-8 w-full h-fit">
      <div className="flex justify-start items-center text-2xs uppercase text-lightGrey mb-2">Type:</div>
      <div onChange={handleChange} className="flex flex-row">
        <Radio name="type" value="overallcount" checked={peopleCounter.type === "overallcount"} buttonName="Overall Count" />
        <Radio name="type" value="entry/exit" checked={peopleCounter.type === "entry/exit"} buttonName="Entry/Exit" className="pl-10" />
      </div>
      {peopleCounter.type === "entry/exit" && (
        <div>
          <div className="flex justify-start items-center text-xs pt-8">Allignment:</div>
          <div className="flex flex-row gap-10">
            <Checkbox name="inputType" value="vertical" checked={peopleCounter.inputType === "vertical"} label="Vertical" onChange={handleChange} />
            <Checkbox
              name="inputType"
              value="horizontal"
              checked={peopleCounter.inputType === "horizontal"}
              label="Horizontal"
              onChange={handleChange}
            />
          </div>
          {isVerticalChecked && (
            <div className="flex flex-row gap-6">
              <span>X:{startPosition.x}</span>
              <span>Y:{startPosition.y}</span>
            </div>
          )}
          {isHorizontalChecked && (
            <div className="flex flex-row gap-6">
              <span>X:{startPosition.x}</span>
              <span>Y:{startPosition.y}</span>
            </div>
          )}
          <div className="m-2 h-fit border-2 border-black-600 w-fit cursor-pointer" style={{ position: "relative" }} onMouseMove={handleMouseClick}>
            <img src="https://pic.onlinewebfonts.com/svg/img_458836.png" alt=" " width="300" height="250" />
            {isVerticalChecked && <div className="bg-red-600 absolute h-2 w-full" style={{ left: "0px", top: `${startPosition.y}px` }} />}
            {isHorizontalChecked && <div className="bg-red-600 absolute h-full w-2" style={{ top: "0px", left: `${startPosition.x}px` }} />}
          </div>
        </div>
      )}
      <div>
        <div className="flex justify-start items-center text-2xs text-lightGrey pt-8 mb-2">RTMP URL:</div>
        <CommonField placeholder="RTMP URL" name="url" value={peopleCounter.url || ""} onChange={handleChange} />
      </div>
    </div>
  );
};

export default PeopleCounter;
