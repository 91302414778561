import { memo, useState } from "react";

const DefaultCard = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className="rounded-md py-8 px-16 flex justify-between items-center shadow-sm border-2 border-l-indigo-600">
      <div className="flex ">
        <div className="px-8 items-center flex justify-center text-white rounded bg-indigo-500 text-sm mr-16 w-fit">D</div>
        <div className="flex-col">
          <div className="font-bold">Default</div>
          <div className="text-darkGrey flex items-center">
            <i className="fa fa-user mr-4" aria-hidden="true" />
            <div className="text-xs">Self</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col relative">
        <i
          className="fa-solid fa-ellipsis items-center justify-end "
          role="presentation"
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
        />
        {openMenu && (
          <div className="rounded-md p-10  flex flex-col absolute right-0 top-10  drop-shadow-lg bg-white">
            <div
              className="flex cursor-pointer items-center mb-10"
              onClick={() => {
                setOpenMenu(false);
              }}
              role="presentation"
            >
              <i className="fa fa-light fa-eye mr-4 " />
              View
            </div>
            <div
              className="flex cursor-pointer items-center"
              onClick={() => {
                setOpenMenu(false);
              }}
              role="presentation"
            >
              <i className="fa fa-light fa-pen mr-4" />
              Edit
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(DefaultCard);
