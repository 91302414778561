import CustomTooltip from "./Tooltip";

const CustomCard = ({ children, tooltip, className }) => {
  return (
    <>
      {tooltip ? (
        <CustomTooltip direction="top" title={tooltip}>
          <div className={`rounded-[4px] border bg-white box-shadow ${className}`}>{children}</div>
        </CustomTooltip>
      ) : (
        <div className={`rounded-[4px] border bg-white box-shadow ${className}`}>{children}</div>
      )}
    </>
  );
};

export default CustomCard;
