import React from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import constants from "./constants";
import "react-toastify/dist/ReactToastify.css";
import { generateQueryString } from "./utils/utils";

const qs = require("qs");

export const Context = React.createContext({});

export const Provider = ({ children }) => {
  const [configCloseId, setConfigCloseId] = React.useState();
  const [userData, setUserData] = React.useState();
  const [teamData, setTeamData] = React.useState();
  const [lakehouseData, setLakehouseData] = React.useState();
  const [FlowData, setFlowData] = React.useState();
  const [postgressData, setPostgressData] = React.useState();
  const [componentData, setComponentData] = React.useState();
  const [configurationData, setConfigurationData] = React.useState();
  const [SchedulerData, setSchedulerData] = React.useState();
  const [configureType, setConfigureType] = React.useState();
  const [namespaceData, setNamespaceData] = React.useState();
  const [ScheduledJobData, setScheduledJobData] = React.useState();
  const [tableData, setTableData] = React.useState();
  const [CPUInfo, setCPUInfo] = React.useState();
  const [uploadFile, setUploadFile] = React.useState();
  const [policyData, setPolicyData] = React.useState();
  const [tablesData, setTablesData] = React.useState();
  const [columnsData, setColumnsData] = React.useState();
  const [lakehouseForm, setLakehouseForm] = React.useState();
  const [activeTab, setActiveTab] = React.useState(0);
  const [userPermission, setUserPermission] = React.useState();
  const [cloudAccountSettings, setCloudAccountSettings] = React.useState();
  const [lakhouseCount, setLakehouseCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [getCatalogs, setGetCatalogs] = React.useState();
  const [editPolicy, setEditPolicy] = React.useState();
  const [getCustomNamespace, setGetCustomNamespace] = React.useState();
  const [graphLakehousesData, setGraphLakehousesData] = React.useState([]);
  const [lakehoseInfo, setLakeHouseInfo] = React.useState();
  const [groupData, setGroupData] = React.useState();
  const [catalogOverviewData, setCatalogOverviewData] = React.useState([]);
  const navigate = useNavigate();

  const checkAdminLoginStatus = () => {
    const admin_token = sessionStorage.getItem("ADMIN_TOKEN");

    if (admin_token && admin_token.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const onRegister = async (values) => {
    try {
      const login_req_obj = {
        method: "POST",
        url: `${constants.url.register}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          username: values.username,
          email: values.email,
          password: values.password,
        },
      };

      const login_response = await axios(login_req_obj);
      if ([200, 201].includes(login_response.status) && login_response.data) {
        toast.success("Register successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        fetchUserData();
      } else {
        toast.error(login_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const deleteUser = async (id) => {
    try {
      const user_req_obj = {
        method: "DELETE",
        url: `${constants.url.users}/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const user_response = await axios(user_req_obj);
      if ([200, 201].includes(user_response.status) && user_response.data) {
        toast.success("User Delete successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        fetchUserData();
      } else {
        toast.error(user_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const putTeam = async (props) => {
    const createData = { name: props.data.name };
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.team}/${props.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Team update successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        GetTeam();
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const deleteTeams = async (id) => {
    try {
      const user_req_obj = {
        method: "DELETE",
        url: `${constants.url.team}/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const user_response = await axios(user_req_obj);
      if ([200, 201].includes(user_response.status) && user_response.data) {
        toast.success("Team Delete successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        GetTeam();
      } else {
        toast.error(user_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const deleteFlow = async (id) => {
    setLoading(true);
    try {
      const user_req_obj = {
        method: "DELETE",
        url: `${constants.url.flow}/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };
      const user_response = await axios(user_req_obj);
      if ([200, 201].includes(user_response.status) && user_response.data) {
        toast.success("Flow Delete successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
        GetFlow({ page: 1, rowsPerPage: 18 });
      } else {
        setLoading(false);
        toast.error(user_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const deleteLakehouse = async (id) => {
    setLoading(true);
    try {
      const user_req_obj = {
        method: "DELETE",
        url: `${constants.url.lakehouses}/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };
      const user_response = await axios(user_req_obj);
      if ([200, 201].includes(user_response.status) && user_response.data) {
        toast.success("Lakehouse Deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
        GetLakehouse({ page: 1, rowsPerPage: 18 });
      } else {
        setLoading(false);

        toast.error(user_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      setLoading(false);

      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const deleteComponent = async (props) => {
    try {
      const login_req_obj = {
        method: "DELETE",
        url: `${constants.url.component}/${props.component_id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };
      const login_response = await axios(login_req_obj);
      if ([200, 201].includes(login_response.status) && login_response.data) {
        GetComponent(props.id);
      } else {
        toast.error(login_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const onLogin = async (values) => {
    try {
      const login_req_obj = {
        method: "POST",
        url: constants.url.login,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          identifier: values.email,
          password: values.password,
        },
      };

      const login_response = await axios(login_req_obj);
      if ([200, 201].includes(login_response.status) && login_response.data) {
        const data = {
          id: login_response.data.user.id,
          username: login_response.data.user.username,
          email: login_response.data.user.email,
        };
        toast.success("Logged in successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        sessionStorage.setItem("ADMIN_TOKEN", login_response.data.jwt);
        sessionStorage.setItem("USER", JSON.stringify(data));
        // fetchRole();
        navigate(constants.route.dashboard);
      } else {
        toast.error(login_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const createTeam = async (props) => {
    const createData = { name: props.name, users: props.id };
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.team}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Team Created successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        GetTeam();
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getVirtualmachineSizes = async (props) => {
    setLoading(!props?.noLoader);
    try {
      const elements_req_obj = {
        method: "GET",
        url: `${constants.url.virtualmachineSizes}/${props?.location}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        setLoading(false);
        return elements_response;
        // toast.success("Team Created successfully", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      } else {
        setLoading(false);
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getTableCount = async () => {
    setLoading(true);
    try {
      const elements_req_obj = {
        method: "GET",
        url: `${constants.url.tableCount}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(elements_req_obj);
      if ([200, 201].includes(response.status) && response.data) {
        setLoading(false);
        return response;
        // toast.success("Team Created successfully", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      } else {
        setLoading(false);
        toast.error(response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const createComponent = async (props) => {
    const createData = {
      name: props.name,
      properties: props.fieldValue,
      egress: props.egress,
      Ingress: props.ingress,
      flow: props.id,
      component_id: props.component_id,
      status: "stop",
    };
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.component}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        GetComponent(props.id);
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const createFlow = async (props) => {
    const createData = {
      name: props.name,
      description: props.description,
      status: props.status,
    };
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.flow}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Flow Created successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        GetFlow();
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const putFlowChart = async (props) => {
    const createData = { chart: props.chart, status: props.status };
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.flow}/${props.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        if (!props?.isNoUpdateChart) {
          GetFlow();
        }
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const putComponent = async (props) => {
    const createData = {
      name: props?.name,
      properties: props?.fieldValue,
      egress: props?.egress,
      Ingress: props?.ingress,
      flow: props?.id,
      status: props?.status,
    };
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.component}/${props.componentId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        GetComponent(props?.id ?? props?.flow);
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const putFlow = async (props) => {
    const createData = { name: props.name };
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.flow}/${props.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Flow update successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        GetFlow();
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const uploadImage = async (props) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append("files", props.file);

      const request_object = {
        method: "POST",
        url: `${constants.url.upload}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: bodyFormData,
      };
      // if (!props.isSubmit) {
      //   try {
      //     const delete_object = {
      //       method: "DELETE",
      //       headers: {
      //         Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
      //       },
      //       url: `${constants.url.upload}/files/${props.componentValue.fieldValue[uploadFile.name].id}`,
      //     };
      //     const files_upload_response = await axios(delete_object);
      //     if ([200, 201].includes(files_upload_response.status)) {
      //       return "deleted";
      //     }
      //   } catch (error) {
      //     toast.error(error, {
      //       position: toast.POSITION.TOP_CENTER,
      //     });
      //   }
      // }
      const files_upload_response = await axios(request_object);
      if ([200, 201].includes(files_upload_response.status) && files_upload_response.data) {
        props.componentValue.fieldValue[uploadFile.name] = {
          id: files_upload_response.data[0].id,
          url: files_upload_response.data[0].url,
          name: files_upload_response.data[0].name,
        };
        if (props.isSubmit) {
          createComponent(props.componentValue);
        } else {
          putComponent(props.componentValue);
        }
        setUploadFile({ ...uploadFile, isupload: false });
      }
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const multiImage = async (props) => {
    try {
      const bodyFormData = new FormData();
      for (const i of props.file) {
        bodyFormData.append(`files`, i);
      }
      const request_object = {
        method: "POST",
        url: `${constants.url.upload}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: bodyFormData,
      };
      const files_upload_response = await axios(request_object);
      if ([200, 201].includes(files_upload_response.status) && files_upload_response.data) {
        const multipleImage = [];
        if (props.componentValue.fieldValue[uploadFile.name]) {
          for (const i of props.componentValue.fieldValue[uploadFile.name]) {
            multipleImage.push(i);
          }
        }
        for (const i of files_upload_response.data) {
          multipleImage.push({
            id: i.id,
            url: i.url,
            name: i.name,
          });
        }
        props.componentValue.fieldValue[uploadFile.name] = multipleImage;
        if (props.isSubmit) {
          createComponent(props.componentValue);
        } else {
          putComponent(props.componentValue);
        }
        setUploadFile({ ...uploadFile, isMultiUpload: false });
      }
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const GetComponent = async (id) => {
    const query = qs.stringify(
      {
        populate: "*",
        pagination: {
          start: 0,
          limit: -1,
        },
        filters: {
          flow: {
            id: {
              $in: id,
            },
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );
    try {
      const request_data = {
        method: "GET",
        url: constants.url.component + `?${query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        for (const data of response.data.data) {
          data.attributes.id = data.id;
        }
        setComponentData(response.data.data);
      } else {
        toast.error(response?.data?.error?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const GetFlow = async (props) => {
    const query = qs.stringify(
      {
        sort: `createdAt:${props?.sort ?? "desc"}`,
        pagination: {
          page: props?.page,
          pageSize: props?.rowsPerPage,
        },
        populate: "*",
        ...(props?.search && {
          filters: {
            $or: [
              {
                name: {
                  $contains: props?.search,
                },
              },
              {
                status: {
                  $contains: props?.search,
                },
              },
            ],
          },
        }),
        ...(props?.flowDate && {
          filters: {
            createdAt: {
              $between: [
                moment(props?.flowDate?.startDate).format("YYYY-MM-DDT00:00:00"),
                moment(props?.flowDate?.endDate).format("YYYY-MM-DDT23:59:59"),
              ],
            },
          },
        }),
      },
      {
        encodeValuesOnly: true,
      }
    );
    try {
      const request_data = {
        method: "GET",
        url: constants.url.flow + `?${query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        for (const data of response.data.data) {
          data.attributes.id = data.id;
        }
        setFlowData(response?.data);
      } else {
        toast.error(response?.data?.error?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const GetTeam = async () => {
    const USER = window.sessionStorage.getItem("USER");
    const ParseUser = JSON.parse(USER);
    const query = qs.stringify(
      {
        populate: "*",
        filters: {
          users: {
            id: {
              $in: ParseUser.id,
            },
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );
    try {
      const request_data = {
        method: "GET",
        url: constants.url.team + `?${query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        for (const data of response.data.data) {
          data.attributes.id = data.id;
        }
        setTeamData(response.data.data);
      } else {
        toast.error(response?.data?.error?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.users,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setUserData(response.data);
        setLoading(false);
      } else {
        toast.error(request_data.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const putUserData = async (props) => {
    const createData = {
      username: props.data.username,
      email: props.data.email,
    };
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.users}/${props.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify(createData),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("User update successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        fetchUserData();
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const createPostgres = async (props) => {
    const createData = {
      name: props.name,
      properties: props.data,
      config_name: props.data.config_name,
      users_permissions_user: props.users_permissions_user,
    };
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.configuration}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };
      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Postgress Created successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getPostgres();
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getPostgres = async () => {
    setLoading(true);
    const USER = window.sessionStorage.getItem("USER");
    const ParseUser = JSON.parse(USER);
    const query = qs.stringify(
      {
        populate: "*",
        filters: {
          users_permissions_user: {
            id: {
              $in: ParseUser.id,
            },
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );
    try {
      const request_data = {
        method: "GET",
        url: constants.url.configuration + `?${query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setPostgressData(response.data);
        setLoading(false);
      } else {
        toast.error(request_data.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const putPostgres = async (props) => {
    const createData = {
      name: props.name,
      properties: props.data,
    };
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.configuration}/${props.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Postgres update successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getPostgres();
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const deletePostgres = async (id) => {
    try {
      const user_req_obj = {
        method: "DELETE",
        url: `${constants.url.configuration}/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };
      const user_response = await axios(user_req_obj);
      if ([200, 201].includes(user_response.status) && user_response.data) {
        toast.success("Postgres Delete successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getPostgres();
      } else {
        toast.error(user_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const createGroup = async (props) => {
    const createData = {
      groupname: props.groupname,
      description: props.description,
      user_permissions: props?.user_permission?.map((item) => item?.value),
      status: props.status,
    };
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.groups}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };
      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Group Created successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getGroup();
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error("Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getGroup = async (props) => {
    const query = qs.stringify(
      {
        ...(props?.limit
          ? {
              pagination: {
                start: 0,
                limit: props?.limit,
              },
            }
          : {
              pagination: {
                page: props?.page,
                pageSize: props?.rowsPerPage,
              },
            }),
        populate: "*",
        filters: {
          ...(props?.status && {
            status: {
              $eq: props?.status,
            },
          }),
          ...(props?.search && {
            $or: [
              {
                name: {
                  $contains: props?.search,
                },
              },
              {
                description: {
                  $contains: props?.search,
                },
              },
              {
                status: {
                  $contains: props?.search,
                },
              },
            ],
          }),
        },
      },
      {
        populate: "*",
      },
      {
        filters: {},
      },
      {
        encodeValuesOnly: true,
      }
    );
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.groups + `?${query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setGroupData({
          data: response?.data?.data.map((e) => ({
            id: e?.id,
            ...e?.attributes,
          })),
          meta: response?.data?.meta?.pagination,
        });
        setLoading(false);
      } else {
        toast.error(request_data.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const putGroup = async (props) => {
    const editData = {
      groupname: props.groupname,
      description: props.description,
      status: props.status,
      user_permissions: props?.user_permissions,
    };
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.groups}/${props.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: editData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Group update successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getGroup();
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error("Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const deleteGroup = async (id) => {
    try {
      const user_req_obj = {
        method: "DELETE",
        url: `${constants.url.groups}/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };
      const user_response = await axios(user_req_obj);
      if ([200, 201].includes(user_response.status) && user_response.data) {
        toast.success("Group Delete successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getGroup();
      } else {
        toast.error(user_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error("Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const GetLakehouse = async (props) => {
    const query = qs.stringify(
      {
        sort: `createdAt:${props?.sort ?? "desc"}`,
        // populate: "*",

        populate: {
          configure: "*",
          spark_cluster: {
            populate: "*",
          },
        },

        pagination: {
          ...(props?.page && {
            page: props?.page ?? 1,
            pageSize: props?.rowsPerPage ?? 18,
          }),
          ...(props?.limit && {
            start: 0,
            limit: props?.limit,
          }),
        },
        filters: {
          ...(props?.status && {
            spark_cluster: {
              master_node: {
                status: {
                  $eq: props?.status,
                },
              },
            },
          }),
          ...(props?.search && {
            $or: [
              {
                name: {
                  $contains: props?.search,
                },
              },
              {
                description: {
                  $contains: props?.search,
                },
              },
              {
                status: {
                  $contains: props?.search,
                },
              },
            ],
          }),
          ...(props?.lakehouseDate && {
            createdAt: {
              $between: [
                moment(props?.lakehouseDate?.startDate).format("YYYY-MM-DDT00:00:00"),
                moment(props?.lakehouseDate?.endDate).format("YYYY-MM-DDT23:59:59"),
              ],
            },
          }),
        },
      },

      {
        encodeValuesOnly: true,
      }
    );
    setLoading(!props?.noLoader);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.lakehouses + `?${query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        if (props?.sort == "asc") {
          setGraphLakehousesData({
            data: response?.data?.data.map((e) => ({
              id: e?.id,
              ...e?.attributes,
            })),
            meta: response?.data?.meta?.pagination,
          });
        } else {
          setLakehouseData({
            data: response?.data?.data.map((e) => ({
              id: e?.id,
              ...e?.attributes,
            })),
            meta: response?.data?.meta?.pagination,
          });
        }
        setLoading(false);
      } else {
        toast.error(response?.data?.error?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const findLakehouse = async (props) => {
    const query = qs.stringify(
      {
        populate: {
          spark_cluster: {
            populate: "*",
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );
    setLoading(!props?.noLoader);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.lakehouses + `/${props?.id}?${query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setLakeHouseInfo({
          id: response?.data?.data?.id,
          ...response?.data?.data?.attributes,
        });
        setLoading(false);
      } else {
        toast.error(response?.data?.error?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      if (props?.navigate) {
        navigate(constants.route.lakehouse);
      }
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const createLakehouse = async (props) => {
    setLoading(true);
    // const createData = {
    //   name: props.name,
    //   status: props.status,
    //   description: props.description,
    //   source: props.source.value,
    //   path: props.path,
    //   configure: props.configure.value,
    //   executors: props.executors.value,
    //   shared_executor: props.shared_executor.value,
    //   executor: props.executor,
    //   master_node: props.master_node.value,
    //   worker_node: props.worker_node.value,
    //   min_executor: props.min_executor,
    //   max_executor: props.max_executor,
    // };
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.lakehouses}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: props?.propsData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response?.status) && elements_response?.data) {
        toast.success("Lakehouse Created successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        // navigate(`${constants.route.lakehouseProperties}/${elements_response?.data?.data?.id}`);
        props?.setOpenSidebar(false);
        props?.setLakehouseData({});
        setLoading(false);
        GetLakehouse({ page: 1, rowsPerPage: 18 });
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
      setLoading(false);
    }
  };

  const updateLakehouse = async (props) => {
    setLoading(true);
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.lakehouses}/${props.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: props?.propsData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response?.status) && elements_response?.data) {
        toast.success("Lakehouse Updated successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        // navigate(`${constants.route.lakehouseProperties}/${elements_response?.data?.data?.id}`);
        props?.setOpenSidebar(false);
        props?.setLakehouseData({});
        setLoading(false);
        GetLakehouse({ page: 1, rowsPerPage: 18 });
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
      setLoading(false);
    }
  };

  const getConfigurations = async (props) => {
    const query = qs.stringify(
      {
        pagination: {
          page: props?.page,
          pageSize: props?.rowsPerPage,
        },
        filters: {
          ...(props?.type && {
            type: {
              $eq: props?.type,
            },
          }),
        },
      },
      {
        populate: "*",
      },
      {
        encodeValuesOnly: true,
      }
    );
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.configuration + `?${query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setConfigurationData({
          data: response?.data?.data.map((e) => ({
            id: e?.id,
            ...e?.attributes,
          })),
          meta: response?.data?.meta?.pagination,
        });
        setLoading(false);
      } else {
        toast.error(request_data.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const createConfiguration = async (props) => {
    setLoading(true);
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.configuration}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: props }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Configuration Created successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getConfigurations({ type: props?.type });
        setLoading(false);
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const updateWorkermanagement = async (props) => {
    setLoading(true);
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.workermanagement}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify(props),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status)) {
        toast.success(`Worker Management ${props?.status} successfully`, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const putConfiguration = async (props) => {
    setLoading(true);
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.configuration}/${props.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: props.data }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Configuration update successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getConfigurations({ type: props?.type });
        setLoading(false);
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const deleteConfiguration = async (props) => {
    try {
      const user_req_obj = {
        method: "DELETE",
        url: `${constants.url.configuration}/${props?.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };
      const user_response = await axios(user_req_obj);
      if ([200, 201].includes(user_response.status) && user_response.data) {
        toast.success("Flow Delete successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getConfigurations({ type: props?.type });
      } else {
        toast.error(user_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getScheduler = async () => {
    const query = qs.stringify(
      {
        populate: "*",
      },
      {
        encodeValuesOnly: true,
      }
    );
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.scheduler + `?${query}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setSchedulerData(
          response?.data?.data.map((e) => ({
            id: e?.id,
            ...e?.attributes,
          }))
        );
        setLoading(false);
      } else {
        toast.error(request_data.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const createSchedulers = async (props) => {
    setLoading(true);
    const createData = {
      lakehouse: props.lakehouse.value,
      // time: props.time,
      // time_format: props.time_format.value,
    };
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.scheduler}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ data: createData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Schedulers Created successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getScheduler();
        setLoading(false);
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const putScheduler = async (props) => {
    setLoading(true);
    const createData = {
      lakehouse: props.lakehouse.value,
      // time: props.time,
      // time_format: props.time_format.value,
    };
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.scheduler}/${props.id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ data: createData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Configuration update successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getScheduler();
        setLoading(false);
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const getLakehouseCount = async () => {
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.count + constants.route.lakehouse,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response?.status)) {
        setLakehouseCount(response?.data);
        setLoading(false);
      } else {
        toast.error(response?.data?.error?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const runFlow = async (props) => {
    const createData = {
      uuid: props.uuid,
      component_id: props.component_id,
      flow_id: props.flow_id,
      action: props.action,
    };
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.flowExecution}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };
      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        if (props.flow_id && props.action == "stop") {
          putFlowChart({ id: props.flow_id, status: "stopped" });
        }
      }
    } catch (error) {
      if (props.flow_id && props.action == "stop") {
        putFlowChart({ flow_id: props.flow_id, status: "stopped" });
      }
    }
  };

  const getNamespaces = async () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${constants.host}/namespaces`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
      },
    };
    axios(config)
      .then((response) => {
        setNamespaceData(response?.data?.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getTables = async (props) => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${constants.host}/namespaces/${props}/tables`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
      },
    };
    axios(config)
      .then((response) => {
        setTableData(response?.data?.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getAllCPUInfo = async () => {
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.getCPUInfo,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setCPUInfo(response.data.data);
        setLoading(false);
      } else {
        toast.error(response?.data?.error?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const GetScheduledJobs = async (props) => {
    const query = qs.stringify(
      {
        sort: `createdAt:${props?.sort ?? "desc"}`,
        pagination: {
          page: props?.page,
          pageSize: props?.rowsPerPage,
        },
        ...(props?.search && {
          filters: {
            name: {
              $contains: props?.search,
            },
          },
        }),
        ...(props?.scheduleJobDate && {
          filters: {
            createdAt: {
              $between: [
                moment(props?.scheduleJobDate?.startDate).format("YYYY-MM-DDT00:00:00"),
                moment(props?.scheduleJobDate?.endDate).format("YYYY-MM-DDT23:59:59"),
              ],
            },
          },
        }),
      },
      {
        populate: "*",
      },
      {
        encodeValuesOnly: true,
      }
    );
    try {
      const request_data = {
        method: "GET",
        url: constants.url.scheduledJobs + `?${query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        for (const data of response.data.data) {
          data.attributes.id = data.id;
        }
        setScheduledJobData({
          data: response?.data?.data.map((e) => ({
            id: e?.id,
            ...e?.attributes,
          })),
          meta: response?.data?.meta?.pagination,
        });
      } else {
        toast.error(response?.data?.error?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const createScheduledJob = async (props) => {
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.scheduledJobs}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: props }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Create job successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        GetScheduledJobs();
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const deleteScheduledJob = async (id) => {
    try {
      const user_req_obj = {
        method: "DELETE",
        url: `${constants.url.scheduledJobs}/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };
      const user_response = await axios(user_req_obj);
      if ([200, 201].includes(user_response.status) && user_response.data) {
        toast.success("Scheduled job delete successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        GetScheduledJobs();
      } else {
        toast.error(user_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const putSchedulerJobs = async (props) => {
    setLoading(true);
    const createData = {
      name: props.name,
      expression: props.expression,
      status: props.status,
    };
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.scheduledJobs}/${props.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Scheduler job updated successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        GetScheduledJobs();
        setLoading(false);
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const getUserPermission = async (props) => {
    const query = qs.stringify(
      {
        sort: "createdAt:desc",
        ...(props?.limit
          ? {
              pagination: {
                start: 0,
                limit: props?.limit,
              },
            }
          : {
              pagination: {
                page: props?.page,
                pageSize: props?.rowsPerPage,
              },
            }),
        ...(props?.search && {
          filters: {
            email: {
              $contains: props?.search,
            },
          },
        }),
        ...(props?.email && {
          filters: {
            email: {
              $eqi: props?.email,
            },
          },
        }),
      },
      {
        populate: "*",
      },
      {
        encodeValuesOnly: true,
      }
    );
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.user_permission + `?${query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN") ?? props.TOKEN}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setUserPermission({
          data: response?.data?.data.map((e) => ({
            id: e?.id,
            ...e?.attributes,
          })),
          meta: response?.data?.meta?.pagination,
        });
        setLoading(false);
        return response?.data?.data.map((e) => ({
          id: e?.id,
          ...e?.attributes,
        }));
      } else {
        toast.error(request_data.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const createUserPermission = async (props) => {
    setLoading(true);
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.user_permission}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: props }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Add User successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getUserPermission();
        setLoading(false);
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const putUserPermission = async (props) => {
    setLoading(true);
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.user_permission}/${props.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: props.data }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("User info update successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getUserPermission();
        setLoading(false);
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const deleteUserPermission = async (id) => {
    try {
      const user_req_obj = {
        method: "DELETE",
        url: `${constants.url.user_permission}/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };
      const user_response = await axios(user_req_obj);
      if ([200, 201].includes(user_response.status) && user_response.data) {
        toast.success("User delete successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getUserPermission();
      } else {
        toast.error(user_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getAllCatalogs = async () => {
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: constants?.url?.getCatalogs,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setGetCatalogs(response?.data?.data);
        setLoading(false);
        return response?.data?.data;
      } else {
        toast.error(request_data.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const customNamespace = async (props) => {
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.getCustomNamespace + generateQueryString(props),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setGetCustomNamespace(response?.data?.data);
        setLoading(false);
        return response?.data?.data;
      } else {
        toast.error(request_data.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const GetTables = async (props) => {
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.tables + generateQueryString(props),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setTablesData(response.data.data);
        setLoading(false);
        return response.data.data;
      } else {
        toast.error(response?.data?.error?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const GetColumns = async (id) => {
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: `${constants.url.columns}/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setColumnsData(response.data.data);
        setLoading(false);
        return response.data.data;
      } else {
        toast.error(response?.data?.error?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getConfigureType = async (props) => {
    const query = qs.stringify(
      {
        sort: "configure_label:asc",
        pagination: {
          page: props?.page,
          pageSize: props?.rowsPerPage,
        },
        ...(props?.search && {
          filters: {
            $or: [
              {
                configure_label: {
                  $contains: props?.search,
                },
              },
              {
                configure_value: {
                  $contains: props?.search,
                },
              },
            ],
          },
        }),
      },
      {
        encodeValuesOnly: true,
      }
    );
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.settingConfigurations + `?${query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setConfigureType({
          data: response?.data?.data.map((e) => ({
            id: e?.id,
            ...e?.attributes,
          })),
          meta: response?.data?.meta?.pagination,
        });
        setLoading(false);
      } else {
        toast.error(request_data.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const GetLakehouseForm = async () => {
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: constants.url.lakehouseForm,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setLakehouseForm(response.data.data);
        setLoading(false);
      } else {
        toast.error(response?.data?.error?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getCloudAccountSettings = async (props) => {
    const query = qs.stringify(
      {
        populate: "*",
        pagination: {
          start: 0,
          limit: -1,
        },
        filters: {
          provider_type: {
            $eq: props?.name,
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: props?.name ? constants.url.cloudAccountSettings + `?${query}` : constants.url.cloudAccountSettings,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setCloudAccountSettings(
          response?.data?.data.map((e) => ({
            id: e?.id,
            ...e?.attributes,
          }))
        );
        setLoading(false);
      } else {
        toast.error(request_data.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const createCloudAccountSettings = async (data) => {
    const createData = {
      provider_type: data.provider_type.value,
      settings: {
        tenant_id: data.tenant_id,
        client_id: data.client_id,
        client_secret: data.client_secret,
        subscription_id: data.subscription_id,
        resource_group_name: data.resource_group_name,
      },
    };
    setLoading(true);
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.cloudAccountSettings}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };
      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Cloud configuration create successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
        getCloudAccountSettings();
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const updateCloudAccountSettings = async ({ data, id }) => {
    const createData = {
      provider_type: data.provider_type.value,
      settings: {
        tenant_id: data.tenant_id,
        client_id: data.client_id,
        client_secret: data.client_secret,
        subscription_id: data.subscription_id,
        resource_group_name: data.resource_group_name,
        master_gallery_name: data.resource_group_name,
        master_image_definition: data.master_image_definition,
        master_image_version: data.master_image_version,
        worker_gallery_name: data.worker_gallery_name,
        worker_image_definition: data.worker_image_definition,
        worker_image_version: data.worker_image_version,
        master_service_port: data.master_service_port,
        vm_password: data.vm_password,
      },
    };
    setLoading(true);
    try {
      const elements_req_obj = {
        method: "PUT",
        url: constants.url.cloudAccountSettings + `/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Cloud configuration update successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        getCloudAccountSettings();
        setLoading(false);
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const createPolicy = async (props) => {
    setLoading(true);
    const createData = {
      name: props.name,
      description: props.description,
      status: props.status,
      type: props.type,
      auditlogging: props.auditlogging,
      allow_condition: props.allow_condition,
      deny_condition: props.deny_condition,
    };
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.policies}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: createData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success(" Created successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        GetPolicies();
        setLoading(false);
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const GetPolicies = async (props) => {
    const query = qs.stringify(
      {
        pagination: {
          page: props?.page,
          pageSize: props?.rowsPerPage,
        },
        filters: {
          ...(props?.activeTab && {
            type: {
              $eq: props?.activeTab,
            },
          }),
        },
      },
      {
        populate: "*",
      },
      {
        encodeValuesOnly: true,
      }
    );
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: `${constants.url.policies}?${query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };
      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setPolicyData({
          data: response?.data?.data.map((e) => ({
            id: e?.id,
            ...e?.attributes,
          })),
          meta: response?.data?.meta?.pagination,
        });
        setLoading(false);
      } else {
        toast.error(response?.data?.error?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const getEditPolicy = async (id) => {
    setLoading(true);
    try {
      const request_data = {
        method: "GET",
        url: `${constants.url.policies}/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(request_data);
      if ([200, 201].includes(response.status) && response.data) {
        setEditPolicy(response?.data?.data);
        setLoading(false);
      } else {
        toast.error(request_data.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const deletePolicy = async (id) => {
    try {
      const user_req_obj = {
        method: "DELETE",
        url: `${constants.url.policies}/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };
      const user_response = await axios(user_req_obj);
      if ([200, 201].includes(user_response.status) && user_response.data) {
        toast.success(" Delete successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        GetPolicies();
      } else {
        toast.error(user_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const putPolicy = async (props) => {
    const putData = {
      name: props.name,
      description: props.description,
      type: props.type,
      auditlogging: props.auditlogging,
      allow_condition: props.allow_condition,
      deny_condition: props.deny_condition,
    };
    setLoading(true);
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.policies}/${props.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: putData }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        toast.success("Policy update successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        GetPolicies();
        setLoading(false);
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const getLogs = async (logId) => {
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.logs}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ id: logId }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        if (elements_response?.data?.name == "Error") {
          toast.error(elements_response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.success("successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          return elements_response?.data;
        }
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const uploadData = async (props) => {
    setLoading(true);
    try {
      const bodyFormData = new FormData();
      bodyFormData.append("files", props[0]);

      const request_object = {
        method: "POST",
        url: `${constants.url.upload}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: bodyFormData,
      };
      const files_upload_response = await axios(request_object);
      setLoading(false);
      if ([200, 201].includes(files_upload_response.status) && files_upload_response.data) {
        return files_upload_response;
      }
    } catch (error) {
      setLoading(false);
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getCatalogLineage = async (props) => {
    setLoading(true);
    try {
      const elements_req_obj = {
        method: "GET",
        url: props ? `${constants.url.getCatalogLineage}/${props}` : constants.url.getCatalogLineage,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        setLoading(false);
        return elements_response;
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getCatalogsData = async (props) => {
    setLoading(true);
    try {
      const elements_req_obj = {
        method: "GET",
        url: `${constants.url.catalogs + generateQueryString(props)}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
      };

      const response = await axios(elements_req_obj);
      if ([200, 201].includes(response.status) && response.data) {
        setLoading(false);
        return response;
      } else {
        setLoading(false);
        toast.error(response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const createLineage = async (props) => {
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.lineage}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: props }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        return elements_response;
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const updateCanvasEdgeDeletion = async (props) => {
    try {
      const elements_req_obj = {
        method: "POST",
        url: `${constants.url.canvasEdgeDeletion}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify(props),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        return elements_response;
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const updateLineage = async (props) => {
    try {
      const elements_req_obj = {
        method: "PUT",
        url: `${constants.url.lineage}/${props.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: props?.data }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        return elements_response;
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getLineage = async (props) => {
    const query = qs.stringify(
      {
        pagination: {
          start: 0,
          limit: -1,
        },
        filters: {
          $or: [
            {
              source_id: {
                $contains: props?.id,
              },
            },
            {
              destination_id: {
                $contains: props?.id,
              },
            },
          ],
        },
      },
      {
        encodeValuesOnly: true,
      }
    );
    try {
      const elements_req_obj = {
        method: "GET",
        url: `${constants.url.lineage}?${query}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("ADMIN_TOKEN")}`,
        },
        data: JSON.stringify({ data: props?.data }),
      };

      const elements_response = await axios(elements_req_obj);
      if ([200, 201].includes(elements_response.status) && elements_response.data) {
        return elements_response;
      } else {
        toast.error(elements_response.data.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? "Something wrong!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <Context.Provider
      value={{
        policyData,
        createPolicy,
        GetPolicies,
        deletePolicy,
        putPolicy,
        setConfigCloseId,
        configCloseId,
        onLogin,
        createTeam,
        fetchUserData,
        GetTeam,
        createFlow,
        GetFlow,
        createComponent,
        putComponent,
        uploadImage,
        setUploadFile,
        uploadFile,
        teamData,
        FlowData,
        userData,
        putFlowChart,
        putFlow,
        GetComponent,
        componentData,
        loading,
        onRegister,
        deleteUser,
        deleteTeams,
        deleteFlow,
        deleteLakehouse,
        putUserData,
        putPostgres,
        multiImage,
        deleteComponent,
        putTeam,
        createPostgres,
        getPostgres,
        postgressData,
        checkAdminLoginStatus,
        deletePostgres,
        createLakehouse,
        createGroup,
        getGroup,
        groupData,
        putGroup,
        deleteGroup,
        GetLakehouse,
        lakehouseData,
        getConfigurations,
        configurationData,
        createConfiguration,
        putConfiguration,
        getScheduler,
        createSchedulers,
        SchedulerData,
        putScheduler,
        getLakehouseCount,
        lakhouseCount,
        runFlow,
        getNamespaces,
        namespaceData,
        getTables,
        tableData,
        // tableMetaData,
        // getTableMetaData,
        getAllCPUInfo,
        CPUInfo,
        deleteConfiguration,
        GetScheduledJobs,
        ScheduledJobData,
        deleteScheduledJob,
        putSchedulerJobs,
        createScheduledJob,
        setLoading,
        getUserPermission,
        userPermission,
        createUserPermission,
        putUserPermission,
        getAllCatalogs,
        getCatalogs,
        customNamespace,
        getCustomNamespace,
        GetTables,
        tablesData,
        GetColumns,
        columnsData,
        deleteUserPermission,
        getConfigureType,
        configureType,
        graphLakehousesData,
        GetLakehouseForm,
        lakehouseForm,
        activeTab,
        setActiveTab,
        updateCloudAccountSettings,
        createCloudAccountSettings,
        getCloudAccountSettings,
        cloudAccountSettings,
        findLakehouse,
        lakehoseInfo,
        updateLakehouse,
        updateWorkermanagement,
        getEditPolicy,
        setPolicyData,
        editPolicy,
        getLogs,
        getVirtualmachineSizes,
        uploadData,
        getTableCount,
        getCatalogLineage,
        getCatalogsData,
        catalogOverviewData,
        setCatalogOverviewData,
        createLineage,
        updateLineage,
        getLineage,
        updateCanvasEdgeDeletion,
      }}
    >
      {children}
      <ToastContainer autoClose={1000} />
    </Context.Provider>
  );
};

export default Context;
