import { memo } from "react";

const SpeedDial = ({ open, setOpen, children }) => {
  return (
    <div>
      {open && <div className="w-full h-full absolute top-0 left-0" role="presentation" onClick={() => setOpen(false)} />}
      <div className="absolute flex space-x-6 right-10 bottom-10">
        {open && <div className="flex space-x-6">{children}</div>}
        <i
          role="presentation"
          onClick={() => setOpen(!open)}
          className="fa-solid fa-plus text-md text-white bg-primaryColor h-28 w-28 rounded-full flex items-center justify-center cursor-pointer"
        />
      </div>
    </div>
  );
};

export default memo(SpeedDial);
