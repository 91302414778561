import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-github";

const CodeEditer = ({ titleText, height, className, placeholder, ...fields }) => {
  return (
    <div className={`h-fit w-full ${className}`}>
      {titleText && (
        <div className="flex justify-start items-center text-2xs text-lightGrey uppercase">
          {titleText}
          <span className="text-red-900 text-xs">*</span>
        </div>
      )}
      <AceEditor
        placeholder={placeholder ?? "Write your json"}
        mode="mysql"
        theme="github"
        fontSize={14}
        width="100%"
        height={height ?? "150px"}
        showPrintMargin
        showGutter
        {...fields}
        highlightActiveLine
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
    </div>
  );
};

export default CodeEditer;
