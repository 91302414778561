import { memo } from "react";

import CommonField from "../../Widgets/CommonField";
import KeyValueField from "../../Widgets/KeyValueField";
import CodeEditer from "../../Widgets/CodeEditer";

function HttpClient({ httpClient, setHttpClient, handleChange }) {
  const handleQueryChange = (e) => {
    setHttpClient({ ...httpClient, body: e });
  };

  return (
    <div className="grid gap-8 w-full h-fit">
      <CommonField titleText="URL" name="url" value={httpClient.url || ""} onChange={handleChange} />
      <KeyValueField titleText="Headers" name="headers" fieldValue={httpClient} setFieldValue={setHttpClient} />
      <CodeEditer name="body" titleText="Body" value={httpClient.body} onChange={handleQueryChange} />
    </div>
  );
}

export default memo(HttpClient);
