import { memo, useContext, useState } from "react";
import { Dropdown } from "antd";
import { useParams } from "react-router-dom";
import { Context } from "../Context";
// import Status from "./Status";
import { transformString } from "../utils/utils";
import CustomTooltip from "./Tooltip";

const DraggedCard = ({
  setIsConfigure,
  node,
  setConfigureData,
  chart,
  setChart,
  setMouseUp,
  mouseup,
  cardData,
  tableData,
  setShowPopup,
  setLogs,
}) => {
  const contextValues = useContext(Context);
  const [status, setStatus] = useState("Start");
  // const [cardData, setCardData] = useState();
  // const [tableData, setTableData] = useState([]);
  const params = useParams();
  const handleClick = (e) => {
    setMouseUp(mouseup == null ? true : !mouseup);
    if (e?.detail == 2) {
      setIsConfigure(true);
      setConfigureData(node?.id);
    }
  };

  const getLogs = async (id) => {
    const res = await contextValues.getLogs(id);
    if (res?.data?.logs != null) {
      setLogs(res?.data?.logs);
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  };

  const customNode = async (props) => {
    if (contextValues.configCloseId == props?.id) {
      setIsConfigure(false);
    }
    const updatechart = chart;
    const nodes = {};
    for (const k of Object.keys(chart.nodes)) {
      for (const l of Object.keys(chart.links)) {
        if (chart.links[l].to.nodeId === props?.id || chart.links[l].from.nodeId === props?.id) {
          if (props?.type == "delete") {
            delete chart.links[l];
          }
        }
      }
      if (k === props?.id) {
        for (const i of contextValues.componentData) {
          if (chart.nodes[k]?.id == i?.attributes?.component_id) {
            if (props?.type == "delete") {
              contextValues.deleteComponent({
                component_id: i?.attributes?.id,
                id: params?.id,
              });
            } else if (props?.type == "update") {
              if (status === "Stop") {
                contextValues.putComponent({
                  componentId: i?.attributes?.id,
                  status: "pause",
                  id: params?.id,
                });
                contextValues.runFlow({
                  component_id: i?.attributes?.id,
                  uuid: i?.attributes?.component_id,
                  action: "stop",
                });
              } else {
                contextValues.putComponent({
                  componentId: i?.attributes?.id,
                  status: "start",
                  id: params?.id,
                });
                contextValues.runFlow({
                  component_id: i?.attributes?.id,
                  uuid: i?.attributes?.component_id,
                  action: "start",
                });
              }
            }
          }
        }
        if (props?.type == "delete") {
          delete chart.nodes[k];
        }
      } else {
        nodes[k] = chart.nodes[k];
      }
    }
    setChart(updatechart);
    contextValues.putFlowChart({ chart, id: params.id, isNoUpdateChart: true });
    if (props?.type == "logs") {
      await getLogs(props?.id);
    }
  };

  const items = [
    {
      label: (
        <a className="w-full text-xs font-normal" role="presentation" onClick={() => customNode({ id: node?.id, type: "update" })}>
          {status}
        </a>
      ),
      key: "run",
      icon: <i className={`${status == "Start" ? "fa-solid fa-play" : "fa-solid fa-pause"} text-xs`} />,
    },
    {
      label: (
        <a className="w-full text-xs font-normal" role="presentation" onClick={() => customNode({ id: node?.id, type: "logs" })}>
          Logs
        </a>
      ),
      key: "logs",
      icon: <i className="fa-regular fa-file-lines text-xs font-normal" />,
    },
    {
      label: (
        <a className="w-full text-xs font-normal" role="presentation" onClick={() => customNode({ id: node?.id, type: "delete" })}>
          Delete
        </a>
      ),
      key: "delete",
      icon: <i className="fa-solid fa-trash-can text-xs font-normal" />,
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      trigger={["contextMenu"]}
      onOpenChange={() => {
        if (node?.id) {
          for (const k of Object.keys(chart.nodes)) {
            if (k === node?.id) {
              for (const i of contextValues?.componentData) {
                if (chart.nodes[k]?.id == i?.attributes?.component_id) {
                  if (i?.attributes?.status == "pause" || i?.attributes?.status == "stop") {
                    setStatus("Start");
                  } else {
                    setStatus("Stop");
                  }
                }
              }
            }
          }
        }
      }}
    >
      <div
        className="flex flex-col"
        role="presentation"
        // onMouseUp={() => {
        //   setMouseUp(mouseup == null ? true : !mouseup);
        // }}
        // onMouseDown={(e) => handleClick(e)}
        onMouseDown={(e) => handleClick(e)}
      >
        <div className="flex justify-between p-6">
          <div className="flex">
            <img src={node.properties.icon} draggable="false" alt="" width="25" />
            <div className="flex items-center text-ellipsis font-normal w-120 overflow-hidden truncate px-4">{node.properties.name}</div>
          </div>
          <div className="flex w-48">
            <div className="flex mr-4 justify-center items-center ">
              <CustomTooltip direction="top" title={cardData?.attributes?.status}>
                <i
                  className={`${
                    cardData?.attributes?.status == "stop"
                      ? "fa-solid fa-stop"
                      : cardData?.attributes?.status == "pause"
                      ? "fa-solid fa-pause"
                      : "fa-solid fa-play"
                  } mx-2 ${
                    cardData?.attributes?.status == "stop"
                      ? "text-red-500"
                      : cardData?.attributes?.status == "start"
                      ? "text-green-500"
                      : "text-gray-500"
                  } text-sm mx-2 cursor-pointer`}
                />
              </CustomTooltip>
            </div>
            <div className="flex mr-4 justify-center  items-center ">
              <CustomTooltip direction="top" title="Messages/Seconds: 0">
                0/ms
                {/* <i className="fa-solid fa-circle-info mx-2 text-lightGrey text-sm cursor-pointer" /> */}
              </CustomTooltip>
              {/* <div className="text-sm text-gray-500">0</div> */}
            </div>
            <div className="flex justify-center  items-center ">
              <CustomTooltip direction="top" title="Failed Count: 1">
                1{/* <i className="fa-solid fa-triangle-exclamation mx-2 text-lightGrey text-sm cursor-pointer" /> */}
              </CustomTooltip>
              {/* <div className="text-sm text-gray-500">0</div> */}
            </div>
          </div>
          {/* <Status title={cardData?.attributes?.status} /> */}
        </div>
        <div className="border-t border-gray-300" />
        <div className="w-full">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              {tableData.map((item, index) => (
                <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}>
                  <td className={`px-6 ${tableData.length - 1 == index && "pb-6"} whitespace-nowrap text-10px font-medium text-gray-900`}>
                    {transformString(item.k)}
                  </td>
                  <td className={`px-6 ${tableData.length - 1 == index && "pb-6"} whitespace-nowrap text-10px text-gray-500`}>
                    {item?.value?.label ?? item?.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Dropdown>
  );
};

export default memo(DraggedCard);
