import { useState, useEffect } from "react";
import CodeEditer from "../../Widgets/CodeEditer";
import CommonField from "../../Widgets/CommonField";
import constants from "../../constants";
// import SelectField from "../../Widgets/SelectField";
// import Input from "../../Widgets/Input";

function Transformation({ transformation, setTransformation }) {
  const [language, setLanguage] = useState({ value: "go_lang", label: "Go Lang" });
  // const [inputFields, setInputFields] = useState({});
  // const [windows, setWindows] = useState({});
  // const Data = {
  //   data1: { key1: "value", key2: "value", id: 1 },
  //   data2: { key3: "value", id: 2 },
  // };
  // const [result, setResult] = useState({});
  // React.useEffect(() => {
  //   let pushData = result;
  //   for (const x in Data) {
  //     // eslint-disable-next-line no-prototype-builtins
  //     if (Data.hasOwnProperty(x)) {
  //       pushData = { ...pushData, ...Data[x] };
  //     }
  //   }
  //   setResult(pushData);
  // }, []);

  // React.useEffect(() => {
  //   setTransformation({ ...transformation, join_condition: inputFields });
  // }, [inputFields]);

  // React.useEffect(() => {
  //   setTransformation({ ...transformation, windows });
  // }, [windows]);

  // const optionsData = [
  //   { value: "grater", label: "Grater Than" },
  //   { value: "less", label: "Less Then" },
  //   { value: "equal", label: "Equal To" },
  // ];

  // const frequencyData = [
  //   { value: "hour", label: "Hour" },
  //   { value: "minutes", label: "Minutes" },
  //   { value: "days", label: "Days" },
  // ];

  // const handleFormChange = (event, isselect, name) => {
  //   setInputFields({
  //     ...inputFields,
  //     [isselect ? name : event.target.name]: isselect ? event : event.target.value,
  //   });
  // };

  useEffect(() => {
    if (transformation?.language) {
      const filteredType = constants.components.transformationLanguages.filter((item) => item.value === transformation?.language);
      setLanguage(filteredType);
    } else {
      setTransformation({
        ...transformation,
        language: "go_lang",
      });
    }
  }, []);

  const handleQueryChange = (e) => {
    setTransformation({ ...transformation, schema: e });
  };

  return (
    <div className="grid gap-8 w-full h-fit">
      <div className="w-full col-span-2">
        <CommonField
          className="col-span-2 mb-8"
          titleText="Language"
          value={language}
          options={constants.components.transformationLanguages}
          onChange={(e) => {
            setLanguage(e);
            setTransformation({
              ...transformation,
              language: e.value,
            });
          }}
          error
          selectfield
        />
        <CodeEditer height="300px" name="schema" titleText="Schema" value={transformation?.schema} onChange={handleQueryChange} />
      </div>
      {/* <div className="h-fit w-full">
        <div className="flex justify-start items-center text-[14px]">Incoming Blocks</div>
        <div className="flex flex-row pt-12">
          {Object.entries(Data).map(([, v]) => {
            return (
              <div className="bg-grey-300 p-14 mr-10">
                {Object.entries(v).map(([key, value]) => {
                  return (
                    <div>
                      {key} : {value}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex justify-start items-center text-[14px]">
        Join Condition<span className="text-red-900 text-md">*</span>
      </div>
      <div className="flex flex-col">
        <div className="flex items-center mb-8">
          <div className="w-full grid grid-cols-5 gap-12">
            <Input
              className="col-span-2"
              name="first_condition"
              placeholder="please enter value"
              value={inputFields.first_value}
              onChange={(event) => handleFormChange(event)}
            />
            <SelectField
              className="col-span-1"
              name="select"
              options={optionsData}
              value={inputFields.select}
              onChange={(event, f) => handleFormChange(event, true, f.name)}
            />
            <Input
              className="col-span-2"
              name="second_condition"
              placeholder="please enter value"
              value={inputFields.second_value}
              onChange={(event) => handleFormChange(event)}
            />
          </div>
        </div>
      </div>
      <div className="h-fit w-full">
        <div className="flex items-center text-[14px]">
          Windows<span className="text-red-900 text-md">*</span>
        </div>
        <div className="flex items-center w-full mb-12">
          <SelectField className="w-144" options={frequencyData} onChange={(event) => setWindows({ ...windows, timeIn: event })} />
          <Input className="w-full ml-8" name="second_value" onChange={(e) => setWindows({ ...windows, time_value: e.target.value })} />
        </div>
      </div> */}
    </div>
  );
}

export default Transformation;
