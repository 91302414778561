import moment from "moment";
import { DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import { Line } from "@ant-design/charts";
import NoData from "../../Widgets/NoData";
import Button from "../../Widgets/Button";

const ReportDetailsCard = ({ data, title, subTitle, countHeading, setDate, viewAllLink, ...rest }) => {
  const { RangePicker } = DatePicker;
  const disableFutureDates = (date) => {
    return date.isAfter(moment());
  };
  const navigate = useNavigate();
  const config = {
    data,
    xField: "createdAt",
    yField: "total",
    seriesField: "status",
    yAxis: {
      label: {
        formatter: (v) => v,
      },
    },
    legend: {
      position: "top",
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };
  return (
    <div {...rest}>
      <div className="w-full h-full bg-white rounded-sm">
        <div className="flex justify-between items-center px-16 py-12 border-b w-full">
          <div>
            <div className="text-sm font-normal">{`${title} (${data?.length})`}</div>
            {subTitle && <div className="text-darkGrey text-xs">{subTitle}</div>}
          </div>
          <div className="flex">
            <RangePicker
              disabledDate={disableFutureDates}
              format="DD-MM-YYYY"
              onChange={(e) => {
                if (e != null) {
                  setDate({ startDate: e[0], endDate: e[1] });
                } else {
                  setDate(null);
                }
              }}
            />
            <Button
              className="ml-8"
              variant="contained"
              color="primaryColor"
              onClick={() => navigate(viewAllLink)}
              startIcon={<i className="fa-solid fa-arrow-up-right-from-square" />}
            />
          </div>
        </div>
        {data?.length == 0 && <NoData />}
        <div className="p-16">{data?.length != 0 && <Line {...config} height={300} />}</div>
      </div>
    </div>
  );
};
export default ReportDetailsCard;
