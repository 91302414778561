import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonField from "../../Widgets/CommonField";
import { Context } from "../../Context";
import constants from "../../constants";

const JsonOutput = ({ jsonOutput, setJsonOutput, handleChange }) => {
  const navigate = useNavigate();
  const contextValues = useContext(Context);
  const [selectedType, setSelectedType] = useState();
  const [configureData, setConfigureData] = useState();
  const [azureBlobConfigureData, setAzureBlobConfigureData] = useState();
  const [selectedConfigure, setSelectedConfigure] = useState();
  const [selectedInterval, setSelectedInterval] = useState();
  const [optionsData, setOptionsData] = useState([]);

  useEffect(() => {
    contextValues.getConfigurations();
    contextValues.getConfigureType();
  }, []);

  useEffect(() => {
    if (contextValues?.configureType?.data?.length > 0) {
      const filterData = contextValues?.configureType?.data?.filter((item) => constants.components.json_output.includes(item?.configure_value));
      if (constants.components.json_output.includes("file_system")) {
        setOptionsData([
          ...filterData?.map((item) => ({
            value: `${item?.configure_value}`,
            label: `${item?.configure_label}`,
            icon: `${item?.icon}`,
          })),
          {
            value: "file_system",
            label: "File System",
          },
        ]);
      } else {
        setOptionsData(
          filterData?.map((item) => ({
            value: `${item?.configure_value}`,
            label: `${item?.configure_label}`,
            icon: `${item?.icon}`,
          }))
        );
      }
    }
  }, [contextValues?.configureType]);

  useEffect(() => {
    if (contextValues.configurationData?.data !== undefined || null) {
      if (jsonOutput?.type == "s3_storage") {
        const filteredData = contextValues.configurationData?.data?.filter((item) => item?.type === "s3_storage");
        setConfigureData([
          ...filteredData.map((item) => ({
            value: `${item?.id}`,
            label: `${item?.configure?.name}`,
          })),
          {
            value: "create_configuration",
            label: "Create Configuration",
            icon: "fa-solid fa-plus",
          },
        ]);
      } else if (jsonOutput?.type == "azure_blob_storage") {
        const filteredAzureBlobData = contextValues.configurationData?.data?.filter((item) => item?.type === "azure_blob_storage");
        setAzureBlobConfigureData([
          ...filteredAzureBlobData.map((item) => ({
            value: `${item?.id}`,
            label: `${item?.configure?.name}`,
          })),
          {
            value: "create_configuration",
            label: "Create Configuration",
            icon: "fa-solid fa-plus",
          },
        ]);
      }
      if (selectedConfigure !== null && jsonOutput?.account_id) {
        const filterDropDown = contextValues.configurationData?.data?.find((item) => item?.id == jsonOutput?.account_id);
        setSelectedConfigure({
          value: filterDropDown?.id,
          label: filterDropDown?.configure?.name,
        });
      }
    }
  }, [contextValues.configurationData, jsonOutput?.type]);

  useEffect(() => {
    if (jsonOutput?.type) {
      const filteredType = optionsData.filter((item) => item.value === jsonOutput?.type ?? jsonOutput?.account_id);
      setSelectedType(filteredType);
    }
    if (jsonOutput?.expiry_interval) {
      const filteredInterval = constants.components.expiryData.filter((item) => item.value === jsonOutput?.expiry_interval);
      setSelectedInterval(filteredInterval);
    }
  }, [optionsData, jsonOutput?.type]);

  return (
    <div className="grid grid-cols-2 gap-8 w-full h-fit">
      <CommonField
        className="col-span-2"
        titleText="Type"
        value={selectedType}
        options={optionsData}
        getOptionLabel={(e) => (
          <div className="flex item-center">
            <img src={e.icon ?? constants.images.noImage} width="20" alt="icon" />
            <span className="ml-5">{e.label}</span>
          </div>
        )}
        onChange={(type) => {
          setSelectedType(type);
          setSelectedConfigure(null);
          setJsonOutput({
            ...jsonOutput,
            type: type.value,
          });
        }}
        error
        selectfield
      />
      {jsonOutput?.type == "file_system" && (
        <>
          <CommonField
            titleText="Destination"
            className="col-span-2"
            name="destination"
            value={jsonOutput.destination || ""}
            onChange={handleChange}
          />
          <CommonField
            className="col-span-2"
            titleText=" Expiry Interval"
            value={selectedInterval}
            options={constants.components.expiryData}
            onChange={(interval) => {
              setSelectedInterval(interval);
              setJsonOutput({
                ...jsonOutput,
                expiry_interval: interval.value,
              });
            }}
            error
            selectfield
          />
        </>
      )}
      {jsonOutput?.type == "s3_storage" && (
        <>
          <CommonField
            className="col-span-2"
            titleText="S3 Storage"
            isClearable
            isSearchable
            value={selectedConfigure}
            options={configureData}
            getOptionLabel={(option) => {
              return (
                <span className="flex items-center">
                  <i className={`${option.icon} flex items-center justify-center mr-4`} />
                  {option.label}
                </span>
              );
            }}
            onChange={(e) => {
              if (e.value == "create_configuration") {
                navigate(constants.route.settings + "?create_configuration");
              }
              setSelectedConfigure(e);
              setJsonOutput({
                ...jsonOutput,
                account_id: e.value,
              });
            }}
            error
            selectfield
          />
        </>
      )}
      {jsonOutput?.type == "azure_blob_storage" && (
        <>
          <CommonField
            className="col-span-2"
            titleText="Azure Blob Configuration"
            value={selectedConfigure}
            options={azureBlobConfigureData}
            getOptionLabel={(option) => {
              return (
                <span className="flex items-center">
                  <i className={`${option.icon} flex items-center justify-center mr-4`} />
                  {option.label}
                </span>
              );
            }}
            onChange={(e) => {
              if (e.value == "create_configuration") {
                navigate(constants.route.settings + "?create_configuration");
              }
              setSelectedConfigure(e);
              setJsonOutput({
                ...jsonOutput,
                account_id: e.value,
              });
            }}
            error
            selectfield
          />
        </>
      )}
    </div>
  );
};

export default JsonOutput;
