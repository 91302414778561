import { useState, memo } from "react";

import Input from "../../Widgets/Input";

const Defaultprojectheader = () => {
  const [search, setSearch] = useState();

  return (
    <div className="flex w-full  items-center justify-between px-16 py-8 drop-shadow-lg bg-white mb-10">
      <Input className="w-1/6" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="search..." search />
      <div className="w-2/3 flex justify-end items-center mr-4">sort by</div>
      <Input className="w-1/6 mr-4" select placeholder="Updated Date" />
      <i className="fa fa-refresh p-6 items-center flex justify-center text-white rounded bg-indigo-500 text-sm mr-4" />
      <i className="fa fa-plus p-6 items-center flex justify-center text-white rounded bg-indigo-500 text-sm" />
    </div>
  );
};

export default memo(Defaultprojectheader);
