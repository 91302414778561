import _ from "lodash";
import moment from "moment";

export const copyDict = (dict) => {
  return JSON.parse(JSON.stringify(dict));
};

export const dateFormat = (date) => {
  return date ? moment(date).fromNow() : "-";
};

export const capitalizeFirstLetter = (str) => {
  if (!str) {
    return null;
  }
  const words = str.split("_");
  const capitalizedWords = words.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word));
  return capitalizedWords.join(" ");
};

export const capitalizeFirstLetterOfEachWord = (text) => {
  const words = text.split(/[\s_]+/);
  const capitalizedWords = words.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1);
    return `${firstLetter}${restOfWord}`;
  });
  return capitalizedWords.join(" ");
};

export const filterDataBySearch = (data, search) => {
  return _.filter(data, (item) =>
    Object.values(item).some((value) => (!search ? true : value?.toString()?.toLowerCase()?.includes(search?.toLowerCase())))
  );
};

export const maskValue = (value) => {
  if (value) {
    const length = value?.length;
    const start = length > 8 ? value?.substring(0, 4) : value?.substring(0, 3);
    const end = length > 8 ? value?.substring(length - 4) : value?.substring(length - 3);
    const maskedMiddle = "*".repeat(Math.max(0, length - 6));
    return `${start}${maskedMiddle}${end}`;
  } else {
    return "";
  }
};

export function transformString(inputString) {
  const words = inputString?.split("_");
  const capitalizedWords = words?.map((word) => {
    return word.charAt(0).toUpperCase() + word?.slice(1);
  });
  return capitalizedWords?.join(" ");
}

export function filterArrayByKeys(dataArray, cardData) {
  return dataArray?.map((k) => ({ k, value: cardData[k] })).filter((item) => item?.value);
}

export function generateQueryString(parameters) {
  if (!parameters) return "";
  const queryString = Object.entries(parameters)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
  return queryString ? `?${queryString}` : "";
}
