import React, { memo, useState } from "react";

import SelectField from "../../Widgets/SelectField";
import Input from "../../Widgets/Input";
import CommonField from "../../Widgets/CommonField";
import Button from "../../Widgets/Button";
import constants from "../../constants";

function JoinStream({ joinStream, setJoinStream }) {
  const [duration, setDuration] = useState({});
  const [inputFields, setInputFields] = useState({
    stream_key_1: "",
    stream_key_2: "",
  });
  const [showFields, setShowFields] = useState([]);
  const [editFields, setEditFields] = useState(null);

  const handleFormChange = (event, isselect, name) => {
    setInputFields({
      ...inputFields,
      [isselect ? name : event.target.name]: isselect ? event : event.target.value,
    });
  };

  const removeShowFields = (index) => {
    const data = [...showFields];
    data.splice(index, 1);
    setShowFields(data);
  };

  const updateList = (input, edit) => {
    const newList = showFields.map((item) => (item.id === edit.id ? input : item));
    setShowFields(newList);
    setEditFields({
      stream_key_1: "",
      stream_key_2: "",
    });
  };

  React.useEffect(() => {
    if (joinStream?.streamKey) {
      setShowFields(joinStream?.streamKey);
    }
  }, []);

  React.useEffect(() => {
    if (editFields) {
      setInputFields(editFields);
    } else {
      setInputFields({
        stream_key_1: "",
        stream_key_2: "",
      });
    }
  }, [setInputFields, editFields]);

  React.useEffect(() => {
    setJoinStream({ ...JoinStream, streamKey: showFields });
  }, [showFields]);

  const addShowFields = () => {
    if (!editFields) {
      const newRecord = { ...inputFields, id: new Date().getTime().toString() };
      setShowFields([...showFields, newRecord]);
      setInputFields({
        stream_key_1: "",
        stream_key_2: "",
        condition: "",
      });
    } else {
      updateList(inputFields, editFields);
      setEditFields("");
    }
  };

  const editItem = (id) => {
    const newEditItem = showFields.find((elem) => {
      return elem.id === id;
    });
    setEditFields(newEditItem);
  };

  React.useEffect(() => {
    setJoinStream({ ...joinStream, duration });
  }, [duration]);

  return (
    <div className="w-full">
      <div>
        {showFields?.map((input, index) => {
          return (
            <div key={index} className="flex flex-col items-center">
              <div className="flex items-center w-full">
                <div className="w-full grid lg:grid-cols-8 sm:grid-cols-1 gap-12">
                  <Input name="stream_key_1" className="lg:col-span-4 sm:col-span-1" value={input.stream_key_1} disabled="true" />
                  <Input disabled="true" className="col-span-4" value={input?.stream_key_2} />
                </div>
                <div className="flex items-center justify-end mx-10">
                  <i
                    className="fa-solid fa-xmark text-sm cursor-pointer hover:text-red-600 mr-10"
                    aria-hidden="true"
                    onClick={() => removeShowFields(index)}
                  />
                  <i
                    className="fa-regular fa-pen-to-square cursor-pointer hover:text-blue-600"
                    aria-hidden="true"
                    onClick={() => editItem(input.id)}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center items-center relative w-full">
                <hr className="border-gray-500 w-full my-16" />
              </div>
            </div>
          );
        })}
        <div className="flex items-center mb-8 w-full">
          <div className="w-full grid grid-cols-4 gap-12">
            <CommonField
              titleText="Stream Key 1"
              className="col-span-2"
              name="stream_key_1"
              place
              value={inputFields.stream_key_1 || ""}
              onChange={(event) => handleFormChange(event)}
            />
            <CommonField
              titleText="Stream Key 2"
              className="col-span-2"
              name="stream_key_2"
              value={inputFields.stream_key_2 || ""}
              onChange={(event) => handleFormChange(event)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center mt-12 mb-8">
        <Button color="indigo" variant="outline" onClick={() => addShowFields()}>
          {!editFields ? "+ add Stream" : "update Stream"}
        </Button>
      </div>
      <div className="h-fit w-full">
        <CommonField
          selectfield
          error
          titleText="Window Function"
          value={joinStream.windowFunction}
          options={constants.components.joinStreamWindowData}
          onChange={(windowFunction) => setJoinStream({ ...joinStream, windowFunction })}
        />
        <div className="flex justify-start items-center text-2xs text-lightGrey pt-8 uppercase">Join Duration</div>
        <div className="flex w-full mb-12">
          <SelectField
            className="w-144"
            error
            options={constants.components.joinStreamFrequencyData}
            value={joinStream?.duration?.timeIn}
            onChange={(event) => setDuration({ ...duration, timeIn: event })}
          />
          <Input
            className="w-full ml-8"
            name="time_value"
            error
            value={joinStream?.duration?.time_value}
            onChange={(e) =>
              setDuration({
                ...duration,
                time_value: e.target.value.replace(/\D/g, ""),
              })
            }
          />
        </div>
      </div>
    </div>
  );
}

export default memo(JoinStream);
