import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Widgets/Header";
import constants from "../../constants";
import { Context } from "../../Context";
import Table from "../../Widgets/Table";
import { filterDataBySearch } from "../../utils/utils";
import CommonField from "../../Widgets/CommonField";

function DataCatalog() {
  const contextValues = useContext(Context);
  const [search, setSearch] = useState();
  const [namespaceOptionData, setNamespaceOptionData] = useState([]);
  const [lakehouseOptionsData, setLakehouseOptionsData] = useState([]);
  const [catalogsData, setCatalogsData] = useState([]);
  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(16);
  const navigate = useNavigate();

  useEffect(() => {
    const getCatalogsData = async () => {
      const response = await contextValues.getCatalogsData({
        catalog_name: filter?.lakehouse?.value,
        table_namespace: filter?.namespace?.value,
        table_name: filter?.table?.value,
      });

      setCatalogsData(response?.data?.data);
    };

    getCatalogsData();
  }, [filter]);

  useEffect(() => {
    const getLakehouse = async () => {
      const response = await contextValues.getAllCatalogs();
      setLakehouseOptionsData(
        response?.map((item) => ({
          value: `${item?.catalog_name}`,
          label: `${item?.catalog_name}`,
        }))
      );
    };
    getLakehouse();
  }, []);

  useEffect(() => {
    const getLakehouse = async () => {
      const response = await contextValues.customNamespace({ catalog_name: filter?.lakehouse?.value });
      const filteredData = response?.filter((item) => item?.table_namespace !== "");
      setNamespaceOptionData(
        filteredData?.map((item) => ({
          value: `${item?.table_namespace}`,
          label: `${item?.table_namespace}`,
        }))
      );
    };
    getLakehouse();
  }, [filter?.lakehouse?.value]);

  useEffect(() => {
    const getLakehouse = async () => {
      const response = await contextValues.GetTables({ catalog_name: filter?.lakehouse?.value, table_namespace: filter?.namespace?.value });
      const filteredData = response?.filter((item) => item?.table_name !== "");
      setNamespaceOptionData(
        filteredData?.map((item) => ({
          value: `${item?.table_name}`,
          label: `${item?.table_name}`,
        }))
      );
    };
    getLakehouse();
  }, [filter]);

  const tablesHead = [
    { id: 1, label: "Name", position: "left" },
    { id: 1, label: "Namespaces", position: "center" },
    { id: 2, label: "Table Name", position: "center" },
    { id: 2, label: "Owners", position: "center" },
    // { id: 2, label: "Groups", position: "center" },
    // { id: 2, label: "Status", position: "center" },
    // { id: 3, label: "Created", position: "center" },
    // { id: 3, label: "Actualizado", position: "center" },
  ];

  // const tableData = [
  //   {
  //     id: 463,
  //     external_name: "kds_clickstream",
  //     ownership: [
  //       {
  //         id: 1,
  //         owner: {
  //           id: 3,
  //           name: "Data Science Engineer",
  //           roles: null,
  //         },
  //         title: {
  //           id: 2,
  //           name: "Alex",
  //         },
  //       },
  //     ],
  //     entity_classes: [
  //       {
  //         id: 1,
  //         name: "DATA_SET",
  //       },
  //     ],
  //     data_source: {
  //       id: 11,
  //       namespace: {
  //         id: 7,
  //         name: "Messaging",
  //       },
  //       name: "KDS Clickstream",
  //     },
  //     status: {
  //       status: "UNASSIGNED",
  //     },
  //     source_created_at: null,
  //     source_updated_at: "2023-01-09T13:27:31.41183Z",
  //     last_ingested_at: "2023-09-18T09:15:47.700604Z",
  //   },
  // ];

  const clearAllFields = () => {
    setFilter({
      lakehouse: null,
      namespace: null,
      table: null,
    });
  };

  return (
    <div className="w-full h-full">
      <Header title="Data Catalog" searchPlaceholder="Search..." search={search} setSearch={setSearch} />
      <div className="h-[calc(100vh-4rem)] p-10">
        <div className="bg-white h-full flex w-full rounded-[4px]">
          <div className="w-186 border-r p-8">
            <div className="flex justify-between items-center mb-10">
              <div className="text-sm font-semibold">Filters</div>
              <div className="text-xs font-medium text-primaryColor cursor-pointer" role="presentation" onClick={clearAllFields}>
                Clear All
              </div>
            </div>
            <div className="h-full w-full overflow-y-auto">
              <CommonField
                className="mb-10"
                selectfield
                titleText="Lakehouse"
                value={filter?.lakehouse}
                options={lakehouseOptionsData}
                onChange={(data) => setFilter({ ...filter, lakehouse: data })}
              />
              <CommonField
                className="mb-10"
                selectfield
                titleText="Namespace"
                value={filter?.namespace}
                options={namespaceOptionData}
                onChange={(data) => setFilter({ ...filter, namespace: data })}
              />
              <CommonField
                className="mb-10"
                selectfield
                titleText="Table"
                value={filter?.table}
                options={namespaceOptionData}
                onChange={(data) => setFilter({ ...filter, table: data })}
              />
            </div>
          </div>
          <Table
            className="w-full overflow-x-auto"
            setPage={setPage}
            tableHeader={tablesHead}
            rowsPerPage={rowsPerPage}
            totalPost={filterDataBySearch(catalogsData, search)?.length}
          >
            {filterDataBySearch(catalogsData, search)
              ?.slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
              ?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr
                      className="border-b last:border-0 w-full hover:bg-tableHoverColor"
                      role="presentation"
                      onClick={() => {
                        navigate(
                          constants.route.dataCatalog +
                            `/${item?.catalog_name}?table_namespace=${item?.table_namespace}&table_name=${item?.table_name}`
                        );
                        contextValues.setCatalogOverviewData(item);
                      }}
                    >
                      <td className="px-16 py-5 cursor-pointer text-xs">
                        {/* <div className="flex items-center justify-between w-144"> */}
                        {/* <div className="flex items-center w-112"> */}
                        {/* <CustomTooltip
                                direction="bottom"
                                title="Ingested at 14 days ago"
                              >
                                <i className="fa-solid fa-arrows-rotate mr-4 text-amber-600" />
                              </CustomTooltip> */}
                        <span className="truncate">{item?.catalog_name}</span>
                        {/* </div> */}
                        {/* <Popover
                              content={
                                <ul className="min-w-180">
                                  <li className="flex justify-between border-b py-4">
                                    <div className="">
                                      <div className="text-sm font-bold">
                                        Tag
                                      </div>
                                      <div className="text-xs text-gray-600">
                                        No Tags
                                      </div>
                                    </div>
                                    <div className="text-xs text-gray-600">
                                      0 tags
                                    </div>
                                  </li>
                                  <li className="flex justify-between py-4">
                                    <div className="">
                                      <div className="text-sm font-bold">
                                        Custom metedata
                                      </div>
                                      <div className="text-xs text-gray-600">
                                        No Custom metedata
                                      </div>
                                    </div>
                                    <div className="text-xs text-gray-600">
                                      0 fields
                                    </div>
                                  </li>
                                </ul>
                              }
                              placement="bottomLeft"
                              showArrow={false}
                            >
                              <i className="fa-solid fa-circle-info text-md ml-4" />
                            </Popover> */}
                        {/* <EntryClassStatus
                              className="ml-6"
                              shortName
                              title={item?.entity_classes?.[0]?.name}
                            /> */}
                        {/* </div> */}
                      </td>
                      <td className="px-16 py-5 text-center cursor-pointer text-xs">
                        {/* <div className="flex flex-col items-start"> */}
                        {/* <div className="font-semibold"> */}
                        {item?.table_namespace}
                        {/* </div> */}
                        {/* <div>{item?.data_source?.name}</div> */}
                        {/* </div> */}
                      </td>
                      <td className="px-16 py-5 text-center cursor-pointer text-xs">{item?.table_name}</td>
                      <td className="px-16 py-5 text-center cursor-pointer text-xs whitespace-nowrap">{item?.metadata?.properties?.owner}</td>
                      {/* <td className="px-16 py-5 text-center cursor-pointer text-xs whitespace-nowrap">
                          Group Name
                        </td> */}
                      {/* <td className="px-16 py-5 text-center cursor-pointer text-xs">
                          <Status title={item?.status?.status} />
                        </td> */}
                      {/* <td className="px-16 py-5 text-center cursor-pointer text-xs whitespace-nowrap">
                          {item?.source_created_at ?? "-"}
                        </td>
                        <td className="px-16 py-5 text-center cursor-pointer text-xs whitespace-nowrap">
                          {moment(item?.source_updated_at).fromNow()}
                        </td> */}
                    </tr>
                  </React.Fragment>
                );
              })}
          </Table>
        </div>
      </div>
    </div>
  );
}

export default DataCatalog;
