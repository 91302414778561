import { memo } from "react";

import CommonField from "../../Widgets/CommonField";

const SendSMS = ({ sendSms, handleChange }) => {
  return (
    <div className="w-full">
      <CommonField className="mb-8" titleText="From" name="from" value={sendSms.from || ""} onChange={handleChange} error="field" />
      <CommonField className="mb-8" titleText="To" name="to" value={sendSms.to || ""} onChange={handleChange} error="field" />
      <CommonField
        className="mb-8"
        titleText="Message"
        rows="4"
        name="message"
        onChange={handleChange}
        value={sendSms.message}
        error="field"
        textarea
      />
    </div>
  );
};

export default memo(SendSMS);
