import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";

import Input from "../../Widgets/Input";
import IntegrationCard from "../../Widgets/IntegrationCard";
import constants from "../../constants";

const IngestionApplication = () => {
  const [search, setSearch] = useState();
  const navigate = useNavigate();

  const Ingestion = [
    {
      id: 1,
      name: "Classification",
      icon: "https://app.gathr.one/assets/images/components/comp-data-generator.png",
    },
    {
      id: 2,
      name: "Regression",
      icon: "https://app.gathr.one/assets/images/components/quickbase-channel.png",
    },
    {
      id: 3,
      name: "Object Detection",
      icon: "https://app.gathr.one/assets/images/components/redshift-channel.png",
    },
  ];

  return (
    <div className="p-16 w-full">
      <div className="relative">
        <Input className="lg:w-1/3 md:w-1/2 sm:w-1/2" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="search..." search />

        <div className="w-full grid lg:grid-cols-6 gap-16 py-16 sm:grid-cols-1 md:grid-cols-2 ">
          {Ingestion?.filter((val) => {
            return !search ? val : val.name.toLowerCase().includes(search.toLowerCase());
          })?.map((item) => (
            <IntegrationCard
              key={item.id}
              title={item?.name}
              icon={item?.icon}
              onClick={() => {
                if (item.id == 1) {
                  navigate(constants.route.Classification);
                } else if (item.id === 3) {
                  navigate(constants.route.detect);
                }
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(IngestionApplication);
