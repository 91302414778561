import moment from "moment";
import { useEffect, useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import CytoscapeComponent from "react-cytoscapejs";
import dagre from "cytoscape-dagre";
import cytoscape from "cytoscape";
import Tooltip from "../../Widgets/Tooltip";
import SpeedDial from "../../Widgets/SpeedDial ";
import { Context } from "../../Context";
import CodeEditer from "../../Widgets/CodeEditer";

cytoscape.use(dagre);

const Lineage = (props) => {
  const cyRef = useRef(null);
  const contextValues = useContext(Context);
  const [dragLevel, setDragLevel] = useState("table");
  const [open, setOpen] = useState(false);
  const [lineageData, setLineageData] = useState();
  const params = useParams();

  useEffect(() => {
    const lineage = async () => {
      const data = await contextValues.getCatalogLineage(params?.id);
      setLineageData(data?.data?.data);
      if (cyRef.current) {
        const cy = cyRef.current._cy;
        const data1 = dragLevel === "column" ? data?.data?.data?.column ?? [] : data?.data?.data?.dag ?? [];
        const layout = dragLevel === "column" ? layoutColumn : layoutTable;
        cy.elements().remove();
        cy.add(data1);
        cy.layout(layout).run();
      }
    };
    lineage();
  }, []);

  const file = props?.tableData?.catalog_name + "-" + props?.tableData?.table_name + "-" + moment().format("DD-MM-YYYY");

  const layoutTable = {
    name: "dagre",
    rankDir: "LR",
    rankSep: 200,
    nodeSep: 50,
  };
  const layoutColumn = {
    name: "dagre",
    rankDir: "LR",
    rankSep: 120,
    nodeSep: 15,
  };

  const handleSave = () => {
    if (cyRef.current) {
      const cy = cyRef.current._cy;
      const aDownloadLink = document.createElement("a");
      aDownloadLink.download = `${file}.jpg`;
      aDownloadLink.href = cy.jpg({ full: true, quality: 1 });
      aDownloadLink.click();
    }
  };

  const handleLevel = (value) => {
    if (cyRef.current) {
      const cy = cyRef.current._cy;
      const data = value === "column" ? lineageData?.column ?? [] : lineageData?.dag ?? [];
      const layout = value === "column" ? layoutColumn : layoutTable;
      cy.elements().remove();
      cy.add(data);
      cy.layout(layout).run();
    }
    setDragLevel(value);
  };

  useEffect(() => {
    if (cyRef.current) {
      const cy = cyRef.current._cy;
      cy.on("mouseover", "node", function mouseover(e) {
        const sel = e.target;
        if (
          sel.parent().size() > 0 ||
          cy
            .elements()
            .filter((node) => node.isParent())
            .size() === 0
        ) {
          const elements = sel.union(sel.successors()).union(sel.predecessors());
          elements.addClass("highlight");
          cy.elements()
            .filter((node) => node.isChild())
            .difference(elements)
            .addClass("semitransparent");
        }
        const parents = sel.data().parent_candidates;
        if (parents !== undefined && parents?.length > 1) {
          const tmp_elem = parents
            .map((p) => [
              { data: { id: p.name, type: p.type, temporary: true } },
              {
                data: {
                  id: sel.data().id + "-" + p.name,
                  source: sel.data().id,
                  target: p.name,
                  temporary: true,
                },
              },
            ])
            .reduce((x, y) => x.concat(y));
          if (
            cy
              .elements()
              .filter((n) => n.data().temporary === true)
              .size() === 0
          ) {
            const zoom = cy.zoom();
            const pan = cy.pan();
            cy.add(tmp_elem);
            cy.elements()
              .filter((n) => n.data().temporary === undefined)
              .lock();
            cy.elements()
              .filter((n) => n.data().temporary === true || n.data().id === sel.data().id)
              .layout({
                boundingBox: {
                  x1: sel.position().x,
                  y1: sel.position().y,
                  w: 200,
                  h: 200,
                },
                circle: true,
                name: "breadthfirst",
              })
              .run();
            cy.viewport({
              zoom,
              pan,
            });
          }
        }
      });
      cy.on("mouseout", "node", function mouseout() {
        cy.elements().removeClass("semitransparent");
        cy.elements().removeClass("highlight");
        cy.remove(cy.elements().filter((n) => n.data().temporary === true));
        cy.elements()
          .filter((n) => n.data().temporary === undefined)
          .unlock();
      });

      cy.removeListener("click", "node");
      cy.on("click", "node", (e) => {
        const sel = e.target;
        const columnLevel = cy.elements().some((i) => i.isNode() && i.data().type === "Column");
        if (sel.data().type === "Column" || !columnLevel) {
          let elements = sel.union(sel.successors()).union(sel.predecessors());
          if (columnLevel) {
            elements = elements.filter((c) => c.isNode() && c.data().type === "Column");
          }
          if (elements.every((t) => t.hasClass("highlight_locked"))) {
            elements.removeClass("highlight_locked");
          } else {
            elements.addClass("highlight_locked");
          }
        }
      });

      cy.edges().forEach((edge) => {
        if (edge.source() !== edge.target()) {
          const x0 = edge.source().position("x");
          const x1 = edge.target().position("x");
          const y0 = edge.source().position("y");
          const y1 = edge.target().position("y");
          const x = x1 - x0;
          const y = y1 - y0;
          const z = Math.sqrt(x * x + y * y);
          const costheta = x / z;
          const alpha = 0.2;
          const controlPointDistances = [-alpha * y * costheta, alpha * y * costheta];
          edge.style("control-point-distances", controlPointDistances);
          edge.style("control-point-weights", [alpha, 1 - alpha]);
        }
      });
    }
  });

  if (lineageData?.dag?.length === 0) {
    return (
      <div className="w-full h-[calc(100vh-10.4rem)] bg-white">
        <div className="flex justify-center items-center w-full p-20 flex-col">
          <div className="text-grey-500 flex flex-col justify-center items-center">
            <img className="w-250" src={process.env.PUBLIC_URL + "/assets/error_image.svg"} alt="Database" />
            <span className="mt-12 text-sm  text-center font-semibold">No Lineage Info found in your SQL.</span>
            <span className="mt-6 text-[14px]  text-center">Please review your code in Script View.</span>
          </div>
        </div>
      </div>
    );
  } else {
    const stylesheet = [
      {
        selector: "node",
        style: {
          height: 8,
          width: 8,
          content: "data(id)",
          "text-valign": "top",
          "text-halign": "right",
          "font-size": 8,
          color: "#35393e",
          "background-color": "#20a7c9",
          "border-color": "#000",
          "border-width": 1,
          "border-opacity": 0.8,
        },
      },
      {
        selector: ":parent",
        style: {
          content: (elem) => elem.data().type + ": " + elem.data().id,
          "font-size": 14,
          "font-weight": "bold",
          "text-halign": "center",
          "text-valign": "top",
        },
      },
      {
        selector: ':parent[type = "Table"], :parent[type = "Path"]',
        style: {
          "background-color": "#f5f5f5",
          "border-color": "#00516c",
        },
      },
      {
        selector: ':parent[type = "SubQuery"]',
        style: {
          "background-color": "#f5f5f5",
          "border-color": "#b46c4f",
        },
      },
      {
        selector: ':parent[type = "Table or SubQuery"]',
        style: {
          "background-color": "#f5f5f5",
          "border-color": "#b46c4f",
          "border-style": "dashed",
        },
      },
      {
        selector: "edge",
        style: {
          width: 1,
          "line-color": "#9ab5c7",
          "target-arrow-color": "#9ab5c7",
          "target-arrow-shape": "triangle",
          "arrow-scale": 0.8,
          "curve-style": "unbundled-bezier",
        },
      },
      {
        selector: ".highlight",
        style: {
          "background-color": "#1A85A0",
        },
      },
      {
        selector: ".highlight_locked",
        style: {
          "background-color": "#e99708",
        },
      },
      {
        selector: ".semitransparent",
        style: { opacity: "0.2" },
      },
      {
        selector: "node[temporary]",
        style: {
          "background-color": "#f5f5f5",
          "border-color": "#b46c4f",
          "border-style": "dashed",
          content: (elem) => elem.data().type + ": " + elem.data().id,
          "font-size": 14,
          "font-weight": "bold",
          shape: "rectangle",
          "text-halign": "center",
          "text-valign": "top",
        },
      },
      {
        selector: "edge[temporary]",
        style: {
          "line-color": "#b46c4f",
          "line-style": "dashed",
          "target-arrow-color": "#b46c4f",
        },
      },
    ];
    const style = { width: props.width, height: props.height };
    return (
      <div>
        {dragLevel == "text" ? (
          <CodeEditer value={lineageData?.verbose} style={style} />
        ) : (
          <CytoscapeComponent
            elements={lineageData?.dag}
            stylesheet={stylesheet}
            style={style}
            layout={layoutTable}
            zoom={1}
            minZoom={0.5}
            maxZoom={2}
            wheelSensitivity={0.2}
            ref={cyRef}
          />
        )}
        <div className="absolute top-1/2 right-0 rounded-l-md overflow-hidden border border-gray-300 bg-white z-99">
          <Tooltip direction="left" title="Text view">
            <i
              role="presentation"
              onClick={() => handleLevel("text")}
              className={`fa-regular fa-file-code h-26 w-26 flex justify-center items-center border-b cursor-pointer ${
                dragLevel === "text" && "bg-gray-300"
              }`}
            />
          </Tooltip>
          <Tooltip direction="left" title="Table Level Lineage">
            <i
              role="presentation"
              onClick={() => handleLevel("table")}
              className={`fa-solid fa-table h-26 w-26 flex justify-center items-center border-b cursor-pointer ${
                dragLevel === "table" && "bg-gray-300"
              }`}
            />
          </Tooltip>
          <Tooltip direction="left" title="Column Level Lineage">
            <i
              role="presentation"
              onClick={() => handleLevel("column")}
              className={`fa-solid fa-table-columns h-26 w-26 flex justify-center items-center cursor-pointer ${
                dragLevel === "column" && "bg-gray-300"
              }`}
            />
          </Tooltip>
        </div>
        {dragLevel != "text" && (
          <SpeedDial open={open} setOpen={setOpen}>
            <Tooltip title="Save">
              <i
                role="presentation"
                onClick={handleSave}
                className="fa-solid fa-download text-sm h-28 w-28 flex justify-center items-center rounded-full cursor-pointer bg-white shadow-xl hover:bg-gray-200"
              />
            </Tooltip>
            <Tooltip title="Zoom In">
              <i
                role="presentation"
                onClick={() => {
                  cyRef.current._cy.zoom(cyRef.current._cy.zoom() + 0.1);
                }}
                className="fa-solid fa-magnifying-glass-plus text-sm h-28 w-28 flex justify-center items-center rounded-full cursor-pointer bg-white shadow-xl hover:bg-gray-200"
              />
            </Tooltip>
            <Tooltip title="Auto Fit">
              <i
                role="presentation"
                onClick={() => {
                  cyRef.current._cy.fit();
                }}
                className="fa-solid fa-expand text-sm h-28 w-28 flex justify-center items-center rounded-full cursor-pointer bg-white shadow-xl hover:bg-gray-200"
              />
            </Tooltip>
            <Tooltip title="Zoom Out">
              <i
                role="presentation"
                onClick={() => {
                  cyRef.current._cy.zoom(cyRef.current._cy.zoom() - 0.1);
                }}
                className="fa-solid fa-magnifying-glass-minus text-sm h-28 w-28 flex justify-center items-center rounded-full cursor-pointer bg-white shadow-xl hover:bg-gray-200"
              />
            </Tooltip>
          </SpeedDial>
        )}
      </div>
    );
  }
};

export default Lineage;
