import React, { useEffect } from "react";

import MultipleFileUpload from "../../Widgets/MultipleFileUpload";
import Checkbox from "../../Widgets/Checkbox";
import CommonField from "../../Widgets/CommonField";
import { Context } from "../../Context";

const FacialRecognition = ({ facialRecognition, setFacialRecognition, handleChange }) => {
  const contextValues = React.useContext(Context);
  useEffect(() => {
    if (!facialRecognition.image) {
      setFacialRecognition({ url_type: "single" });
    }
    contextValues.setUploadFile({ isMultiUpload: false });
  }, []);

  return (
    <div className="grid gap-8 w-full h-fit">
      <MultipleFileUpload name="upload_Images" fieldValue={facialRecognition} setFieldValue={setFacialRecognition} />
      <div className="flex flex-row">
        <Checkbox name="url_type" value="single" checked={facialRecognition.url_type === "single"} label="Single" onChange={handleChange} />
        <Checkbox
          className="ml-12"
          name="url_type"
          value="stream"
          checked={facialRecognition.url_type === "stream"}
          label="Stream"
          onChange={handleChange}
        />
      </div>
      <CommonField
        placeholder={facialRecognition.url_type === "single" ? "Single Url" : facialRecognition.url_type === "stream" ? "Stream Url" : ""}
        titleText="URL"
        name="url"
        error="email"
        value={facialRecognition.url}
        onChange={handleChange}
      />
    </div>
  );
};

export default FacialRecognition;
