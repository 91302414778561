import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../Context";
import Button from "../Widgets/Button";
import Modal from "../Widgets/Modal";
import CommonField from "../Widgets/CommonField";
import constants from "../constants";
import Header from "../Widgets/Header";
import { dateFormat } from "../utils/utils";
import Pagination from "../Widgets/Pagination";
import CustomTooltip from "../Widgets/Tooltip";

function Flow() {
  const contextValues = React.useContext(Context);
  const [showModel, setShowModel] = useState();
  const params = useParams();
  const [fieldValue, setFieldValue] = useState();
  const [search, setSearch] = useState();
  const [editItem, setEditItem] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(16);
  const navigate = useNavigate();

  React.useEffect(() => {
    contextValues.fetchUserData();
    contextValues.GetFlow();
  }, []);

  const handleChange = (event) => {
    setFieldValue({ ...fieldValue, id: params.id });
    const { name } = event.target;
    const { value } = event.target;
    setFieldValue((values) => ({ ...values, [name]: value }));
  };

  const validate = () => {
    let isValidate = true;
    if (fieldValue?.name == "" || !fieldValue?.name) {
      isValidate = false;
    }
    return isValidate;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!editItem) {
      contextValues.createFlow(fieldValue);
    } else {
      contextValues.putFlow({
        name: fieldValue.name,
        id: editItem,
        paramId: params.id,
      });
    }
    setShowModel(false);
  };

  const selectItemToEdit = (id) => {
    const selectedItem = contextValues.FlowData?.data.filter((item) => item.id === id);
    setFieldValue(selectedItem[0]?.attributes);
    setEditItem(id);
  };
  const flowHead = [
    { id: 1, label: "Id", position: "left" },
    { id: 2, label: "Created At", position: "center" },
    { id: 3, label: "Updated At", position: "center" },
    { id: 5, label: "Flow Name", position: "center" },
    { id: 4, label: "Team Name", position: "center" },
    { id: 6, label: "Action", position: "center" },
  ];

  const handleFlow = (id) => {
    navigate(`${constants.route.workflowCreate}/${id}`);
  };

  return (
    <div className="w-full h-full">
      <Header
        title="Flows"
        createButtonText="Create Flow"
        onButtonClick={() => {
          setFieldValue();
          setShowModel(true);
          setEditItem("");
        }}
        searchPlaceholder="Search..."
        search={search}
        setSearch={setSearch}
      />
      <div className="p-10">
        <div className="bg-indigo-500 text-white font-medium w-full grid grid-cols-6 rounded-t-lg">
          {flowHead.map((item) => (
            <div className={`p-8 text-${item?.position}`} key={item.id}>
              {item.label}
            </div>
          ))}
        </div>
        {contextValues.FlowData?.data
          ?.filter((val) => {
            return !search
              ? val
              : val.attributes?.name.toLowerCase().includes(search.toLowerCase()) ||
                  val.attributes?.team?.data?.attributes?.name.toLowerCase().includes(search.toLowerCase());
          })
          ?.slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
          ?.map((item) => {
            return (
              <div key={item?.id} className="border-b w-full grid grid-cols-6 hover:bg-indigo-50">
                <div className="p-6 cursor-pointer" role="presentation" onClick={() => handleFlow(item?.id)}>
                  {item?.id}
                </div>
                <div className="p-6 text-center cursor-pointer" role="presentation" onClick={() => handleFlow(item?.id)}>
                  {dateFormat(item?.attributes?.createdAt)}
                </div>
                <div className="p-6 text-center cursor-pointer" role="presentation" onClick={() => handleFlow(item?.id)}>
                  {dateFormat(item?.attributes?.updatedAt)}
                </div>
                <div className="p-6 text-center cursor-pointer" role="presentation" onClick={() => handleFlow(item?.id)}>
                  {item?.attributes?.name}
                </div>
                <div className="p-6 text-center cursor-pointer" role="presentation" onClick={() => handleFlow(item?.id)}>
                  {item?.attributes?.team?.data?.attributes?.name}
                </div>
                <div className="p-6 text-center">
                  <div className="">
                    <CustomTooltip direction="bottom" title="Edit">
                      <i
                        className="fa-solid fa-pen-to-square mx-8 text-lightGrey font-normal cursor-pointer hover:text-blue-600 z-999"
                        role="presentation"
                        onClick={() => {
                          setShowModel(true);
                          selectItemToEdit(item.id);
                        }}
                      />
                    </CustomTooltip>
                    <CustomTooltip direction="bottom" title="Delete">
                      <i
                        className="fa-solid fa-trash-can mx-8 text-lightGrey font-normal cursor-pointer hover:text-blue-600"
                        role="presentation"
                        onClick={() => contextValues.deleteFlow(item.id, params.id)}
                      />
                    </CustomTooltip>
                  </div>
                </div>
              </div>
            );
          })}
        <Pagination rowsPerPage={rowsPerPage} totalPost={contextValues?.FlowData?.data?.length} setPage={setPage} />
        <Modal showModel={showModel} setShowModel={setShowModel}>
          <form onSubmit={handleSubmit} className="flex flex-col w-full justify-center items-center">
            <div className="flex w-full justify-between items-center py-6 border-b">
              <div className="text-sm">{editItem ? "Update Flow" : "Create Flow"}</div>
            </div>
            <CommonField titleText="Name" name="name" value={fieldValue?.name || ""} onChange={handleChange} error="field" />
            <div className="flex justify-end items-center w-full mt-40">
              <Button className="mr-12" color="blue" variant="outline" onClick={() => setShowModel(false)}>
                Cancel
              </Button>
              <Button color="indigo" variant="contained" disable={!validate()} submit>
                {editItem ? "Update" : "Create"}
              </Button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
}

export default Flow;
