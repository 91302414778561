import { memo } from "react";

import CommonField from "../../Widgets/CommonField";
import KeyValueField from "../../Widgets/KeyValueField";
import constants from "../../constants";

function GStream({ gStream, setGStream, handleChange }) {
  return (
    <div className="w-full">
      <CommonField
        className="mb-8"
        titleText="Method"
        value={gStream.method}
        options={constants.components.methods}
        onChange={(method) => setGStream({ ...gStream, method })}
        error
        selectfield
      />
      <CommonField className="mb-8" titleText="End Point" name="endpoint" value={gStream.endpoint || ""} onChange={handleChange} error="field" />
      <KeyValueField titleText="start_selector" name="start_selector" fieldValue={gStream} setFieldValue={setGStream} />
    </div>
  );
}

export default memo(GStream);
