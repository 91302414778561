import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Widgets/Header";
import CustomTooltip from "../../Widgets/Tooltip";
import { dateFormat, filterDataBySearch } from "../../utils/utils";
import Status from "../../Widgets/Status";
import { Context } from "../../Context";
import Table from "../../Widgets/Table";
import constants from "../../constants";
import Deletepopup from "../../Widgets/Deletepopup";

function Policy() {
  const contextValues = useContext(Context);
  const navigate = useNavigate();
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(16);
  const [openDeletePopup, setDeletepopup] = useState(false);
  // const [activeTab, setActiveTab] = useState("access");
  // const [allowPermission, setAllowPermission] = useState([]);

  // const updateAllowCondition = (index, e) => {
  //   const updatedArray = [...allowCondition]; // Create a copy of the array
  //   // Update or add the desired changes
  //   updatedArray[index] = { e };
  //   setAllowCondition(updatedArray); // Update the state
  // };

  useEffect(() => {
    if (search !== null) {
      const timer = setTimeout(() => {
        setPage(1);
        contextValues.GetPolicies({ search, page, rowsPerPage });
      }, 800);
      return () => clearTimeout(timer);
    }
  }, [search]);

  useEffect(() => {
    contextValues.GetPolicies({ search, page, rowsPerPage });
  }, [page]);

  useEffect(() => {
    contextValues.getGroup({ limit: -1 });
    // contextValues.GetLakehouse({ limit: -1 });
    contextValues.getUserPermission({ limit: -1 });
  }, []);

  useEffect(() => {
    contextValues.GetPolicies({ search, page, rowsPerPage });
  }, []);

  // const tabData = [
  //   { title: "Access", value: "access" },
  //   { title: "Masking", value: "masking" },
  //   { title: "Row Level Filter", value: "rowFilter" },
  // ];

  const PolicyHead = [
    { id: 1, label: "Policy No.", position: "left" },
    { id: 2, label: "Policy Name", position: "left" },
    { id: 3, label: "Status", position: "center" },
    { id: 4, label: "Create on", position: "center" },
    { id: 5, label: "Description", position: "center" },
    { id: 6, label: "Audit Logging", position: "center" },
    { id: 7, label: "Actions", position: "center" },
  ];

  return (
    <div className="w-full h-full">
      <Header
        title=" All Policy "
        createButtonText="Add Policy"
        onButtonClick={() => {
          navigate(constants.route.security + "/add-policy");
        }}
        searchPlaceholder="Search..."
        search={search}
        setSearch={setSearch}
      />
      {/* <Tabs tabs={tabData} setActiveTab={setActiveTab} activeTab={activeTab} className="bg-white h-40 flex items-center" /> */}
      <div className="p-10">
        <div className="px-16 pb-12  inline-block  flex items-center text-131 font-semibold text-333333">Policies</div>
        <Table
          setPage={setPage}
          page={page}
          tableHeader={PolicyHead}
          rowsPerPage={rowsPerPage}
          totalPost={contextValues?.policyData?.meta?.total}
          bodyHight="max-h-[calc(100vh-14.4rem)]"
        >
          {filterDataBySearch(contextValues?.policyData?.data, search)?.map((item, index) => {
            return (
              <tr key={index} className="border-b w-full cursor-pointer hover:bg-tableHeaderColor">
                <td className="px-16 py-5 text-xs">
                  <span>{item?.id}</span>
                </td>
                <td className="px-16 py-5 text-xs">
                  <span>{item?.name}</span>
                </td>
                <td className="px-16 py-5 text-center flex justify-center text-xs">
                  <Status title={item?.status} />
                </td>
                <td className="px-16 py-5 text-center text-xs">{dateFormat(item?.createdAt)}</td>
                <td className="px-16 py-5 text-center text-xs">{item?.description}</td>
                <td className="px-16 py-5 text-center text-xs">{item?.auditlogging ? "True" : "False"}</td>
                <td className="px-16 py-5 text-center text-xs">
                  <CustomTooltip direction="top" title="Edit">
                    <i
                      className="fa-solid fa-pen-to-square mx-4 font-normal text-lightGrey cursor-pointer hover:text-blue-600"
                      role="presentation"
                      onClick={() => {
                        navigate(constants.route.security + "/edit-policy/" + item.id);
                      }}
                    />
                  </CustomTooltip>
                  <Deletepopup
                    open={openDeletePopup.id == item?.id}
                    setOpen={setDeletepopup}
                    onDelete={() => {
                      contextValues.deletePolicy(openDeletePopup.id);
                      setDeletepopup({ open: false });
                    }}
                  >
                    <CustomTooltip direction="top" title="Delete">
                      <i
                        className="fa-solid fa-trash-can text-lightGrey font-normal cursor-pointer hover:text-blue-600"
                        role="presentation"
                        onClick={() => {
                          setDeletepopup({ open: true, id: item?.id });
                        }}
                      />
                    </CustomTooltip>
                  </Deletepopup>
                </td>
              </tr>
            );
          })}
        </Table>
        {/* ): null} */}
      </div>
    </div>
    // </div>
  );
}

export default Policy;
