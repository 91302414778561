import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { cloneDeep, mapValues } from "lodash";
import { FlowChart, actions } from "@mrblenny/react-flow-chart";
import Header from "../Widgets/Header";
import Modal from "../Widgets/Modal";
import Confugure from "../Components/flowConfigure/Confugure";
import { Context } from "../Context";
import constants from "../constants";
import Input from "../Widgets/Input";
import DraggedCard from "../Widgets/DraggedCard";
import DraggableCard from "../Widgets/DraggableCard";
import NoData from "../Widgets/NoData";
import { filterArrayByKeys } from "../utils/utils";

const draggableCardItems = [
  {
    name: "Aggregate",
    category: "transformations",
    icon: "https://www.svgrepo.com/show/448924/aggregate.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Conditional Logic",
    category: "transformations",
    icon: "https://www.svgrepo.com/show/21117/robot.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
    },
  },
  {
    name: "Facial Recognition",
    category: "AI/MLComponents",
    icon: "https://www.svgrepo.com/show/525867/face-scan-square.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Gstream Sink",
    category: "dataSink",
    icon: "https://www.svgrepo.com/show/130434/chart.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "HTTP Server",
    category: "triggers",
    icon: "https://www.svgrepo.com/show/439279/proxy-server.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "HTTP Client",
    category: "dataSources",
    icon: "https://www.svgrepo.com/show/224795/http.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Join streams",
    category: "transformations",
    icon: "https://www.svgrepo.com/show/7371/data-flow-chart.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "bottom" },
    },
  },
  {
    name: "Kafka Producer",
    category: "dataSources",
    icon: "https://www.svgrepo.com/show/329947/apachekafka.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Kafka Consumer",
    category: "triggers",
    icon: "https://www.svgrepo.com/show/329947/apachekafka.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Postgres Consumer",
    category: "triggers",
    icon: "https://www.svgrepo.com/show/303301/postgresql-logo.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Postgres Producer",
    category: "dataSources",
    icon: "https://www.svgrepo.com/show/303301/postgresql-logo.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Qr Code Detection",
    category: "AI/MLComponents",
    icon: "https://www.svgrepo.com/show/379577/qr-code-scan.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Send SMS",
    category: "communication",
    icon: "https://www.svgrepo.com/show/190318/sms.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Send Email",
    category: "communication",
    icon: "https://www.svgrepo.com/show/349354/email.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Slack Integration",
    category: "communication",
    icon: "https://www.svgrepo.com/show/452102/slack.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "SNMP Client",
    category: "triggers",
    icon: "https://www.svgrepo.com/show/208358/manager-user.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Tensorflow",
    category: "AI/MLComponents",
    icon: "https://www.svgrepo.com/show/375503/tensorflow-enterprise.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Transformation",
    category: "transformations",
    icon: "https://www.svgrepo.com/show/299285/data-transformation.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Object Storage",
    category: "dataSources",
    icon: "https://www.svgrepo.com/show/340754/object-storage.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "People Counter",
    category: "AI/MLComponents",
    icon: "https://www.svgrepo.com/show/435045/person-people.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "JSON Output",
    category: "dataSink",
    icon: "https://www.svgrepo.com/show/361211/json.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Lakehouse",
    category: "dataSink",
    icon: "https://www.svgrepo.com/show/527682/database.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Parquet",
    category: "dataSink",
    icon: "https://www.svgrepo.com/show/247366/brickwall-stone.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Azure Event Hub",
    category: "triggers",
    icon: "https://www.svgrepo.com/show/448269/azure-aks.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Iceberg",
    category: "dataSink",
    icon: "https://www.svgrepo.com/show/312371/iceberg.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Pub/Sub",
    category: "triggers",
    icon: "https://www.svgrepo.com/show/375484/pubsub.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "Azure Blob Storage",
    category: "dataSources",
    icon: "https://www.svgrepo.com/show/448272/azure-blob-storage.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
  {
    name: "AWS S3",
    category: "dataSources",
    icon: "https://www.svgrepo.com/show/448266/aws.svg",
    ports: {
      port1: { id: "port1", type: "top" },
      port2: { id: "port2", type: "bottom" },
      port3: { id: "port3", type: "left" },
      port4: { id: "port4", type: "right" },
    },
  },
];

function FlowCanvas() {
  let searchResults;
  let categoryIndicesWithResults;
  const INITIAL_FLOW = {
    offset: {
      x: 0,
      y: 0,
    },
    scale: 1,
    nodes: {},
    links: {},
    selected: {},
    hovered: {},
  };
  const params = useParams();
  const navigate = useNavigate();
  const contextValues = useContext(Context);
  const [chart, setChart] = useState(cloneDeep(INITIAL_FLOW));
  const [isConfigure, setIsConfigure] = useState(false);
  const [configureData, setConfigureData] = useState();
  const [flowHeader, setFlowHeader] = useState("");
  const [flowHeaderStatus, setFlowHeaderStatus] = useState("");
  const [isValidate, setIsValidate] = useState(true);
  const [openComponentBar, setOpenComponentBar] = useState(true);
  const [mouseup, setMouseUp] = useState(null);
  const [search, setSearch] = useState();
  const [isDeleteKeyUp, setIsDeleteKeyUp] = useState(null);
  const [collapsedItemId, setCollapsedItemId] = useState(search ? null : [0, 1, 2, 3, 4, 5]);
  const [showPopup, setShowPopup] = useState(false);
  const [chartUpdate, setChartUpdate] = useState(true);
  const [logs, setLogs] = useState("");
  useEffect(() => {
    if (Object.keys(chart.nodes).length > 0) {
      const timer = setTimeout(() => {
        contextValues.putFlowChart({
          chart,
          id: params.id,
          isNoUpdateChart: true,
        });
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [mouseup, chart]);

  useEffect(() => {
    contextValues.GetFlow();
    contextValues.GetComponent(params.id);
  }, []);

  useEffect(() => {
    if (Object.keys(chart.links).length !== 0) {
      contextValues.putFlowChart({ chart, id: params.id });
    }
  }, [isValidate]);

  useEffect(() => {
    if (contextValues.FlowData?.data !== undefined || null) {
      const filterData = contextValues.FlowData?.data?.filter((item) => item.id == params.id);
      if (filterData.length == 0) {
        navigate(constants.route.lackhouseFlow);
      }
      setFlowHeader(filterData[0]?.attributes?.name);
      setFlowHeaderStatus(filterData[0]?.attributes?.status);
      if (filterData[0]?.attributes?.chart !== null) {
        setChart(cloneDeep(filterData[0]?.attributes?.chart));
        // setKey(!key);
      }
    }
  }, [contextValues.FlowData?.data]);

  const handleDeleteKeyPress = (event) => {
    if (event.key === "Delete") {
      if (isDeleteKeyUp) {
        setIsDeleteKeyUp(!isValidate);
      } else {
        setIsDeleteKeyUp(true);
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener("keyup", handleDeleteKeyPress);
    return () => {
      document.removeEventListener("keyup", handleDeleteKeyPress);
    };
  }, []);

  React.useEffect(() => {
    if (isDeleteKeyUp !== null) {
      contextValues.putFlowChart({ chart, id: params.id });
    }
  }, [isDeleteKeyUp]);

  const componentCategories = [
    { title: "Triggers", name: "triggers" },
    { title: "Data Sources", name: "dataSources" },
    { title: "Communication", name: "communication" },
    { title: "Transformations", name: "transformations" },
    { title: "Data Sink", name: "dataSink" },
    { title: "AI/ML Components", name: "AI/MLComponents" },
  ];

  const NodeInnerCustom = ({ node }) => {
    let cardData;
    let tableData = [];
    if (node?.id && contextValues?.componentData) {
      for (const k of Object.keys(chart?.nodes)) {
        if (k === node?.id) {
          for (const i of contextValues?.componentData) {
            if (chart.nodes[k]?.id == i?.attributes?.component_id) {
              cardData = i;
            }
          }
        }
      }
    }
    if (cardData?.attributes?.properties) {
      if (cardData?.attributes?.name == "Conditional Logic") {
        tableData = filterArrayByKeys(["conditional_routing"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "HTTP Server") {
        tableData = filterArrayByKeys(["path", "port", "method", "content_type"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Kafka Consumer") {
        tableData = filterArrayByKeys(["consumergroup", "topic"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Postgres Consumer") {
        tableData = filterArrayByKeys(["connection", "frequency", "query"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "SNMP Client") {
        tableData = filterArrayByKeys(["host", "version", "username", "authkey", "privekey", "oid"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Azure Event Hub") {
        tableData = [
          {
            k: "Event Hub",
            value: contextValues?.configurationData?.data?.find((item) => item?.id == cardData?.attributes?.properties?.event_hub)?.configure?.name,
          },
        ];
      } else if (cardData?.attributes?.name == "Pub/Sub") {
        tableData = filterArrayByKeys(["number_of_outstanding", "number_of_routines"], cardData?.attributes?.properties);
        tableData.unshift({
          k: "Account Name",
          value: contextValues?.configurationData?.data?.find((item) => item?.id == cardData?.attributes?.properties?.account_id)?.configure?.name,
        });
      } else if (cardData?.attributes?.name == "HTTP Client") {
        tableData = filterArrayByKeys(["url"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Kafka Producer") {
        tableData = filterArrayByKeys(["topic"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Postgres Producer") {
        tableData = filterArrayByKeys(["table"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Object Storage") {
        tableData = filterArrayByKeys(["endpoint", "bucketname", "accesskey", "secret", "region"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Send SMS") {
        tableData = filterArrayByKeys(["from", "to"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Send Email") {
        tableData = filterArrayByKeys(["fromEmail", "toEmail"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Slack Integration") {
        tableData = filterArrayByKeys(["botname", "channel"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Aggregate") {
        tableData = filterArrayByKeys(["Uniquekey"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Join streams") {
        tableData = filterArrayByKeys(["windowFunction"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Transformation") {
        tableData = filterArrayByKeys(["language"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Gstream Sink") {
        tableData = filterArrayByKeys(["method", "endpoint"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "JSON Output") {
        tableData = filterArrayByKeys(["type"], cardData?.attributes?.properties);
        tableData.push({
          k: "Storage",
          value: contextValues?.configurationData?.data?.find((item) => item?.id == cardData?.attributes?.properties?.account_id)?.configure?.name,
        });
      } else if (cardData?.attributes?.name == "Lakehouse") {
        tableData = filterArrayByKeys(["lakehouse"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Parquet") {
        tableData = filterArrayByKeys(["name", "max_file_size", "max_row_group_size"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Iceberg") {
        tableData = filterArrayByKeys(["catalog", "namespace", "table"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Facial Recognition") {
        tableData = filterArrayByKeys(["url_type", "url"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Qr Code Detection") {
        tableData = filterArrayByKeys(["image", "url"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Tensorflow") {
        tableData = filterArrayByKeys(["type", "url"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "Tensorflow") {
        tableData = filterArrayByKeys(["type", "url"], cardData?.attributes?.properties);
      } else if (cardData?.attributes?.name == "People Counter") {
        tableData = filterArrayByKeys(["type", "url"], cardData?.attributes?.properties);
      }
    }
    return (
      <DraggedCard
        setIsConfigure={setIsConfigure}
        setConfigureData={setConfigureData}
        node={node}
        setChart={setChart}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        setLogs={setLogs}
        chart={chart}
        setMouseUp={setMouseUp}
        mouseup={mouseup}
        cardData={cardData}
        tableData={tableData}
      />
    );
  };

  const getChart = () => {
    return chart != null ? chart : cloneDeep(INITIAL_FLOW);
  };
  const handlebackbtn = () => {
    navigate(-1);
  };
  const stateActions = mapValues(actions, (func, funcName) => (...args) => {
    let chartData = getChart();
    chartData = {
      ...chartData,
      ...func(...args)(chartData),
    };
    if (funcName == "onDeleteKey") {
      contextValues?.updateCanvasEdgeDeletion({
        flow_id: params?.id,
        link_id: chartData?.hovered?.id,
      });
    }
    if (chartUpdate && funcName == "onNodeMouseEnter") {
      setChartUpdate(false);
      setChart(chartData);
    } else if (funcName == "onNodeMouseLeave") {
      setChartUpdate(true);
    }
    if (funcName != "onNodeMouseEnter" && funcName != "onDragNodeStop") {
      setChart(chartData);
    }
  });

  // const stateActions = mapValues(actions, (func) => (...args) => {
  //   setChart(func(...args));
  // });

  const PortCustom = (props) => {
    return (
      <>
        {props?.port?.value && (
          <div className="flex content-center justify-center items-center cursor-pointer rounded-full w-14 h-14 font-bold bg-[#eaeff2] border-gray-400 border-2 mr-2">
            {props?.port?.value !== "true" && props?.port?.value !== "false" && props?.port?.value}
            {props?.port?.value == "true" && <i className="fa-solid fa-check" />}
            {props?.port?.value == "false" && <i className="fa-solid fa-xmark" />}
          </div>
        )}
        {!props?.port?.value && (
          <div className="flex content-center items-center cursor-pointer rounded-full bg-[#eaeff2] border-gray-400 border-2 p-3" />
        )}
      </>
    );
  };

  // const NodeCustom = React.forwardRef(({ node, children, ...otherProps }, ref) => {
  //   return (
  //     <div ref={ref} {...otherProps} className="shadow-xl bg-white absolute" id="draggable">
  //       {children}
  //     </div>
  //   );
  // });

  return (
    <div className="w-full h-screen flex">
      <div className="flex flex-col overflow-hidden w-full">
        <Header
          title={flowHeader}
          status={flowHeaderStatus}
          onMenuIconClick={() => {
            setOpenComponentBar(true);
          }}
          buttonIcons={flowHeaderStatus !== "started" ? <i className="fa-solid fa-play mr-6" /> : <i className="fa-solid fa-stop mr-6" />}
          showMenuIcon={openComponentBar}
          openComponentBar
          showBackIcon
          runButtonText={flowHeaderStatus !== "started" ? "Run" : "Stop"}
          onRunClick={() => {
            if (flowHeaderStatus !== "started") {
              contextValues.putFlowChart({ id: params.id, status: "started" });
              contextValues.runFlow({ flow_id: params.id, action: "start" });
            } else {
              contextValues.runFlow({ flow_id: params.id, action: "stop" });
            }
          }}
          onBack={() => {
            handlebackbtn();
          }}
        />

        {/* <FlowChartWithState
          initialValue={chart}
          callbacks={stateActions}
          key={key}
          config={{
            smartRouting: true,
            zoom: {
              maxScale: 1,
              minScale: 0.1,
            },
            validateLink: (props) => {
              const egresslink = [];
              const ingresslink = [];
              let egressName;
              let ingressName;
              for (const i of Object.keys(chart.links)) {
                if (chart.links[i].from.nodeId == props.fromNodeId) {
                  if (chart.links[i].to.nodeId !== undefined) {
                    egresslink.push({
                      from: chart.links[i].from.nodeId,
                      to: chart.links[i].to.nodeId,
                    });
                  }
                }
                if (chart.links[i].to.nodeId == props.toNodeId) {
                  ingresslink.push({
                    from: chart.links[i].from.nodeId,
                    to: chart.links[i].to.nodeId,
                  });
                }
              }
              egresslink.push({ from: props.fromNodeId, to: props.toNodeId });
              ingresslink.push({ from: props.fromNodeId, to: props.toNodeId });
              for (const i of Object.keys(chart.nodes)) {
                if (chart.nodes[i].id == props.fromNodeId) {
                  egressName = chart.nodes[i].properties.name;
                }
                if (chart.nodes[i].id == props.toNodeId) {
                  ingressName = chart.nodes[i].properties.name;
                }
              }
              const fromData = contextValues.componentData.filter((data) => data?.attributes?.component_id?.includes(props.fromNodeId));
              const toData = contextValues.componentData.filter((data) => data?.attributes?.component_id?.includes(props.toNodeId));
              contextValues.putComponent({
                name: egressName,
                egress: egresslink,
                flow: params.id,
                componentId: fromData[0]?.id,
              });
              contextValues.putComponent({
                name: ingressName,
                ingress: ingresslink,
                id: params.id,
                componentId: toData[0]?.id,
              });
              setIsValidate(!isValidate);
              return true;
            },
          }}
          Components={{
            NodeInner: NodeInnerCustom,
            Node: NodeCustom,
            Port: PortCustom,
          }}
        /> */}

        <FlowChart
          chart={chart}
          callbacks={stateActions}
          config={{
            smartRouting: true,
            zoom: {
              maxScale: 1,
              minScale: 0.1,
            },
            validateLink: (props) => {
              const egresslink = [];
              const ingresslink = [];
              let egressName;
              let ingressName;
              for (const i of Object.keys(chart.links)) {
                if (chart.links[i].from.nodeId == props.fromNodeId) {
                  if (chart.links[i].to.nodeId !== undefined) {
                    egresslink.push({
                      from: chart.links[i].from.nodeId,
                      to: chart.links[i].to.nodeId,
                      fromPortId: chart.links[i].from.portId,
                      toPortId: chart.links[i].to.portId,
                    });
                  }
                }
                if (chart.links[i].to.nodeId == props.toNodeId) {
                  ingresslink.push({
                    from: chart.links[i].from.nodeId,
                    to: chart.links[i].to.nodeId,
                    fromPortId: chart.links[i].from.portId,
                    toPortId: chart.links[i].to.portId,
                  });
                }
              }
              egresslink.push({
                from: props.fromNodeId,
                to: props.toNodeId,
                fromPortId: props.fromPortId,
                toPortId: props.toPortId,
              });
              ingresslink.push({
                from: props.fromNodeId,
                to: props.toNodeId,
                fromPortId: props.fromPortId,
                toPortId: props.toPortId,
              });
              for (const i of Object.keys(chart.nodes)) {
                if (chart.nodes[i].id == props.fromNodeId) {
                  egressName = chart.nodes[i].properties.name;
                }
                if (chart.nodes[i].id == props.toNodeId) {
                  ingressName = chart.nodes[i].properties.name;
                }
              }
              const fromData = contextValues.componentData.find((data) => data?.attributes?.component_id?.includes(props.fromNodeId));
              const toData = contextValues.componentData.find((data) => data?.attributes?.component_id?.includes(props.toNodeId));
              function getToValuesByFromPortId(data, fromPortId) {
                const toValues = [];
                for (const item of data) {
                  if (item.fromPortId == `port${fromPortId}`) {
                    toValues.push(item.to);
                  }
                }
                return [...new Set(toValues)];
              }
              if (props?.fromPortId != "port1" && fromData?.attributes?.properties?.condition) {
                if (fromData?.attributes?.properties?.condition) {
                  fromData?.attributes.properties.condition.forEach((e, index) => {
                    e.child_node = getToValuesByFromPortId(egresslink, index + 2);
                  });
                }
              }
              contextValues.putComponent({
                name: egressName,
                egress: egresslink,
                flow: params.id,
                componentId: fromData?.id,
                fieldValue: fromData?.attributes?.properties,
              });
              contextValues.putComponent({
                name: ingressName,
                ingress: ingresslink,
                id: params.id,
                componentId: toData?.id,
                fieldValue: toData?.attributes?.properties,
              });
              contextValues.createLineage({
                source_id: fromData?.attributes?.component_id,
                destination_id: toData?.attributes?.component_id,
                source_type: fromData?.attributes?.name,
                destination_type: toData?.attributes?.name,
                source_properties: fromData?.attributes?.properties,
                destination_properties: toData?.attributes?.properties,
              });
              setIsValidate(!isValidate);
              return true;
            },
          }}
          Components={{
            NodeInner: NodeInnerCustom,
            Port: PortCustom,
          }}
        />

        <Modal showModel={isConfigure} setShowModel={setIsConfigure}>
          <Confugure
            setIsConfigure={setIsConfigure}
            isConfigure={isConfigure}
            chart={chart}
            setChart={setChart}
            // setKey={setKey}
            // key={key}
            configureData={configureData}
            node={chart?.nodes[configureData]}
          />
        </Modal>
      </div>
      <Modal showModel={showPopup} setShowModel={setShowPopup}>
        <div className="pb-16 flex flex-col justify-between">
          <div className="p-10 bg-backgroundColor flex  w-full  justify-between text-md font-medium text-sidebarFormHeader h-38 rounded-t-sm">
            <span>Logs</span>
            <i
              className="fa-solid fa-xmark text-md cursor-pointer text-00000073 hover:text-363e63"
              role="presentation"
              onClick={() => setShowPopup(false)}
            />
          </div>
          <div className={`flex h-340 p-16 overflow-y-auto ${!logs ? "justify-center" : "justify-start"}`}>{logs == "" ? <NoData /> : logs}</div>
        </div>
      </Modal>
      {openComponentBar && (
        <div className="flex flex-col w-200 h-screen overflow-hidden overflow-y-auto bg-white">
          <div className="fixed z-99 bg-white px-8 py-8">
            <div className="flex justify-between items-center pb-10">
              <div className="text-lg text-gray-900 font-bold  truncate">Components</div>
              <i className="fa-solid fa-xmark text-md cursor-pointer" role="presentation" onClick={() => setOpenComponentBar(false)} />
            </div>
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="search..."
              search
              className="w-full"
              setSearch={setSearch}
            />
          </div>
          <div className="mt-56 px-8 py-8">
            {componentCategories.map((category, catIndex) => {
              searchResults =
                search !== undefined || search !== ""
                  ? draggableCardItems.filter((cardItem) => cardItem.name?.toLowerCase()?.includes(search?.toLowerCase()))
                  : null;
              categoryIndicesWithResults =
                search !== undefined || search !== ""
                  ? searchResults.map((cardItem) => componentCategories.findIndex((cat) => cat.name === cardItem.category))
                  : null;

              return (
                <div key={catIndex}>
                  <div
                    className={`overflow-hidden px-2 py-5 cursor-pointer flex items-center text-xs bg-f5f5f5 mt-8 ${
                      collapsedItemId?.includes(catIndex) || categoryIndicesWithResults?.includes(catIndex)
                        ? "rounded-t-sm border-b border-gray-200"
                        : "rounded-sm"
                    } min-h-[40px] border-b-2`}
                    role="presentation"
                    key={catIndex}
                    onClick={() => {
                      const id = [...collapsedItemId];
                      if (id?.includes(catIndex)) {
                        id.splice(id.indexOf(catIndex), 1);
                      } else {
                        id.push(catIndex);
                      }
                      setCollapsedItemId(id);
                    }}
                  >
                    <i
                      className={`fa-solid mr-6 hover:text-primaryColor px-4 ${
                        collapsedItemId?.includes(catIndex) ? "fa-angle-up" : "fa-angle-down"
                      }`}
                    />
                    {category.title}
                  </div>
                  {collapsedItemId?.includes(catIndex) || categoryIndicesWithResults?.includes(catIndex) ? (
                    <div className="px-6 bg-white rounded-b-sm border-b border-l border-r border-gray-300">
                      <div className="grid grid-cols-3 gap-6 py-6">
                        {draggableCardItems
                          ?.filter((val) => {
                            return !search ? val : val.name?.toLowerCase()?.includes(search.toLowerCase());
                          })
                          ?.map((cardItem, index) => (
                            <React.Fragment key={index}>
                              {cardItem.category === category.name ? (
                                <DraggableCard
                                  type={cardItem.type}
                                  properties={{
                                    name: cardItem.name,
                                    icon: cardItem.icon,
                                  }}
                                  ports={cardItem.ports}
                                  chart={chart}
                                />
                              ) : null}
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default FlowCanvas;
