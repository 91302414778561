import { memo } from "react";
import { useNavigate } from "react-router-dom";

import IntegrationCard from "../../Widgets/IntegrationCard";
import constants from "../../constants";

const SourceIngestion = [
  {
    id: 1,
    name: "PostgreSQL",
    icon: "https://www.svgrepo.com/show/439268/postgresql.svg",
  },
];

const Configurations = () => {
  const navigate = useNavigate();

  return (
    <div className="p-16">
      <div>Configurations</div>
      <div className="grid grid-cols-8 gap-8 pt-12">
        {SourceIngestion.map((item) => (
          <IntegrationCard
            key={item?.id}
            title={item?.name}
            icon={item?.icon}
            onClick={() => {
              navigate(constants.route.postgreSQL);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(Configurations);
