import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CodeEditer from "../../Widgets/CodeEditer";
import CommonField from "../../Widgets/CommonField";
import { Context } from "../../Context";
import constants from "../../constants";

const Parquet = ({ parquet, setParquet, handleChange }) => {
  const contextValues = useContext(Context);
  const navigate = useNavigate();

  const handleQueryChange = (e) => {
    setParquet({ ...parquet, schema: e });
  };

  return (
    <div className="grid grid-cols-2 gap-8 w-full h-fit">
      <div className="w-full col-span-2">
        <CodeEditer name="schema" titleText="Schema" value={parquet?.schema} onChange={handleQueryChange} />
      </div>
      <CommonField className="w-full col-span-2" titleText="Name" name="name" value={parquet?.name || ""} onChange={handleChange} />
      <CommonField
        className="col-span-2"
        titleText="Source"
        value={parquet?.source}
        options={constants.components.parquetSources}
        onChange={(source) => {
          setParquet({
            ...parquet,
            source,
          });
        }}
        error
        selectfield
      />

      {parquet?.source?.value === "local_fs" && (
        <CommonField className="col-span-2" titleText="Path" name="path" value={parquet?.path || ""} onChange={handleChange} error="field" />
      )}

      {parquet?.source?.value === "s3" && contextValues?.configurationData?.data?.length == 0 && (
        <div className="p-12 bg-amber-50 bg-opacity-30 rounded-md col-span-2">
          <i className="fa-solid fa-triangle-exclamation text-amber-600 text-lg mb-8" />
          <div className="text-xs text-amber-600">
            To customize your experience, please access the{" "}
            <span
              className="hover:text-blue-500 hover:underline cursor-pointer font-semibold"
              role="presentation"
              onClick={() => navigate(constants.route.settings)}
            >
              settings
            </span>{" "}
            and configure the desired options accordingly.
          </div>
        </div>
      )}
      <CommonField
        titleText="Max file size"
        name="max_file_size"
        value={parquet.max_file_size == undefined ? "1GB" : parquet.max_file_size || ""}
        onChange={handleChange}
      />
      <CommonField
        titleText="Max row group size"
        name="max_row_group_size"
        value={parquet.max_row_group_size == undefined ? "750MB" : parquet.max_row_group_size || ""}
        onChange={handleChange}
      />
    </div>
  );
};

export default Parquet;
