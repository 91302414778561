import { memo } from "react";

const IntegrationCard = ({ title, icon, onClick }) => {
  return (
    <div className="cursor-pointer" onClick={onClick} role="presentation">
      <div className="rounded-md py-4 px-6 flex justify-start flex-col h-88 shadow-sm border-2 border-b-indigo-600 border-r-indigo-600">
        <div className="text-sm text-semibold text-indigo-800">{title}</div>
        <div className="flex h-full justify-center items-center">
          <img width={60} src={icon} alt="" />
        </div>
      </div>
    </div>
  );
};

export default memo(IntegrationCard);
