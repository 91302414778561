const NoData = () => {
  return (
    <div className="flex justify-center items-center w-full p-20 flex-col">
      <div className="flex flex-col justify-center items-center">
        <img src={process.env.PUBLIC_URL + "/assets/no-data.svg"} alt="No Data..." />
        <span className="mt-8 text-[16px] text-cardShadowColor font-semibold">No Data</span>
      </div>
    </div>
  );
};

export default NoData;
