/* eslint-disable no-console */
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import Radio from "../../Widgets/Radio";
import CommonField from "../../Widgets/CommonField";
import Checkbox from "../../Widgets/Checkbox";
import Table from "../../Widgets/Table";
import Button from "../../Widgets/Button";
import CustomTooltip from "../../Widgets/Tooltip";
import { Context } from "../../Context";
import Header from "../../Widgets/Header";
import constants from "../../constants";
import Tabs from "../../Widgets/Tabs";

const AddPolicy = () => {
  const params = useParams();
  const contextValues = useContext(Context);
  const navigate = useNavigate();
  const [policyData, setPolicyData] = useState({ type: "access" });
  const [allowCondition, setAllowCondition] = useState([]);
  const [maskingAllowCondition, setMaskingAllowCondition] = useState([]);
  const [userData, setuserData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [catalogData, setCatalogData] = useState([]);
  const [activeTab, setActiveTab] = useState("access");
  const [namespaceOptionData, setNamespaceOptionData] = useState([]);
  const [tableOptionData, setTableOptionData] = useState([]);
  const [columnOptionData, setColumnOptionData] = useState([]);
  const [page, setPage] = useState(1);
  const [denyCondition, setDenyCondition] = useState([]);
  const [maskingDenyCondition, setMaskingDenyCondition] = useState([]);
  const [denyNamespaceOptionData, setDenyNamespaceOptionData] = useState([]);
  const [maskingDenyNamespaceOptionData, setMaskingDenyNamespaceOptionData] = useState([]);
  const [maskingNamespaceOptionData, setMaskingNamespaceOptionData] = useState([]);
  const [denyTableOptionData, setDenyTableOptionData] = useState([]);
  const [maskingDenyTableOptionData, setMaskingDenyTableOptionData] = useState([]);
  const [maskingTableOptionData, setMaskingTableOptionData] = useState([]);
  const [denyColumnOptionData, setDenyColumnOptionData] = useState([]);
  const [maskingDenyColumnOptionData, setMaskingDenyColumnOptionData] = useState([]);
  const [maskingColumnOptionData, setMaskingColumnOptionData] = useState([]);
  const [allowSelectedIndex, setAllowSelectedIndex] = useState(null);
  const [maskingAllowSelectedIndex, setMaskingAllowSelectedIndex] = useState(null);
  const [denySelectedIndex, setDenySelectedIndex] = useState(null);
  const [maskingDenySelectedIndex, setMaskingDenySelectedIndex] = useState(null);

  useEffect(() => {
    contextValues.getGroup({ limit: -1 });
    // contextValues.GetLakehouse({ limit: -1 });
    contextValues.getAllCatalogs({ limit: -1 });
    contextValues.getUserPermission({ limit: -1 });
  }, []);

  const tabData = [
    { title: "Access", value: "access" },
    { title: "Masking", value: "masking" },
    { title: "Row Level Filter", value: "rowFilter" },
  ];

  useEffect(() => {
    if (contextValues?.groupData?.data?.length > 0) {
      setGroupData(
        contextValues?.groupData?.data?.map((item) => ({
          value: `${item?.id}`,
          label: `${item?.groupname}`,
        }))
      );
    }
    if (contextValues?.getCatalogs) {
      setCatalogData(
        contextValues?.getCatalogs?.map((item) => ({
          value: `${item.catalog_name}`,
          label: `${item.catalog_name}`,
        }))
      );
    }
    if (contextValues?.userPermission) {
      setuserData(
        contextValues?.userPermission?.data?.map((item) => ({
          value: `${item.id}`,
          label: `${item.username}`,
        }))
      );
    }
  }, [contextValues?.groupData, contextValues?.getCatalogs, contextValues?.userPermission]);

  const typeData = [
    { value: "user", label: "User" },
    { value: "group", label: "Group" },
  ];
  useEffect(() => {
    if (params.id) {
      contextValues.getEditPolicy(params.id);
    } else {
      contextValues.setPolicyData({});
      setPolicyData({ type: "access" });
    }
  }, [params.id]);
  useEffect(() => {
    if (contextValues.editPolicy && params.id) {
      const item = contextValues?.editPolicy?.attributes;
      setPolicyData({
        type: item?.type,
        name: item?.name,
        status: item?.status,
        description: item?.description,
        auditlogging: item?.auditlogging,
      });
      setAllowCondition(item?.allow_condition?.access ?? [{}]);
      setDenyCondition(item?.deny_condition?.access ?? [{}]);
      setMaskingAllowCondition(item?.allow_condition?.masking ?? [{}]);
      setMaskingDenyCondition(item?.deny_condition?.masking ?? [{}]);
    }
  }, [contextValues.editPolicy]);
  //   const handleEdit = (item) => {
  //     if (item) {
  //       setEditItem(item?.id);
  //       setPolicyData({
  //         type: item?.type,
  //         name: item?.name,
  //         status: item?.status,
  //         description: item?.description,
  //         auditlogging: item?.auditlogging,
  //       });
  //       setAllowCondition(item?.allow_condition ?? [{}]);
  //       setDenyCondition(item?.deny_condition ?? [{}]);
  //     }
  //   };
  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    setPolicyData((values) => ({
      ...values,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    if (params.id) {
      contextValues.putPolicy({
        ...policyData,
        allow_condition: { access: allowCondition, masking: maskingAllowCondition },
        deny_condition: { access: denyCondition, masking: maskingDenyCondition },
        status: "active",
        id: params.id,
      });
      navigate(constants.route.security + "/accessmanager");
    } else {
      contextValues.createPolicy({
        ...policyData,
        allow_condition: { access: allowCondition, masking: maskingAllowCondition },
        deny_condition: { access: denyCondition, masking: maskingDenyCondition },
        status: "active",
      });
      navigate(constants.route.security + "/accessmanager");
    }
    // setEditItem(null);
    // setOpenSidebar(false);
    setPolicyData({});
    setAllowCondition([{}]);
    setDenyCondition([{}]);
  };

  const addAllowNewCondition = () => {
    const newCondition = { type: typeData[0] };
    setAllowCondition([...allowCondition, newCondition]);
  };
  const removeCondition = (index) => {
    const updatedConditions = [...allowCondition];
    updatedConditions.splice(index, 1);
    setAllowCondition(updatedConditions);
  };

  const addMaskingAllowNewCondition = () => {
    const newCondition = { type: typeData[0] };
    setMaskingAllowCondition([...maskingAllowCondition, newCondition]);
  };

  const maskingRemoveCondition = (index) => {
    const updatedConditions = [...maskingAllowCondition];
    updatedConditions.splice(index, 1);
    setMaskingAllowCondition(updatedConditions);
  };

  const denyRemoveCondition = (index) => {
    const updatedConditions = [...denyCondition];
    updatedConditions.splice(index, 1);
    setDenyCondition(updatedConditions);
  };
  const addDenyNewCondition = () => {
    const newCondition = { type: typeData[0] };
    setDenyCondition([...denyCondition, newCondition]);
  };

  const maskingDenyRemoveCondition = (index) => {
    const updatedConditions = [...maskingDenyCondition];
    updatedConditions.splice(index, 1);
    setMaskingDenyCondition(updatedConditions);
  };
  const addMaskingDenyNewCondition = () => {
    const newCondition = { type: typeData[0] };
    setMaskingDenyCondition([...maskingDenyCondition, newCondition]);
  };
  const handlebackbtn = () => {
    navigate(-1);
  };

  const validate = () => {
    let isValidate = false;
    // const checkConditions = (conditions) => {
    //   return conditions.every((item) => {
    //     return item.user_premission?.label && item.catalog?.label;
    //   });
    // };
    function checkConditions(data) {
      return data.every((item) => ((item?.type && item?.user_premission?.length > 0) || item?.group?.length > 0) && item?.catalog);
    }
    if (Object.keys(policyData).length !== 0) {
      if (!policyData?.name || /[A-Z]/.test(policyData?.name) || policyData?.name?.includes(" ")) {
        isValidate = false;
      } else if (allowCondition && Array.isArray(allowCondition)) {
        isValidate = checkConditions(allowCondition);
      } else {
        isValidate = true;
      }
      if (isValidate && denyCondition && Array.isArray(denyCondition)) {
        isValidate = checkConditions(denyCondition);
      }
    }

    // function checkAllowDenyCondition(array) {
    //   for (const item of array) {
    //     if (!item.user_premission || !item.group) {
    //       return false;
    //     }
    //   }
    //   return true;
    // }

    // const checking = checkAllowDenyCondition(allowCondition);
    // isValidate = checkAllowDenyCondition(denyCondition);
    // console.log("checking", checking);

    return isValidate;
  };

  function isSaveAllowed() {
    if (allowCondition) return allowCondition.every((i) => ((i?.type && i?.user_premission?.length > 0) || i?.group?.length > 0) && i?.catalog);
  }
  function isSaveMasked() {
    if (maskingAllowCondition)
      return maskingAllowCondition.every((i) => ((i?.type && i?.user_premission?.length > 0) || i?.group?.length > 0) && i?.catalog);
  }
  function isSaveDeny() {
    if (denyCondition) return denyCondition.every((i) => ((i?.type && i?.user_premission?.length > 0) || i?.group?.length > 0) && i?.catalog);
  }
  function isDenyMaskingSave() {
    if (maskingDenyCondition)
      return maskingDenyCondition.every((i) => ((i?.type && i?.user_premission?.length > 0) || i?.group?.length > 0) && i?.catalog);
  }

  const AllowHead = [
    { id: 1, label: "Select Type", position: "center" },
    { id: 2, label: "User/Group", position: "center" },
    { id: 3, label: "Catalog", position: "center" },
    { id: 4, label: "Namespaces", position: "center" },
    { id: 5, label: "Tables", position: "center" },
    { id: 6, label: "Columns", position: "center" },
    { id: 7, label: "Actions", position: "center" },
  ];
  return (
    <div className="w-full h-full">
      <Header
        title={params.id ? "Edit Policy" : "Add Policy"}
        showBackIcon
        onBack={() => {
          handlebackbtn();
        }}
      />
      {/* <OverlayForm
          title={editItem ? "Edit Policy" : "Add Policy"}
          disable={!validate()}
          onClose={() => {
            setOpenSidebar(false);
            setPolicyData({});
            setAllowCondition([{}]);
            setDenyCondition([{}]);
          }}
          handleSubmit={handleSubmit}
          createButtonName={editItem ? "Save" : "Create"}
        > */}
      <div className="p-10">
        <div className="flex flex-col">
          <div className="flex justify-start items-center text-2xs uppercase text-lightGrey">Type</div>
          <div className="flex">
            <Tabs tabs={tabData} setActiveTab={setActiveTab} activeTab={activeTab} className="flex items-center mb-8 mt-2" />
            {/* <Radio
              value="access"
              buttonName="Access"
              name="policyType"
              onChange={() => {
                setPolicyData({
                  ...policyData,
                  type: "access",
                });
              }}
              checked={policyData?.type === "access"}
              // className="p-4"
            />
            <Radio
              value="masking"
              buttonName="Masking"
              name="policyType"
              onChange={() => {
                setPolicyData({
                  ...policyData,
                  type: "masking",
                });
              }}
              checked={policyData?.type === "masking"}
              className="p-4"
            />
            <Radio
              value="rowFilter"
              buttonName="RowLevelFilter"
              name="policyType"
              onChange={() => {
                setPolicyData({
                  ...policyData,
                  type: "rowFilter",
                });
              }}
              checked={policyData?.type === "rowFilter"}
              className="p-4"
            /> */}
          </div>
        </div>
        <CommonField className="mb-8" titleText="Policy Name" name="name" value={policyData.name || ""} onChange={handleChange} error="lowercase" />

        <CommonField titleText="Description" rows="4" name="description" onChange={handleChange} value={policyData.description} textarea />
        <div className="flex justify-start items-center text-xs mb-8">
          <Checkbox
            checked={policyData.auditlogging}
            label="Audit Logging"
            onChange={() => {
              setPolicyData({
                ...policyData,
                auditlogging: !policyData.auditlogging,
              });
            }}
          />
        </div>
        {activeTab == "access" && (
          <div className="mb-8">
            <Table
              title={
                <div className="flex w-full items-center justify-between mb-8">
                  <div>Allow condition</div>
                  <Button
                    variant="contained"
                    onClick={() => addAllowNewCondition()}
                    color="primaryColor"
                    startIcon={<i className="fa-solid fa-plus mr-4" />}
                  >
                    Add
                  </Button>
                </div>
              }
              setPage={setPage}
              page={page}
              tableHeader={AllowHead}
              removeSidePadding
            >
              {allowCondition?.map((item, index) => {
                return (
                  <tr key={index} className={`border-b w-full ${allowSelectedIndex === index ? "bg-highlightColor" : "hover:bg-tableHeaderColor"}`}>
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={typeData}
                        isDisabled={allowSelectedIndex !== index}
                        value={item?.type ?? ""}
                        onChange={(e) => {
                          const updatedArray = [...allowCondition];
                          delete updatedArray[index]?.user;
                          delete updatedArray[index]?.group;
                          updatedArray[index] = { ...updatedArray[index], type: e };
                          setAllowCondition(updatedArray);
                        }}
                        error
                        selectfield
                      />
                    </td>
                    {item?.type?.value == "user" && (
                      <td className="px-4 py-5 max-w-160 cursor-pointer text-primaryColor text-xs">
                        <CommonField
                          className="mb-3"
                          options={userData}
                          isMulti
                          isDisabled={allowSelectedIndex !== index}
                          value={item?.user_premission ?? ""}
                          onChange={(e) => {
                            const updatedArray = [...allowCondition];
                            updatedArray[index] = { ...updatedArray[index], user_premission: e };
                            setAllowCondition(updatedArray);
                          }}
                          error
                          selectfield
                        />
                      </td>
                    )}
                    {item?.type?.value == "group" && (
                      <td className="px-4 py-5 max-w-160 cursor-pointer  text-primaryColor text-xs">
                        <CommonField
                          className="mb-3"
                          isDisabled={allowSelectedIndex !== index}
                          options={groupData}
                          isMulti
                          value={item?.group ?? ""}
                          onChange={(e) => {
                            const updatedArray = [...allowCondition];
                            updatedArray[index] = { ...updatedArray[index], group: e };
                            setAllowCondition(updatedArray);
                          }}
                          error
                          selectfield
                        />
                      </td>
                    )}
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={catalogData}
                        value={item?.catalog ?? ""}
                        isDisabled={allowSelectedIndex !== index}
                        onChange={async (e) => {
                          const updatedArray = [...allowCondition];
                          updatedArray[index] = { ...updatedArray[index], catalog: e, namespace: null, table: null, column: null };
                          setAllowCondition(updatedArray);
                          const namespaces = await contextValues.customNamespace(e?.value);
                          const updatedNamespaceData = [...namespaceOptionData];
                          updatedNamespaceData[index] = namespaces?.map((namespace) => ({
                            value: `${namespace.table_namespace}`,
                            label: `${namespace.table_namespace}`,
                          }));
                          setNamespaceOptionData(updatedNamespaceData);
                        }}
                        error
                        selectfield
                      />
                    </td>
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={namespaceOptionData[index]}
                        value={item?.namespace ?? ""}
                        isDisabled={allowSelectedIndex == index ? !namespaceOptionData[index] : true}
                        onChange={async (e) => {
                          const updatedArray = [...allowCondition];
                          updatedArray[index] = { ...updatedArray[index], namespace: e, table: null, column: null };
                          setAllowCondition(updatedArray);
                          const tables = await contextValues.GetTables({ table_namespace: e?.value });
                          const updatedTableData = [...tableOptionData];
                          updatedTableData[index] = tables?.map((table) => ({
                            value: `${table.table_name}`,
                            label: `${table.table_name}`,
                          }));
                          setTableOptionData(updatedTableData);
                        }}
                        error
                        selectfield
                      />
                    </td>
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={tableOptionData[index]}
                        value={item?.table ?? ""}
                        isDisabled={allowSelectedIndex == index ? !tableOptionData[index] : true}
                        onChange={async (e) => {
                          const updatedArray = [...allowCondition];
                          updatedArray[index] = { ...updatedArray[index], table: e, column: null };
                          setAllowCondition(updatedArray);
                          const Columns = await contextValues.getCatalogsData({
                            table_name: e?.value,
                            catalog_name: updatedArray[index]?.catalog?.value,
                            table_namespace: updatedArray[index]?.namespace?.value,
                          });
                          const updatedColumnData = [...columnOptionData];
                          updatedColumnData[index] = Columns?.data?.data?.[0]?.metadata?.schema?.fields?.map((column) => ({
                            value: `${column.name}`,
                            label: `${column.name}`,
                          }));
                          setColumnOptionData(updatedColumnData);
                        }}
                        error
                        selectfield
                      />
                    </td>
                    <td className="px-4 py-5 max-w-160 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={columnOptionData[index]}
                        isDisabled={allowSelectedIndex == index ? !columnOptionData[index] : true}
                        value={item?.column ?? ""}
                        onChange={(e) => {
                          const updatedArray = [...allowCondition];
                          updatedArray[index] = { ...updatedArray[index], column: e };
                          setAllowCondition(updatedArray);
                        }}
                        isMulti
                        selectfield
                      />
                    </td>
                    <td className="px-4 py-5 text-center cursor-pointer justify-center text-xs">
                      <CustomTooltip direction="top" title={allowSelectedIndex !== index ? "Edit" : "Save"}>
                        <i
                          className={
                            allowSelectedIndex !== index
                              ? "fa-solid fa-pen-to-square mx-8"
                              : allowSelectedIndex === index &&
                                !allowCondition.every((i) => ((i?.type && i?.user_premission?.length > 0) || i?.group?.length > 0) && i?.catalog)
                              ? "fa-solid fa-check mx-8 cursor-not-allowed opacity-50"
                              : "fa-solid fa-check mx-8 cursor-pointer hover:text-blue-600"
                          }
                          role="presentation"
                          onClick={async () => {
                            if (allowSelectedIndex !== index) {
                              setAllowSelectedIndex(index);
                              try {
                                const [namespaceData, tableData, columnData] = await Promise.all([
                                  item?.catalog?.value ? contextValues.customNamespace(item?.catalog?.value) : Promise.resolve(null),
                                  item?.namespace?.value
                                    ? contextValues.GetTables({ table_namespace: item?.namespace?.value })
                                    : Promise.resolve(null),
                                  item?.table?.value
                                    ? contextValues?.getCatalogsData({
                                        table_name: item?.table?.value,
                                        catalog_name: item?.catalog?.value,
                                        table_namespace: item?.namespace?.value,
                                      })
                                    : Promise.resolve(null),
                                ]);
                                const updatedNamespaceData = [...namespaceOptionData];
                                updatedNamespaceData[index] = namespaceData?.map((namespace) => ({
                                  value: `${namespace.table_namespace}`,
                                  label: `${namespace.table_namespace}`,
                                }));
                                setNamespaceOptionData(updatedNamespaceData);
                                const updatedTableData = [...tableOptionData];
                                updatedTableData[index] = tableData?.map((table) => ({
                                  value: `${table.table_name}`,
                                  label: `${table.table_name}`,
                                }));
                                setTableOptionData(updatedTableData);
                                const updatedColumnData = [...columnOptionData];
                                updatedColumnData[index] = columnData?.data?.data?.[0]?.metadata?.schema?.fields?.map((column) => ({
                                  value: `${column.name}`,
                                  label: `${column.name}`,
                                }));
                                setColumnOptionData(updatedColumnData);
                              } catch (error) {
                                // Handle any errors from the API calls
                                // console.error("Error fetching data:", error);
                              }
                            } else if (isSaveAllowed()) {
                              setAllowSelectedIndex(null);
                            }
                          }}
                          disabled={allowSelectedIndex === index && !isSaveAllowed()}
                        />
                      </CustomTooltip>
                      <CustomTooltip direction="top" title="Delete">
                        <i
                          className="fa-solid fa-trash-can mx-4 cursor-pointer hover:text-red-600"
                          role="presentation"
                          onClick={() => removeCondition(index)}
                          // onClick={() => contextValues.deleteConfiguration(item.id)}
                        />
                      </CustomTooltip>
                    </td>
                  </tr>
                );
              })}
            </Table>
          </div>
        )}
        {activeTab == "masking" && (
          <div className="mb-8">
            <Table
              title={
                <div className="flex w-full items-center justify-between mb-8">
                  <div>Allow condition</div>
                  <Button
                    variant="contained"
                    onClick={() => addMaskingAllowNewCondition()}
                    color="primaryColor"
                    startIcon={<i className="fa-solid fa-plus mr-4" />}
                  >
                    Add
                  </Button>
                </div>
              }
              setPage={setPage}
              page={page}
              tableHeader={AllowHead}
              removeSidePadding
            >
              {maskingAllowCondition?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className={`border-b w-full ${maskingAllowSelectedIndex === index ? "bg-highlightColor" : "hover:bg-tableHeaderColor"}`}
                  >
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={typeData}
                        isDisabled={maskingAllowSelectedIndex !== index}
                        value={item?.type ?? ""}
                        onChange={(e) => {
                          const updatedArray = [...maskingAllowCondition];
                          delete updatedArray[index]?.user;
                          delete updatedArray[index]?.group;
                          updatedArray[index] = { ...updatedArray[index], type: e };
                          setMaskingAllowCondition(updatedArray);
                        }}
                        error
                        selectfield
                      />
                    </td>
                    {item?.type?.value == "user" && (
                      <td className="px-4 py-5 max-w-160 cursor-pointer text-primaryColor text-xs">
                        <CommonField
                          className="mb-3"
                          options={userData}
                          isMulti
                          isDisabled={maskingAllowSelectedIndex !== index}
                          value={item?.user_premission ?? ""}
                          onChange={(e) => {
                            const updatedArray = [...maskingAllowCondition];
                            updatedArray[index] = { ...updatedArray[index], user_premission: e };
                            setMaskingAllowCondition(updatedArray);
                          }}
                          error
                          selectfield
                        />
                      </td>
                    )}
                    {item?.type?.value == "group" && (
                      <td className="px-4 py-5 max-w-160 cursor-pointer  text-primaryColor text-xs">
                        <CommonField
                          className="mb-3"
                          isDisabled={maskingAllowSelectedIndex !== index}
                          options={groupData}
                          isMulti
                          value={item?.group ?? ""}
                          onChange={(e) => {
                            const updatedArray = [...maskingAllowCondition];
                            updatedArray[index] = { ...updatedArray[index], group: e };
                            setMaskingAllowCondition(updatedArray);
                          }}
                          error
                          selectfield
                        />
                      </td>
                    )}
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={catalogData}
                        value={item?.catalog ?? ""}
                        isDisabled={maskingAllowSelectedIndex !== index}
                        onChange={async (e) => {
                          const updatedArray = [...maskingAllowCondition];
                          updatedArray[index] = { ...updatedArray[index], catalog: e, namespace: null, table: null, column: null };
                          setMaskingAllowCondition(updatedArray);
                          const namespaces = await contextValues.customNamespace(e?.value);
                          const updatedNamespaceData = [...maskingNamespaceOptionData];
                          updatedNamespaceData[index] = namespaces?.map((namespace) => ({
                            value: `${namespace.table_namespace}`,
                            label: `${namespace.table_namespace}`,
                          }));
                          setMaskingNamespaceOptionData(updatedNamespaceData);
                        }}
                        error
                        selectfield
                      />
                    </td>
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={maskingNamespaceOptionData[index]}
                        value={item?.namespace ?? ""}
                        isDisabled={maskingAllowSelectedIndex == index ? !maskingNamespaceOptionData[index] : true}
                        onChange={async (e) => {
                          const updatedArray = [...maskingAllowCondition];
                          updatedArray[index] = { ...updatedArray[index], namespace: e, table: null, column: null };
                          setMaskingAllowCondition(updatedArray);
                          const tables = await contextValues.GetTables({ table_namespace: e?.value });
                          const updatedTableData = [...maskingTableOptionData];
                          updatedTableData[index] = tables?.map((table) => ({
                            value: `${table.table_name}`,
                            label: `${table.table_name}`,
                          }));
                          setMaskingTableOptionData(updatedTableData);
                        }}
                        error
                        selectfield
                      />
                    </td>
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={maskingTableOptionData[index]}
                        value={item?.table ?? ""}
                        isDisabled={maskingAllowSelectedIndex == index ? !maskingTableOptionData[index] : true}
                        onChange={async (e) => {
                          const updatedArray = [...maskingAllowCondition];
                          updatedArray[index] = { ...updatedArray[index], table: e, column: null };
                          setMaskingAllowCondition(updatedArray);
                          const Columns = await contextValues.getCatalogsData({
                            table_name: e?.value,
                            catalog_name: updatedArray[index]?.catalog?.value,
                            table_namespace: updatedArray[index]?.namespace?.value,
                          });
                          const updatedColumnData = [...columnOptionData];
                          updatedColumnData[index] = Columns?.data?.data?.[0]?.metadata?.schema?.fields?.map((column) => ({
                            value: `${column.name}`,
                            label: `${column.name}`,
                          }));
                          setMaskingColumnOptionData(updatedColumnData);
                        }}
                        error
                        selectfield
                      />
                    </td>
                    <td className="px-4 py-5 max-w-160 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={columnOptionData[index]}
                        isDisabled={maskingAllowSelectedIndex == index ? !maskingColumnOptionData[index] : true}
                        value={item?.column ?? ""}
                        onChange={(e) => {
                          const updatedArray = [...maskingAllowCondition];
                          updatedArray[index] = { ...updatedArray[index], column: e };
                          setMaskingAllowCondition(updatedArray);
                        }}
                        isMulti
                        selectfield
                      />
                    </td>
                    <td className="px-4 py-5 text-center cursor-pointer justify-center text-xs">
                      <CustomTooltip direction="top" title={maskingAllowSelectedIndex !== index ? "Edit" : "Save"}>
                        <i
                          className={
                            maskingAllowSelectedIndex !== index
                              ? "fa-solid fa-pen-to-square mx-8"
                              : maskingAllowSelectedIndex === index &&
                                !maskingAllowCondition.every(
                                  (i) => ((i?.type && i?.user_premission?.length > 0) || i?.group?.length > 0) && i?.catalog
                                )
                              ? "fa-solid fa-check mx-8 cursor-not-allowed opacity-50"
                              : "fa-solid fa-check mx-8 cursor-pointer hover:text-blue-600"
                          }
                          role="presentation"
                          onClick={async () => {
                            if (maskingAllowSelectedIndex !== index) {
                              setMaskingAllowSelectedIndex(index);
                              try {
                                const [namespaceData, tableData, columnData] = await Promise.all([
                                  item?.catalog?.value ? contextValues.customNamespace(item?.catalog?.value) : Promise.resolve(null),
                                  item?.namespace?.value
                                    ? contextValues.GetTables({ table_namespace: item?.namespace?.value })
                                    : Promise.resolve(null),
                                  item?.table?.value
                                    ? contextValues?.getCatalogsData({
                                        table_name: item?.table?.value,
                                        catalog_name: item?.catalog?.value,
                                        table_namespace: item?.namespace?.value,
                                      })
                                    : Promise.resolve(null),
                                ]);
                                const updatedNamespaceData = [...maskingNamespaceOptionData];
                                updatedNamespaceData[index] = namespaceData?.map((namespace) => ({
                                  value: `${namespace.table_namespace}`,
                                  label: `${namespace.table_namespace}`,
                                }));
                                setMaskingNamespaceOptionData(updatedNamespaceData);
                                const updatedTableData = [...tableOptionData];
                                updatedTableData[index] = tableData?.map((table) => ({
                                  value: `${table.table_name}`,
                                  label: `${table.table_name}`,
                                }));
                                setMaskingTableOptionData(updatedTableData);
                                const updatedColumnData = [...columnOptionData];
                                updatedColumnData[index] = columnData?.data?.data?.[0]?.metadata?.schema?.fields?.map((column) => ({
                                  value: `${column.name}`,
                                  label: `${column.name}`,
                                }));
                                setMaskingColumnOptionData(updatedColumnData);
                              } catch (error) {
                                // Handle any errors from the API calls
                                // console.error("Error fetching data:", error);
                              }
                            } else if (isSaveMasked()) {
                              setMaskingAllowSelectedIndex(null);
                            }
                          }}
                          disabled={maskingAllowSelectedIndex === index && !isSaveMasked()}
                        />
                      </CustomTooltip>
                      <CustomTooltip direction="top" title="Delete">
                        <i
                          className="fa-solid fa-trash-can mx-4 cursor-pointer hover:text-red-600"
                          role="presentation"
                          onClick={() => maskingRemoveCondition(index)}
                          // onClick={() => contextValues.deleteConfiguration(item.id)}
                        />
                      </CustomTooltip>
                    </td>
                  </tr>
                );
              })}
            </Table>
          </div>
        )}
        {activeTab == "access" && (
          <div className="mb-8">
            <Table
              title={
                <div className="flex w-full items-center justify-between mb-8">
                  <div>Deny condition</div>
                  <Button
                    variant="contained"
                    onClick={() => addDenyNewCondition()}
                    color="primaryColor"
                    startIcon={<i className="fa-solid fa-plus mr-4" />}
                  >
                    Add
                  </Button>
                </div>
              }
              setPage={setPage}
              page={page}
              tableHeader={AllowHead}
              removeSidePadding
            >
              {denyCondition?.map((item, index) => {
                return (
                  <tr key={index} className={`border-b w-full ${denySelectedIndex === index ? "bg-highlightColor" : "hover:bg-tableHeaderColor"}`}>
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={typeData}
                        isDisabled={denySelectedIndex !== index}
                        value={item?.type ?? ""}
                        onChange={(e) => {
                          const updatedArray = [...denyCondition];
                          delete updatedArray[index]?.user;
                          delete updatedArray[index]?.group;
                          updatedArray[index] = { ...updatedArray[index], type: e };
                          setDenyCondition(updatedArray);
                        }}
                        error
                        selectfield
                      />
                    </td>
                    {item?.type?.value == "user" && (
                      <td className="px-4 py-5 max-w-160 cursor-pointer text-primaryColor text-xs">
                        <CommonField
                          className="mb-3"
                          options={userData}
                          isDisabled={denySelectedIndex !== index}
                          isMulti
                          value={item?.user_premission ?? ""}
                          onChange={(e) => {
                            const updatedArray = [...denyCondition];
                            updatedArray[index] = { ...updatedArray[index], user_premission: e };
                            setDenyCondition(updatedArray);
                          }}
                          error
                          selectfield
                        />
                      </td>
                    )}
                    {item?.type?.value == "group" && (
                      <td className="px-4 py-5 max-w-160 cursor-pointer  text-primaryColor text-xs">
                        <CommonField
                          className="mb-3"
                          options={groupData}
                          isMulti
                          isDisabled={denySelectedIndex !== index}
                          value={item?.group ?? ""}
                          onChange={(e) => {
                            const updatedArray = [...denyCondition];
                            updatedArray[index] = { ...updatedArray[index], group: e };
                            setDenyCondition(updatedArray);
                          }}
                          error
                          selectfield
                        />
                      </td>
                    )}
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={catalogData}
                        isDisabled={denySelectedIndex !== index}
                        value={item?.catalog ?? ""}
                        onChange={async (e) => {
                          const updatedArray = [...denyCondition];
                          updatedArray[index] = { ...updatedArray[index], catalog: e, namespace: null, table: null, column: null };
                          setDenyCondition(updatedArray);
                          const namespaces = await contextValues.customNamespace(e?.value);
                          const updatedNamespaceData = [...denyNamespaceOptionData];
                          updatedNamespaceData[index] = namespaces?.map((namespace) => ({
                            value: `${namespace.table_namespace}`,
                            label: `${namespace.table_namespace}`,
                          }));
                          setDenyNamespaceOptionData(updatedNamespaceData);
                        }}
                        error
                        selectfield
                      />
                    </td>
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={denyNamespaceOptionData[index]}
                        value={item?.namespace ?? ""}
                        // isDisabled={!denyNamespaceOptionData[index]}
                        isDisabled={denySelectedIndex == index ? !denyNamespaceOptionData[index] : true}
                        onChange={async (e) => {
                          const updatedArray = [...denyCondition];
                          updatedArray[index] = { ...updatedArray[index], namespace: e, table: null, column: null };
                          setDenyCondition(updatedArray);
                          const tables = await contextValues.GetTables({ table_namespace: e?.value });
                          const updatedTableData = [...denyTableOptionData];
                          updatedTableData[index] = tables?.map((table) => ({
                            value: `${table.table_name}`,
                            label: `${table.table_name}`,
                          }));
                          setDenyTableOptionData(updatedTableData);
                        }}
                        error
                        selectfield
                      />
                    </td>
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={denyTableOptionData[index]}
                        value={item?.table ?? ""}
                        // isDisabled={!denyTableOptionData[index]}
                        isDisabled={denySelectedIndex == index ? !denyTableOptionData[index] : true}
                        onChange={async (e) => {
                          const updatedArray = [...denyCondition];
                          updatedArray[index] = { ...updatedArray[index], table: e, column: null };
                          setDenyCondition(updatedArray);
                          const Columns = await contextValues.getCatalogsData({
                            table_name: e?.value,
                            catalog_name: updatedArray[index]?.catalog?.value,
                            table_namespace: updatedArray[index]?.namespace?.value,
                          });
                          const updatedColumnData = [...columnOptionData];
                          updatedColumnData[index] = Columns?.data?.data?.[0]?.metadata?.schema?.fields?.map((column) => ({
                            value: `${column.name}`,
                            label: `${column.name}`,
                          }));
                          setDenyColumnOptionData(updatedColumnData);
                        }}
                        error
                        selectfield
                      />
                    </td>
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3 max-w-180"
                        options={denyColumnOptionData[index]}
                        // isDisabled={!denyColumnOptionData[index]}\
                        isDisabled={denySelectedIndex == index ? !denyColumnOptionData[index] : true}
                        value={item?.column}
                        onChange={(e) => {
                          const updatedArray = [...denyCondition];
                          updatedArray[index] = { ...updatedArray[index], column: e };
                          setDenyCondition(updatedArray);
                        }}
                        isMulti
                        selectfield
                      />
                    </td>
                    <td className="px-4 py-5 text-center cursor-pointer justify-center text-xs">
                      <CustomTooltip direction="top" title={denySelectedIndex !== index ? "Edit" : "Save"}>
                        <i
                          className={
                            denySelectedIndex !== index
                              ? "fa-solid fa-pen-to-square mx-8"
                              : denySelectedIndex === index &&
                                !denyCondition.every((i) => ((i?.type && i?.user_premission?.length > 0) || i?.group?.length > 0) && i?.catalog)
                              ? "fa-solid fa-check mx-8 cursor-not-allowed opacity-50"
                              : "fa-solid fa-check mx-8 cursor-pointer hover:text-blue-600"
                          }
                          role="presentation"
                          onClick={async () => {
                            if (denySelectedIndex !== index) {
                              setDenySelectedIndex(index);
                              try {
                                const [namespaceData, tableData, columnData] = await Promise.all([
                                  item?.catalog?.value ? contextValues.customNamespace(item?.catalog?.value) : Promise.resolve(null),
                                  item?.namespace?.value
                                    ? contextValues.GetTables({ table_namespace: item?.namespace?.value })
                                    : Promise.resolve(null),
                                  item?.table?.value
                                    ? contextValues?.getCatalogsData({
                                        table_name: item?.table?.value,
                                        catalog_name: item?.catalog?.value,
                                        table_namespace: item?.namespace?.value,
                                      })
                                    : Promise.resolve(null),
                                ]);
                                const updatedNamespaceData = [...denyNamespaceOptionData];
                                updatedNamespaceData[index] = namespaceData?.map((namespace) => ({
                                  value: `${namespace.table_namespace}`,
                                  label: `${namespace.table_namespace}`,
                                }));
                                setDenyNamespaceOptionData(updatedNamespaceData);
                                const updatedTableData = [...denyTableOptionData];
                                updatedTableData[index] = tableData?.map((table) => ({
                                  value: `${table.table_name}`,
                                  label: `${table.table_name}`,
                                }));
                                setDenyTableOptionData(updatedTableData);
                                const updatedColumnData = [...denyColumnOptionData];
                                updatedColumnData[index] = columnData?.data?.data?.[0]?.metadata?.schema?.fields?.map((column) => ({
                                  value: `${column.name}`,
                                  label: `${column.name}`,
                                }));
                                setDenyColumnOptionData(updatedColumnData);
                              } catch (error) {
                                // Handle any errors from the API calls
                                // console.error("Error fetching data:", error);
                              }
                            } else if (isSaveDeny()) {
                              setDenySelectedIndex(null);
                            }
                          }}
                          disabled={denySelectedIndex === index && !isSaveDeny()}
                        />
                      </CustomTooltip>
                      <CustomTooltip direction="top" title="Delete">
                        <i
                          className="fa-solid fa-trash-can mx-8 cursor-pointer hover:text-red-600"
                          role="presentation"
                          onClick={() => denyRemoveCondition(index)}
                          // onClick={() => contextValues.deleteConfiguration(item.id)}
                        />
                      </CustomTooltip>
                    </td>
                  </tr>
                );
              })}
            </Table>
          </div>
        )}
        {activeTab == "masking" && (
          <div className="mb-8">
            <Table
              title={
                <div className="flex w-full items-center justify-between mb-8">
                  <div>Deny condition</div>
                  <Button
                    variant="contained"
                    onClick={() => addMaskingDenyNewCondition()}
                    color="primaryColor"
                    startIcon={<i className="fa-solid fa-plus mr-4" />}
                  >
                    Add
                  </Button>
                </div>
              }
              setPage={setPage}
              page={page}
              tableHeader={AllowHead}
              removeSidePadding
            >
              {maskingDenyCondition?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className={`border-b w-full ${maskingDenySelectedIndex === index ? "bg-highlightColor" : "hover:bg-tableHeaderColor"}`}
                  >
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={typeData}
                        isDisabled={maskingDenySelectedIndex !== index}
                        value={item?.type ?? ""}
                        onChange={(e) => {
                          const updatedArray = [...maskingDenyCondition];
                          delete updatedArray[index]?.user;
                          delete updatedArray[index]?.group;
                          updatedArray[index] = { ...updatedArray[index], type: e };
                          setMaskingDenyCondition(updatedArray);
                        }}
                        error
                        selectfield
                      />
                    </td>
                    {item?.type?.value == "user" && (
                      <td className="px-4 py-5 max-w-160 cursor-pointer text-primaryColor text-xs">
                        <CommonField
                          className="mb-3"
                          options={userData}
                          isMulti
                          isDisabled={maskingDenySelectedIndex !== index}
                          value={item?.user_premission ?? ""}
                          onChange={(e) => {
                            const updatedArray = [...maskingDenyCondition];
                            updatedArray[index] = { ...updatedArray[index], user_premission: e };
                            setMaskingDenyCondition(updatedArray);
                          }}
                          error
                          selectfield
                        />
                      </td>
                    )}
                    {item?.type?.value == "group" && (
                      <td className="px-4 py-5 max-w-160 cursor-pointer  text-primaryColor text-xs">
                        <CommonField
                          className="mb-3"
                          options={groupData}
                          isMulti
                          isDisabled={maskingDenySelectedIndex !== index}
                          value={item?.group ?? ""}
                          onChange={(e) => {
                            const updatedArray = [...maskingDenyCondition];
                            updatedArray[index] = { ...updatedArray[index], group: e };
                            setMaskingDenyCondition(updatedArray);
                          }}
                          error
                          selectfield
                        />
                      </td>
                    )}
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={catalogData}
                        isDisabled={maskingDenySelectedIndex !== index}
                        value={item?.catalog ?? ""}
                        onChange={async (e) => {
                          const updatedArray = [...maskingDenyCondition];
                          updatedArray[index] = { ...updatedArray[index], catalog: e, namespace: null, table: null, column: null };
                          setMaskingDenyCondition(updatedArray);
                          const namespaces = await contextValues.customNamespace(e?.value);
                          const updatedNamespaceData = [...maskingDenyNamespaceOptionData];
                          updatedNamespaceData[index] = namespaces?.map((namespace) => ({
                            value: `${namespace.table_namespace}`,
                            label: `${namespace.table_namespace}`,
                          }));
                          setMaskingDenyNamespaceOptionData(updatedNamespaceData);
                        }}
                        error
                        selectfield
                      />
                    </td>
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={maskingDenyNamespaceOptionData[index]}
                        value={item?.namespace ?? ""}
                        // isDisabled={!denyNamespaceOptionData[index]}
                        isDisabled={maskingDenySelectedIndex == index ? !maskingDenyNamespaceOptionData[index] : true}
                        onChange={async (e) => {
                          const updatedArray = [...maskingDenyCondition];
                          updatedArray[index] = { ...updatedArray[index], namespace: e, table: null, column: null };
                          setMaskingDenyCondition(updatedArray);
                          const tables = await contextValues.GetTables({ table_namespace: e?.value });
                          const updatedTableData = [...maskingDenyTableOptionData];
                          updatedTableData[index] = tables?.map((table) => ({
                            value: `${table.table_name}`,
                            label: `${table.table_name}`,
                          }));
                          setMaskingDenyTableOptionData(updatedTableData);
                        }}
                        error
                        selectfield
                      />
                    </td>
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3"
                        options={maskingDenyTableOptionData[index]}
                        value={item?.table ?? ""}
                        // isDisabled={!denyTableOptionData[index]}
                        isDisabled={maskingDenySelectedIndex == index ? !maskingDenyTableOptionData[index] : true}
                        onChange={async (e) => {
                          const updatedArray = [...maskingDenyCondition];
                          updatedArray[index] = { ...updatedArray[index], table: e, column: null };
                          setMaskingDenyCondition(updatedArray);
                          const Columns = await contextValues.getCatalogsData({
                            table_name: e?.value,
                            catalog_name: updatedArray[index]?.catalog?.value,
                            table_namespace: updatedArray[index]?.namespace?.value,
                          });
                          const updatedColumnData = [...columnOptionData];
                          updatedColumnData[index] = Columns?.data?.data?.[0]?.metadata?.schema?.fields?.map((column) => ({
                            value: `${column.name}`,
                            label: `${column.name}`,
                          }));
                          setMaskingDenyColumnOptionData(updatedColumnData);
                        }}
                        error
                        selectfield
                      />
                    </td>
                    <td className="px-4 py-5 cursor-pointer text-primaryColor text-xs">
                      <CommonField
                        className="mb-3 max-w-180"
                        options={maskingDenyColumnOptionData[index]}
                        // isDisabled={!denyColumnOptionData[index]}\
                        isDisabled={denySelectedIndex == index ? !maskingDenyColumnOptionData[index] : true}
                        value={item?.column}
                        onChange={(e) => {
                          const updatedArray = [...maskingDenyCondition];
                          updatedArray[index] = { ...updatedArray[index], column: e };
                          setMaskingDenyCondition(updatedArray);
                        }}
                        isMulti
                        selectfield
                      />
                    </td>
                    <td className="px-4 py-5 text-center cursor-pointer justify-center text-xs">
                      <CustomTooltip direction="top" title={maskingDenySelectedIndex !== index ? "Edit" : "Save"}>
                        <i
                          className={
                            maskingDenySelectedIndex !== index
                              ? "fa-solid fa-pen-to-square mx-8"
                              : maskingDenySelectedIndex === index &&
                                !maskingDenyCondition.every(
                                  (i) => ((i?.type && i?.user_premission?.length > 0) || i?.group?.length > 0) && i?.catalog
                                )
                              ? "fa-solid fa-check mx-8 cursor-not-allowed opacity-50"
                              : "fa-solid fa-check mx-8 cursor-pointer hover:text-blue-600"
                          }
                          role="presentation"
                          onClick={async () => {
                            if (maskingDenySelectedIndex !== index) {
                              setMaskingDenySelectedIndex(index);
                              try {
                                const [namespaceData, tableData, columnData] = await Promise.all([
                                  item?.catalog?.value ? contextValues.customNamespace(item?.catalog?.value) : Promise.resolve(null),
                                  item?.namespace?.value
                                    ? contextValues.GetTables({ table_namespace: item?.namespace?.value })
                                    : Promise.resolve(null),
                                  item?.table?.value
                                    ? contextValues?.getCatalogsData({
                                        table_name: item?.table?.value,
                                        catalog_name: item?.catalog?.value,
                                        table_namespace: item?.namespace?.value,
                                      })
                                    : Promise.resolve(null),
                                ]);
                                const updatedNamespaceData = [...maskingDenyNamespaceOptionData];
                                updatedNamespaceData[index] = namespaceData?.map((namespace) => ({
                                  value: `${namespace.table_namespace}`,
                                  label: `${namespace.table_namespace}`,
                                }));
                                setMaskingDenyNamespaceOptionData(updatedNamespaceData);
                                const updatedTableData = [...maskingDenyTableOptionData];
                                updatedTableData[index] = tableData?.map((table) => ({
                                  value: `${table.table_name}`,
                                  label: `${table.table_name}`,
                                }));
                                setDenyTableOptionData(updatedTableData);
                                const updatedColumnData = [...maskingDenyColumnOptionData];
                                updatedColumnData[index] = columnData?.data?.data?.[0]?.metadata?.schema?.fields?.map((column) => ({
                                  value: `${column.name}`,
                                  label: `${column.name}`,
                                }));
                                setDenyColumnOptionData(updatedColumnData);
                              } catch (error) {
                                // Handle any errors from the API calls
                                // console.error("Error fetching data:", error);
                              }
                            } else if (isDenyMaskingSave()) {
                              setMaskingDenySelectedIndex(null);
                            }
                          }}
                          disabled={maskingDenySelectedIndex === index && !isDenyMaskingSave()}
                        />
                      </CustomTooltip>
                      <CustomTooltip direction="top" title="Delete">
                        <i
                          className="fa-solid fa-trash-can mx-8 cursor-pointer hover:text-red-600"
                          role="presentation"
                          onClick={() => maskingDenyRemoveCondition(index)}
                          // onClick={() => contextValues.deleteConfiguration(item.id)}
                        />
                      </CustomTooltip>
                    </td>
                  </tr>
                );
              })}
            </Table>
          </div>
        )}
        <div className="flex justify-end mt-20">
          <Button type="button" role="button" onClick={handleSubmit} disable={!validate()} color="primaryColor" variant="contained">
            {params.id ? "Save" : "Create"}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default AddPolicy;
