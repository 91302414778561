import { memo } from "react";
import Input from "./Input";
import SelectField from "./SelectField";
import Textarea from "./Textarea";

function CommonField({
  titleText,
  name,
  value,
  onChange,
  error,
  selectfield,
  options,
  textarea,
  rows,
  isMulti,
  type,
  className,
  copyText,
  selectPlaceholder,
  ...filds
}) {
  return (
    <div className={`h-fit ${className}`}>
      {titleText && (
        <div className="flex justify-start items-center text-2xs capitalize mb-2 text-lightGrey ">
          {titleText.toUpperCase()}
          {error && error !== "second" && error !== "minute" && error !== "hour" && error !== "day_of_month" && (
            <span className="text-red-900 text-xs">*</span>
          )}
        </div>
      )}
      {selectfield ? (
        <SelectField {...filds} placeholder={selectPlaceholder} value={value} options={options} onChange={onChange} isMulti={isMulti} />
      ) : textarea ? (
        <Textarea {...filds} name={name} rows={rows} value={value} onChange={onChange} error={error} />
      ) : (
        <Input {...filds} name={name} value={value} onChange={onChange} error={error} type={type} copyText={copyText} />
      )}
    </div>
  );
}

export default memo(CommonField);
