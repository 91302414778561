import { useContext, memo } from "react";
import { useParams } from "react-router-dom";

import { REACT_FLOW_CHART } from "@mrblenny/react-flow-chart";

import { Context } from "../Context";

function DraggableCard({ type, ports, properties, chart }) {
  const contextValues = useContext(Context);
  const params = useParams();
  return (
    <div className="tooltip-container">
      <div
        className="flex flex-col h-40 border-w-2 bg-white rounded hover:bg-indigo-100 shadow-md border-black justify-center items-center cursor-pointer"
        draggable="true"
        onDragStart={(event) => {
          event.dataTransfer.setData(REACT_FLOW_CHART, JSON.stringify({ type, ports, properties }));
        }}
        onDragEnd={(event) => {
          let component_id;
          for (const i of Object.keys(chart.nodes)) {
            if (chart.nodes[i].properties?.name == properties?.name && i.properties?.type === undefined) {
              component_id = i;
            }
          }
          if (event.dataTransfer.dropEffect !== "none") {
            contextValues.createComponent({
              id: params.id,
              name: properties.name,
              component_id,
            });
            contextValues.putFlowChart({ chart, id: params.id, isNoUpdateChart: true });
          }
        }}
      >
        <img src={properties.icon} draggable="false" alt="" width="25" />
        <div className="text-ellipsis px-4 pt-2 overflow-hidden text-center text-[10px] w-full h-10 truncate">{properties.name}</div>
      </div>
      <div className="tooltip">
        <div className="tooltip-content">{properties?.name}</div>
        <div className="tooltip-arrow" />
      </div>
    </div>
  );
}

export default memo(DraggableCard);
