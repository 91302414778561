import React, { memo } from "react";

import CommonField from "../../Widgets/CommonField";
import constants from "../../constants";

function KafkaProducer({ kafkaProducer, setkafkaProducer, handleChange }) {
  const [brokerId, setBrokerId] = React.useState(false);
  return (
    <div className="w-full">
      {!brokerId ? (
        <>
          <CommonField className="mb-8" titleText="Topic" name="topic" value={kafkaProducer.topic || ""} onChange={handleChange} error="field" />
          <CommonField
            className="mb-8"
            selectfield
            error
            titleText="Broker"
            value={kafkaProducer.broker}
            options={constants.components.selectKafkaBroker}
            onChange={(broker) => {
              if (broker.value == "select") {
                setBrokerId(true);
              } else {
                setkafkaProducer({ ...kafkaProducer, broker });
              }
            }}
          />
        </>
      ) : (
        <>
          <div>
            <i className="fa-solid fa-arrow-left cursor-pointer" role="presentation" onClick={() => setBrokerId(false)} />
          </div>
          <div className="flex justify-center items-center">No Data</div>
        </>
      )}
    </div>
  );
}

export default memo(KafkaProducer);
