import { memo, useEffect, useState } from "react";

import CommonField from "../../Widgets/CommonField";
import Input from "../../Widgets/Input";
import SelectField from "../../Widgets/SelectField";
import constants from "../../constants";

const PostgresConsumer = ({ consumer, setConsumer, handleChange }) => {
  const [windows, setWindows] = useState({});

  useEffect(() => {
    setConsumer({ ...consumer, windows });
  }, [windows]);

  return (
    <div className="w-full">
      <CommonField
        className="mb-8"
        selectfield
        error
        titleText="Connection"
        value={consumer.connection}
        options={constants.components.postgressMethods}
        onChange={(connection) => setConsumer({ ...consumer, connection })}
      />
      <CommonField className="mb-8" titleText="Query" name="query" value={consumer.query || ""} onChange={handleChange} error="field" />
      <CommonField className="mb-8" titleText="Frequency" name="frequency" value={consumer.frequency || ""} onChange={handleChange} error="field" />
      <div className="h-fit w-full">
        <div className="flex justify-start items-center text-2xs uppercase text-lightGrey">
          Windows
          <span className="text-red-900 text-xs">*</span>
        </div>
        <div className="flex w-full mb-12">
          <SelectField
            className="w-144"
            value={consumer?.windows?.timeIn || ""}
            options={constants.components.frequencyData}
            onChange={(event) => setWindows({ ...windows, timeIn: event })}
          />
          <Input
            className="w-full ml-8"
            name="second_value"
            value={consumer?.windows?.time_value || ""}
            onChange={(e) =>
              setWindows({
                ...windows,
                time_value: e.target.value.replace(/\D/g, ""),
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default memo(PostgresConsumer);
