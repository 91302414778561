import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../Widgets/Button";
import Input from "../../Widgets/Input";

function Classification() {
  const [imageURL, setImageURL] = useState("https://picsum.photos/200");
  const [results, setResults] = useState([]);
  const [active, setActive] = useState(1);
  const imageRef = useRef();
  const textInputRef = useRef();
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    setImageURL(e.target.value);
    setResults([]);
  };

  const uploadImage = (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      const url = URL.createObjectURL(files[0]);
      setImageURL(url);
    } else {
      setImageURL(null);
    }
  };

  return (
    <div className="p-16 h-screen w-full">
      <div className="flex items-center h-1/12">
        <i className="fa-solid fa-chevron-left text-lg cursor-pointer font-bold" role="presentation" onClick={() => navigate(-1)} />
        <div className="ml-10">
          <div className="text-xl font-bold">Image Classification</div>
          <div className="text-xs text-gray-700">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Harum, unde.</div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-12 mt-16 h-4/5">
        <div className="flex flex-col col-span-1">
          <div className="shadow-lg p-12 bg-white h-200 w-full rounded-md border">
            <div className="flex mb-8">
              <Button variant={active === 1 ? "contained" : "outline"} color="indigo" onClick={() => setActive(1)}>
                Upload Image
              </Button>
              <Button className="ml-8" color="indigo" onClick={() => setActive(2)} variant={active === 2 ? "contained" : "outline"}>
                Use URL
              </Button>
            </div>
            {active === 1 && (
              <div className="">
                <div className="text-sm font-medium mb-8">Upload a PNG/JPEG file:</div>
                <input type="file" accept="image/*" capture="camera" onChange={uploadImage} />
              </div>
            )}
            {active === 2 && (
              <div className="">
                <div className="text-sm font-medium mb-8">Image URL:</div>
                <Input
                  type="text"
                  value={imageURL}
                  className="w-fit pb-12"
                  placeholder="Paster image URL"
                  ref={textInputRef}
                  onChange={handleOnChange}
                />
              </div>
            )}
          </div>
          <div className="shadow-lg p-12 bg-white mt-12 h-full w-full rounded-md border">
            <div className="text-lg font-semibold">What is in it?</div>
            <div className="w-2/3">
              {results.length > 0 && (
                <div className="flex w-2/3 justify-center items-start flex-col">
                  {results.map((result, index) => {
                    return (
                      <div
                        key={result.className}
                        className={`w-2/3 ${index == 0 ? "bg-black" : "white"} ${
                          index == 0 ? "text-white" : "black"
                        } border-2 border-black m-10 p-4 flex flex-col`}
                      >
                        <span>{result.className}</span>
                        <span>
                          Confidence level:
                          {(result.probability * 100).toFixed(2)}% {index === 0 && <span className="ml-4 p-2 bg-white text-black">Best Guess</span>}
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col col-span-2 shadow-lg bg-white h-480 w-full object-contain rounded-md border relative">
          {imageURL && <img src={imageURL} className="w-full h-full rounded-md border" alt="Upload Preview" crossOrigin="anonymous" ref={imageRef} />}
          {imageURL && (
            <div className="flex items-center justify-center h-120 rounded-b-md absolute bottom-0 from-black bg-gradient-to-t w-full">
              <Button variant="contained" className="bg-blue hover:-translate-y-1 mt-10" role="presentation">
                Identify Image
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Classification;
