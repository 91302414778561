import { memo } from "react";

const Button = ({ children, variant, startIcon, endIcon, color, className, submit, disable, borderColor, ...rest }) => {
  return (
    <button
      type={submit ? "submit" : "button"}
      disabled={disable}
      {...rest}
      className={`flex truncate w-fit justify-center items-center px-16 h-20 rounded-sm text-2xs ${className} ${
        variant === "contained"
          ? `${disable ? "bg-grey-400" : `bg-${color} hover:bg-1A85A0` ?? `bg-primaryColor`} ${
              disable && "cursor-not-allowed"
            } text-white font-semibold`
          : variant === "outline"
          ? `border-0.8 h-20 text-primaryColor bg-white font-semibold ${
              borderColor !== undefined ? `border-${borderColor}` : "border-1A85A0 hover:border-1A85A0"
            } text-${color}`
          : `h-20 text-primaryColor bg-e9f6f9 font-semibold hover:bg-d4eef4
        } text-${color}`
      } 
     
    
    `}
    >
      {startIcon}
      <span className="text-xs uppercase">{children}</span>
      {endIcon}
    </button>
  );
};

export default memo(Button);
