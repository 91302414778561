import { useState } from "react";
import { Modal } from "antd";
import Input from "./Input";
// import Checkbox from "./Checkbox";
import Button from "./Button";

const Deletepopup = ({ open, setOpen, onDelete, children }) => {
  const [inputValue, setInputValue] = useState("");
  //   const [isChecked, setIschecked] = useState(false);

  const handleCancel = () => {
    setInputValue("");
    setOpen({ open: false });
    // setIschecked(false);
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  //   const handlecheckboxChange = (e) => {
  //     setIschecked(e.target.checked);
  //   };

  const isDisabled = inputValue !== "" && inputValue === "delete";

  return (
    <>
      {children}
      <Modal open={open} centered footer={null} onCancel={handleCancel}>
        <div>
          {/* <div className="flex mb-10 items-start">
            <Checkbox
              name="delete"
              checked={isChecked}
              onChange={handlecheckboxChange}
            />
            <span>
              Apply force delete for selected Virtual machines and Virtual
              machine scale sets
            </span>
          </div> */}
          <div className="flex justify-start">
            Enter "delete" to confirm deletion
            <span className="text-red-900 text-md">*</span>
          </div>
          <Input value={inputValue} onChange={handleChange} />
          <div className="flex items-center mt-10 justify-end">
            <Button
              onClick={() => {
                setInputValue("");
                setOpen({ open: false });
                // setIschecked(false);
              }}
              color="5569FF"
            >
              Cancle
            </Button>
            <Button
              className="ml-8"
              type="button"
              role="button"
              color="primaryColor"
              disable={!isDisabled}
              onClick={() => {
                setInputValue("");
                onDelete();
              }}
              variant="contained"
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Deletepopup;
