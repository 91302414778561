import { memo, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonField from "../../Widgets/CommonField";
import { Context } from "../../Context";
import constants from "../../constants";

function AzureEventHub({ azureEventHub, setAzureEventHub }) {
  const contextValues = useContext(Context);
  const navigate = useNavigate();
  const [optionsData, setOptionsData] = useState();
  const [selectedDropDown, setSelectedDropDown] = useState();

  useEffect(() => {
    if (contextValues.configurationData?.data !== undefined || null) {
      const filteredData = contextValues.configurationData?.data?.filter((item) => item?.type === "azure_event_hub_configuration");
      setOptionsData([
        ...filteredData.map((item) => ({
          value: `${item?.id}`,
          label: `${item?.configure?.name}`,
        })),
        {
          value: "create_configuration",
          label: "Create Configuration",
          icon: "fa-solid fa-plus",
        },
      ]);
      if (azureEventHub?.event_hub) {
        const filterDropDown = contextValues.configurationData?.data?.find((item) => item?.id == azureEventHub?.event_hub);
        setSelectedDropDown({
          value: filterDropDown?.id,
          label: filterDropDown?.configure?.name,
        });
      }
    }
  }, [contextValues.configurationData]);

  return (
    <div className="grid gap-8 w-full h-fit">
      <CommonField
        className="col-span-2"
        titleText="Event Hub"
        value={selectedDropDown}
        options={optionsData}
        getOptionLabel={(option) => {
          return (
            <span className="flex items-center">
              <i className={`${option.icon} flex items-center justify-center mr-4`} />
              {option.label}
            </span>
          );
        }}
        onChange={(event_hub) => {
          if (event_hub.value == "create_configuration") {
            navigate(constants.route.settings + "?create_configuration");
          }
          setSelectedDropDown(event_hub);
          const azureConfigureId = contextValues.configurationData?.data?.find((item) => item?.id == event_hub?.value)?.id;
          setAzureEventHub({
            ...azureEventHub,
            event_hub: azureConfigureId,
          });
        }}
        error
        selectfield
      />
    </div>
  );
}

export default memo(AzureEventHub);
