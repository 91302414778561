import { memo } from "react";
import Select from "react-select";

function SelectField({ isDisabled, isClearable, placeholder, components, ...fields }) {
  return (
    <Select
      isDisabled={isDisabled}
      noOptionsMessage={() => "No Data"}
      {...fields}
      placeholder={placeholder}
      isClearable={isClearable ?? true}
      menuPosition="fixed"
      components={components}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          width: "100%",
          minHeight: "33px",
          backgroundColor: isDisabled ? "#F5F5F5" : "white",
          cursor: isDisabled && "not-allowed",
          "&:hover": {
            borderColor: state.isFocused ? "#20A7C9" : "#d9d9d9",
          },
          borderColor: state.isFocused ? "#20A7C9" : "#d9d9d9",
          boxShadow: state.isFocused ? "none" : baseStyles.boxShadow,
          borderRadius: "4px",
          fontSize: "14px",
        }),

        menu: (base) => ({
          ...base,
          width: "99%",
          marginLeft: "2px",
          zIndex: "9999",
        }),

        valueContainer: (provided) => ({
          ...provided,
          minHeight: "29px",
          padding: "0 6px",
        }),

        input: (provided) => ({
          ...provided,
          margin: "0px",
          height: "29px",
        }),
        clearIndicator: (provided) => ({
          ...provided,
          padding: "0px",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding: "0px",
          paddingRight: "6px",
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          minHeight: "32px",
        }),
        option: (styles, { isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isSelected ? "#F0FEFF" : isFocused ? "#f7f7f7" : "",
            color: "#000000d9",
          };
        },
      }}
    />
  );
}

export default memo(SelectField);
