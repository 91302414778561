import React, { memo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import constants from "../../constants";

const Sidebar = () => {
  // const [open, setOpen] = useState(false);
  const [isActionOpenMap, setIsActionOpenMap] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (location?.pathname?.includes("sql")) {
      setIsActionOpenMap({ 4: true });
    } else if (location?.pathname?.includes("security")) {
      setIsActionOpenMap({ 9: true });
    }
  }, []);

  const handleMenuClick = (menuId, url) => {
    setIsActionOpenMap((prevState) => ({
      ...prevState,
      [menuId]: !prevState[menuId],
    }));
    if (url) {
      navigate(url);
    }
  };

  // const logout = () => {
  //   localStorage.clear();
  //   sessionStorage.clear();
  // };
  const Menus = [
    {
      id: 1,
      title: "Dashboard",
      icon: "fa-solid fa-table-cells",
      url: constants.route.dashboard,
    },
    {
      id: 2,
      title: "Lakehouses",
      icon: "fa-solid fa-warehouse",
      url: constants.route.lakehouse,
    },
    {
      id: 3,
      title: "Flows",
      icon: "fa-solid fa-network-wired",
      url: constants.route.lackhouseFlow,
    },
    {
      id: 4,
      title: "SQL",
      icon: "fa-solid fa-laptop-code",
      actions: [
        // {
        //   label: "Dashboard",
        //   link: constants.route.sqlEditor + "?dashboard",
        // },
        {
          label: "SQL Lab",
          link: constants.route.sqlEditor + "?sqllab",
        },
        {
          label: "Saved Queries",
          link: constants.route.sqlEditor + "?savedqueryview/list",
        },
        {
          label: "Query History",
          link: constants.route.sqlEditor + "?history",
        },
        {
          label: "Reports",
          link: constants.route.sqlEditor + "?report",
        },
      ],
    },
    // {
    //   id: 5,
    //   title: "Scheduled Queries",
    //   icon: "fa-solid fa-calendar-days",
    //   url: constants.route.schedulerQueries,
    // },
    {
      id: 6,
      title: "Scheduled Jobs",
      icon: "fa-regular fa-calendar-check",
      url: constants.route.scheduledJobs,
    },
    {
      id: 8,
      title: "Data Catalog",
      icon: "fa-sharp fa-solid fa-database",
      url: constants.route.dataCatalog,
    },
    // {
    //   id: 9,
    //   title: "Policy",
    //   icon: "fa-solid fa-shield",
    //   url: constants.route.security,
    // },

    {
      id: 9,
      title: "Security",
      icon: "fa-solid fa-shield",
      actions: [
        {
          label: "Access Manager",
          link: constants.route.security + "/accessmanager",
        },
        {
          label: "Users/Groups",
          link: constants.route.security + "/groups",
        },
      ],
    },
    {
      id: 10,
      title: "Settings",
      icon: "fa fa-cog",
      url: constants.route.settings,
    },
  ];
  // const handleSubMenu = (url) => {
  //   navigate(`${constants.route.sqlEditor}/?${url}`);
  // };
  // const handleSubMenu = (url) => {
  //   if (url.startsWith("/")) {
  //     navigate(url);
  //   } else {
  //     navigate(`${constants.route.sqlEditor}${url}`);
  //   }
  // };

  return (
    <div className="flex flex-col shadow-lg bg-white w-160 overflow-y-auto h-screen justify-between relative">
      <div className="w-full">
        <Link to={constants.route.dashboard} className="flex w-full justify-center items-center h-33 cursor-pointer">
          <img className="w-112" src={process.env.PUBLIC_URL + "/assets/Hermes_logos_black.png"} alt="Logo" />
        </Link>
        <hr />
        <ul className="flex flex-col justify-between mt-12">
          {Menus.map((Menu, index) => {
            return (
              <ul key={index}>
                {Menu.id !== undefined && (
                  <>
                    <li
                      to={Menu?.url && Menu?.url}
                      className={`flex group text-grey-800 hover:text-black items-center rounded-sm justify-between m-auto p-3 cursor-pointer text-sm  w-10/12
                      ${
                        location.pathname.includes(Menu.url) || (location.pathname.includes("query-history") && Menu.title === "Scheduled Queries")
                          ? "bg-white text-black drop-shadow-md opacity-70"
                          : ""
                      }`}
                      role="presentation"
                      onClick={() => {
                        handleMenuClick(Menu.id, Menu.url);
                      }}
                    >
                      <div className="flex items-center">
                        <div className="bg-white rounded-sm">
                          <i
                            className={`${Menu?.icon} flex items-center justify-center text-xs leading-4 h-16 w-16 rounded-sm shadow-md  ${
                              location.pathname.includes(Menu.url) ||
                              (location.pathname.includes("query-history") && Menu.title === "Scheduled Queries")
                                ? "bg-primaryColor text-white"
                                : ""
                            } ${
                              !location.pathname.includes(Menu.url) ||
                              (location.pathname.includes("query-history") && Menu.title === "Scheduled Queries")
                                ? "text-grey-600"
                                : ""
                            }`}
                          />
                        </div>
                        <div
                          className={`whitespace-nowrap ml-6 leading-4 text-xs truncate ${
                            location.pathname.includes(Menu.url) ||
                            (location.pathname.includes("query-history") && Menu.title === "Scheduled Queries")
                              ? "text-black font-medium"
                              : null
                          }`}
                        >
                          {Menu.title}
                        </div>
                      </div>
                      {Menu.actions && <i className={`fa-solid ${isActionOpenMap[Menu.id] ? "fa-chevron-down" : "fa-chevron-right"} text-[12px]`} />}
                    </li>
                    <ul className="my-4">
                      {isActionOpenMap[Menu.id] &&
                        Menu?.actions?.map((actions, i) => (
                          <li
                            key={i}
                            role="presentation"
                            onClick={() => navigate(actions.link)}
                            className={`flex group text-grey-800 hover:text-black items-center rounded-sm m-auto py-3 cursor-pointer text-sm px-3 w-10/12 ${
                              actions.link.includes(location.search != "" ? location.search : location.search == "" ? location.pathname : null)
                                ? "bg-white text-black font-medium drop-shadow"
                                : ""
                            }
                            hover:text-black`}
                          >
                            <i className="fa-solid fa-circle text-[6px] ml-6 text-grey-800" />
                            <div className="whitespace-nowrap ml-12 leading-4 text-xs truncate">{actions.label}</div>
                          </li>
                        ))}
                    </ul>
                  </>
                )}
              </ul>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default memo(Sidebar);
