import { memo } from "react";

import CommonField from "../../Widgets/CommonField";
import KeyValueField from "../../Widgets/KeyValueField";
import CodeEditer from "../../Widgets/CodeEditer";

function Api({ api, setApi, handleChange }) {
  const handleQueryChange = (e) => {
    setApi({ ...api, body: e });
  };

  return (
    <div className="grid gap-8 w-full h-fit">
      <CommonField titleText="URL" name="url" value={api?.url || ""} onChange={handleChange} />
      <KeyValueField titleText="Headers" name="headers" fieldValue={api} setFieldValue={setApi} />
      <CodeEditer name="body" titleText="Body" value={api.body} onChange={handleQueryChange} />
    </div>
  );
}

export default memo(Api);
