import { memo, useState } from "react";

import constants from "../../constants";
import Button from "../../Widgets/Button";
import Input from "../../Widgets/Input";
import Modal from "../../Widgets/Modal";
import Applicationheader from "./Applicationheader";
import IntegrationCard from "../../Widgets/IntegrationCard";
import ApplicationCard from "../../Widgets/ApplicationCard";

function Application() {
  const [selectedValue, setSelectedValue] = useState("all");
  const [showModel, setShowModel] = useState();
  const [search, setSearch] = useState();

  const headerData = [
    { id: 1, name: "All", value: "all" },
    { id: 2, name: "Ingestion", value: "ingestion" },
    { id: 3, name: "Change Data Capture", value: "cdc" },
    { id: 4, name: "Advanced ETL", value: "advanced_etl" },
    { id: 5, name: "Workflows", value: "workflows" },
  ];

  const applicationCardData = [
    {
      id: 1,
      title: "Ingestion",
      type: "ingestion",
      img: "https://www.springboard.com/blog/wp-content/uploads/2021/08/what-does-a-data-analyst-do-2022-career-guide.png",
      text: "Ingest Data from any source to target in four simple wizard based steps. Apply transformations on the fly.",
      primaryButton: "CREATE INGESITION APPLICATION",
      secondoryButton: "EXPLORE SAMPLE APPLICATIONS",
      url: constants.route.ingestionApplication,
    },
    {
      id: 2,
      type: "advanced_etl",
      title: "ETL Applications",
      img: "https://www.springboard.com/blog/wp-content/uploads/2021/08/what-does-a-data-analyst-do-2022-career-guide.png",
      text: "Build A Fully Integrated ETL Application In Minutes. Easily Integrate With Sources, Processors and Emitters. Spend Less Time On Maintenance & More On Analysis.",
      primaryButton: "EXPLORE SAMPLE APPLICATIONS",
      secondoryButton: "CREATE ELT APPLICATION",
      url: constants.route.workflowCreate,
    },
    {
      id: 3,
      type: "cdc",
      title: "CDC (Change Data Capture)",
      img: "https://www.springboard.com/blog/wp-content/uploads/2021/08/what-does-a-data-analyst-do-2022-career-guide.png",
      text: "Build a simple cloud-native Change Data Capture application, the easiest and probably most efficient way to track data changes for BI and analytics.",
      secondoryButton: "CREATE CDC APPLICATION",
    },
  ];

  const SourceIngestion = [
    {
      id: 1,
      name: "File Upload",
      icon: "https://app.gathr.one/assets/images/components/comp-data-generator.png",
    },
    {
      id: 2,
      name: "Quick Base",
      icon: "https://app.gathr.one/assets/images/components/quickbase-channel.png",
    },
    {
      id: 3,
      name: "RedShift",
      icon: "https://app.gathr.one/assets/images/components/redshift-channel.png",
    },
  ];

  const TargetIngestion = [
    {
      id: 4,
      name: "HDFC",
      icon: "https://app.gathr.one/assets/images/components/DFSReceiver-channel.png",
    },
    {
      id: 5,
      name: "MS Excle",
      icon: "https://app.gathr.one/assets/images/components/comp-excel.png",
    },
    {
      id: 6,
      name: "MySQL",
      icon: "https://app.gathr.one/assets/images/components/comp-mysql.png",
    },
  ];

  return (
    <div className="flex w-full flex-col p-16">
      <div className="pb-10">
        <Applicationheader selectedValue={selectedValue} setSelectedValue={setSelectedValue} headerData={headerData} />
      </div>
      <div className="inline-grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-2 gap-12">
        {applicationCardData
          .filter((i) => (selectedValue === "all" ? i : i.type === selectedValue))
          .map((items) => {
            return (
              <ApplicationCard
                key={items.id}
                title={items.title}
                text={items.text}
                img={items.img}
                primaryButton={items.primaryButton}
                secondoryButton={items.secondoryButton}
                handleCreate={items?.url}
                setShowModel={setShowModel}
              />
            );
          })}
      </div>
      <Modal showModel={showModel} setShowModel={setShowModel}>
        <div className="text-blue-800 text-sm">Create Change Data Capture Application</div>
        <div className="grid grid-cols-2 gap-4 items-center w-4/5 my-12">
          <div className="flex justify-end">Name</div>
          <Input value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
        <div className="flex w-full justify-between my-24">
          <div className="w-1/2 border-r-2 pr-8 py-8">
            <div className="mb-12 text-blue-900 text-xs">Select a Source</div>
            <div className="grid grid-cols-3 gap-8 ">
              {search !== "" &&
                SourceIngestion?.filter((val) => {
                  return !search ? val : val.name.toLowerCase().includes(search.toLowerCase());
                })?.map((item) => <IntegrationCard key={item?.id} title={item?.name} icon={item?.icon} />)}
              {search == "" && SourceIngestion.map((item) => <IntegrationCard key={item?.id} title={item?.name} icon={item?.icon} />)}
            </div>
          </div>
          <div className="w-1/2 pl-8 py-8">
            <div className="mb-12 text-blue-900 text-xs">Select a Target</div>
            <div className="grid grid-cols-3 gap-8">
              {search !== "" &&
                TargetIngestion?.filter((val) => {
                  return !search ? val : val.name.toLowerCase().includes(search.toLowerCase());
                })?.map((item) => <IntegrationCard key={item?.id} title={item?.name} icon={item?.icon} />)}
              {search == "" && TargetIngestion.map((item) => <IntegrationCard key={item?.id} title={item?.name} icon={item?.icon} />)}
            </div>
          </div>
        </div>
        <div className="flex w-full justify-end mt-12">
          <Button type="button" onClick={() => setShowModel(false)} className="w-fit" variant="outline" color="indigo">
            Cancle
          </Button>
          <Button type="button" onClick={() => setShowModel(false)} className="ml-8 w-fit" variant="contained" color="indigo">
            ok
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default memo(Application);
