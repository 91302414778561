import { memo } from "react";

const Applicationheader = ({ selectedValue, setSelectedValue, headerData, isrefresh }) => {
  return (
    <div className="flex justify-between w-full pb-10 overflow-x-auto">
      <div className="flex" style={{ width: "inherit" }}>
        {headerData.map((items) => {
          return (
            <div
              className={`whitespace-nowrap px-12 py-4 mr-8 text-[16px] cursor-pointer rounded-full ${
                selectedValue === items.value ? "bg-indigo-700" : "bg-hawkesblue"
              } ${selectedValue === items.value ? "text-white" : "text-grey-800"} `}
              key={items.id}
              role="presentation"
              onClick={() => {
                setSelectedValue(items.value);
              }}
            >
              {items.name}
            </div>
          );
        })}
      </div>
      {isrefresh && <i className="fa fa-refresh p-6 items-center flex justify-center text-white rounded bg-indigo-500 text-sm" />}
    </div>
  );
};

export default memo(Applicationheader);
