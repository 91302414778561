import React, { useEffect, useState } from "react";
import Input from "./Input";

function KeyValueField({ titleText, name, fieldValue, setFieldValue }) {
  const [inputFields, setInputFields] = useState([{ first_value: "", second_value: "" }]);
  React.useEffect(() => {
    const array = [];
    if (fieldValue[name] !== undefined || null) {
      for (const i of fieldValue[name]) {
        array.push({
          first_value: Object.keys(i)[0],
          second_value: i[Object.keys(i)[0]],
        });
      }
    }
    if (array.length !== 0) {
      setInputFields(array);
    }
  }, []);

  const handleFormChange = (index, event) => {
    const data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  const removeFields = (index) => {
    const data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const addFields = () => {
    const newfield = { first_value: "", second_value: "" };
    setInputFields([...inputFields, newfield]);
  };

  useEffect(() => {
    const headers_array = [];
    for (const x of inputFields) {
      const headers_obj = {};
      headers_obj[x.first_value] = x.second_value;
      headers_array.push(headers_obj);
    }
    setFieldValue({ ...fieldValue, [name]: headers_array });
  }, [inputFields]);

  return (
    <div className="h-fit w-full">
      <div className="flex justify-start items-center text-2xs text-lightGrey uppercase">
        {titleText}
        <span className="text-red-900 text-xs">*</span>
      </div>
      <div className="w-full max-h-112 overflow-auto">
        {inputFields.map((input, index) => {
          const lastIndex = inputFields.length - 1 == index;
          return (
            <div key={index} className="flex items-center w-full mb-12">
              <div className="w-full grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-12">
                <Input
                  name="first_value"
                  placeholder="please enter key"
                  value={input.first_value}
                  onChange={(event) => handleFormChange(index, event)}
                />
                <Input
                  name="second_value"
                  placeholder="Please enter value"
                  value={input.second_value}
                  onChange={(event) => handleFormChange(index, event)}
                />
              </div>
              {lastIndex && (
                <i
                  className="fa-solid fa-plus h-16 w-16 mt-4 mx-4 flex items-center justify-center mb-4 cursor-pointer z-99 rounded-full bg-primaryColor hover:bg-1A85A0 text-white"
                  role="presentation"
                  onClick={addFields}
                />
              )}
              <i
                className="fa fa-trash-can h-18 w-20 mt-4 mx-4 font-normal flex items-center justify-center cursor-pointer hover:text-red-600 mb-4 hover:bg-red-50 rounded-full"
                aria-hidden="true"
                onClick={() => removeFields(index)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default KeyValueField;
