import { useLocation } from "react-router-dom";
import Sidebar from "./Components/Sidebar/Sidebar";
import constants from "./constants";

const Body = ({ children }) => {
  const location = useLocation();
  return (
    <div className="flex w-full h-screen text-black">
      {location.pathname === constants.route.signup ||
      location.pathname === constants.route.login ||
      location.pathname === constants.route.pageNotFound ||
      location.pathname.includes(constants.route.flowCreation_canvas) ||
      location.pathname.includes(constants.route.flowCreation) ? null : (
        <Sidebar />
      )}
      <div className="w-full overflow-auto bg-backgroundColor">{children}</div>
    </div>
  );
};

export default Body;
