import { memo } from "react";

const Radio = ({ value, buttonName, name, onChange, checked, className }) => {
  return (
    <div className={`${className} flex items-center`}>
      <input type="radio" className="mr-4 w-10 h-10 cursor-pointer" value={value} name={name} onChange={onChange} checked={checked} />
      <span className="text-[14px]">{buttonName}</span>
    </div>
  );
};

export default memo(Radio);
