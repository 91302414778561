import React, { useContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Configurations from "./Components/Configurations/Configurations";
import Application from "./Components/Application/Application";
import IngestionApplication from "./Components/Application/IngestionApplication";
import Flowchart from "./Components/canvas/Flowchart";
import Defaultproject from "./Components/DefaultProject/Defaultproject";
import Login from "./Components/Login/Login";
import SignUp from "./Components/Login/SignUp";
import Classification from "./Components/tensorflow/Classification";
import Detection from "./Components/tensorflow/Detection";
import Users from "./Components/Users/Users";
import Lakehouse from "./Components/lakehouse/Lakehouse";
import LakehouseProperties from "./Components/lakehouse/LakehouseProperties";
import Flow from "./Flow/Flow";
import { Context } from "./Context";
import PostgreSQL from "./Components/Configurations/PostgreSQL";
import FlowTable from "./flowCreation/FlowTable";
import SqlEditor from "./Components/SqlEditor/SqlEditor";
import Dashboard from "./Components/Dashboard/dashboard";
import Settings from "./Components/Settings/Settings";
import ScheduledJobs from "./Components/Scheduler/ScheduledJobs";
import SchedulerQueryDetails from "./Components/Scheduler/SchedulerQueryDetails";
import PageNotFound from "./Widgets/PageNotFound";
import Policy from "./Components/Security/Policy";
import Groups from "./Components/Security/Groups";
import FlowCanvas from "./FlowCanvas/FlowCanvas";
import AddPolicy from "./Components/Security/AddPolicy";
import constants from "./constants";
import DataCatalog from "./Components/DataCatalog/DataCatalog";
import CatalogOverview from "./Components/CatalogOverview/CatalogOverview";

const Router = () => {
  const ROLE = window.sessionStorage.getItem("ROLE");
  const contextValues = useContext(Context);
  React.useEffect(() => {
    if (!window.location.href.includes("login") && contextValues.checkAdminLoginStatus()) {
      contextValues.getConfigurations();
    }
  }, []);
  return (
    <Routes>
      <Route path={constants.route.default} element={<Navigate to={constants.route.dashboard} />} />
      <Route
        path={constants.route.lakehouse}
        element={
          <RequireAuth>
            <Lakehouse />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.dataCatalog}
        element={
          <RequireAuth>
            <DataCatalog />
          </RequireAuth>
        }
      />

      <Route
        path={constants.route.lakehouseProperties + "/:id"}
        element={
          <RequireAuth>
            <LakehouseProperties />
          </RequireAuth>
        }
      />
      <Route path={constants.route.default} element={<Navigate to={constants.route.lakehouse} />} />
      {/* <Route path={constants.route.lakehouse} element={<Team />} /> */}
      <Route
        path={constants.route.flow + "/:id"}
        element={
          <RequireAuth>
            <Flow />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.application}
        element={
          <RequireAuth>
            <Application />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.sqlEditor}
        element={
          <RequireAuth>
            <SqlEditor />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.dashboard}
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route path={constants.route.login} element={<Login />} />
      <Route path={constants.route.signup} element={<SignUp />} />
      <Route
        path={constants.route.lackhouseFlow}
        element={
          <RequireAuth>
            <FlowTable />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.flowCreation + "/:id"}
        element={
          <RequireAuth>
            <FlowCanvas />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.flowCreation_canvas + "/:id"}
        element={
          <RequireAuth>
            <FlowCanvas />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.settings}
        element={
          <RequireAuth>
            <Settings />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.dataCatalog + "/:id"}
        element={
          <RequireAuth>
            <CatalogOverview />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.security + "/accessmanager"}
        element={
          <RequireAuth>
            <Policy />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.security + "/add-policy"}
        element={
          <RequireAuth>
            <AddPolicy />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.security + "/edit-policy/:id"}
        element={
          <RequireAuth>
            <AddPolicy />
          </RequireAuth>
        }
      />
      {/* <Route
        path={constants.route.security}
        element={
          <RequireAuth>
            <Policy />
          </RequireAuth>
        }
      /> */}
      <Route
        path={constants.route.security + "/groups"}
        element={
          <RequireAuth>
            <Groups />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.scheduledJobs}
        element={
          <RequireAuth>
            <ScheduledJobs />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.scheduledQueryDetail}
        element={
          <RequireAuth>
            <SchedulerQueryDetails />
          </RequireAuth>
        }
      />
      <Route path={constants.route.pageNotFound} element={<PageNotFound />} />
      <Route
        path={constants.route.ingestionApplication}
        element={
          <RequireAuth>
            <IngestionApplication />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.configurations}
        element={
          <RequireAuth>
            <Configurations />
          </RequireAuth>
        }
      />
      {ROLE == "admin" && (
        <Route
          path={constants.route.users}
          element={
            <RequireAuth>
              <Users />
            </RequireAuth>
          }
        />
      )}
      <Route
        path={constants.route.workflowCreate + "/:id"}
        element={
          <RequireAuth>
            <Flowchart />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.projects}
        element={
          <RequireAuth>
            <Defaultproject />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.Classification}
        element={
          <RequireAuth>
            <Classification />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.detect}
        element={
          <RequireAuth>
            <Detection />
          </RequireAuth>
        }
      />
      <Route
        path={constants.route.postgreSQL}
        element={
          <RequireAuth>
            <PostgreSQL />
          </RequireAuth>
        }
      />
      <Route
        path="*"
        element={
          <RequireAuth>
            <Lakehouse />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default Router;

function RequireAuth({ children }) {
  const contextValues = React.useContext(Context);
  const authed = contextValues.checkAdminLoginStatus();
  const location = useLocation();
  const pagePath = location.pathname;
  const route = Object.values(constants.route).filter((value) => value !== "/");
  const pageExists = route.some((item) => pagePath.includes(item.substring(1)));
  if (!pageExists) {
    return <Navigate to={constants.route.pageNotFound} />;
  }
  if (!authed) {
    return <Navigate to={constants.route.login} state={{ from: location }} replace />;
  }

  return children;
}
