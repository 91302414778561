import React, { useState, memo } from "react";

import Input from "../../Widgets/Input";
import SelectField from "../../Widgets/SelectField";
import constants from "../../constants";
import CommonField from "../../Widgets/CommonField";
import Button from "../../Widgets/Button";

const Condition = ({ condition, setCondition }) => {
  const [conditionalData, setConditionalData] = useState();
  const [inputFields, setInputFields] = useState([]);
  const [collapsedItemId, setCollapsedItemId] = useState([]);

  const handleChange = (event, conditionIndex, secinputIndex, isselect, name) => {
    const updatedInputFields = [...inputFields];
    if (!isselect) {
      updatedInputFields[conditionIndex][Object.keys(inputFields[conditionIndex])[0]][secinputIndex][event.target.name] = event.target.value;
    } else {
      updatedInputFields[conditionIndex][Object.keys(inputFields[conditionIndex])[0]][secinputIndex][name] = event;
    }

    setInputFields(updatedInputFields);
  };

  const addConditionField = (conditionIndex) => {
    const updatedInputFields = [...inputFields];
    updatedInputFields[conditionIndex][Object.keys(inputFields[conditionIndex])[0]].push({
      first_value: "",
      second_value: "",
      logical_operator: "",
    });

    setInputFields(updatedInputFields);
  };

  const removeConditionField = (conditionIndex, secinputIndex) => {
    const updatedInputFields = [...inputFields];
    updatedInputFields[conditionIndex][Object.keys(inputFields[conditionIndex])[0]].splice([secinputIndex], 1);

    setInputFields(updatedInputFields);
  };

  function simplifyLogicalOperator(obj) {
    if (obj && obj.relational_operator && obj.relational_operator.value) {
      obj.relational_operator = obj.relational_operator.value;
    }
    if (obj && obj.logical_operator && obj.logical_operator.value) {
      obj.logical_operator = obj.logical_operator.value;
    }
    return obj;
  }

  function firstTimeLogicalOperator(obj) {
    if (obj && obj?.relational_operator) {
      obj.relational_operator = constants.components.booleanOperators?.find((bool) => bool?.value == obj?.relational_operator);
    }
    if (obj && obj?.logical_operator) {
      obj.logical_operator = constants.components.comparisonOperators?.find((comp) => comp?.value == obj?.logical_operator);
    }
    return obj;
  }

  React.useEffect(() => {
    setConditionalData(constants.components.conditionalRouting?.find((item) => item?.value == condition?.conditional_routing));
    if (condition?.condition) {
      const updatedData = JSON.parse(JSON.stringify(condition?.condition));
      updatedData.forEach((item) => {
        for (const key in item) {
          if (item[key] && Array.isArray(item[key])) {
            item[key].forEach((e) => {
              firstTimeLogicalOperator(e);
            });
          }
        }
      });
      setInputFields(updatedData);
    }
  }, []);

  React.useEffect(() => {
    const updatedData = JSON.parse(JSON.stringify(inputFields));
    updatedData.forEach((item) => {
      for (const key in item) {
        if (item[key] && Array.isArray(item[key])) {
          item[key].forEach((e) => {
            simplifyLogicalOperator(e);
          });
        }
      }
    });
    setCondition({ conditional_routing: conditionalData?.value, condition: updatedData });
  }, [inputFields, conditionalData]);

  return (
    <div className="flex flex-col w-full">
      <CommonField
        className="mb-16"
        titleText="Conditional Routing"
        value={conditionalData}
        options={constants.components.conditionalRouting}
        onChange={(e) => {
          setConditionalData(e);
          setInputFields([]);
          if (e?.value == "binary_conditional_routing") {
            const data = {
              [`condition_${inputFields?.length + 1}`]: [{ first_value: null, second_value: null, logical_operator: "" }],
            };
            setCollapsedItemId((prevCollapsedItemId) => [...prevCollapsedItemId, inputFields?.length + 1]);
            setInputFields([data]);
          }
        }}
        selectfield
      />
      {inputFields?.map((input, i) => (
        <div className={`w-full ${inputFields?.length != 1 && i == 0 ? "border-t border-l border-r" : "border"} p-8`} key={i}>
          <tr
            className="flex w-full last:border-0"
            role="presentation"
            key={i}
            onClick={() => {
              if (collapsedItemId.includes(i + 1)) {
                setCollapsedItemId(collapsedItemId.filter((item) => item !== i + 1));
              } else {
                setCollapsedItemId((prevCollapsedItemId) => [...prevCollapsedItemId, i + 1]);
              }
            }}
          >
            <td className="w-full cursor-pointer flex items-center text-xs">
              <div className="w-12 flex items-center">
                <i
                  className={`fa-solid  mr-6 text-black hover:text-blue-600 ${collapsedItemId.includes(i + 1) ? "fa-angle-down" : "fa-angle-right"}`}
                />
              </div>
              <span>{i + 1} Condition</span>
            </td>
          </tr>
          {collapsedItemId.includes(i + 1) && (
            <div className="w-full pl-12">
              {input[Object.keys(input)[0]]?.map(
                (secinput, index) =>
                  index !== input.length - 1 && (
                    <div key={secinput?.id} className="flex flex-col items-center">
                      <div className="flex items-center w-full">
                        <div className="w-full grid grid-cols-6 gap-12 pt-8">
                          <Input
                            className="col-span-2"
                            name="first_value"
                            placeholder="Condition First Value"
                            value={secinput?.first_value}
                            onChange={(event) => handleChange(event, i, index)}
                          />
                          <SelectField
                            className="col-span-1"
                            name="logical_operator"
                            options={constants.components.comparisonOperators}
                            value={secinput?.logical_operator}
                            onChange={(event, f) => handleChange(event, i, index, true, f.name)}
                          />
                          <Input
                            className="col-span-2"
                            name="second_value"
                            placeholder="Condition Second Value"
                            value={secinput?.second_value}
                            onChange={(event) => handleChange(event, i, index)}
                          />
                          <div className="flex items-center">
                            <SelectField
                              className="col-span-1 w-full"
                              name="relational_operator"
                              options={constants.components.booleanOperators}
                              isDisabled={!secinput?.first_value || !secinput?.logical_operator || !secinput?.second_value}
                              isClearable={false}
                              value={secinput?.relational_operator}
                              onChange={(event, f) => {
                                handleChange(event, i, index, true, f.name);
                                if (index === input[Object.keys(input)[0]]?.length - 1) {
                                  addConditionField(i);
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex items-center justify-center mx-10 mt-8">
                          {input[Object.keys(input)[0]]?.length != 1 && (
                            <i
                              className="fa-solid fa-trash-can text-sm cursor-pointer hover:text-red-600"
                              aria-hidden="true"
                              onClick={() => {
                                removeConditionField(i, index);
                                if (input[Object.keys(input)[0]]?.length != 0 && index !== input[Object.keys(input)[0]]?.length - 1) {
                                  const updatedInputFields = [...inputFields];
                                  updatedInputFields[i][Object.keys(inputFields[i])[0]][index - 1].relational_operator = "";
                                  setInputFields(updatedInputFields);
                                }
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          )}
        </div>
      ))}
      {conditionalData?.value == "multi_conditional_routing" && (
        <div className={`flex justify-center ${inputFields?.length != 0 && "mt-16"}`}>
          <Button
            variant="contained"
            color="primaryColor"
            startIcon={<i className="fa-solid fa-plus mr-6" />}
            onClick={() => {
              const data = {
                [`condition_${inputFields?.length + 1}`]: [{ first_value: null, second_value: null, logical_operator: "" }],
              };

              setInputFields([...inputFields, data]);
            }}
          >
            Add Condition
          </Button>
        </div>
      )}
    </div>
  );
};

export default memo(Condition);
