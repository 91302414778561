import React, { useState, memo } from "react";

import { useParams } from "react-router-dom";
import { Context } from "../../Context";
import Button from "../../Widgets/Button";
import HttpServer from "../Fields/HttpServer";
import KafkaProducer from "../Fields/KafkaProducer";
import KafkaConsumer from "../Fields/KafkaConsumer";
import SendSMS from "../Fields/SendSMS";
import SendEmail from "../Fields/SendEmail";
import SlackIntegration from "../Fields/SlackIntegration";
import JoinStream from "../Fields/JoinStream";
import GStream from "../Fields/GStream";
import Tensorflow from "../Fields/Tensorflow";
import HttpClient from "../Fields/HttpClient";
import PostgresConsumer from "../Fields/PostgresConsumer";
import PostgresProducer from "../Fields/PostgresProducer";
import SnmpClient from "../Fields/SnmpClient";
import Aggregate from "../Fields/Aggregate";
import FacialRecognition from "../Fields/FacialRecognition";
import QRCodeDetection from "../Fields/QRCodeDetection";
import Transformation from "../Fields/Transformation";
import ObjectStorage from "../Fields/ObjectStorage";
import PeopleCounter from "../Fields/PeopleCounter";
import Api from "../Fields/Api";
import Lakehouse from "../Fields/Lakehouse";
import Parquet from "../Fields/Parquet";
import AzureEventHub from "../Fields/AzureEventHub";
import Iceberg from "../Fields/Iceberg";
import PubSub from "../Fields/PubSub";
import JsonOutput from "../Fields/JsonOutput";
import NoData from "../../Widgets/NoData";
import Conditional from "../Fields/Conditional";

function Confugure({ node, setIsConfigure, isConfigure, chart, setChart }) {
  const params = useParams();
  const contextValues = React.useContext(Context);
  const [nodeName, setNodeName] = useState(0);
  const [fieldValue, setFieldValue] = useState({});
  const [isSubmit, setIsSubmit] = useState(true);
  const [componentId, setComponentId] = useState();

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    setFieldValue((values) => ({ ...values, [name]: value }));
  };
  React.useEffect(() => {
    contextValues.GetComponent(params.id);
  }, []);

  React.useEffect(() => {
    if (node?.properties?.name !== undefined) {
      contextValues.setConfigCloseId(node.id);
      setNodeName(node?.properties);
    }
  }, [node?.properties?.name]);

  React.useEffect(() => {
    let fieldValues = {};
    if (contextValues.componentData !== undefined || null) {
      for (const i of contextValues.componentData) {
        if (i.attributes.component_id == node?.id) {
          fieldValues = i.attributes.properties;
          setIsSubmit(false);
          setComponentId(i.attributes.id);
        }
      }
    }
    if (fieldValues !== null) {
      setFieldValue(fieldValues);
    }
  }, [isConfigure, contextValues.componentData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const ingress = [];
    const egress = [];
    for (const i of Object.keys(chart.links)) {
      if (chart.links[i].to.nodeId == node?.id) {
        ingress.push({
          from: chart.links[i].from.nodeId,
          to: chart.links[i].to.nodeId,
        });
      }
      if (chart.links[i].from.nodeId == node?.id) {
        egress.push({
          from: chart.links[i].from.nodeId,
          to: chart.links[i].to.nodeId,
        });
      }
    }
    const componentValue = {
      name: node?.properties?.name,
      ingress,
      egress,
      fieldValue,
      id: params.id,
      componentId,
      component_id: node?.id,
    };
    if (contextValues.uploadFile?.isupload) {
      contextValues.uploadImage({
        file: contextValues.uploadFile.file,
        componentValue,
        isSubmit,
      });
    }
    if (contextValues.uploadFile?.isMultiUpload) {
      contextValues.multiImage({
        file: contextValues.uploadFile.file,
        componentValue,
        isSubmit,
      });
    }
    if (fieldValue?.conditional_routing) {
      if (fieldValue?.conditional_routing == "multi_conditional_routing") {
        const portUpdateChart = chart;
        for (const nodeId of Object.keys(portUpdateChart.nodes)) {
          if (node?.id == nodeId) {
            const updatedPorts = { ...portUpdateChart?.nodes[nodeId].ports };
            // delete updatedPorts.port2;
            Object.keys(updatedPorts)
              .filter((k) => k !== "port1")
              .forEach((d) => {
                delete updatedPorts[d];
              });
            // eslint-disable-next-line no-plusplus
            for (let i = 2; i <= fieldValue?.condition?.length + 1; i++) {
              const newPortKey = `port${i}`;
              updatedPorts[newPortKey] = {
                id: newPortKey,
                type: "bottom",
                value: i - 1,
              };
            }
            portUpdateChart.nodes[nodeId].ports = updatedPorts;
            setChart(portUpdateChart);
            // setKey(!key);
            contextValues.putFlowChart({ chart: portUpdateChart, id: params.id });
          }
        }
      } else if (fieldValue?.conditional_routing == "binary_conditional_routing") {
        const portUpdateChart = chart;
        for (const nodeId of Object.keys(portUpdateChart.nodes)) {
          if (node?.id == nodeId) {
            const updatedPorts = { ...portUpdateChart?.nodes[nodeId].ports };
            // delete updatedPorts.port2;
            Object.keys(updatedPorts)
              .filter((k) => k !== "port1")
              .forEach((d) => {
                delete updatedPorts[d];
              });
            // eslint-disable-next-line no-plusplus
            for (let i = 2; i <= 3; i++) {
              const newPortKey = `port${i}`;
              updatedPorts[newPortKey] = {
                id: newPortKey,
                type: "bottom",
                value: i == 2 ? "true" : "false",
              };
            }
            portUpdateChart.nodes[nodeId].ports = updatedPorts;
            setChart(portUpdateChart);
            // setKey(!key);
            contextValues.putFlowChart({ chart: portUpdateChart, id: params.id });
          }
        }
      }
    }
    if (isSubmit && !contextValues.uploadFile?.isupload && !contextValues.uploadFile?.isMultiUpload) {
      contextValues.createComponent(componentValue);
    } else if (!contextValues.uploadFile?.isupload && !contextValues.uploadFile?.isMultiUpload) {
      const lineageData = await contextValues.getLineage({ id: node?.id });
      const destination = lineageData?.data?.data?.find?.((i) => i?.attributes?.destination_id == node?.id)?.id;
      const source = lineageData?.data?.data?.find?.((i) => i?.attributes?.source_id == node?.id)?.id;
      if (destination) {
        contextValues.updateLineage({ id: destination, data: { destination_properties: fieldValue } });
      } else if (source) {
        contextValues.updateLineage({ id: source, data: { source_properties: fieldValue } });
      }
      contextValues.putComponent(componentValue);
    }
    setIsConfigure(false);
  };

  // const validate = () => {
  //   const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  //   let isValidate = true;
  //   if (nodeName?.name == "HTTP Server") {
  //     if (
  //       fieldValue?.path == "" ||
  //       !fieldValue?.path ||
  //       !regex.test(fieldValue?.path)
  //     ) {
  //       isValidate = false;
  //     } else if (fieldValue?.port == "" || !fieldValue?.port) {
  //       isValidate = false;
  //     } else if (fieldValue?.method == "" || !fieldValue?.method) {
  //       isValidate = false;
  //     } else if (fieldValue?.content_type == "" || !fieldValue?.content_type) {
  //       isValidate = false;
  //     }
  //   } else if (nodeName?.name == "HTTP Client") {
  //     if (fieldValue?.url == "" || !fieldValue?.url) {
  //       isValidate = false;
  //     } else if (fieldValue?.body == "" || !fieldValue?.body) {
  //       isValidate = false;
  //     } else if (fieldValue?.headers == "" || !fieldValue?.headers) {
  //       isValidate = false;
  //     }
  //   } else if (nodeName?.name == "Kafka Producer") {
  //     if (fieldValue?.topic == "" || !fieldValue?.topic) {
  //       isValidate = false;
  //     } else if (fieldValue?.brokers == "" || !fieldValue?.brokers) {
  //       isValidate = false;
  //     }
  //   } else if (nodeName?.name == "Kafka Consumer") {
  //     if (fieldValue?.topic == "" || !fieldValue?.topic) {
  //       isValidate = false;
  //     } else if (fieldValue?.brokers == "" || !fieldValue?.brokers) {
  //       isValidate = false;
  //     } else if (
  //       fieldValue?.consumergroup == "" ||
  //       !fieldValue?.consumergroup
  //     ) {
  //       isValidate = false;
  //     }
  //   } else if (nodeName?.name == "Send SMS") {
  //     if (fieldValue?.from == "" || !fieldValue?.from) {
  //       isValidate = false;
  //     } else if (fieldValue?.to == "" || !fieldValue?.to) {
  //       isValidate = false;
  //     } else if (fieldValue?.message == "" || !fieldValue?.message) {
  //       isValidate = false;
  //     }
  //   } else if (nodeName?.name == "Send Email") {
  //     if (
  //       fieldValue?.fromEmail == "" ||
  //       !fieldValue?.fromEmail ||
  //       !regex.test(fieldValue?.url)
  //     ) {
  //       isValidate = false;
  //     } else if (
  //       fieldValue?.toEmail == "" ||
  //       !fieldValue?.toEmail ||
  //       !regex.test(fieldValue?.url)
  //     ) {
  //       isValidate = false;
  //     } else if (fieldValue?.subject == "" || !fieldValue?.subject) {
  //       isValidate = false;
  //     } else if (fieldValue?.message == "" || !fieldValue?.message) {
  //       isValidate = false;
  //     }
  //   } else if (nodeName?.name == "Slack Integration") {
  //     if (fieldValue?.botname == "" || !fieldValue?.botname) {
  //       isValidate = false;
  //     } else if (fieldValue?.apitoken == "" || !fieldValue?.apitoken) {
  //       isValidate = false;
  //     } else if (fieldValue?.channels == "" || !fieldValue?.channels) {
  //       isValidate = false;
  //     } else if (fieldValue?.image == "" || !fieldValue?.image) {
  //       isValidate = false;
  //     }
  //   } else if (nodeName?.name == "Join streams") {
  //     if (fieldValue?.windowFunction == "" || !fieldValue?.windowFunction) {
  //       isValidate = false;
  //     }
  //   } else if (nodeName?.name == "Gstream Sink") {
  //     if (fieldValue?.method == "" || !fieldValue?.method) {
  //       isValidate = false;
  //     } else if (
  //       fieldValue?.start_selector == "" ||
  //       !fieldValue?.start_selector
  //     ) {
  //       isValidate = false;
  //     } else if (fieldValue?.endpoint == "" || !fieldValue?.endpoint) {
  //       isValidate = false;
  //     }
  //   } else if (nodeName?.name == "SNMP Client") {
  //     if (fieldValue?.host == "" || !fieldValue?.host) {
  //       isValidate = false;
  //     } else if (fieldValue?.version == "" || !fieldValue?.version) {
  //       isValidate = false;
  //     } else if (fieldValue?.username == "" || !fieldValue?.username) {
  //       isValidate = false;
  //     } else if (fieldValue?.authkey == "" || !fieldValue?.authkey) {
  //       isValidate = false;
  //     } else if (fieldValue?.privekey == "" || !fieldValue?.privekey) {
  //       isValidate = false;
  //     } else if (fieldValue?.oid == "" || !fieldValue?.oid) {
  //       isValidate = false;
  //     }
  //   } else if (nodeName?.name == "Aggregate") {
  //     if (fieldValue?.timeIn == "" || !fieldValue?.timeIn) {
  //       isValidate = false;
  //     } else if (fieldValue?.time_value == "" || !fieldValue?.time_value) {
  //       isValidate = false;
  //     } else if (fieldValue?.key == "" || !fieldValue?.key) {
  //       isValidate = false;
  //     }
  //   } else if (nodeName?.name == "Transformation") {
  //     if (fieldValue?.join_condition == "" || !fieldValue?.join_condition) {
  //       isValidate = false;
  //     } else if (
  //       fieldValue?.windows?.timeIn == "" ||
  //       !fieldValue?.windows?.timeIn
  //     ) {
  //       isValidate = false;
  //     } else if (
  //       fieldValue?.windows?.time_value == "" ||
  //       !fieldValue?.windows?.time_value
  //     ) {
  //       isValidate = false;
  //     }
  //   } else if (nodeName?.name == "Facial Recognition") {
  //     if (fieldValue?.url == "" || !fieldValue?.url) {
  //       isValidate = false;
  //     }
  //   } else if (nodeName?.name == "Qr Code Detection") {
  //     if (fieldValue?.url == "" || !fieldValue?.url) {
  //       isValidate = false;
  //     }
  //   } else if (nodeName?.name == "Postgres Consumer") {
  //     if (fieldValue?.connection == "" || !fieldValue?.connection) {
  //       isValidate = false;
  //     } else if (fieldValue?.frequency == "" || !fieldValue?.frequency) {
  //       isValidate = false;
  //     } else if (fieldValue?.quary == "" || !fieldValue?.quary) {
  //       isValidate = false;
  //     } else if (
  //       fieldValue?.windows?.timeIn == "" ||
  //       !fieldValue?.windows?.timeIn
  //     ) {
  //       isValidate = false;
  //     } else if (
  //       fieldValue?.windows?.time_value == "" ||
  //       !fieldValue?.windows?.time_value
  //     ) {
  //       isValidate = false;
  //     }
  //   } else if (nodeName?.name == "Postgres Producer") {
  //     if (fieldValue?.connection == "" || !fieldValue?.connection) {
  //       isValidate = false;
  //     } else if (fieldValue?.body == "" || !fieldValue?.body) {
  //       isValidate = false;
  //     } else if (fieldValue?.table == "" || !fieldValue?.table) {
  //       isValidate = false;
  //     }
  //   }
  //   return isValidate;
  // };
  const validate = () => {
    function checkConditions(conditions) {
      for (const condition of conditions) {
        for (const key in condition) {
          if (Object.prototype.hasOwnProperty.call(condition, key)) {
            const conditionArray = condition[key];
            if (key != "child_node") {
              for (const item of conditionArray) {
                if (!item.first_value || !item.second_value || !item.logical_operator) {
                  return false;
                }
              }
            }
          }
        }
      }
      return true;
    }
    let isValidate = true;
    if (nodeName?.name == "Conditional Logic") {
      if (fieldValue?.conditional_routing == "" || !fieldValue?.conditional_routing) {
        isValidate = false;
      } else if (fieldValue?.condition?.length == 0) {
        isValidate = false;
      } else if (fieldValue?.condition) {
        isValidate = checkConditions(fieldValue?.condition);
      }
    }

    return isValidate;
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col w-full h-full items-center">
      <div className="p-10 bg-backgroundColor flex w-full items-center justify-between text-md font-medium text-sidebarFormHeader h-38 rounded-t-sm">
        <div className="flex items-center">
          <img className="mr-6 border p-6 rounded-md border-blue-900" src={nodeName.icon} draggable="false" alt="" width="50" />
          <span>{nodeName?.name}</span>
        </div>
        <div>
          <i
            className="fa-solid fa-xmark text-md cursor-pointer text-00000073 hover:text-363e63"
            role="presentation"
            onClick={() => setIsConfigure(false)}
          />
        </div>
      </div>
      <div className="flex w-full h-full justify-between flex-col p-16">
        <div className="flex w-full h-264 overflow-y-auto justify-center mt-12">
          {nodeName?.name == "HTTP Server" ? (
            <HttpServer httpServer={fieldValue} setHttpServer={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "API" ? (
            <Api api={fieldValue} setApi={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "HTTP Client" ? (
            <HttpClient httpClient={fieldValue} setHttpClient={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Kafka Producer" ? (
            <KafkaProducer kafkaProducer={fieldValue} setkafkaProducer={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Kafka Consumer" ? (
            <KafkaConsumer kafkaConsumer={fieldValue} setkafkaConsumer={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Send SMS" ? (
            <SendSMS sendSms={fieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Send Email" ? (
            <SendEmail sendEmail={fieldValue} handleChange={handleChange} setEmail={setFieldValue} />
          ) : nodeName?.name == "Conditional Logic" ? (
            <Conditional condition={fieldValue} setCondition={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Slack Integration" ? (
            <SlackIntegration slackIntegration={fieldValue} setSlackIntegration={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Join streams" ? (
            <JoinStream joinStream={fieldValue} setJoinStream={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Gstream Sink" ? (
            <GStream gStream={fieldValue} setGStream={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Tensorflow" ? (
            <Tensorflow tensorFlow={fieldValue} setTensorFlow={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Postgres Consumer" ? (
            <PostgresConsumer consumer={fieldValue} setConsumer={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "SNMP Client" ? (
            <SnmpClient snmpClient={fieldValue} setSnmpClient={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Aggregate" ? (
            <Aggregate aggregate={fieldValue} setAggregate={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Postgres Producer" ? (
            <PostgresProducer producer={fieldValue} setProducer={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Transformation" ? (
            <Transformation transformation={fieldValue} setTransformation={setFieldValue} />
          ) : nodeName?.name == "Facial Recognition" ? (
            <FacialRecognition facialRecognition={fieldValue} setFacialRecognition={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Qr Code Detection" ? (
            <QRCodeDetection qrCodeDetection={fieldValue} setQrCodeDetection={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Object Storage" ? (
            <ObjectStorage objectStorage={fieldValue} setObjectStorage={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "People Counter" ? (
            <PeopleCounter peopleCounter={fieldValue} setPeopleCounter={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Lakehouse" ? (
            <Lakehouse lakehouse={fieldValue} setLakehouse={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Parquet" ? (
            <Parquet parquet={fieldValue} setParquet={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Azure Event Hub" ? (
            <AzureEventHub azureEventHub={fieldValue} setAzureEventHub={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Iceberg" ? (
            <Iceberg iceberg={fieldValue} setIceberg={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "Pub/Sub" ? (
            <PubSub pubSub={fieldValue} setPubSub={setFieldValue} handleChange={handleChange} />
          ) : nodeName?.name == "JSON Output" ? (
            <JsonOutput jsonOutput={fieldValue} setJsonOutput={setFieldValue} handleChange={handleChange} />
          ) : (
            <div className="w-full flex justify-center items-center">
              <NoData />
            </div>
          )}
        </div>
        <div className="flex justify-end items-center w-full mt-20">
          <Button className="mr-12" color="indigo" onClick={() => setIsConfigure(false)}>
            Cancel
          </Button>
          <Button variant="contained" color="primaryColor" submit disable={!validate()}>
            {isSubmit ? "Save" : "Save"}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default memo(Confugure);
