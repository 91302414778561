import { memo } from "react";
import CommonField from "../../Widgets/CommonField";
import Radio from "../../Widgets/Radio";

function Postgres({ fieldValue, handleChange }) {
  return (
    <div className="grid gap-8 w-full h-fit">
      <div className="h-fit w-full">
        <div className="flex justify-start items-center text-[14px]">
          Connect by<span className="text-red-900 text-md">*</span>
        </div>
        <div onChange={handleChange} className="flex flex-row">
          <Radio name="connect" value="host" checked={fieldValue?.connect === "host"} buttonName="Host" />
          <Radio name="connect" value="url" checked={fieldValue?.connect === "url"} buttonName="Url" className="pl-10" />
        </div>
      </div>
      <CommonField titleText="Name" name="config_name" value={fieldValue?.config_name || ""} onChange={handleChange} error="field" />
      <CommonField titleText="URL" name="url" value={fieldValue?.url || ""} onChange={handleChange} error="field" />
      {fieldValue?.connect === "host" && (
        <>
          <CommonField
            titleText="Host"
            name="host"
            value={fieldValue?.host || ""}
            onChange={handleChange}
            error="field"
            disabled={fieldValue?.connect === "url"}
          />
          <CommonField
            titleText="Port"
            name="port"
            value={fieldValue?.port || ""}
            onChange={handleChange}
            error="field"
            disabled={fieldValue?.connect === "url"}
          />
          <CommonField
            titleText="Database"
            name="database"
            value={fieldValue.database || ""}
            onChange={handleChange}
            error="field"
            disabled={fieldValue?.connect === "url"}
          />
        </>
      )}
    </div>
  );
}
export default memo(Postgres);
