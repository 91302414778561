import { useEffect } from "react";

import CommonField from "../../Widgets/CommonField";
import Radio from "../../Widgets/Radio";
import UploadButton from "../../Widgets/UploadButton";

const Tensorflow = ({ tensorFlow, setTensorFlow, handleChange }) => {
  useEffect(() => {
    if (!tensorFlow.type) {
      setTensorFlow({ type: "regression", inputType: "" });
    }
  }, []);
  return (
    <div className="grid gap-8 w-full h-fit">
      <div onChange={handleChange} className="flex flex-row">
        <Radio name="type" value="regression" checked={tensorFlow.type === "regression"} buttonName="Regression" />
        <Radio name="type" value="classification" checked={tensorFlow.type === "classification"} buttonName="Classification" className="pl-10" />
        <Radio name="type" value="objectdetection" checked={tensorFlow.type === "objectdetection"} buttonName="Object Detection" className="pl-10" />
      </div>
      <div>
        <div className="flex justify-start items-center text-2xs text-lightGrey uppercase mb-2">Upload File:</div>
        <UploadButton imgName fileUpload={tensorFlow} handleFileUpload={setTensorFlow} name="uploadFile" />
      </div>
      <div>
        <div className="flex justify-start items-center text-2xs mb-2 text-lightGrey uppercase">Stream URL:</div>
        <CommonField placeholder="STREAM URL" name="url" value={tensorFlow.url || ""} onChange={handleChange} />
      </div>
    </div>
  );
};

export default Tensorflow;
