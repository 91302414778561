import { memo } from "react";

import CommonField from "../../Widgets/CommonField";
import CodeEditer from "../../Widgets/CodeEditer";
import constants from "../../constants";

const PostgresProducer = ({ producer, setProducer, handleChange }) => {
  const handleQueryChange = (e) => {
    setProducer({ ...producer, body: e });
  };

  return (
    <div className="w-full">
      <CommonField
        className="mb-8"
        selectfield
        error
        titleText="Connection"
        value={producer.connection}
        options={constants.components.postgressProducerConnection}
        onChange={(connection) => {
          setProducer({
            ...producer,
            connection,
            connection_id: connection.value,
          });
        }}
      />
      <CommonField className="mb-8" titleText="Table" name="table" value={producer.table || ""} onChange={handleChange} error="field" />
      <CodeEditer name="body" titleText="Body" value={producer.body} onChange={handleQueryChange} />
    </div>
  );
};

export default memo(PostgresProducer);
