import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { Context } from "../../Context";
import Header from "../../Widgets/Header";
import Status from "../../Widgets/Status";
import CommonField from "../../Widgets/CommonField";
import Tabs from "../../Widgets/Tabs";
import Button from "../../Widgets/Button";
import SidebarForm from "./SidebarForm";
import Deletepopup from "../../Widgets/Deletepopup";
import constants from "../../constants";
import { capitalizeFirstLetter } from "../../utils/utils";

const LakehouseProperties = () => {
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [selectedWorkerNode, setSelectedWorkerNode] = useState(0);
  const [lakehouseData, setLakehouseData] = useState({});
  const [openSidebar, setOpenSidebar] = useState(false);
  const [collapsedItemId, setCollapsedItemId] = useState(["master_node", "worker_node"]);
  const [tabOptions, setTabOptions] = useState(null);
  const [workerNodes, setWorkerNodes] = useState({});
  // const [virtualSizes, setvirtualSizes] = useState();
  const [deletePopup, setDeletePopup] = useState({ open: false });
  const USER = window.sessionStorage.getItem("USER");
  const ParseUser = JSON.parse(USER);
  const parseName = ParseUser?.username?.split("@")[0];
  const contextValues = useContext(Context);
  const navigate = useNavigate();
  const params = useParams();

  const menuList = [{ title: "Connection Details", value: "connection_details" }];
  const workerNodeData = lakehouseData?.spark_cluster?.data?.attributes?.worker_nodes?.data;

  useEffect(() => {
    const isAnyMasterInitializing = contextValues?.lakehoseInfo?.spark_cluster?.data?.attributes?.worker_nodes?.data?.some((item) => {
      return item?.attributes?.status == "initializing";
    });
    if (isAnyMasterInitializing) {
      const timer = setTimeout(() => {
        contextValues.findLakehouse({ id: params?.id, noLoader: true });
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [contextValues?.lakehoseInfo]);

  useEffect(() => {
    async function fetchData() {
      if (workerNodeData?.length !== 0) {
        setTabOptions(
          workerNodeData?.map((item, index) => ({
            title:
              item?.attributes?.settings?.name ??
              `${lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.computerName ?? ""}-${index + 1}`,
            value: item?.id,
          }))
        );
      }
      if (tabOptions == null) {
        setSelectedWorkerNode(workerNodeData?.[0]?.id);
      }
    }

    fetchData();
  }, [lakehouseData]);

  const filterWorkerData = (data) => {
    if (workerNodeData?.length !== 0) {
      const filterData = workerNodeData?.find((i) => i?.id === data);
      return filterData;
    }
  };

  const validate = () => {
    let isValidate = false;
    if (Object.keys(workerNodes).length !== 0) {
      if (!workerNodes?.min || workerNodes?.min == "") {
        if (!/^-?\d+(\.\d+)?$/.test(workerNodes?.min)) {
          isValidate = false;
        }
      } else {
        isValidate = true;
      }
    }
    return isValidate;
  };

  const handleCollapse = (id) => {
    if (collapsedItemId?.includes(id)) {
      const updatedItems = collapsedItemId.filter((item) => item !== id);
      setCollapsedItemId(updatedItems);
    } else {
      setCollapsedItemId([...collapsedItemId, id]);
    }
  };

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    setWorkerNodes((values) => ({
      ...values,
      status: "create",
      lakehouse_id: params?.id,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    await contextValues.updateWorkermanagement(workerNodes);
    await contextValues.findLakehouse({ id: params?.id });
    setSelectedWorkerNode(workerNodeData?.[0]?.id);
    setOpenSidebar(false);
    setWorkerNodes({});
  };

  useEffect(() => {
    contextValues.findLakehouse({ id: params?.id, navigate: true });
  }, [params?.id]);

  useEffect(() => {
    if (contextValues?.lakehoseInfo) {
      if (contextValues?.lakehoseInfo?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.status == "online") {
        setLakehouseData(contextValues?.lakehoseInfo);
      } else {
        navigate(constants.route.lakehouse);
      }
    }
  }, [contextValues?.lakehoseInfo, params.id]);

  return (
    <div className="w-full h-full">
      <Header
        title="Lakehouse Register"
        headerChild={
          <div className="ml-24 flex">
            <div
              className="px-8 text-xs py-4 rounded-md cursor-pointer hover:underline hover:text-primaryColor"
              role="presentation"
              onClick={() => navigate(constants.route.lakehouse)}
            >
              Lakehouses
            </div>
            <div className="flex items-center">
              <i className="fa-solid fa-chevron-right flex items-center mr-4 text-grey-700" />
              <div className="px-8 text-xs py-4 rounded-md">{lakehouseData?.name}</div>
            </div>
          </div>
        }
      />
      {openSidebar && (
        <SidebarForm
          title="Create Worker Nodes"
          disable={!validate()}
          onClose={() => {
            setOpenSidebar(false);
          }}
          handleSubmit={handleSubmit}
        >
          <CommonField
            className="mb-8"
            titleText="Number of Worker Nodes"
            name="min"
            onChange={handleChange}
            value={workerNodes?.min || ""}
            error="number"
          />
        </SidebarForm>
      )}
      <div className="p-16">
        <div className="p-12 mb-12 rounded-[4px] bg-white">
          <table className="w-3/5 text-xs">
            <tbody>
              {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.computerName && (
                <tr className="h-28">
                  <td className="w-160">Master Computer Name</td>
                  <td>{lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.computerName}</td>
                </tr>
              )}
              <tr className="h-28">
                <td className="w-160">Status</td>
                <td>
                  <Status title={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.status} />
                </td>
              </tr>
              {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.executor && (
                <tr className="h-28">
                  <td className="w-160">Executor</td>
                  <td>{capitalizeFirstLetter(lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.executor)}</td>
                </tr>
              )}
              {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.master_url && (
                <tr className="h-28">
                  <td className="w-160">Master URL</td>
                  <td>{lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.master_url}</td>
                </tr>
              )}
              {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.description && (
                <tr className="h-28">
                  <td className="w-160">Description</td>
                  <td>{lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.description}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Tabs type="basic" className="border-b-2 mb-10" activeTab={selectedMenu} setActiveTab={setSelectedMenu} tabs={menuList} />
        {selectedMenu === "connection_details" && (
          <div className="">
            <div className="bg-white rounded-sm p-12 mb-10">
              <CommonField
                className="w-2/3 pb-8"
                titleText="JDBC"
                disabled
                name="jdbc"
                value={"jdbc:hive2://hermes-executor.dataphion.com:80/;transportMode=http;httpPath=/lakehouse/" + lakehouseData?.name}
                copyText={"jdbc:hive2://hermes-executor.dataphion.com:80/;transportMode=http;httpPath=/lakehouse/" + lakehouseData?.name}
                error="field"
                onChange={() => {}}
              />
              <CommonField
                className="w-2/3"
                titleText="App/Python"
                disabled
                name="App/Python"
                /* need to change this in future, this should come from backend */
                value={
                  "hive+http://" +
                  parseName +
                  ":<jwt>@hermes-executor.dataphion.com:80/" +
                  lakehouseData?.name +
                  "?httpPath=lakehouse/" +
                  lakehouseData?.name
                }
                copyText={
                  "hive+http://" +
                  parseName +
                  `:${sessionStorage.getItem("ADMIN_TOKEN")}@hermes-executor.dataphion.com:80/` +
                  lakehouseData?.name +
                  "?httpPath=lakehouse/" +
                  lakehouseData?.name
                }
                error="field"
                onChange={() => {}}
              />
            </div>
            {lakehouseData?.spark_cluster?.data !== null && (
              <>
                <div className="w-full h-31 flex items-center justify-start">
                  <div className="flex items-center w-fit cursor-pointer" role="presentation" onClick={() => handleCollapse("master_node")}>
                    <i
                      className={`fa-solid text-[12px] mr-9 hover:text-blue-600 ${
                        collapsedItemId?.includes("master_node") ? "fa-chevron-down" : "fa-chevron-right"
                      }`}
                    />
                    <span className="text-sm font-normal w-full">Master Node</span>
                  </div>
                </div>
                {collapsedItemId?.includes("master_node") && lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data !== null && (
                  <>
                    {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.status !== "failed" && (
                      <div className="w-full grid grid-cols-2 gap-x-16 bg-white rounded-sm p-12">
                        {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.ip && (
                          <CommonField
                            titleText="Public IP Address"
                            className="mb-8"
                            value={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.ip}
                            disabled
                            error="field"
                            copyText={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.ip}
                          />
                        )}
                        {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.master_private_ip_address && (
                          <CommonField
                            titleText="Private IP Address"
                            className="mb-8"
                            value={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.master_private_ip_address}
                            disabled
                            error="field"
                            copyText={
                              lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.master_private_ip_address
                            }
                          />
                        )}
                        {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.hostname && (
                          <CommonField
                            titleText="Hostname"
                            className="mb-8"
                            value={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.hostname}
                            disabled
                            error="field"
                            copyText={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.hostname}
                          />
                        )}
                        {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.master_exposed_ports && (
                          <CommonField
                            titleText="Exposed Port"
                            className="mb-8"
                            value={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.master_exposed_ports.join(
                              ", "
                            )}
                            disabled
                            error="field"
                            copyText={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.master_exposed_ports.join(
                              ", "
                            )}
                          />
                        )}
                        {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.location && (
                          <CommonField
                            titleText="Location/Region"
                            className="mb-8"
                            value={
                              constants.location?.find(
                                (i) => i?.value == lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.location
                              )?.label
                            }
                            disabled
                            error="field"
                            copyText={
                              constants.location?.find(
                                (i) => i?.value == lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.location
                              )?.label
                            }
                          />
                        )}
                        {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.cloud_provider && (
                          <CommonField
                            titleText="Cloud Provider"
                            className="mb-8"
                            value={
                              constants.cloud_provider?.find(
                                (i) =>
                                  i?.value == lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.cloud_provider
                              )?.label
                            }
                            disabled
                            error="field"
                            copyText={
                              constants.cloud_provider?.find(
                                (i) =>
                                  i?.value == lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.cloud_provider
                              )?.label
                            }
                          />
                        )}
                        {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.url && (
                          <CommonField
                            titleText="URL"
                            className="mb-8"
                            value={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.url}
                            disabled
                            error="field"
                            copyText={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.url}
                          />
                        )}
                        {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.servicePort && (
                          <CommonField
                            titleText="Service Port"
                            className="mb-8"
                            value={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.servicePort}
                            disabled
                            error="field"
                            copyText={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.servicePort}
                          />
                        )}
                        {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.metastore?.value && (
                          <CommonField
                            titleText="Hive Metastore Endpoint"
                            className="mb-8"
                            value={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.metastore?.value}
                            disabled
                            error="field"
                            copyText={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.metastore?.value}
                          />
                        )}
                        {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.master_node && (
                          <CommonField
                            titleText="Master Node Size"
                            className="mb-8"
                            value={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.master_node}
                            disabled
                            error="field"
                            copyText={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.master_node}
                          />
                        )}
                        {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.min_worker_nodes && (
                          <CommonField
                            titleText="Minimum Worker Nodes"
                            className="mb-8"
                            value={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.min_worker_nodes}
                            disabled
                            error="field"
                            copyText={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.min_worker_nodes}
                          />
                        )}
                        {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.max_worker_nodes && (
                          <CommonField
                            titleText="Maximum Worker Nodes"
                            className="mb-8"
                            value={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.max_worker_nodes}
                            disabled
                            error="field"
                            copyText={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.max_worker_nodes}
                          />
                        )}
                        {/* {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.worker_idle_time && (
                      <CommonField
                        titleText="Worker Idle Time"
                        className="mb-8"
                        value={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.worker_idle_time}
                        disabled
                        error="field"
                        copyText={lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.worker_idle_time}
                      />
                    )} */}
                        {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.cluster_idle_time && (
                          <CommonField
                            titleText="Cluster Idle Time"
                            className="mb-8"
                            value={
                              constants.Idle_Time?.find(
                                (i) =>
                                  i?.value ==
                                  lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.cluster_idle_time
                              )?.label
                            }
                            disabled
                            error="field"
                            copyText={
                              constants.Idle_Time?.find(
                                (i) =>
                                  i?.value ==
                                  lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.settings?.cluster_idle_time
                              )?.label
                            }
                          />
                        )}
                      </div>
                    )}
                    {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.status == "failed" && (
                      <div className="bg-white rounded-sm m-12 flex items-center flex-col m-20 justify-center text-red-700 font-semibold text-sm">
                        <img src={process.env.PUBLIC_URL + "/assets/error_image.svg"} height={200} width={200} alt="No Data..." />
                        <div className="max-h-300 text-center overflow-y-auto mt-8 w-full break-all">
                          {lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.creation_error}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {collapsedItemId.includes("master_node") && lakehouseData?.spark_cluster?.data?.attributes?.master_node?.data == null && (
                  <div className="bg-white rounded-sm p-12 flex items-center flex-col p-20 justify-center text-cardShadowColor font-semibold text-sm">
                    <img src={process.env.PUBLIC_URL + "/assets/no-data.svg"} alt="No Data..." />
                    <span className="mt-8">Master node data not found</span>
                  </div>
                )}
              </>
            )}
            <div className="w-full h-31 flex items-center justify-between justify-start">
              <div className="flex items-center w-fit cursor-pointer" role="presentation" onClick={() => handleCollapse("worker_node")}>
                <i
                  className={`fa-solid text-[12px] mr-9 hover:text-blue-600 ${
                    collapsedItemId.includes("worker_node") ? "fa-chevron-down" : "fa-chevron-right"
                  }`}
                />
                <span className="text-sm font-normal w-full">{`Worker Nodes(${workerNodeData?.length ?? 0})`}</span>
              </div>
              <Button
                className="ml-8"
                type="button"
                role="button"
                color="primaryColor"
                variant="contained"
                onClick={() => {
                  setWorkerNodes({});
                  setOpenSidebar(true);
                }}
              >
                Create
              </Button>
            </div>
            {collapsedItemId.includes("worker_node") && (
              <div className="w-full">
                {workerNodeData !== undefined && workerNodeData?.length !== 0 ? (
                  <>
                    <Deletepopup
                      open={deletePopup.open}
                      setOpen={setDeletePopup}
                      onDelete={async () => {
                        await contextValues.updateWorkermanagement({
                          status: "delete",
                          vm_name: filterWorkerData(selectedWorkerNode)?.attributes?.settings?.computerName,
                          node_id: filterWorkerData(selectedWorkerNode)?.id,
                        });
                        await contextValues.findLakehouse({ id: params?.id });
                        setDeletePopup({ open: false });
                        setSelectedWorkerNode(workerNodeData?.[0]?.id);
                      }}
                    >
                      <div className="w-full bg-white rounded-sm px-12 pt-12">
                        <Tabs
                          type="basic"
                          className="border-b-2"
                          activeTab={selectedWorkerNode}
                          setActiveTab={setSelectedWorkerNode}
                          onDelete={() => {
                            setDeletePopup({ open: true });
                          }}
                          tabs={tabOptions}
                        />
                      </div>
                    </Deletepopup>
                    <>
                      <div className="px-12 bg-white">
                        <table className="w-full text-xs border-b-2">
                          <tbody>
                            <tr className="h-28">
                              <td className="w-160">Status</td>
                              <td>
                                <Status title={filterWorkerData(selectedWorkerNode)?.attributes?.status} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {filterWorkerData(selectedWorkerNode)?.attributes?.status !== "failed" && (
                        <div className="w-full grid grid-cols-2 gap-x-16 bg-white rounded-sm px-12 pb-12 pt-12">
                          {filterWorkerData(selectedWorkerNode)?.attributes?.settings?.ip && (
                            <CommonField
                              titleText="Public IP Address"
                              className="mb-8"
                              value={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.ip}
                              disabled
                              error="field"
                              copyText={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.ip}
                            />
                          )}
                          {filterWorkerData(selectedWorkerNode)?.attributes?.settings?.worker_private_ip_address && (
                            <CommonField
                              titleText="Private IP Address"
                              className="mb-8"
                              value={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.worker_private_ip_address}
                              disabled
                              error="field"
                              copyText={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.worker_private_ip_address}
                            />
                          )}
                          {filterWorkerData(selectedWorkerNode)?.attributes?.settings?.hostname && (
                            <CommonField
                              titleText="Hostname"
                              className="mb-8"
                              value={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.hostname}
                              disabled
                              error="field"
                              copyText={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.hostname}
                            />
                          )}
                          {filterWorkerData(selectedWorkerNode)?.attributes?.settings?.master_url && (
                            <CommonField
                              titleText="Master URL"
                              className="mb-8"
                              value={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.master_url}
                              disabled
                              error="field"
                              copyText={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.master_url}
                            />
                          )}
                          {filterWorkerData(selectedWorkerNode)?.attributes?.settings?.location && (
                            <CommonField
                              titleText="Location/Region"
                              className="mb-8"
                              value={
                                constants.location?.find((i) => i?.value == filterWorkerData(selectedWorkerNode)?.attributes?.settings?.location)
                                  ?.label
                              }
                              disabled
                              error="field"
                              copyText={
                                constants.location?.find((i) => i?.value == filterWorkerData(selectedWorkerNode)?.attributes?.settings?.location)
                                  ?.label
                              }
                            />
                          )}
                          {filterWorkerData(selectedWorkerNode)?.attributes?.settings?.cloud_provider && (
                            <CommonField
                              titleText="Cloud Provider"
                              className="mb-8"
                              value={
                                constants.cloud_provider?.find(
                                  (i) => i?.value == filterWorkerData(selectedWorkerNode)?.attributes?.settings?.cloud_provider
                                )?.label
                              }
                              disabled
                              error="field"
                              copyText={
                                constants.cloud_provider?.find(
                                  (i) => i?.value == filterWorkerData(selectedWorkerNode)?.attributes?.settings?.cloud_provider
                                )?.label
                              }
                            />
                          )}
                          {filterWorkerData(selectedWorkerNode)?.attributes?.settings?.url && (
                            <CommonField
                              titleText="URL"
                              className="mb-8"
                              value={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.url}
                              disabled
                              error="field"
                              copyText={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.url}
                            />
                          )}
                          {filterWorkerData(selectedWorkerNode)?.attributes?.settings?.servicePort && (
                            <CommonField
                              titleText="Service Port"
                              className="mb-8"
                              value={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.servicePort}
                              disabled
                              error="field"
                              copyText={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.servicePort}
                            />
                          )}
                          {filterWorkerData(selectedWorkerNode)?.attributes?.settings?.master_node && (
                            <CommonField
                              titleText="Master Node Size"
                              className="mb-8"
                              value={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.master_node}
                              disabled
                              error="field"
                              copyText={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.master_node}
                            />
                          )}
                          {filterWorkerData(selectedWorkerNode)?.attributes?.settings?.worker_node && (
                            <CommonField
                              titleText="Worker Node Size"
                              className="mb-8"
                              value={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.worker_node}
                              disabled
                              error="field"
                              copyText={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.worker_node}
                            />
                          )}
                          {/* {filterWorkerData(selectedWorkerNode)?.attributes?.settings?.min_worker_nodes && (
                        <CommonField
                          titleText="Minimum Worker Nodes"
                          className="mb-8"
                          value={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.min_worker_nodes}
                          disabled
                          error="field"
                          copyText={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.min_worker_nodes}
                        />
                      )} */}
                          {/* {filterWorkerData(selectedWorkerNode)?.attributes?.settings?.max_worker_nodes && (
                        <CommonField
                          titleText="Maximum Worker Nodes"
                          className="mb-8"
                          value={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.max_worker_nodes}
                          disabled
                          error="field"
                          copyText={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.max_worker_nodes}
                        />
                      )} */}
                          {filterWorkerData(selectedWorkerNode)?.attributes?.settings?.worker_idle_time && (
                            <CommonField
                              titleText="Worker Idle Time"
                              className="mb-8"
                              value={
                                constants.Idle_Time?.find(
                                  (i) => i?.value == filterWorkerData(selectedWorkerNode)?.attributes?.settings?.worker_idle_time
                                )?.label
                              }
                              disabled
                              error="field"
                              copyText={
                                constants.Idle_Time?.find(
                                  (i) => i?.value == filterWorkerData(selectedWorkerNode)?.attributes?.settings?.worker_idle_time
                                )?.label
                              }
                            />
                          )}
                          {/* {filterWorkerData(selectedWorkerNode)?.attributes?.settings?.cluster_idle_time && (
                        <CommonField
                          titleText="Cluster Idle Time"
                          className="mb-8"
                          value={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.cluster_idle_time}
                          disabled
                          error="field"
                          copyText={filterWorkerData(selectedWorkerNode)?.attributes?.settings?.cluster_idle_time}
                        />
                      )} */}
                        </div>
                      )}
                      {filterWorkerData(selectedWorkerNode)?.attributes?.status == "failed" && (
                        // <div className="flex justify-center text-red-700 font-semibold text-sm w-full bg-white rounded-sm px-12 pb-12">
                        //   {filterWorkerData(selectedWorkerNode)?.attributes?.creation_error}
                        // </div>
                        <div className="bg-white rounded-sm m-12 flex items-center flex-col m-20 justify-center text-red-700 font-semibold text-sm">
                          <img src={process.env.PUBLIC_URL + "/assets/error_image.svg"} height={200} width={200} alt="No Data..." />
                          <div className="max-h-300 text-center overflow-y-auto mt-8 w-full break-all">
                            {filterWorkerData(selectedWorkerNode)?.attributes?.creation_error}
                          </div>
                        </div>
                      )}
                    </>
                  </>
                ) : (
                  <div className="bg-white rounded-sm p-12 flex items-center flex-col p-20 justify-center text-cardShadowColor font-semibold text-sm">
                    <img src={process.env.PUBLIC_URL + "/assets/no-data.svg"} alt="No Data..." />
                    <span className="mt-8">Worker node not connected</span>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LakehouseProperties;
