import { memo } from "react";

import Input from "../../Widgets/Input";
import CommonField from "../../Widgets/CommonField";
import constants from "../../constants";

const SendEmail = ({ sendEmail, handleChange }) => {
  return (
    <div className="w-full">
      <CommonField className="mb-8" titleText="From" name="fromEmail" value={sendEmail.fromEmail || ""} onChange={handleChange} error="email" />
      <div className="h-fit w-full ">
        <div className="flex items-center text-[14px]">
          <select
            id="underline_select"
            className="block py-2 px-0 text-2xs font-normal uppercase text-lightGrey bg-transparent border appearance focus:outline-none focus:ring-0 focus:border-gray-200 peer"
            value={sendEmail.method}
            onChange={handleChange}
          >
            <option selected value="">
              Select
            </option>
            {constants.components.emailSendSelect.map((item) => (
              <option>{item.label}</option>
            ))}
          </select>
          <span className="text-red-900 text-md">*</span>
        </div>
        <Input className="w-full" name="toEmail" value={sendEmail.toEmail || ""} onChange={handleChange} error="email" />
      </div>
      <CommonField className="mb-8" titleText="Subject" name="subject" value={sendEmail.subject || ""} onChange={handleChange} />
      <CommonField className="mb-8" titleText="Message" name="message" onChange={handleChange} value={sendEmail.message} error="field" textarea />
    </div>
  );
};

export default memo(SendEmail);
