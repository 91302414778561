import { capitalizeFirstLetter } from "../utils/utils";
import CustomTooltip from "./Tooltip";

const Status = ({ title, padding, tooltipMessage = "" }) => {
  let colorClass = "";

  switch (title) {
    case "active":
    case "started":
    case "success":
    case "allowed":
    case "online":
      colorClass = "bg-green-500 text-green-700";
      break;
    case "draft":
    case "initializing":
      colorClass = "bg-amber-500 text-amber-700";
      break;
    case "failed":
      colorClass = "bg-red-500 text-red-700";
      break;
    case "UNASSIGNED":
    case "unassigned":
      colorClass = "bg-purple-500 text-purple-700";
      break;
    default:
      colorClass = "bg-gray-500 text-gray-700";
      break;
  }

  if (title) {
    return (
      <CustomTooltip direction="bottom" title={tooltipMessage}>
        <div className={`w-fit px-8 ${padding ?? "py-3"} rounded-sm ${colorClass} font-bold text-2xs bg-opacity-10`}>
          {capitalizeFirstLetter(title)}
        </div>
      </CustomTooltip>
    );
  }

  return null; // Return null if title is falsy
};

export default Status;
