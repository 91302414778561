import Button from "../../Widgets/Button";

const SidebarForm = ({ children, title, onClose, disable, handleSubmit, createButtonName, buttonClass }) => {
  return (
    <form className="w-screen h-screen overflow-y-auto absolute left-0 top-0 bg-black bg-opacity-40 backdrop-blur-xs flex justify-end z-999">
      <div className="w-full h-full absolute top-0 left-0" role="presentation" onClick={onClose} />
      <div className="shadow-xl w-320 overflow-y-auto h-screen border bg-white absolute z-1">
        <div className="flex justify-between items-center px-12 h-33 bg-backgroundColor">
          <h1 className="text-sm font-500 bg-buttonGradient inline-block text-sidebarFormHeader bg-clip-text flex items-center">{title}</h1>
          <i className="fa-solid fa-xmark text-sm cursor-pointer text-00000073 hover:text-363e63" role="presentation" onClick={onClose} />
        </div>
        <hr />
        <div className="w-full p-16">{children}</div>
        <div className={`w-full px-16 py-12 flex ${buttonClass || "justify-between"} items-center border-y`}>
          <Button onClick={onClose} color="5569FF">
            Cancle
          </Button>
          <Button className="ml-8" type="button" role="button" color="primaryColor" onClick={handleSubmit} variant="contained" disable={disable}>
            {createButtonName ?? "Create"}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SidebarForm;
