import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { components } from "react-select";
import Header from "../../Widgets/Header";
import SidebarForm from "./SidebarForm";
import CommonField from "../../Widgets/CommonField";
import { dateFormat, filterDataBySearch } from "../../utils/utils";
import Status from "../../Widgets/Status";
import { Context } from "../../Context";
import Table from "../../Widgets/Table";
import constants from "../../constants";
import Deletepopup from "../../Widgets/Deletepopup";
import CustomTooltip from "../../Widgets/Tooltip";

function Lakehouse() {
  const navigate = useNavigate();
  const contextValues = useContext(Context);
  const [search, setSearch] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [isEditStatus, setIsEditStatus] = useState(null);
  const [openDeletePopup, setDeletepopup] = useState(false);
  const [lakehouseData, setLakehouseData] = useState({ min_worker_nodes: "0" });
  const [configureData, setConfigureData] = useState();
  const [optionsData, setOptionsData] = useState([]);
  const [virtualSizes, setvirtualSizes] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(18);
  const cloudComutingData = contextValues?.lakehouseForm?.attributes?.lakehouse_form;

  useEffect(() => {
    contextValues.getConfigurations();
    contextValues.getConfigureType();
    contextValues.GetLakehouseForm();
  }, []);

  useEffect(() => {
    const isAnyMasterInitializing = contextValues?.lakehouseData?.data.some((item) => {
      const masterNode = item?.spark_cluster?.data?.attributes?.master_node?.data?.attributes;
      return masterNode?.status === "initializing";
    });
    if (isAnyMasterInitializing) {
      const timer = setTimeout(() => {
        setPage(1);
        contextValues.GetLakehouse({
          search,
          page,
          rowsPerPage,
          noLoader: true,
        });
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [contextValues?.lakehouseData]);

  useEffect(() => {
    if (contextValues?.configureType?.data?.length > 0) {
      const filterData = contextValues?.configureType?.data?.filter((item) =>
        constants?.components?.lakehouse_source?.includes(item?.configure_value)
      );
      const sourceData = filterData?.map((item) => ({
        value: `${item?.configure_value}`,
        label: `${item?.configure_label}`,
        icon: `${item?.icon}`,
      }));
      sourceData.push({ value: "local_fs", label: "Local FS" });
      setOptionsData(sourceData);
    }
  }, [contextValues?.configureType]);

  useEffect(() => {
    // if (contextValues?.configureType?.data?.length > 0) {
    //   const filterData = contextValues?.configureType?.data?.filter((item) =>
    //     constants?.components?.lakehouse_source?.includes(item?.configure_value)
    //   );
    //   const sourceData = filterData?.map((item) => ({
    //     value: `${item?.configure_value}`,
    //     label: `${item?.configure_label}`,
    //     icon: `${item?.icon}`,
    //   }));
    //   sourceData.push({ value: "local_fs", label: "Local FS" });
    //   setOptionsData(sourceData);
    // }
    if (isEdit) {
      setvirtualSizes();
      setOpenSidebar(true);
      const findData = contextValues?.lakehouseData?.data?.find((item) => item?.id == isEdit);
      contextValues.getConfigurations();
      if (findData?.cloud_provider == "azure") {
        contextValues?.getCloudAccountSettings();
      }
      const findConfigure = contextValues.configurationData?.data?.find((c) => c?.id == findData?.configure?.data?.id);
      setLakehouseData({
        name: findData?.name,
        storage_location: optionsData?.find((v) => v?.value == findData?.storage_location),
        // configure: configureData?.find((c) => c?.value == findData?.configure?.data?.id),
        configure: { value: findConfigure?.id, label: findConfigure?.configure?.name },
        cloud_provider: cloudComutingData?.children?.find((d) => d?.value == findData?.cloud_provider),
        executor: cloudComutingData?.children
          ?.find((first) => first?.value == findData?.cloud_provider)
          ?.children?.find((second) => second?.value == "executor")
          ?.option?.find((third) => third?.value == findData?.executor),
        location: cloudComutingData?.children
          ?.find((first) => first?.value == findData?.cloud_provider)
          ?.children?.find((second) => second?.value == "location")
          ?.option?.find((third) => third?.value == findData?.location),
        // master_node: cloudComutingData?.children
        //   ?.find((first) => first?.value == findData?.cloud_provider)
        //   ?.children?.find((second) => second?.value == "master_node")
        //   ?.option?.find((third) => third?.value == findData?.master_node),
        // worker_node: cloudComutingData?.children
        //   ?.find((first) => first?.value == findData?.cloud_provider)
        //   ?.children?.find((second) => second?.value == "master_node")
        //   ?.option?.find((third) => third?.value == findData?.master_node),
        master_node: { value: findData?.master_node, label: findData?.master_node },
        worker_node: { value: findData?.worker_node, label: findData?.worker_node },
        min_worker_nodes: findData?.min_worker_nodes,
        master_url: findData?.master_url,
        max_worker_nodes: findData?.max_worker_nodes,
        worker_idle_time: cloudComutingData?.children
          ?.find((first) => first?.value == findData?.cloud_provider)
          ?.children?.find((second) => second?.value == "worker_idle_time")
          ?.option?.find((third) => third?.value == findData?.worker_idle_time),
        cluster_idle_time: cloudComutingData?.children
          ?.find((first) => first?.value == findData?.cloud_provider)
          ?.children?.find((second) => second?.value == "cluster_idle_time")
          ?.option?.find((third) => third?.value == findData?.cluster_idle_time),
        path: findData?.path?.split("/")[3],
        description: findData?.description,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (search !== null) {
      const timer = setTimeout(() => {
        setPage(1);
        contextValues.GetLakehouse({ search, page, rowsPerPage });
      }, 800);
      return () => clearTimeout(timer);
    }
  }, [search]);

  useEffect(() => {
    contextValues.GetLakehouse({ search, page, rowsPerPage });
  }, [page]);

  useEffect(() => {
    if (contextValues.configurationData?.data !== undefined || null) {
      const filteredData = contextValues.configurationData?.data?.filter((item) => item?.type == lakehouseData?.storage_location?.value);
      setConfigureData([
        ...filteredData.map((item) => ({
          value: `${item?.id}`,
          label: `${item?.configure?.name}`,
        })),
        {
          value: "create_configuration",
          label: "Create Configuration",
          icon: "fa-solid fa-plus",
        },
      ]);
    }
  }, [contextValues.configurationData, lakehouseData?.storage_location]);

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    setLakehouseData((values) => ({
      ...values,
      [name]: value,
      status: "active",
    }));
  };

  const handleSubmit = () => {
    function replaceObjectValuesWithKeyValue(obj) {
      const newObj = {};

      for (const key in obj) {
        if (obj[key] !== "") {
          if (typeof obj[key] === "object" && obj[key] !== null && "value" in obj[key]) {
            newObj[key] = obj[key].value;
          } else if (key == "path") {
            newObj[key] = `/home/${lakehouseData?.name}/${obj[key]}`;
          } else {
            newObj[key] = obj[key];
          }
        }
      }

      return newObj;
    }
    const propsData = replaceObjectValuesWithKeyValue(lakehouseData);
    if (isEdit) {
      contextValues.updateLakehouse({
        propsData,
        id: isEdit,
        setOpenSidebar,
        setLakehouseData,
      });
      setIsEdit(null);
    } else {
      contextValues.createLakehouse({
        propsData,
        setOpenSidebar,
        setLakehouseData,
      });
    }
  };

  const validate = () => {
    let isValidate = false;
    if (Object.keys(lakehouseData)?.length !== 0) {
      if (!lakehouseData?.name || lakehouseData?.name.trim() === "") {
        isValidate = false;
      } else if (!lakehouseData?.[cloudComutingData?.value] || lakehouseData?.[cloudComutingData?.value] === "") {
        isValidate = false;
      } else if (/[A-Z]/.test(lakehouseData?.name) || lakehouseData?.name?.includes(" ") || lakehouseData?.name?.includes("_")) {
        isValidate = false;
      } else if (!lakehouseData?.storage_location || lakehouseData?.storage_location === "") {
        isValidate = false;
      } else if (lakehouseData?.storage_location?.value === "local_fs") {
        if (!lakehouseData?.path || lakehouseData?.path === "") {
          isValidate = false;
        } else {
          isValidate = true;
        }
      } else if (lakehouseData?.storage_location?.value !== "local_fs") {
        if (!lakehouseData?.configure || lakehouseData?.configure === "") {
          isValidate = false;
        } else {
          isValidate = true;
        }
      } else {
        isValidate = true;
      }
    }

    cloudComutingData?.children?.forEach((data) => {
      if (lakehouseData?.[cloudComutingData?.value]?.value == data?.value) {
        data?.children?.forEach((field) => {
          if (Object.keys(lakehouseData)?.length !== 0) {
            if (!field?.show) {
              if (!lakehouseData[field?.value] || lakehouseData[field?.value] == "") {
                isValidate = false;
              }
            } else if (field?.show?.includes(lakehouseData?.executor?.value)) {
              if (field?.validate == "number") {
                if (!lakehouseData[field?.value] || lakehouseData[field?.value] == "" || !/^-?\d+(\.\d+)?$/.test(lakehouseData[field?.value])) {
                  isValidate = false;
                }
              } else if (!lakehouseData[field?.value] || lakehouseData[field?.value] == "") {
                isValidate = false;
              }
            }
          }
        });
      }
    });
    return isValidate;
  };

  const lakehouseHead = [
    { id: 1, label: "Name", position: "left" },
    { id: 2, label: "Status", position: "center" },
    { id: 3, label: "Created on", position: "center" },
    { id: 4, label: "Description", position: "center" },
    { id: 5, label: "Actions", position: "center" },
  ];

  return (
    <div className="w-full h-full">
      <Header
        title="Lakehouses"
        createButtonText="Create Lakehouse"
        onButtonClick={() => {
          setvirtualSizes();
          setOpenSidebar(true);
        }}
        searchPlaceholder="Search..."
        search={search}
        setSearch={setSearch}
      />
      {openSidebar && (
        <SidebarForm
          title={isEdit ? "Lakehouse" : "Create Lakehouse"}
          disable={!validate()}
          createButtonName={isEdit ? (isEditStatus == "offline" ? "GO ONLINE" : "RECREATE") : "CREATE"}
          onClose={() => {
            setOpenSidebar(false);
            setIsEdit(null);
            setLakehouseData({});
          }}
          handleSubmit={handleSubmit}
        >
          <CommonField className="mb-8" titleText="Name" name="name" value={lakehouseData.name || ""} onChange={handleChange} error="lowercase" />

          <CommonField
            className="mb-8"
            titleText="Storage Location"
            value={lakehouseData?.storage_location}
            options={optionsData}
            getOptionLabel={(e) => (
              <div className="flex item-center">
                <img src={e.icon ?? constants.images.noImage} width="20" alt="icon" />
                <span className="ml-5">{e.label}</span>
              </div>
            )}
            onChange={(storage_location) => {
              setLakehouseData({
                ...lakehouseData,
                storage_location,
                configure: "",
              });
            }}
            error
            selectfield
          />

          {lakehouseData?.storage_location?.value === "local_fs" && (
            <CommonField
              className="mb-8"
              titleText="Path"
              name="path"
              value={`/home/${lakehouseData?.name}/${lakehouseData.path || ""}`}
              onChange={(e) => {
                setLakehouseData({
                  ...lakehouseData,
                  path: e.target.value.split("/")[3],
                });
              }}
              error="field"
            />
          )}

          {lakehouseData?.storage_location && lakehouseData?.storage_location?.value !== "local_fs" && (
            <CommonField
              className="mb-8"
              titleText="Configuration"
              value={lakehouseData?.configure}
              options={configureData}
              getOptionLabel={(option) => {
                return (
                  <span className="flex items-center">
                    <div className="h-12 w-12 flex items-center justify-center mr-4">
                      <i className={`${option?.icon} flex items-center justify-center`} />
                    </div>
                    {option.label}
                  </span>
                );
              }}
              onChange={(configure) => {
                if (configure?.value == "create_configuration") {
                  navigate(constants.route.settings + "?create_configuration");
                }
                setLakehouseData({
                  ...lakehouseData,
                  configure,
                });
              }}
              error
              selectfield
            />
          )}
          <CommonField
            className="mb-8"
            titleText={cloudComutingData?.label}
            value={lakehouseData?.[cloudComutingData?.value]}
            options={cloudComutingData?.children}
            isClearable={false}
            onChange={(e) => {
              setLakehouseData({
                ...lakehouseData,
                [cloudComutingData?.value]: { label: e.label, value: e.value },
              });
              if (e.value == "azure") {
                contextValues?.getCloudAccountSettings();
              }
            }}
            error
            selectfield
          />
          {lakehouseData?.cloud_provider?.value == "azure" &&
            contextValues?.cloudAccountSettings != undefined &&
            Object.keys(contextValues?.cloudAccountSettings).some(
              (key) => key !== "service_port" && contextValues?.cloudAccountSettings[key] === null
            ) && (
              <div className="p-12 bg-amber-50 bg-opacity-30 rounded-md">
                <i className="fa-solid fa-triangle-exclamation text-amber-600 text-lg mb-8" />
                <div className="text-xs text-amber-600">
                  To Cloud Config Microsoft Azure go to
                  <span
                    className="hover:text-blue-500 hover:underline cursor-pointer font-semibold ml-4"
                    role="presentation"
                    onClick={() => {
                      navigate(constants.route.settings);
                      contextValues?.setActiveTab("cloud_provider_configurations");
                    }}
                  >
                    settings
                  </span>
                </div>
              </div>
            )}
          {lakehouseData?.cloud_provider?.value == "azure" &&
            contextValues?.cloudAccountSettings != undefined &&
            !Object.keys(contextValues?.cloudAccountSettings).some(
              (key) => key !== "service_port" && contextValues?.cloudAccountSettings[key] === null
            ) && (
              <div className="grid grid-cols-2 gap-x-8">
                {cloudComutingData?.children?.map((child) => {
                  if (child?.value == lakehouseData?.[cloudComutingData?.value]?.value) {
                    return child?.children?.map((azure) => {
                      if (azure?.type == "inputbox" && !azure?.show) {
                        return (
                          <CommonField
                            key={azure?.value}
                            className={`mb-8 ${azure?.full_width && "col-span-2"}`}
                            titleText={azure?.label}
                            name={azure?.value}
                            value={lakehouseData?.[azure?.value] || ""}
                            onChange={handleChange}
                            error="field"
                          />
                        );
                      }
                      if (azure?.type == "inputbox" && azure?.show) {
                        if (azure?.show?.includes(lakehouseData?.executor?.value)) {
                          return (
                            <CommonField
                              key={azure?.value}
                              className={`mb-8 ${azure?.full_width && "col-span-2"}`}
                              titleText={azure?.label}
                              name={azure?.value}
                              value={lakehouseData?.[azure?.value] || ""}
                              onChange={handleChange}
                              error={azure?.validate ? azure?.validate : "field"}
                            />
                          );
                        }
                      }
                      if (azure?.type == "dropdown" && !azure?.show) {
                        // const targetLabel = lakehouseData?.cluster_idle_time?.label;
                        let clusterOptions;
                        if (lakehouseData?.cluster_idle_time?.label != undefined) {
                          clusterOptions = true;
                        } else {
                          clusterOptions = false;
                        }

                        return (
                          <CommonField
                            key={azure?.value}
                            className={`mb-8 ${azure?.full_width && "col-span-2"}`}
                            titleText={azure?.label}
                            value={lakehouseData?.[azure?.value]}
                            isClearable={false}
                            options={
                              clusterOptions == true && azure?.value === "worker_idle_time"
                                ? azure?.option.filter((item) => parseInt(item.value, 10) < lakehouseData?.cluster_idle_time?.value)
                                : azure?.option
                            }
                            onChange={(e) => {
                              setLakehouseData({
                                ...lakehouseData,
                                [azure?.value]: {
                                  label: e.label,
                                  value: e.value,
                                },
                              });
                            }}
                            error
                            selectfield
                            isDisabled={lakehouseData?.cluster_idle_time?.label === undefined && azure?.value === "worker_idle_time"}
                          />
                        );
                      }
                      if (azure?.type == "dropdown" && azure?.show) {
                        if (azure?.show?.includes(lakehouseData?.executor?.value)) {
                          // const targetLabel = lakehouseData?.cluster_idle_time?.label;
                          let clusterOptions;
                          if (lakehouseData?.cluster_idle_time?.label != undefined) {
                            clusterOptions = true;
                          } else {
                            clusterOptions = false;
                          }
                          return (
                            <CommonField
                              key={azure?.value}
                              className={`mb-8 ${azure?.full_width && "col-span-2"}`}
                              titleText={azure?.label}
                              value={lakehouseData?.[azure?.value]}
                              isDisabled={
                                (lakehouseData?.cluster_idle_time?.label === undefined && azure?.value === "worker_idle_time") ||
                                ((azure?.value === "worker_node" || azure?.value === "master_node") && !virtualSizes)
                              }
                              options={
                                clusterOptions == true && azure?.value === "worker_idle_time"
                                  ? azure?.option.filter((item) => parseInt(item.value, 10) < lakehouseData?.cluster_idle_time?.value)
                                  : virtualSizes && (azure?.value == "worker_node" || azure?.value == "master_node")
                                  ? virtualSizes?.data?.vm_sizes.map((vm) => {
                                      return {
                                        value: vm.name,
                                        label: `${vm.name} - ${vm.numberOfCores} vcpus, ${vm.memoryInMB / 1024} GB memory`,
                                      };
                                    })
                                  : azure?.option
                              }
                              isClearable={false}
                              onChange={async (e) => {
                                setLakehouseData({
                                  ...lakehouseData,
                                  ...(azure?.value === "cluster_idle_time"
                                    ? {
                                        [azure.value]: {
                                          label: e.label,
                                          value: e.value,
                                        },
                                        worker_idle_time: null,
                                      }
                                    : azure?.value === "location"
                                    ? {
                                        [azure.value]: {
                                          label: e.label,
                                          value: e.value,
                                        },
                                        master_node: null,
                                        worker_node: null,
                                      }
                                    : {
                                        [azure.value]: {
                                          label: e.label,
                                          value: e.value,
                                        },
                                      }),
                                });
                                if (azure?.value == "location") {
                                  const data = await contextValues?.getVirtualmachineSizes({ location: e?.value });
                                  setvirtualSizes(data);
                                }
                              }}
                              error
                              selectfield
                            />
                          );
                        }
                      }
                      return null;
                    });
                  }
                  return null;
                })}
              </div>
            )}
          <CommonField
            className="mb-8"
            titleText="Description"
            rows="4"
            name="description"
            onChange={handleChange}
            value={lakehouseData?.description}
            textarea
          />
        </SidebarForm>
      )}

      <div className="p-10">
        <Table
          // subTitle="Get insights into the available lakehouses and their attributes. Explore further by clicking on a lakehouse for a detailed description."
          setPage={setPage}
          page={page}
          tableHeader={lakehouseHead}
          bodyHight="max-h-[calc(100vh-14.4rem)]"
          rowsPerPage={rowsPerPage}
          totalPost={contextValues?.lakehouseData?.meta?.total}
        >
          {filterDataBySearch(contextValues?.lakehouseData?.data, search)?.map((item, index) => {
            return (
              <tr key={index} className="border-b w-full hover:bg-tableHoverColor last:border-0">
                <td
                  className={`px-16 py-5 cursor-pointer ${
                    item?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.status == "online" && "hover:underline"
                  } text-primaryColor text-xs`}
                >
                  <span
                    role="presentation"
                    onClick={() => {
                      return item?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.status == "online"
                        ? navigate(`${constants.route.lakehouseProperties}/${item.id}`)
                        : null;
                    }}
                  >
                    {item?.name}
                  </span>
                </td>
                <td className="px-16 py-5 text-center items-center cursor-pointer flex justify-center text-xs">
                  <Status
                    title={item?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.status ?? "offline"}
                    tooltipMessage={
                      item?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.status === "failed"
                        ? item?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.creation_error
                        : undefined
                    }
                  />
                </td>
                <td className="px-16 py-5 text-center cursor-pointer justify-center text-xs">{dateFormat(item?.createdAt)}</td>
                <td className="px-16 py-5 text-center cursor-pointer justify-center text-xs">{item?.description}</td>
                <td className="px-16 py-8 text-center cursor-pointerjustify-center text-xs">
                  <div className="flex justify-center">
                    <div
                      className={`flex ${
                        !item?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.status ||
                        item?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.status === "failed"
                          ? "justify-end"
                          : "justify-center"
                      } w-24`}
                    >
                      {(!item?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.status ||
                        item?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.status === "failed") && (
                        // <i className="fa-solid fa-arrows-rotate ml-5" role="presentation" onClick={() => setIsEdit(item?.id)} />
                        <CustomTooltip
                          direction="top"
                          title={item?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.status == "failed" ? "Retry" : "Go Online"}
                        >
                          <i
                            className={`${
                              item?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.status
                                ? "fa-solid fa-arrows-rotate"
                                : "fa-solid fa-arrow-rotate-left"
                            } text-lightGrey mr-5 cursor-pointer hover:text-blue-600`}
                            role="presentation"
                            onClick={() => {
                              setIsEdit(item?.id);
                              setIsEditStatus(item?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.status ?? "offline");
                            }}
                          />
                        </CustomTooltip>
                      )}
                      <Deletepopup
                        open={openDeletePopup.id == item?.id}
                        setOpen={setDeletepopup}
                        onDelete={() => {
                          contextValues.deleteLakehouse(openDeletePopup.id);
                          setDeletepopup({ open: false });
                        }}
                      >
                        <CustomTooltip direction="top" title="Delete">
                          <i
                            className="fa-solid fa-trash-can text-lightGrey font-normal cursor-pointer hover:text-blue-600"
                            role="presentation"
                            onClick={() => {
                              setDeletepopup({ open: true, id: item?.id });
                            }}
                          />
                        </CustomTooltip>
                      </Deletepopup>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </Table>
      </div>
    </div>
  );
}

export default Lakehouse;
