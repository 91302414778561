import { memo } from "react";

import UploadButton from "../../Widgets/UploadButton";
import CommonField from "../../Widgets/CommonField";

function SlackIntegration({ slackIntegration, setSlackIntegration, handleChange }) {
  const uploadImage = (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      const url = URL.createObjectURL(files[0]);
      setSlackIntegration({ image: url });
    } else {
      setSlackIntegration({ image: null });
    }
  };

  return (
    <div className="w-full">
      <CommonField className="mb-8" titleText="Name" name="botname" value={slackIntegration.botname || ""} onChange={handleChange} error="field" />
      <CommonField
        className="mb-8"
        titleText="Api Token"
        name="apitoken"
        value={slackIntegration.apitoken || ""}
        onChange={handleChange}
        error="field"
      />
      <CommonField className="mb-8" titleText="Channel" name="channel" value={slackIntegration.channel || ""} onChange={handleChange} error="field" />
      <div className="h-fit w-full flex items-center">
        <div className="flex items-center text-[14px] mr-12">
          User Image<span className="text-red-900 text-md">*</span>
        </div>
        <UploadButton handleFileUpload={uploadImage} accept="image/*" imgName />
      </div>
    </div>
  );
}

export default memo(SlackIntegration);
