import { useEffect } from "react";

import Checkbox from "../../Widgets/Checkbox";
import CommonField from "../../Widgets/CommonField";

const QRCodeDetection = ({ qrCodeDetection, setQrCodeDetection, handleChange }) => {
  useEffect(() => {
    setQrCodeDetection({ image: "single" });
  }, []);

  return (
    <div className="grid gap-8 w-full h-fit">
      <div className="flex flex-row">
        <Checkbox name="image" value="single" checked={qrCodeDetection.image === "single"} label="Single" onChange={handleChange} />
        <Checkbox className="ml-12" name="image" value="stream" checked={qrCodeDetection.image === "stream"} label="Stream" onChange={handleChange} />
      </div>
      <CommonField
        placeholder={qrCodeDetection.image === "single" ? "Single Url" : qrCodeDetection.image === "stream" ? "Stream Url" : ""}
        titleText="URL"
        name="url"
        value={qrCodeDetection.url || ""}
        onChange={handleChange}
        error="email"
      />
    </div>
  );
};

export default QRCodeDetection;
