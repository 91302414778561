// const host = "http://localhost:1337/api";
const host = "/admin/api";
// const host = "http://122.166.179.60:1337/api";

const constants = {
  token: "",
  host,
  url: {
    // login: `${host}/auth/local`,
    login: `${host}/custom/login`,
    register: `${host}/auth/local/register`,
    users: `${host}/users`,
    team: `${host}/teams`,
    flow: `${host}/flows`,
    lakehouses: `${host}/lakehouses`,
    component: `${host}/components`,
    upload: `${host}/upload`,
    configuration: `${host}/configures`,
    scheduler: `${host}/schedulers`,
    user_permission: `${host}/user-premissions`,
    count: `${host}/count`,
    flowExecution: `${host}/flow-execution`,
    getCPUInfo: `${host}/get-cpu-info`,
    scheduledJobs: `${host}/scheduled-jobs`,
    settingConfigurations: `${host}/setting-configurations`,
    login_with_microsoft: `${host}/connect/microsoft`,
    auth_with_microsoft: `${host}/auth/microsoft/callback`,
    lakehouseForm: `${host}/lakehouse-form`,
    microsoftAzure: `${host}/microsoft-azure`,
    policies: `${host}/policies`,
    accessmanager: `${host}/policies`,
    groups: `${host}/groups`,
    tableCount: `${host}/custom/catalog/table-count`,
    cloudAccountSettings: `${host}/cloud-account-settings`,
    workermanagement: `${host}/custom/worker-management`,
    virtualmachineSizes: `${host}/custom/get-virtual-machine-sizes`,
    logs: `${host}/logs`,
    getCatalogLineage: `${host}/custom/catalog/get-catalog-lineage`,
    catalogs: `${host}/custom/catalog/get-catalogs-data`,
    getCatalogs: `${host}/custom/catalog/get-catalogs`,
    getCustomNamespace: `${host}/custom/catalog/get-namespaces`,
    tables: `${host}/custom/catalog/get-tables`,
    lineage: `${host}/lineages`,
    canvasEdgeDeletion: `${host}/custom/canvas-edge-deletion`,
  },

  route: {
    // Pages
    pageNotFound: "/404",
    default: "/",
    application: "/application",
    team: "/team",
    lakehouse: "/lakehouse",
    lakehouseProperties: "/lakehouse/lakehouseProperties",
    flow: "/team/flow",
    login: "/login",
    signup: "/signup",
    reportsDetails: "/reports-details",
    sqlEditor: "/sqlEditor",
    ingestionApplication: "/tensorflow",
    configurations: "/configurations",
    users: "/users",
    workflowCreate: "/workflow/create",
    Classification: "/tensorflow/Classification",
    detect: "/tensorflow/detect",
    postgreSQL: `/configurations/postgreSQL`,
    lackhouseFlow: `/flow`,
    dashboard: `/dashboard`,
    flowCreation: `/flow/flow-creation`,
    flowCreation_canvas: `/flow/canvas-0.0.14`,
    settings: "/settings",
    scheduledJobs: "/scheduled-jobs",
    scheduledQueryDetail: "/query-history",
    dataCatalog: `/data-catalog`,
    security: "/security",
  },

  images: {
    noImage: "https://www.svgrepo.com/show/528243/file-corrupted.svg",
  },

  components: {
    json_output: ["s3_storage", "azure_blob_storage", "file_system"],

    lakehouse_source: ["s3_storage", "azure_blob_storage"],

    methods: [
      { value: "GET", label: "GET" },
      { value: "PUT", label: "PUT" },
      { value: "POST", label: "POST" },
    ],

    google_cloud_platform: [
      {
        value: "azure",
        label: "Microsoft Azure",
        icon: "https://www.svgrepo.com/show/448274/azure.svg",
      },
      {
        value: "amazon_web_services",
        label: "Amazon Web Services",
        icon: "https://www.svgrepo.com/show/376356/aws.svg",
      },
      {
        value: "google_cloud_platform",
        label: "Google Cloud Platform",
        icon: "https://www.svgrepo.com/show/353805/google-cloud.svg",
      },
    ],

    token: [
      { value: "short_lived_token", label: "Short Lived Token" },
      { value: "long_lived_token", label: "Long Lived Token" },
    ],

    selectKafkaBroker: [{ value: "select", label: "Select Broker" }],

    postgressMethods: [
      { value: "GET", label: "Demo-1" },
      { value: "PUT", label: "Demo-2" },
    ],

    frequencyData: [
      { value: "hour", label: "Hour" },
      { value: "minutes", label: "Minutes" },
      { value: "days", label: "Days" },
    ],

    snmpHost: [
      { value: "5432", label: "5432" },
      { value: "5433", label: "5433" },
    ],

    postgressProducerConnection: [{ value: "select", label: "Create PostgreSQL" }],

    objectStorageType: [
      { value: "AWS_S3", label: "AWS S3" },
      { value: "MINIO", label: "Minio" },
      { value: "DELL_ECS", label: "DELL ECS" },
    ],

    emailSendSelect: [
      { value: "To", label: "To" },
      { value: "CC", label: "CC" },
      { value: "BCC", label: "BCC" },
    ],

    selectWindow: [
      { value: "hourly", label: "Hourly" },
      { value: "daily", label: "Daily" },
      { value: "weekly", label: "Weekly" },
    ],

    functions: [
      { value: "groupBy", label: "Group By" },
      { value: "uniqueCount", label: "Unique Count" },
      { value: "sum", label: "Sum" },
    ],

    comparisonOperators: [
      { value: "is", label: "Is" },
      { value: "is_not", label: "Isn't" },
      { value: "before", label: "Before" },
      { value: "after", label: "After" },
      { value: "starts", label: "Starts" },
      { value: "ends", label: "Ends" },
      { value: "contains", label: "Contains" },
      { value: "greater_than", label: "Greater than" },
      { value: "less_than", label: "Less than" },
    ],

    conditionalRouting: [
      {
        value: "binary_conditional_routing",
        label: "Binary Conditional Routing",
      },
      {
        value: "multi_conditional_routing",
        label: "Multi Conditional Routing",
      },
    ],

    booleanOperators: [
      { value: "and", label: "And" },
      { value: "or", label: "Or" },
    ],

    joinStreamFrequencyData: [
      { value: "hour", label: "Hour" },
      { value: "minutes", label: "Minutes" },
      { value: "seconds", label: "Seconds" },
    ],

    joinStreamWindowData: [
      { value: "slidingWindow", label: "Sliding Window" },
      { value: "tumblingWindow", label: "Tumbling Window" },
    ],

    transformationLanguages: [
      { value: "go_lang", label: "Go Lang" },
      { value: "python", label: "Python" },
      { value: "javascript", label: "Javascript" },
    ],

    parquetSources: [
      { value: "local_fs", label: "Local FS" },
      { value: "s3", label: "S3" },
    ],

    scheduledQueriesData: [
      { label: "Every hour", value: "every_hour" },
      { label: "Every day", value: "every_day" },
      { label: "Every week", value: "every_week" },
      { label: "Every month", value: "every_month" },
    ],

    scheduledQuerieDetailsData: [
      { value: "local_fs", label: "Local FS" },
      { value: "s3", label: "S3" },
    ],

    userTableData: [
      { value: "admin", label: "Admin" },
      { value: "user", label: "User" },
    ],

    expiryData: [
      { value: "never", label: "Never" },
      { value: "every_minute", label: "Every Minute" },
      { value: "every_hour", label: "Every Hour" },
      { value: "every_12_hours", label: "Every 12 Hours" },
      { value: "every_day", label: "Every Day" },
    ],

    settingsData: [
      { value: "shared_executors", label: "Shared Executors" },
      { value: "independent_executors", label: "Independent Executors" },
    ],
  },

  cloud_provider: [
    {
      label: "Microsoft Azure",
      value: "azure",
    },
    {
      label: "Amazon Web Services",
      value: "aws",
    },
    {
      label: "Google Cloud Platform",
      value: "gcp",
    },
  ],

  location: [
    {
      value: "eastus",
      label: "(US) East US",
    },
    {
      value: "eastus2",
      label: "(US) East US 2",
    },
    {
      value: "southcentralus",
      label: "(US) South Central US",
    },
    {
      value: "westus2",
      label: "(US) West US 2",
    },
    {
      value: "westus3",
      label: "(US) West US 3",
    },
    {
      value: "australiaeast",
      label: "(Asia Pacific) Australia East",
    },
    {
      value: "southeastasia",
      label: "(Asia Pacific) Southeast Asia",
    },
    {
      value: "northeurope",
      label: "(Europe) North Europe",
    },
    {
      value: "swedencentral",
      label: "(Europe) Sweden Central",
    },
    {
      value: "uksouth",
      label: "(Europe) UK South",
    },
    {
      value: "westeurope",
      label: "(Europe) West Europe",
    },
    {
      value: "centralus",
      label: "(US) Central US",
    },
    {
      value: "southafricanorth",
      label: "(Africa) South Africa North",
    },
    {
      value: "centralindia",
      label: "(Asia Pacific) Central India",
    },
    {
      value: "eastasia",
      label: "(Asia Pacific) East Asia",
    },
    {
      value: "japaneast",
      label: "(Asia Pacific) Japan East",
    },
    {
      value: "koreacentral",
      label: "(Asia Pacific) Korea Central",
    },
    {
      value: "canadacentral",
      label: "(Canada) Canada Central",
    },
    {
      value: "francecentral",
      label: "(Europe) France Central",
    },
    {
      value: "germanywestcentral",
      label: "(Europe) Germany West Central",
    },
    {
      value: "norwayeast",
      label: "(Europe) Norway East",
    },
    {
      value: "polandcentral",
      label: "(Europe) Poland Central",
    },
    {
      value: "switzerlandnorth",
      label: "(Europe) Switzerland North",
    },
    {
      value: "uaenorth",
      label: "(Middle East) UAE North",
    },
    {
      value: "brazilsouth",
      label: "(South America) Brazil South",
    },
    {
      value: "centraluseuap",
      label: "(US) Central US EUAP",
    },
    {
      value: "qatarcentral",
      label: "(Middle East) Qatar Central",
    },
    {
      value: "centralusstage",
      label: "(US) Central US (Stage)",
    },
    {
      value: "eastusstage",
      label: "(US) East US (Stage)",
    },
    {
      value: "eastus2stage",
      label: "(US) East US 2 (Stage)",
    },
    {
      value: "northcentralusstage",
      label: "(US) North Central US (Stage)",
    },
    {
      value: "southcentralusstage",
      label: "(US) South Central US (Stage)",
    },
    {
      value: "westusstage",
      label: "(US) West US (Stage)",
    },
    {
      value: "westus2stage",
      label: "(US) West US 2 (Stage)",
    },
    {
      value: "asia",
      label: "Asia",
    },
    {
      value: "asiapacific",
      label: "Asia Pacific",
    },
    {
      value: "australia",
      label: "Australia",
    },
    {
      value: "brazil",
      label: "Brazil",
    },
    {
      value: "canada",
      label: "Canada",
    },
    {
      value: "europe",
      label: "Europe",
    },
    {
      value: "france",
      label: "France",
    },
    {
      value: "germany",
      label: "Germany",
    },
    {
      value: "global",
      label: "Global",
    },
    {
      value: "india",
      label: "India",
    },
    {
      value: "japan",
      label: "Japan",
    },
    {
      value: "korea",
      label: "Korea",
    },
    {
      value: "norway",
      label: "Norway",
    },
    {
      value: "singapore",
      label: "Singapore",
    },
    {
      value: "southafrica",
      label: "South Africa",
    },
    {
      value: "switzerland",
      label: "Switzerland",
    },
    {
      value: "uae",
      label: "United Arab Emirates",
    },
    {
      value: "uk",
      label: "United Kingdom",
    },
    {
      value: "unitedstates",
      label: "United States",
    },
    {
      value: "unitedstateseuap",
      label: "United States EUAP",
    },
    {
      value: "eastasiastage",
      label: "(Asia Pacific) East Asia (Stage)",
    },
    {
      value: "southeastasiastage",
      label: "(Asia Pacific) Southeast Asia (Stage)",
    },
    {
      value: "brazilus",
      label: "(South America) Brazil US",
    },
    {
      value: "eastusstg",
      label: "(US) East US STG",
    },
    {
      value: "northcentralus",
      label: "(US) North Central US",
    },
    {
      value: "westus",
      label: "(US) West US",
    },
    {
      value: "jioindiawest",
      label: "(Asia Pacific) Jio India West",
    },
    {
      value: "eastus2euap",
      label: "(US) East US 2 EUAP",
    },
    {
      value: "southcentralusstg",
      label: "(US) South Central US STG",
    },
    {
      value: "westcentralus",
      label: "(US) West Central US",
    },
    {
      value: "southafricawest",
      label: "(Africa) South Africa West",
    },
    {
      value: "australiacentral",
      label: "(Asia Pacific) Australia Central",
    },
    {
      value: "australiacentral2",
      label: "(Asia Pacific) Australia Central 2",
    },
    {
      value: "australiasoutheast",
      label: "(Asia Pacific) Australia Southeast",
    },
    {
      value: "japanwest",
      label: "(Asia Pacific) Japan West",
    },
    {
      value: "jioindiacentral",
      label: "(Asia Pacific) Jio India Central",
    },
    {
      value: "koreasouth",
      label: "(Asia Pacific) Korea South",
    },
    {
      value: "southindia",
      label: "(Asia Pacific) South India",
    },
    {
      value: "westindia",
      label: "(Asia Pacific) West India",
    },
    {
      value: "canadaeast",
      label: "(Canada) Canada East",
    },
    {
      value: "francesouth",
      label: "(Europe) France South",
    },
    {
      value: "germanynorth",
      label: "(Europe) Germany North",
    },
    {
      value: "norwaywest",
      label: "(Europe) Norway West",
    },
    {
      value: "switzerlandwest",
      label: "(Europe) Switzerland West",
    },
    {
      value: "ukwest",
      label: "(Europe) UK West",
    },
    {
      value: "uaecentral",
      label: "(Middle East) UAE Central",
    },
    {
      value: "brazilsoutheast",
      label: "(South America) Brazil Southeast",
    },
  ],

  Idle_Time: [
    {
      value: "300",
      label: "5 Minutes",
    },
    {
      value: "600",
      label: "10 Minutes",
    },
    {
      value: "900",
      label: "15 Minutes",
    },
    {
      value: "1800",
      label: "30 Minutes",
    },
    {
      value: "3600",
      label: "1 hour",
    },
    {
      value: "7200",
      label: "2 Hours",
    },
    {
      value: "21600",
      label: "6 Hours",
    },
    {
      value: "86400",
      label: "1 Day",
    },
    {
      value: " 172800",
      label: "2 Days",
    },
    {
      value: "432000",
      label: "5 Days",
    },
  ],
};

export default constants;
