import { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function SqlEditor() {
  const params = useLocation();
  const [url, setUrl] = useState();

  useEffect(() => {
    if (params.search.includes("sqllab")) {
      setUrl("https://reports.dataphion.com/superset/sqllab/");
    } else if (params.search.includes("savedqueryview")) {
      setUrl("https://reports.dataphion.com/savedqueryview/list/");
    } else if (params.search.includes("history")) {
      setUrl("https://reports.dataphion.com/superset/sqllab/history/");
    } else if (params.search.includes("dashboard")) {
      setUrl("https://reports.dataphion.com/superset/welcome/");
    } else if (params.search.includes("report")) {
      setUrl("https://reports.dataphion.com/superset/welcome/");
    }
  }, [params]);

  return (
    <div className="w-full h-full">
      <iframe id="superset-iframe" width="100%" height="100%" src={url} title="Reports" />
    </div>
  );
}
export default memo(SqlEditor);
