import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CommonField from "../../Widgets/CommonField";
import { Context } from "../../Context";
import constants from "../../constants";

const PubSub = ({ pubSub, setPubSub, handleChange }) => {
  const contextValues = useContext(Context);
  const navigate = useNavigate();
  const [optionsData, setOptionsData] = useState();
  const [selectedDropDown, setSelectedDropDown] = useState();

  useEffect(() => {
    if (contextValues.configurationData?.data !== undefined || null) {
      const filteredData = contextValues.configurationData?.data?.filter((item) => item?.type === "pub_sub_configuration");
      setOptionsData([
        ...filteredData.map((item) => ({
          value: `${item?.id}`,
          label: `${item?.configure?.name}`,
        })),
        {
          value: "create_configuration",
          label: "Create Configuration",
          icon: "fa-solid fa-plus",
        },
      ]);

      if (pubSub?.account_id) {
        const filterDropDown = contextValues.configurationData?.data?.find((item) => item?.id == pubSub?.account_id);
        setSelectedDropDown({
          value: filterDropDown?.id,
          label: filterDropDown?.configure?.name,
        });
      }
    }
    if (!pubSub?.number_of_outstanding && !pubSub?.number_of_routines) {
      setPubSub({
        ...pubSub,
        number_of_outstanding: "500",
        number_of_routines: "30",
      });
    } else if (!pubSub?.number_of_outstanding) {
      setPubSub({
        ...pubSub,
        number_of_outstanding: "500",
      });
    } else if (!pubSub?.number_of_routines) {
      setPubSub({
        ...pubSub,
        number_of_routines: "30",
      });
    }
  }, [contextValues.configurationData]);

  return (
    <div className="grid grid-cols-2 gap-8 w-full h-fit">
      <CommonField
        className="col-span-2"
        titleText="Account name"
        value={selectedDropDown}
        options={optionsData}
        getOptionLabel={(option) => {
          return (
            <span className="flex items-center">
              <i className={`${option.icon} flex items-center justify-center mr-4`} />
              {option.label}
            </span>
          );
        }}
        onChange={(data) => {
          if (data.value == "create_configuration") {
            navigate(constants.route.settings + "?create_configuration");
          }
          setSelectedDropDown(data);
          const pubsubConfigureId = contextValues.configurationData?.data?.find((item) => item?.id == data?.value)?.id;
          setPubSub({
            ...pubSub,
            account_id: pubsubConfigureId,
          });
        }}
        error
        selectfield
      />
      <CommonField
        titleText="No. of Routines"
        name="number_of_routines"
        value={pubSub.number_of_routines == undefined ? "30" : pubSub.number_of_routines || ""}
        onChange={handleChange}
      />
      <CommonField
        titleText="No. of OutStanding Message"
        name="number_of_outstanding"
        value={pubSub.number_of_outstanding == undefined ? "500" : pubSub.number_of_outstanding || ""}
        onChange={handleChange}
      />
    </div>
  );
};

export default PubSub;
