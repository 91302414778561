import { useState, memo } from "react";

import DefaultCard from "../../Widgets/DefaultCard";
import Applicationheader from "../Application/Applicationheader";
import Defaultprojectheader from "./Defaultprojectheader";

const Defaultproject = () => {
  const [selectedValue, setSelectedValue] = useState("My Projects");

  const defaultHeaderData = [
    { id: 1, name: "My Projects", value: "my_projects" },
    { id: 2, name: "User Projects", value: "user_projects" },
  ];

  return (
    <div>
      <Defaultprojectheader />
      <div className="flex w-full flex-col px-16 ">
        <Applicationheader selectedValue={selectedValue} setSelectedValue={setSelectedValue} headerData={defaultHeaderData} />
        <DefaultCard />
      </div>
    </div>
  );
};

export default memo(Defaultproject);
