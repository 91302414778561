import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import Input from "./Input";
import CustomTooltip from "./Tooltip";
import Status from "./Status";
import constants from "../constants";

const Header = ({
  title,
  setSearch,
  search,
  searchPlaceholder,
  createButtonText,
  onButtonClick,
  headerChild,
  showMenuIcon,
  onMenuIconClick,
  onRunClick,
  runButtonText,
  showBackIcon,
  onBack,
  buttonIcons,
  status,
}) => {
  const [userProfile, setProfile] = useState(false);
  const userData = sessionStorage.getItem("USER");
  const navigate = useNavigate();
  return (
    <div className="flex justify-between items-center px-9 border-b bg-white min-h-33 w-auto relative">
      <div className="flex items-center">
        <div className="text-sm font-normal bg-buttonGradient inline-block text-transparent bg-clip-text flex items-center">
          {showBackIcon && (
            <i
              className="fa-solid fa-angle-left font-semibold flex-row mr-5 cursor-pointer hover:text-blue-600 h-20 w-20 flex items-center justify-center hover:bg-d4eef4 rounded-full"
              role="presentation"
              onClick={onBack}
            />
          )}
          {title}
        </div>
        {headerChild}
      </div>
      <div className="flex items-center">
        {searchPlaceholder && (
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={searchPlaceholder}
            setSearch={setSearch}
            search
            className="mr-8 !w-fit"
          />
        )}
        {createButtonText && (
          <Button variant="contained" color="primaryColor" startIcon={<i className="fa-solid fa-plus mr-6" />} onClick={onButtonClick}>
            {createButtonText}
          </Button>
        )}
        {status && <Status title={status} padding="py-5" />}
        {runButtonText && (
          <Button className="ml-8" variant="contained" color="primaryColor" startIcon={buttonIcons} onClick={onRunClick}>
            {runButtonText}
          </Button>
        )}
        {/* <div
          className="h-24 w-24 flex items-center justify-center rounded-full ml-8 bg-primaryColor hover:bg-1A85A0"
          role="presentation"
          onClick={() => setProfile(true)}
        >
          {JSON.parse(userData).email ? (
            <span className="text-white font-semibold text-lg">
              {JSON.parse(userData).email.charAt(0).toUpperCase()}
            </span>
          ) : (
            <i className="fa-solid fa-user text-sm cursor-pointer text-white" />
          )}
        </div> */}
        <div
          className="flex items-center justify-center  ml-8 hover:text-primaryColor cursor-pointer"
          role="presentation"
          onClick={() => setProfile(true)}
        >
          Settings
          <i className="fa-solid fa-caret-down text-xxs ml-4 cursor-pointer" />
        </div>
        {showMenuIcon == false && (
          <CustomTooltip direction="left" title="Components">
            <i className="fa-solid fa-bars text-md cursor-pointer ml-8" role="presentation" onClick={onMenuIconClick} />
          </CustomTooltip>
        )}
      </div>
      {userProfile && (
        <div className="w-full h-screen absolute left-0 top-0 bg-opacity-10 backdrop-blur-xs z-999">
          <div className="w-full h-full absolute top-0 left-0" role="presentation" onClick={() => setProfile(false)} />
          <div className="w-121 absolute z-1 top-33 right-2  bg-white  shadow-md shadow-cardShadowColor">
            {/* <div className="flex items-center p-8 bg-white border-b"> */}
            {/* <div className="h-22 w-22 flex items-center justify-center rounded-sm bg-white border">
                {JSON.parse(userData).email ? (
                  <span className="font-semibold text-sm">
                    {JSON.parse(userData).email.charAt(0).toUpperCase()}
                  </span>
                ) : (
                  <i className="fa-solid fa-user text-md cursor-pointer" />
                )}
              </div> */}
            {/* <div className="ml-8">
                <div className="text-[14px]">{JSON.parse(userData).email}</div>
              </div> */}
            {/* </div> */}
            <div className="p-8 text-xs text-00000073">User</div>
            <div
              className="px-16 pb-8 cursor-pointer hover:text-primaryColor text-xs"
              role="presentation"
              onClick={() => navigate(constants.route.settings)}
            >
              Settings
            </div>
            <div
              onClick={() => {
                localStorage.clear();
                sessionStorage.clear();
                navigate(constants.route.login);
              }}
              role="presentation"
              className="px-16 pb-8 w-full border-b text-xs  cursor-pointer hover:text-primaryColor"
            >
              {/* <i className="fa-solid fa-right-from-bracket text-[14px] mr-4" /> */}
              <span>Sign out</span>
            </div>
            <div className="p-8 text-xs text-00000073">About</div>
            <div className="text-[10px] px-16 pb-8">{JSON.parse(userData).email}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
