import { memo, useEffect } from "react";

import CommonField from "../../Widgets/CommonField";
import Checkbox from "../../Widgets/Checkbox";
import constants from "../../constants";

function HttpServer({ httpServer, setHttpServer, handleChange }) {
  useEffect(() => {
    setHttpServer({ ...httpServer, autogen_id: false });
  }, []);

  return (
    <div className="grid gap-8 w-full h-fit">
      <CommonField titleText="URL Path" name="path" value={httpServer.path || ""} error="email" onChange={handleChange} />
      <CommonField titleText="Port" name="port" value={httpServer.port || ""} error="field" onChange={handleChange} />
      <CommonField
        selectfield
        titleText="Method"
        value={httpServer.method}
        options={constants.components.methods}
        error="field"
        onChange={(method) => setHttpServer({ ...httpServer, method })}
      />
      <CommonField titleText="Content-Type" name="content_type" value={httpServer.content_type || ""} onChange={handleChange} error="field" />
      <Checkbox
        name="autogen_id"
        value="single"
        checked={httpServer?.autogen_id == true}
        label="Autogen ID"
        onChange={() => setHttpServer({ ...httpServer, autogen_id: !httpServer.autogen_id })}
      />
    </div>
  );
}

export default memo(HttpServer);
