import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import moment from "moment";
import Header from "../../Widgets/Header";
import CommonField from "../../Widgets/CommonField";
import { Context } from "../../Context";
import SidebarForm from "../lakehouse/SidebarForm";
import CodeEditer from "../../Widgets/CodeEditer";
import CustomTooltip from "../../Widgets/Tooltip";
import Table from "../../Widgets/Table";
import Tabs from "../../Widgets/Tabs";
import Status from "../../Widgets/Status";
import CustomSwitch from "../../Widgets/CustomSwitch";
import constants from "../../constants";
import Button from "../../Widgets/Button";
import { capitalizeFirstLetter, maskValue } from "../../utils/utils";
import Deletepopup from "../../Widgets/Deletepopup";

function Settings() {
  const contextValues = useContext(Context);
  const params = useLocation();
  const openCreate = params.search.includes("create_configuration");
  const [search, setSearch] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [optionSelected, setOptionSelected] = useState();
  const [configuration, setConfiguration] = useState({});
  // const [token, setToken] = useState();
  const [cloudConfigurationData, setCloudConfigurationData] = useState({});
  const [userPermissionData, setUserPermissionData] = useState([]);
  const [optionsData, setOptionsData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [collapsedItemId, setCollapsedItemId] = useState(null);
  const [editItem, setEditItem] = useState();
  const [typePage, setTypePage] = useState(1);
  const [pageUser, setPageUser] = useState(1);
  const [editId, setEditId] = useState(null);
  const [rowsPerPage] = useState(16);
  const [editField, setEditField] = useState(null);
  const [openDeletePopup, setDeletepopup] = useState(false);

  useEffect(() => {
    if (openCreate) {
      setActiveTab("connectors_configurations");
      setOpenSidebar(true);
    }
  }, [openCreate]);

  const getExpirationDateFromToken = (token) => {
    const decoded = jwt_decode(token);
    const expiryDate = decoded.exp;
    const expiryDateString = moment.unix(expiryDate).format("YYYY-MM-DDTHH:mm:ss.sssZ");
    return moment(expiryDateString).fromNow();
  };

  useEffect(() => {
    if (activeTab == "connectors_configurations") {
      contextValues.getConfigureType({ search, page: typePage, rowsPerPage });
    }
  }, [activeTab, search, typePage]);

  useEffect(() => {
    if (contextValues?.cloudAccountSettings?.[0]) {
      setEditId(contextValues?.cloudAccountSettings?.[0]?.id);
      const { settings, ...newData } = contextValues.cloudAccountSettings[0];
      const updatedData = {
        ...newData,
        ...settings,
        provider_type: constants.components.google_cloud_platform[0],
      };
      setCloudConfigurationData(updatedData);
    }
  }, [contextValues?.cloudAccountSettings]);

  useEffect(() => {
    if (activeTab == "connectors_configurations" && collapsedItemId) {
      contextValues?.getConfigurations({
        type: collapsedItemId,
      });
    }
  }, [activeTab, collapsedItemId]);

  useEffect(() => {
    if (activeTab == "user_and_permission") {
      contextValues.getUserPermission({ search, page: pageUser, rowsPerPage });
    }
  }, [pageUser, activeTab]);

  useEffect(() => {
    if (contextValues?.activeTab) {
      setActiveTab(contextValues?.activeTab);
    }
  }, [contextValues?.activeTab]);

  useEffect(() => {
    if (search !== null && activeTab == "user_and_permission") {
      const timer = setTimeout(() => {
        setPageUser(1);
        contextValues.getUserPermission({
          search,
          page: pageUser,
          rowsPerPage,
        });
      }, 800);
      return () => clearTimeout(timer);
    }
  }, [search]);

  useEffect(() => {
    if (contextValues?.configureType?.data?.length > 0) {
      setOptionsData(
        contextValues?.configureType?.data?.map((item) => ({
          value: `${item?.configure_value}`,
          label: `${item?.configure_label}`,
          icon: `${item?.icon}`,
        }))
      );
    }
  }, [contextValues?.configureType]);

  const subConfigurationTableHead = [
    { id: 1, label: "Name", position: "left" },
    { id: 2, label: "Actions", position: "center" },
  ];

  const tabData = [
    {
      title: "Cloud Provider Configurations",
      value: "cloud_provider_configurations",
    },
    { title: "Connectors Configurations", value: "connectors_configurations" },
    // { title: "Users and Permissions", value: "user_and_permission" },
    { title: "JWT", value: "jwt" },
  ];

  const handleChange = (event) => {
    if (activeTab == "user_and_permission") {
      const { name, type, value, checked } = event.target;
      setUserPermissionData((values) => ({
        ...values,
        [name]: type === "checkbox" ? checked : value,
      }));
    } else if (activeTab == "connectors_configurations") {
      const { name, value } = event.target;
      setConfiguration((values) => ({
        ...values,
        [name]: value,
      }));
    } else if (activeTab == "cloud_provider_configurations") {
      const { name, value } = event.target;
      setCloudConfigurationData((values) => ({
        ...values,
        [name]: value,
      }));
    }
  };

  const handleEditClick = (fieldName) => {
    if (editField !== fieldName) {
      setEditField(fieldName);
    } else {
      setEditField(null);
    }
  };
  const handleSubmit = () => {
    if (activeTab == "user_and_permission") {
      if (editItem) {
        contextValues.putUserPermission({
          data: userPermissionData,
          id: editItem,
        });
        setEditItem("");
      } else {
        contextValues.createUserPermission(userPermissionData);
      }
      setUserPermissionData({});
    } else if (activeTab == "connectors_configurations") {
      const data = {
        name: optionSelected?.label,
        type: optionSelected?.value,
        configure: configuration,
        setting_configuration: contextValues?.configureType?.data?.find((i) => i?.configure_value == optionSelected?.value)?.id,
      };
      if (editItem) {
        contextValues.putConfiguration({ data, id: editItem });
        setEditItem("");
      } else {
        contextValues.createConfiguration(data);
      }
      setConfiguration({});
      setOptionSelected();
    } else if (activeTab == "cloud_provider_configurations") {
      if (contextValues?.cloudAccountSettings?.length == 0) {
        contextValues.createCloudAccountSettings(cloudConfigurationData);
      } else
        contextValues.updateCloudAccountSettings({
          data: cloudConfigurationData,
          id: editId,
        });
      setEditId(null);
    }
    setOpenSidebar(false);
  };

  // const handleReset = () => {
  //   if (activeTab == "cloud_provider_configurations" && selectedCloudPlatform?.value === "microsoft_azure") {
  //     setCloudConfigurationData(contextValues?.cloudAccountSettings?.attributes);
  //   }
  // };

  // useEffect(() => {
  //   const transformed = [];

  //   contextValues?.configurationData?.data.forEach((item) => {
  //     const existingCategory = transformed.find((category) => category.name === item.name);

  //     if (existingCategory) {
  //       existingCategory.data.push(item);
  //     } else {
  //       transformed.push({
  //         id: item.id,
  //         name: item.name,
  //         data: [item],
  //       });
  //     }
  //   });

  //   setConfigurationData(transformed);
  // }, [contextValues?.configurationData]);

  const validate = () => {
    let isValidate = false;
    if (activeTab == "user_and_permission") {
      if (Object.keys(userPermissionData).length !== 0) {
        isValidate = true;
      } else {
        isValidate = false;
      }
      contextValues?.configureType?.data?.forEach((data) => {
        if (data?.configure_value == optionSelected?.value) {
          data?.fields?.forEach((field) => {
            if (Object.keys(userPermissionData).length !== 0) {
              if (!userPermissionData[field?.key] || userPermissionData[field?.key] == "") {
                isValidate = false;
              }
            }
          });
        }
      });
    } else if (activeTab == "connectors_configurations") {
      if (Object.keys(configuration).length !== 0) {
        isValidate = true;
      } else {
        isValidate = false;
      }
      contextValues?.configureType?.data?.forEach((data) => {
        if (data?.configure_value == optionSelected?.value) {
          data?.fields?.forEach((field) => {
            if (Object.keys(configuration).length !== 0) {
              if (!configuration[field?.key] || configuration[field?.key] == "") {
                isValidate = false;
              }
            }
          });
        }
      });
    } else if (activeTab == "cloud_provider_configurations") {
      if (cloudConfigurationData?.provider_type?.value === "azure") {
        if (Object.keys(cloudConfigurationData).length !== 0) {
          if (!cloudConfigurationData.tenant_id || cloudConfigurationData.tenant_id.trim() === "") {
            isValidate = false;
          } else if (!cloudConfigurationData.client_id || cloudConfigurationData.client_id === "") {
            isValidate = false;
          } else if (!cloudConfigurationData.client_secret || cloudConfigurationData.client_secret === "") {
            isValidate = false;
          } else if (!cloudConfigurationData.subscription_id || cloudConfigurationData.subscription_id === "") {
            isValidate = false;
          } else if (!cloudConfigurationData.resource_group_name || cloudConfigurationData.resource_group_name === "") {
            isValidate = false;
          } else {
            isValidate = true;
          }
        }
      }
    }
    return isValidate;
  };

  const settingsHead = [{ id: 1, label: "Configuration Type", position: "left" }];

  const userHeader = [
    { id: 1, label: "Email", position: "left" },
    { id: 2, label: "Permission", position: "center" },
    { id: 3, label: "Actions", position: "center" },
  ];

  const handleEdit = (item) => {
    if (item) {
      if (activeTab == "user_and_permission") {
        setEditItem(item?.id);
        setUserPermissionData(item);
      } else if (activeTab == "connectors_configurations") {
        setEditItem(item?.id);
        setOptionSelected(optionsData.find((i) => i.value == item?.type));
        setConfiguration({
          ...item?.configure,
          source: optionsData.find((i) => i.value == item?.type),
        });
      }
    }
  };

  return (
    <div className="w-full h-full">
      <Header
        title="Settings"
        createButtonText={
          activeTab == "connectors_configurations" ? "Create Configuration" : activeTab == "user_and_permission" ? "Create User" : null
        }
        onButtonClick={() => {
          setOpenSidebar(true);
        }}
        searchPlaceholder={activeTab !== 0 && "Search..."}
        search={search}
        setSearch={setSearch}
      />
      {openSidebar && (
        <SidebarForm
          title={
            activeTab == "connectors_configurations"
              ? editItem
                ? "Update Configuration"
                : "Create Configuration"
              : activeTab == "user_and_permission"
              ? "Create User"
              : null
          }
          buttonClass="justify-end"
          disable={!validate()}
          onClose={() => {
            setOpenSidebar(false);
            setConfiguration({});
            setEditItem("");
            setOptionSelected();
          }}
          handleSubmit={handleSubmit}
          createButtonName={editItem ? "Save" : "Create"}
        >
          {activeTab === "user_and_permission" ? (
            <>
              <CommonField className="mb-8" titleText="Email" name="email" value={userPermissionData?.email} onChange={handleChange} error="email" />
              <div className="flex justify-start items-center uppercase text-2xs mb-2 text-lightGrey">Permission</div>
              <CustomSwitch name="permission" checked={userPermissionData?.permission ?? false} onChange={handleChange} size="small" />
            </>
          ) : activeTab == "connectors_configurations" ? (
            <>
              <CommonField
                className="mb-8"
                titleText="Source"
                value={configuration?.source}
                options={optionsData}
                getOptionLabel={(e) => (
                  <div className="flex item-center">
                    <img src={e.icon ?? constants.images.noImage} width="20" alt="icon" />
                    <span className="ml-5">{e.label}</span>
                  </div>
                )}
                onChange={(source) => {
                  setOptionSelected(source);
                  setConfiguration({});
                }}
                selectfield
              />
              {optionSelected?.value &&
                contextValues?.configureType?.data?.map((data) => {
                  if (data?.configure_value == optionSelected?.value) {
                    return data?.fields?.map((field) => {
                      if (field?.type == "input") {
                        return (
                          <CommonField
                            key={field?.key}
                            className="mb-8"
                            titleText={field?.label}
                            name={field?.key}
                            value={configuration?.[field?.key] || ""}
                            onChange={handleChange}
                            error="field"
                          />
                        );
                      } else if (field?.type == "code_editor") {
                        return (
                          <CodeEditer
                            key={field?.key}
                            className="mb-8"
                            name={field?.key}
                            titleText={field?.label}
                            value={configuration?.[field?.key]}
                            onChange={(e) => {
                              setConfiguration({
                                ...configuration,
                                [field?.key]: e,
                              });
                            }}
                          />
                        );
                      }
                      return null;
                    });
                  }
                  return null;
                })}
            </>
          ) : null}
        </SidebarForm>
      )}
      <div>
        <Tabs type="basic" tabs={tabData} setActiveTab={setActiveTab} activeTab={activeTab} className="bg-white h-40 flex items-end" />
        <div className="p-10">
          {activeTab == "connectors_configurations" ? (
            <>
              <div className="px-16 pb-12 inline-block flex items-center text-131 font-semibold text-333333">Configurations</div>
              <Table
                setPage={setTypePage}
                page={typePage}
                tableHeader={settingsHead}
                rowsPerPage={rowsPerPage}
                totalPost={contextValues?.configureType?.meta?.total}
              >
                {contextValues?.configureType?.data?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr
                        className="border-b w-full hover:bg-tableHoverColor last:border-0"
                        role="presentation"
                        onClick={() => {
                          setCollapsedItemId((prevCollapsedItemId) => (prevCollapsedItemId === item?.configure_value ? null : item?.configure_value));
                        }}
                      >
                        <td className="px-16 py-5 cursor-pointer flex items-center text-xs">
                          <div className="w-12 flex items-center">
                            <i
                              className={`fa-solid  mr-6 text-black hover:text-blue-600 ${
                                collapsedItemId === item?.configure_value ? "fa-angle-down" : "fa-angle-right"
                              }`}
                            />
                          </div>
                          <img className="mx-4" src={item?.icon ?? constants.images.noImage} draggable="false" alt="" width="20" />
                          <span>{item?.configure_label}</span>
                          {/* <div className="px-4 py-1 bg-blue-600 text-white font-medium text-xs rounded-full ml-6">{item?.data?.length}</div> */}
                        </td>
                      </tr>
                      {collapsedItemId === item?.configure_value ? (
                        <tr className="border-b">
                          <td colSpan={subConfigurationTableHead?.length}>
                            <Table className="mx-12 my-4 rounded-sm border" tableHeader={subConfigurationTableHead}>
                              {contextValues?.configurationData?.data?.map((type, subIndex) => {
                                return (
                                  <tr key={subIndex} className="border-b last:pb-2 last:border-0">
                                    <td className="px-16 py-5 cursor-pointer text-xs">
                                      {typeof type?.configure?.name == "string" ? type?.configure?.name : "-"}
                                    </td>
                                    <td className="px-16 py-5 text-center cursor-pointer text-xs">
                                      <CustomTooltip direction="top" title="Edit">
                                        <i
                                          className="fa-solid fa-pen-to-square mx-4 font-normal cursor-pointer hover:text-blue-600 z-999"
                                          role="presentation"
                                          onClick={() => {
                                            setOpenSidebar(true);
                                            handleEdit(type);
                                          }}
                                        />
                                      </CustomTooltip>
                                      <Deletepopup
                                        open={openDeletePopup.id == type.id}
                                        setOpen={setDeletepopup}
                                        onDelete={() => {
                                          contextValues.deleteConfiguration({ id: openDeletePopup.id, type: openDeletePopup.type });
                                          setDeletepopup({ open: false });
                                        }}
                                      >
                                        <CustomTooltip direction="top" title="Delete">
                                          <i
                                            className="fa-solid fa-trash-can text-lightGrey font-normal cursor-pointer hover:text-blue-600"
                                            role="presentation"
                                            onClick={() => {
                                              setDeletepopup({ open: true, id: type.id, type: type.type });
                                            }}
                                          />
                                        </CustomTooltip>
                                      </Deletepopup>
                                    </td>
                                  </tr>
                                );
                              })}
                            </Table>
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </Table>
            </>
          ) : activeTab == "user_and_permission" ? (
            <>
              <div className="px-16 pb-12 inline-block flex items-center text-131 font-semibold text-333333">Users</div>
              <Table
                setPage={setPageUser}
                page={pageUser}
                tableHeader={userHeader}
                rowsPerPage={rowsPerPage}
                totalPost={contextValues?.userPermission?.meta?.total}
              >
                {contextValues?.userPermission?.data?.map((item, index) => {
                  return (
                    <tr key={index} className="border-b w-full hover:bg-tableHoverColor last:border-0">
                      <td className="px-16 py-5 cursor-pointer text-left text-xs">{item?.email}</td>
                      <td className="px-16 py-5 cursor-pointer text-center text-xs flex justify-center items-center">
                        <Status title={item?.permission ? "allowed" : "not_allowed"} />
                      </td>
                      <td className="px-16 py-5 text-center cursor-pointer text-xs">
                        <CustomTooltip direction="bottom" title="Edit">
                          <i
                            className="fa-solid fa-pen-to-square mx-4 font-normal cursor-pointer hover:text-blue-600"
                            role="presentation"
                            onClick={() => {
                              setOpenSidebar(true);
                              handleEdit(item);
                            }}
                          />
                        </CustomTooltip>
                        <CustomTooltip direction="bottom" title="Delete">
                          <i
                            className="fa-solid fa-trash-can font-normal mx-4 cursor-pointer hover:text-blue-600"
                            role="presentation"
                            onClick={() => contextValues.deleteUserPermission(item.id)}
                          />
                        </CustomTooltip>
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </>
          ) : activeTab == "cloud_provider_configurations" ? (
            <div className="w-full bg-white rounded-sm grid grid-cols-2 gap-x-16 p-16">
              <CommonField
                className="mb-8 col-span-2"
                titleText="Cloud Providers"
                value={cloudConfigurationData?.provider_type}
                options={constants.components.google_cloud_platform}
                getOptionLabel={(e) => (
                  <div className="flex item-center">
                    <img src={e.icon ?? constants.images.noImage} width="20" alt="icon" />
                    <span className="ml-5">{e.label}</span>
                  </div>
                )}
                onChange={(provider_type) => {
                  setCloudConfigurationData({
                    ...cloudConfigurationData,
                    provider_type,
                  });
                  if (provider_type?.value === "azure") {
                    contextValues.getCloudAccountSettings({
                      name: provider_type?.value,
                    });
                  }
                }}
                selectfield
              />
              {cloudConfigurationData?.provider_type?.value === "azure" ? (
                <>
                  <CommonField
                    className="mb-8"
                    titleText="tenant id"
                    name="tenant_id"
                    value={editField == "tenant_id" ? cloudConfigurationData?.tenant_id : maskValue(cloudConfigurationData?.tenant_id) || ""}
                    disabled={editField !== "tenant_id"}
                    onChange={handleChange}
                    error="field"
                    copyText={cloudConfigurationData?.tenant_id}
                    handleFieldEdit={() => handleEditClick("tenant_id")}
                  />
                  <CommonField
                    className="mb-8"
                    titleText="client secret"
                    name="client_secret"
                    value={
                      editField == "client_secret" ? cloudConfigurationData?.client_secret : maskValue(cloudConfigurationData?.client_secret) || ""
                    }
                    disabled={editField !== "client_secret"}
                    onChange={handleChange}
                    error="field"
                    copyText={cloudConfigurationData?.client_secret}
                    handleFieldEdit={() => handleEditClick("client_secret")}
                  />
                  <CommonField
                    className="mb-8"
                    titleText="client id"
                    name="client_id"
                    value={editField == "client_id" ? cloudConfigurationData?.client_id : maskValue(cloudConfigurationData?.client_id) || ""}
                    disabled={editField !== "client_id"}
                    onChange={handleChange}
                    error="field"
                    copyText={cloudConfigurationData?.client_id}
                    handleFieldEdit={() => handleEditClick("client_id")}
                  />
                  <CommonField
                    className="mb-8"
                    titleText="subscription id"
                    name="subscription_id"
                    value={
                      editField == "subscription_id"
                        ? cloudConfigurationData?.subscription_id
                        : maskValue(cloudConfigurationData?.subscription_id) || ""
                    }
                    disabled={editField !== "subscription_id"}
                    onChange={handleChange}
                    error="field"
                    copyText={cloudConfigurationData?.subscription_id}
                    handleFieldEdit={() => handleEditClick("subscription_id")}
                  />
                  <CommonField
                    className="mb-8"
                    titleText="resource group name"
                    name="resource_group_name"
                    value={
                      editField == "resource_group_name"
                        ? cloudConfigurationData?.resource_group_name
                        : maskValue(cloudConfigurationData?.resource_group_name) || ""
                    }
                    disabled={editField !== "resource_group_name"}
                    onChange={handleChange}
                    error="field"
                    copyText={cloudConfigurationData?.resource_group_name}
                    handleFieldEdit={() => handleEditClick("resource_group_name")}
                  />
                </>
              ) : cloudConfigurationData?.provider_type?.value === "amazon_web_services" ? (
                <div>"Amazon Web Services"</div>
              ) : cloudConfigurationData?.provider_type?.value === "google_cloud_platform" ? (
                <div>"Google Cloud Platform"</div>
              ) : null}
              <div className="col-span-2 w-full flex items-center justify-end">
                {/* <Button onClick={handleReset} color="5569FF">
                  Reset
                </Button> */}
                <Button
                  className="ml-8"
                  type="button"
                  role="button"
                  color="primaryColor"
                  onClick={handleSubmit}
                  variant="contained"
                  disable={!validate()}
                >
                  Save
                </Button>
              </div>
            </div>
          ) : activeTab == "jwt" ? (
            <>
              <div className="px-16 pb-12 inline-block flex items-center text-131 font-semibold text-333333">Short Lived Token</div>
              <div className="w-full bg-white rounded-sm ">
                {/* <CommonField
                className="mb-8 col-span-4"
                titleText="Lived Tokens"
                value={token}
                placeholder="Select..."
                options={constants.components.token}
                onChange={(e) => {
                  setToken(e);
                }}
                selectfield
              /> */}
                {/* <div className="text-131 font-semibold text-333333">
                Short Lived Token
              </div> */}
                {/* {token?.value == "short_lived_token" && ( */}
                <div className=" w-1/2 grid grid-cols-5 gap-x-16  p-16">
                  <CommonField
                    className="mb-8 col-span-4"
                    titleText="Token"
                    value={maskValue(sessionStorage.getItem("ADMIN_TOKEN"))}
                    disabled
                    copyText={sessionStorage.getItem("ADMIN_TOKEN")}
                  />
                  <CommonField
                    className="mb-8  min-w-92"
                    titleText="EXPIRY TIME"
                    // value={capitalizeFirstLetter(moment(sessionStorage.getItem("TOKEN_TIME")).fromNow())}
                    value={capitalizeFirstLetter(getExpirationDateFromToken(sessionStorage.getItem("ADMIN_TOKEN")))}
                    disabled
                    // copyText={maskValue(sessionStorage.getItem("ADMIN_TOKEN"))}
                  />
                </div>
                {/* )} */}
                {/* {token?.value == "long_lived_token" && ( */}
                {/* <>
                  <div
                    role="presentation"
                    className="shadow-md mb-20 flex items-center justify-center py-4 px-6 bg-white border w-full text-center border-gray-400 cursor-pointer rounded text-sm shadow-sm placeholder-slate-400 col-span-1"
                  >
                    <img
                      className="w-12"
                      src={process.env.PUBLIC_URL + "/assets/microsoft.svg"}
                      alt="microsoft"
                    />
                    <p className="ml-8 text-xs font-medium">Connect With AD</p>
                  </div>
                </> */}
                {/* )} */}
              </div>
              <>
                <div className="px-16 py-12 inline-block flex items-center text-131 font-semibold text-333333">Long Lived Token</div>
                <div className=" w-full bg-white rounded-sm p-16">
                  <div
                    role="presentation"
                    className="shadow-md  flex items-center justify-center py-4 px-6 bg-white border w-1/4 text-center border-gray-400 cursor-pointer rounded text-sm shadow-sm placeholder-slate-400 col-span-1"
                  >
                    <img className="w-12" src={process.env.PUBLIC_URL + "/assets/microsoft.svg"} alt="microsoft" />
                    <p className="ml-8 text-xs font-medium">Connect With AD</p>
                  </div>
                </div>
              </>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Settings;
