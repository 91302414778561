import { memo } from "react";

import CommonField from "../../Widgets/CommonField";
import constants from "../../constants";

function SnmpClient({ snmpClient, setSnmpClient, handleChange }) {
  return (
    <div className="grid gap-8 w-full h-fit">
      <CommonField
        titleText="Host"
        value={snmpClient.host}
        options={constants.components.snmpHost}
        onChange={(host) => setSnmpClient({ ...snmpClient, host })}
        error
        selectfield
      />
      <CommonField titleText="Version" name="version" value={snmpClient.version || ""} onChange={handleChange} error="field" />
      <CommonField titleText="User-Name" name="username" value={snmpClient.username || ""} onChange={handleChange} error="field" />
      <CommonField titleText="Auth-Key" name="authkey" value={snmpClient.authkey || ""} onChange={handleChange} error="field" />
      <CommonField titleText="Private-Key" name="privekey" value={snmpClient.privekey || ""} onChange={handleChange} error="field" />
      <CommonField titleText=" O-Id" name="oid" value={snmpClient.oid || ""} onChange={handleChange} error="field" />
    </div>
  );
}

export default memo(SnmpClient);
