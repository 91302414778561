import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Widgets/Header";
import CustomCard from "../../Widgets/CustomCard";
import { Context } from "../../Context";
import Table from "../../Widgets/Table";
import Status from "../../Widgets/Status";
import { dateFormat } from "../../utils/utils";
import constants from "../../constants";
import ReportDetails from "../ReportDetails/ReportDetails";

function Dashboard() {
  const contextValues = useContext(Context);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    contextValues.getLakehouseCount();
    contextValues.getAllCPUInfo();
    const tableCount = async () => {
      const data = await contextValues.getTableCount();
      setCount(data?.data?.data?.[0]?.count);
    };
    tableCount();
  }, []);

  useEffect(() => {
    contextValues.GetLakehouse({ status: "online", limit: 5 });
  }, []);

  const cardData = [
    {
      id: 1,
      title: "Lakehouses",
      number: contextValues?.lakhouseCount ?? 0,
      subText: false,
    },
    {
      id: 2,
      title: "Tables",
      number: count ?? 0,
      subText: false,
    },
    {
      id: 3,
      title: "CPU Usage",
      number: contextValues?.CPUInfo?.cpuUsage ?? 0,
      subText: false,
    },
    {
      id: 4,
      title: "Memory Usage",
      number: contextValues?.CPUInfo?.memoryUsage ?? 0,
      subText: true,
    },
  ];

  const dashboardHead = [
    { id: 1, label: "Name", position: "left" },
    { id: 2, label: "Description", position: "center" },
    { id: 3, label: "Status", position: "center" },
    { id: 4, label: "Created on", position: "center" },
  ];

  return (
    <div className="w-full h-full">
      <Header title="Dashboard" />
      <div className="p-10">
        <div className="grid grid-cols-4 gap-12 mb-14">
          {cardData.map((item) => {
            return (
              <CustomCard
                className="text-center relative text-base leading-tight flex flex-col justify-center items-center h-112 shadow-l"
                key={item.id}
                tooltip={
                  item.title === "Memory Usage" ? (
                    <>
                      <div>Total Memory: {contextValues?.CPUInfo?.totalMemory}</div>
                      <div>Used Memory: {contextValues?.CPUInfo?.usedMemory}</div>
                      <div>Free Memory: {contextValues?.CPUInfo?.freeMemory}</div>
                    </>
                  ) : (
                    false
                  )
                }
              >
                <p className="text-32 bg-primaryColor font-bold inline-block text-transparent bg-clip-text">{item.number}</p>
                <p className="text-sm font-medium text-black mt-4 text-base text-neutral-600 dark:text-neutral-200 bg-buttonGradient inline-block text-transparent bg-clip-text">
                  {item.title}
                </p>
              </CustomCard>
            );
          })}
        </div>
        <ReportDetails />
        <div className="px-16 py-12  inline-block  flex items-center text-131 font-semibold text-333333">Recent Active Lakehouses</div>
        <Table tableHeader={dashboardHead}>
          {contextValues?.lakehouseData?.data?.map((item, index) => {
            return (
              <tr key={index} className="border-b w-full bg-white hover:bg-tableHoverColor last:border-0">
                <td className="px-16 py-5 cursor-pointer hover:underline text-primaryColor text-xs">
                  <span role="presentation" onClick={() => navigate(`${constants.route.lakehouseProperties}/${item.id}`)}>
                    {item?.name}
                  </span>
                </td>
                <td className="px-16 py-8 text-center cursor-pointer text-xs">{item?.description}</td>
                <td className="px-16 py-5 text-center cursor-pointer flex justify-center text-xs">
                  <Status title={item?.spark_cluster?.data?.attributes?.master_node?.data?.attributes?.status} />
                </td>
                <td className="px-16 py-8 text-center cursor-pointer text-xs items-center">{dateFormat(item?.createdAt)}</td>
              </tr>
            );
          })}
        </Table>
      </div>
    </div>
  );
}
export default Dashboard;
