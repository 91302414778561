import { useContext, useEffect, useState } from "react";
import cronParser from "cron-parser";
import cronstrue from "cronstrue";
import { Upload } from "antd";
import Header from "../../Widgets/Header";
import Status from "../../Widgets/Status";
import SidebarForm from "../lakehouse/SidebarForm";
import CustomMenu from "../../Widgets/Menu";
import Table from "../../Widgets/Table";
import { Context } from "../../Context";
import CommonField from "../../Widgets/CommonField";
import CodeEditer from "../../Widgets/CodeEditer";
// import Button from "../../Widgets/Button";
import Deletepopup from "../../Widgets/Deletepopup";

const { Dragger } = Upload;

function ScheduledJobs() {
  const [search, setSearch] = useState(null);
  const contextValues = useContext(Context);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [scheduler, setScheduler] = useState({ expression: "* * * * * *" });
  const [isCronValid, setIsCronValid] = useState();
  const [lakehouse, setLakehouse] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(18);
  const [openDeletePopup, setDeletepopup] = useState(false);

  useEffect(() => {
    if (search !== null) {
      const timer = setTimeout(() => {
        setPage(1);
        contextValues.GetScheduledJobs({ search, page, rowsPerPage });
      }, 800);
      return () => clearTimeout(timer);
    }
  }, [search]);

  useEffect(() => {
    setIsCronValid(true);
    try {
      cronParser.parseExpression(scheduler?.expression);
      setIsCronValid(cronstrue.toString(scheduler?.expression));
    } catch (e) {
      setIsCronValid(e.toString());
    }
  }, [scheduler?.expression]);

  useEffect(() => {
    contextValues.GetScheduledJobs({ search, page, rowsPerPage });
  }, [page]);

  useEffect(() => {
    contextValues.GetLakehouse({ limit: -1 });
  }, []);

  useEffect(() => {
    if (contextValues?.lakehouseData) {
      const filterData = contextValues?.lakehouseData?.data?.map((item) => ({
        value: `${item.id}`,
        label: `${item.name}`,
      }));
      setLakehouse(filterData);
    }
  }, [contextValues?.lakehouseData?.data]);

  const handleSubmit = async () => {
    if (Object.keys(scheduler)?.length !== 0) {
      const uploadFile = await contextValues?.uploadData(fileList);
      const data = {
        name: scheduler?.name,
        status: true,
        expression: scheduler?.expression,
        jar: uploadFile?.data?.[0]?.id,
        lakehouse: scheduler?.lakehouse?.value,
        classname: scheduler?.classname,
        command_line: scheduler?.command_line,
        job_type: scheduler?.job_type?.value,
        sql_query: scheduler?.sql_query,
      };
      contextValues.createScheduledJob(data);
    }
    setOpenSidebar(false);
    setScheduler({ expression: "* * * * * *" });
    setFileList([]);
  };

  const validate = () => {
    let isValidate = false;
    if (Object.keys(scheduler)?.length !== 0) {
      if (!scheduler?.name || scheduler?.name === "") {
        isValidate = false;
      } else if (isCronValid?.includes("Error")) {
        isValidate = false;
      } else if (!scheduler?.job_type || scheduler?.job_type === "") {
        isValidate = false;
      } else if (scheduler?.job_type?.value == "spark_job") {
        if (!scheduler?.lakehouse || scheduler?.lakehouse === "") {
          isValidate = false;
        } else if (!scheduler?.classname || scheduler?.classname === "") {
          isValidate = false;
        } else if (!scheduler?.command_line || scheduler?.command_line === "") {
          isValidate = false;
        } else if (fileList?.length == 0) {
          isValidate = false;
        } else {
          isValidate = true;
        }
      } else if (scheduler?.job_type?.value == "sql_query") {
        if (!scheduler?.sql_query || scheduler?.sql_query === "") {
          isValidate = false;
        } else if (!scheduler?.lakehouse || scheduler?.lakehouse === "") {
          isValidate = false;
        } else {
          isValidate = true;
        }
      } else {
        isValidate = true;
      }
    }
    return isValidate;
  };

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    setScheduler((values) => ({
      ...values,
      [name]: value,
      status: "start",
    }));
  };

  const scheduledQueriesHead = [
    { id: 1, label: "Jobs", position: "left" },
    { id: 2, label: "Schedule Expression", position: "center" },
    { id: 3, label: "Status", position: "center" },
    { id: 4, label: "Actions", position: "center" },
  ];

  const jobData = [
    { value: "sql_query", label: "SQL Query" },
    { value: "spark_job", label: "Spark Job" },
  ];

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  return (
    <div className="w-full h-full">
      <Header
        title="Scheduled Jobs"
        createButtonText="Schedule Job"
        onButtonClick={() => {
          setOpenSidebar(true);
        }}
        searchPlaceholder="Search..."
        search={search}
        setSearch={setSearch}
      />
      {openSidebar && (
        <SidebarForm
          title="Schedule Job"
          disable={!validate()}
          onClose={() => {
            setOpenSidebar(false);
            setScheduler({ expression: "* * * * * *" });
            setFileList([]);
          }}
          handleSubmit={handleSubmit}
        >
          <CommonField className="mb-8" titleText="Name" name="name" error value={scheduler.name || ""} onChange={handleChange} />
          <ul className="mb-8">
            <li className="text-xs text-737373">
              You can create a cron expression using the following elements: minute, hour, day of the month, month, and day of the week. You can
              separate these elements with the following symbols Asterisk (*) Comma (,) Hyphen (-) Slash (/)
            </li>
          </ul>
          <CommonField className="mb-4" titleText="Expression" error name="expression" value={scheduler.expression || ""} onChange={handleChange} />
          <div className={`${isCronValid?.includes("Error") && "text-red-800"} mb-8`}>
            {isCronValid?.includes("Error") ? isCronValid.split("Error: ")[1] : isCronValid}
          </div>
          <CommonField
            titleText="Select Job"
            className="mb-8"
            value={scheduler.job_type}
            options={jobData}
            error
            onChange={(job_type) => {
              setScheduler({ ...scheduler, job_type, lakehouse: null, sql_query: null, command_line: null, classname: null });
              setFileList([]);
            }}
            selectfield
          />
          {scheduler?.job_type && (
            <CommonField
              titleText="Lakehouse"
              className="mb-8"
              error
              value={scheduler.lakehouse}
              options={lakehouse}
              onChange={(lake) => setScheduler({ ...scheduler, lakehouse: lake })}
              selectfield
            />
          )}
          {scheduler?.job_type?.value == "sql_query" && (
            <CodeEditer
              className="mb-8"
              name="sql_query"
              titleText="SQL Query"
              placeholder="Write your query"
              value={scheduler?.sql_query}
              onChange={(e) => {
                setScheduler({
                  ...scheduler,
                  sql_query: e,
                });
              }}
            />
          )}
          {scheduler?.job_type?.value == "spark_job" && (
            <>
              {/* <Upload {...props} className="mb-8" maxCount={1} accept=".jar">
                <Button variant="outline" className={`${!fileList?.length > 0 && "mb-8"}`} startIcon={<i className="fa-solid fa-upload mr-4" />}>
                  Select File
                </Button>
              </Upload> */}
              <div className="mb-8">
                <div className="flex justify-start items-center text-2xs capitalize mb-2 text-lightGrey ">
                  UPLOAD JAR
                  <span className="text-red-900 text-xs">*</span>
                </div>
                <Dragger {...props} accept=".jar" maxCount={1}>
                  <p className="ant-upload-drag-icon">
                    <i className="fa-solid fa-box text-blue-500 text-20" />
                  </p>
                  <p className="ant-upload-text">Click or drag JAR file to upload</p>
                </Dragger>
              </div>
              <CommonField className="mb-8" titleText="Classname" name="classname" error value={scheduler.classname || ""} onChange={handleChange} />
              <CommonField
                className="mb-8"
                titleText="Command line Arguments"
                name="command_line"
                textarea={4}
                value={scheduler.command_line}
                error
                onChange={handleChange}
              />
            </>
          )}
        </SidebarForm>
      )}

      <Deletepopup
        open={openDeletePopup.id}
        setOpen={setDeletepopup}
        onDelete={() => {
          contextValues.deleteScheduledJob(openDeletePopup.id);
          setDeletepopup({ open: false });
        }}
      />

      <div className="p-10">
        <Table
          // subTitle="Effortlessly manage and organize scheduled jobs with easy updates and deletions."
          setPage={setPage}
          tableHeader={scheduledQueriesHead}
          rowsPerPage={rowsPerPage}
          bodyHight="max-h-[calc(100vh-14.4rem)]"
          page={page}
          totalPost={contextValues?.ScheduledJobData?.meta?.total}
        >
          {contextValues?.ScheduledJobData?.data?.map((item, index) => {
            return (
              <tr key={index} className="border-b w-full hover:bg-tableHoverColor last:border-0">
                <td className="px-16 py-5 text-xs whitespace-nowrap">{item?.name}</td>
                <td className="px-16 py-5 text-center text-xs">{cronstrue.toString(item?.expression)}</td>
                <td className="px-16 py-5 text-center text-xs">
                  <div className="flex items-center justify-center w-full h-full">
                    <Status title={item?.status ? "started" : "Stopped"} />
                  </div>
                </td>
                <td className="px-16 py-5 text-center text-xs w-fit">
                  <CustomMenu
                    menuItems={[
                      {
                        title: item?.status ? "Stop" : "Start",
                        onclick: () => {
                          contextValues.putSchedulerJobs({
                            ...item,
                            status: !item?.status,
                          });
                        },
                        icon: item?.status ? "fa-solid fa-pause" : "fa-solid fa-play",
                      },
                      {
                        title: "Delete",
                        onclick: () => {
                          setDeletepopup({ open: true, id: item.id });
                        },
                        icon: "fa-solid fa-trash-can",
                      },
                    ]}
                  />
                </td>
              </tr>
            );
          })}
        </Table>
      </div>
    </div>
  );
}

export default ScheduledJobs;
