import { memo, useContext, useEffect, useState } from "react";
import { Context } from "../../Context";
import { dateFormat } from "../../utils/utils";
import Button from "../../Widgets/Button";
import CommonField from "../../Widgets/CommonField";
import Header from "../../Widgets/Header";
import Modal from "../../Widgets/Modal";
import CustomTooltip from "../../Widgets/Tooltip";
import constants from "../../constants";

const UsersTable = () => {
  const contextValues = useContext(Context);
  const [showModel, setShowModel] = useState(false);
  const [fieldValue, setFieldValue] = useState();
  const [search, setSearch] = useState("");
  const [editItem, setEditItem] = useState();

  useEffect(() => {
    contextValues.fetchUserData();
  }, []);

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    setFieldValue((values) => ({ ...values, [name]: value }));
  };

  const validate = () => {
    let isValidate = true;
    if (fieldValue?.username == "" || !fieldValue?.username) {
      isValidate = false;
    } else if (fieldValue?.email == "" || !fieldValue?.email) {
      isValidate = false;
    } else if (fieldValue?.role == "" || !fieldValue?.role) {
      isValidate = false;
    } else if (fieldValue?.password == "" || !fieldValue?.password) {
      isValidate = false;
    }
    return isValidate;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!editItem) {
      contextValues.onRegister(fieldValue);
    } else {
      contextValues.putUserData({
        id: editItem,
        data: fieldValue,
      });
    }
    setShowModel(false);
  };

  const tableHeader = [
    {
      id: "createdAt",
      label: "Created At",
      align: "left",
    },
    {
      id: "updated-at",
      label: "Updated At",
      align: "center",
    },
    {
      id: "username",
      label: "Username",
      align: "center",
    },
    {
      id: "email",
      label: "Email",
      align: "center",
    },
    {
      id: "action",
      label: "Action",
      align: "center",
    },
  ];

  const selectItemToEdit = (id) => {
    const selectedItem = contextValues.userData.filter((item) => item.id === id);
    setFieldValue(selectedItem[0]);
    setEditItem(id);
  };

  return (
    <>
      <Header
        title="Users"
        searchPlaceholder="Search..."
        createButtonText="Create User"
        onButtonClick={() => {
          setShowModel(true);
          setEditItem("");
        }}
        search={search}
        setSearch={setSearch}
      />
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg m-14">
        <table className="w-full text-[14px] text-left text-gray-500">
          <thead className="bg-indigo-500 text-white">
            <tr>
              {tableHeader.map((headItem) => (
                <th key={headItem.id} scope="col" className={`p-12 text-${headItem.align}`}>
                  {headItem.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {contextValues.userData
              ?.filter((val) => {
                return !search
                  ? val
                  : val.username.toLowerCase().includes(search.toLowerCase()) || val.email.toLowerCase().includes(search.toLowerCase());
              })
              .map((bodyItem) => (
                <tr key={bodyItem.id} className="bg-white border-b text-[14px] text-black h-full">
                  <td className="p-8 text-left truncate">{dateFormat(bodyItem.createdAt)}</td>
                  <td className="p-8 text-center truncate">{dateFormat(bodyItem.updatedAt)}</td>
                  <td className="p-8 text-center truncate">{bodyItem.username}</td>
                  <td className="p-8 text-center truncate">{bodyItem.email}</td>
                  <td className="p-8 m-auto flex w-full items-center justify-center">
                    <div className="flex">
                      <CustomTooltip direction="bottom" title="Edit">
                        <i
                          className="fa-solid fa-pen-to-square font-normal mx-8 cursor-pointer hover:text-blue-600"
                          role="presentation"
                          onClick={() => {
                            setShowModel(true);
                            selectItemToEdit(bodyItem.id);
                          }}
                        />
                      </CustomTooltip>
                      <CustomTooltip direction="bottom" title="Delete">
                        <i
                          className="fa-solid fa-trash-can mx-8 font-normal cursor-pointer hover:text-red-600"
                          role="presentation"
                          onClick={() => contextValues.deleteUser(bodyItem.id)}
                        />
                      </CustomTooltip>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Modal showModel={showModel} setShowModel={setShowModel}>
        <form onSubmit={handleSubmit} className="flex flex-col w-full justify-center items-center">
          <div className="flex w-full justify-between items-center pt-6 pb-12 border-b">
            <div className="text-sm">{editItem ? "Update User" : "Create User"}</div>
          </div>
          <div className="grid grid-cols-1 gap-8 w-full py-8">
            <CommonField titleText="Username" name="username" value={fieldValue?.username || ""} onChange={handleChange} error="field" />
            <CommonField titleText="Email" name="email" value={fieldValue?.email || ""} onChange={handleChange} error="field" />
            <CommonField
              selectfield
              error
              titleText="Role"
              value={fieldValue?.role}
              options={constants.components.userTableData}
              onChange={(role) => setFieldValue({ ...fieldValue, role })}
            />
            <CommonField
              type="password"
              titleText="Password"
              name="password"
              value={fieldValue?.password || ""}
              onChange={handleChange}
              error="field"
            />
          </div>
          <div className="flex justify-end items-center w-full mt-40">
            <Button className="mr-12" color="indigo" variant="outline" onClick={() => setShowModel(false)}>
              Cancel
            </Button>
            <Button color="indigo" variant="contained" disable={!validate()} submit>
              {editItem ? "Update" : "Create"}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default memo(UsersTable);
