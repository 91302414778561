import { useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import constants from "../../constants";

import Button from "../../Widgets/Button";
import Input from "../../Widgets/Input";

function SignUp() {
  const [SignUpField, SetSignUpField] = useState({});
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    SetSignUpField((values) => ({ ...values, [name]: value }));
  };

  const validate = () => {
    let isValidate = true;
    if (SignUpField.email == "" || !SignUpField.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(SignUpField.email)) {
      isValidate = false;
    } else if (SignUpField.password == "" || !SignUpField.password) {
      isValidate = false;
    } else if (SignUpField.phone == "" || !SignUpField.phone) {
      isValidate = false;
    }
    return isValidate;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div className="h-screen w-full flex relative justify-end">
      <div className="h-screen w-3/4 bg-indigo-900 flex justify-end">
        <span className="flex items-center text-9xl mr-160 font-bold text-white opacity-95">FlowLite</span>
      </div>
      <form onSubmit={handleSubmit} className="w-400 bg-white rounded-md absolute left-96 top-112 shadow-2xl p-12 flex items-center justify-center">
        <div className="w-5/6 py-20">
          <div className="text-3xl font-bold text-indigo-900">Sign Up</div>
          <div className="my-20">
            <div className="flex flex-col mb-12">
              <div className="mb-2 text-[14px] text-indigo-900">Business Email </div>
              <Input
                placeholder="Enter your registered email address"
                name="email"
                value={SignUpField.email || ""}
                className="placeholder-text-sm"
                onChange={handleChange}
              />
            </div>
            <div className="mb-12">
              <div className="mb-2 text-[14px] text-indigo-900">Password </div>
              <Input
                placeholder="Enter password"
                className="placeholder-text-sm"
                name="password"
                type="password"
                value={SignUpField.password || ""}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col mb-12">
              <div className="mb-2 text-[14px] text-indigo-900">Phone</div>
              <Input placeholder="Phone no." className="placeholder-text-sm" name="phone" value={SignUpField.phone || ""} onChange={handleChange} />
            </div>
          </div>
          <Button className="bg-indigo-900" variant="contained" color="pink-300" submit disable={!validate()}>
            Register
          </Button>
          <div className="flex flex-row pt-14">
            <div className="flex items-center pr-32 text-indigo-900">
              Already have an account?
              <span
                className="text-light-blue ml-4 cursor-pointer hover:underline"
                role="presentation"
                onClick={() => navigate(constants.route.login)}
              >
                Log In
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default memo(SignUp);
