import React, { useState, memo, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import Button from "../../Widgets/Button";
import Input from "../../Widgets/Input";
import { Context } from "../../Context";
import constants from "../../constants";

function Login() {
  const contextValues = React.useContext(Context);
  const navigate = useNavigate();
  const [loginField, SetLoginField] = useState({});
  const TOKEN = window.sessionStorage.getItem("ADMIN_TOKEN");

  useEffect(() => {
    if (TOKEN) {
      navigate(constants.route.dashboard);
    }
  }, [TOKEN]);

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    SetLoginField((values) => ({ ...values, [name]: value }));
  };

  const validate = () => {
    let isValidate = true;
    if (loginField.email == "" || !loginField.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(loginField.email)) {
      isValidate = false;
    } else if (loginField.password == "" || !loginField.password) {
      isValidate = false;
    }
    return isValidate;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    contextValues.onLogin(loginField);
  };

  useEffect(() => {
    const access_token = new URLSearchParams(window.location.search).get("access_token");

    // get the user information from token
    if (access_token && access_token.length > 0) {
      axios
        .get(constants.url.auth_with_microsoft, { params: { access_token } })
        .then(async (response) => {
          if ([200, 201].includes(response.status) && response.data) {
            const data = {
              id: response.data.user.id,
              username: response.data.user.username,
              email: response.data.user.email,
            };
            const userPermissionData = await contextValues.getUserPermission({
              TOKEN: response.data.jwt,
              email: response.data.user.email,
            });
            const user = await userPermissionData?.find((item) => item?.email === data?.email);
            if (user && user?.permission) {
              toast.success("Logged in successfully", {
                position: toast.POSITION.TOP_CENTER,
              });
              sessionStorage.setItem("ADMIN_TOKEN", response.data.jwt);
              sessionStorage.setItem("USER", JSON.stringify(data));
              navigate(constants.route.dashboard);
            } else {
              navigate(constants.route.login);
              toast.error("Please contact your admin for the permission", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          } else {
            toast.error(response?.data?.error?.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((error) => {
          // console.log("An error occurred:", error);
          toast.error(error?.response?.data?.error?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  }, []);

  return (
    <div className="h-screen w-full flex relative justify-between bg-primaryColor">
      <form onSubmit={handleSubmit} className="w-560 bg-white shadow-2xl p-12 flex items-center justify-center">
        <div className="w-3/4 py-20">
          <div className="text-2xl font-bold text-primaryColor">Welcome Back!</div>

          <div className="text-sm text-grey-700 mb-16">Login to continue</div>

          <div className="flex flex-col mb-12 mt-16">
            <div className="text-2xs capitalize mb-2 text-lightGrey">Email </div>
            <Input
              placeholder="Enter your registered email address"
              name="email"
              value={loginField.email || ""}
              className="placeholder-text-sm"
              onChange={handleChange}
              error="email"
            />
          </div>
          <div className="mb-16">
            <div className="text-2xs capitalize mb-2 text-lightGrey">Password </div>
            <Input
              placeholder="Enter password"
              className="placeholder-text-sm mb-4"
              name="password"
              type="password"
              value={loginField.password || ""}
              onChange={handleChange}
            />
          </div>
          {/* <div className="flex justify-end items-center mb-20">
            <div className="text-[14px] cursor-pointer font-medium text-indigo-700">Forgot Password</div>
          </div> */}
          <Button className="w-full" variant="contained" color="primaryColor" submit disable={!validate()}>
            LOG IN
          </Button>
          <div className="border relative my-20">
            <div className="w-full flex justify-center items-center absolute -top-6">
              <span className="px-6 bg-white">OR</span>
            </div>
          </div>
          <div
            role="presentation"
            onClick={() => {
              window.location.href = constants.url.login_with_microsoft;
            }}
            className="shadow-md mb-20 flex items-center justify-center py-4 px-6 bg-white border w-full text-center border-gray-400 cursor-pointer rounded text-sm shadow-sm placeholder-slate-400"
          >
            <img className="w-12" src={process.env.PUBLIC_URL + "/assets/microsoft.svg"} alt="microsoft" />
            <p className="ml-8 text-xs font-medium">Login with Azure AD</p>
          </div>
          {/* <div className="flex flex-row pt-14">
            <div className="flex items-center pr-32 text-indigo-900">
              Dont't have an account?{" "}
              <span
                className="text-light-blue ml-4 cursor-pointer hover:underline"
                role="presentation"
                onClick={() => navigate(constants.route.signup)}
              >
                Register Now
              </span>
            </div>
          </div> */}
        </div>
      </form>
      <div className="h-screen w-full flex justify-center items-center">
        <img className="xl:w-480 lg:w-420 px-40" src={process.env.PUBLIC_URL + "/assets/hermes_logo_transparent.png"} alt="Hermes" />
      </div>
    </div>
  );
}

export default memo(Login);
