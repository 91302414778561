import { useState, useEffect } from "react";
import Input from "./Input";
import CommonField from "./CommonField";
import constants from "../constants";

function KeyValueFieldAggregate({
  setAggregate,
  aggregate,
  selectName,
  selectInputClassName,
  selectInputName,
  inputSelectPlaceholder,
  selectFunctionsName,
  selectTitle,
  selectFunctionsTitle,
}) {
  const [userData, setUserData] = useState(Array.isArray(aggregate?.userData) ? aggregate?.userData : [{}]);
  const removeFields = (index) => {
    const data = [...userData];
    data.splice(index, 1);
    setUserData(data);
  };

  const addFields = () => {
    const newfield = {};
    setUserData([...userData, newfield]);
  };
  const handleChange = (index, value, name) => {
    const updatedFields = [...Object.values(userData)];
    updatedFields[index] = {
      ...userData[index],
      [name]: value,
    };
    setUserData(updatedFields);
  };

  useEffect(() => {
    const data = { ...aggregate };
    data.userData = userData;
    setAggregate(data);
  }, [userData]);
  return (
    <div className="h-fit w-full">
      <div className="w-full max-h-112">
        {userData?.map((input, index) => {
          const lastIndex = userData?.length - 1 == index;
          return (
            <div key={index} className="flex items-center w-full pb-12 items-baseline">
              <div className="w-full grid lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-6 items-start">
                <Input
                  name={selectInputName}
                  placeholder={inputSelectPlaceholder}
                  className={selectInputClassName}
                  value={input?.timeIn}
                  onChange={(e) => {
                    handleChange(index, e.target.value, selectInputName);
                  }}
                />
                <CommonField
                  selectfield
                  selectPlaceholder={selectTitle}
                  value={input?.windows}
                  options={constants.components.selectWindow}
                  error="field"
                  onChange={(e) => handleChange(index, e, selectName)}
                />
                <CommonField
                  selectfield
                  selectPlaceholder={selectFunctionsTitle}
                  value={input?.functions}
                  options={constants.components.functions}
                  error="field"
                  onChange={(e) => handleChange(index, e, selectFunctionsName)}
                />
                {/* <Input
                  name={inputName}
                  placeholder={inputPlaceholder}
                  value={input?.key}
                  onChange={(e) =>
                    handleChange(index, e.target.value, inputName)
                  }
                /> */}
              </div>
              {lastIndex && (
                <div
                  className="h-16 w-16 mx-4 mt-4 flex items-center justify-center ml-6 cursor-pointer z-99 rounded-full bg-primaryColor hover:bg-1A85A0"
                  role="presentation"
                  onClick={addFields}
                >
                  <i className="fa-solid fa-plus text-white text-xs leading-normal" />
                </div>
              )}
              {userData.length > 1 ? (
                <i
                  className="h-18 w-20 mt-4 mx-4 flex items-center justify-center hover:bg-red-50 border rounded-full text-xs fa fa-trash-can cursor-pointer hover:text-red-600 ml-6"
                  aria-hidden="true"
                  onClick={() => removeFields(index)}
                />
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default KeyValueFieldAggregate;
