import { memo } from "react";

import CommonField from "../../Widgets/CommonField";
import constants from "../../constants";

function ObjectStorage({ objectStorage, setObjectStorage, handleChange }) {
  return (
    <div className="grid gap-8 w-full h-fit">
      <CommonField titleText="End Point" name="endpoint" value={objectStorage.endpoint || ""} onChange={handleChange} error="field" />
      <CommonField titleText="Bucket Name" name="bucketname" value={objectStorage.bucketname || ""} onChange={handleChange} error="field" />
      <CommonField titleText="Access Key" name="accesskey" value={objectStorage.accesskey || ""} onChange={handleChange} error="field" />
      <CommonField titleText="Secret" name="secret" value={objectStorage.secret || ""} onChange={handleChange} error="field" />
      <CommonField titleText="Region" name="region" value={objectStorage.region || ""} onChange={handleChange} error="field" />
      <CommonField
        selectfield
        error
        titleText="Type"
        value={objectStorage.type}
        options={constants.components.objectStorageType}
        onChange={(type) => setObjectStorage({ ...objectStorage, type })}
      />
    </div>
  );
}

export default memo(ObjectStorage);
