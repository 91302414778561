import { memo } from "react";
import { useNavigate } from "react-router-dom";

import Button from "./Button";

const ApplicationCard = ({ title, text, primaryButton, secondoryButton, handleCreate, img, setShowModel }) => {
  const navigate = useNavigate();

  const createOnClickHendler = () => {
    navigate(handleCreate);
    setShowModel(true);
  };

  return (
    <div className="flex flex-col items-center w-full justify-between mr-8 mb-8 rounded shadow-xl border bg-white">
      <div className="flex flex-col w-full">
        <div className="flex flex-row items-center w-full p-8 border-b">
          <div className="inline-flex overflow-hidden relative justify-center items-center w-10 h-10 p-10 bg-gray-100 border rounded-full dark:bg-gray-600 mr-8">
            <span className="font-medium text-gray-600 dark:text-gray-300">{title[0]}</span>
          </div>
          <div className="flex w-full text-indigo-700 text-sm truncate">{title}</div>
        </div>
        {img && (
          <div className="flex justify-center items-center w-full">
            <img className="w-fit h-128" src={img} alt="www.video.com" />
          </div>
        )}
      </div>
      <div className="flex flex-col items-center justify-between w-full h-full">
        <div className="text-black text-xs p-14 w-full">{text}</div>
        <div className="px-10 mb-32 flex flex-col items-center justify-between h-64 w-full">
          {primaryButton && (
            <Button
              className="transition ease-in-out delay-150 border-blue-700 w-4/5  hover:-translate-y-1 hover:scale-110 duration-300"
              variant="outline"
              color="indigo"
              disable
            >
              {primaryButton}
            </Button>
          )}
          <Button
            className="transition ease-in-out delay-150 bg-blue-700  w-4/5 hover:-translate-y-1 hover:scale-110 duration-300"
            variant="contained"
            color="indigo"
            onClick={createOnClickHendler}
          >
            {secondoryButton}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(ApplicationCard);
