import { memo } from "react";
import KeyValueFieldAggregate from "../../Widgets/KeyValueFieldAggregate";
import CommonField from "../../Widgets/CommonField";

function Aggregate({ aggregate, setAggregate, handleChange }) {
  return (
    <div className="grid gap-8 w-full h-fit">
      <CommonField titleText="Timestamp Column" name="Uniquekey" value={aggregate.Uniquekey || ""} error="field" onChange={handleChange} />
      <KeyValueFieldAggregate
        setAggregate={setAggregate}
        aggregate={aggregate}
        selectTitle="Select Window"
        selectClassName="w-144"
        selectName="windows"
        selectInputTitle=""
        inputSelectPlaceholder="Column Name"
        selectInputClassName="w-full flex items-end"
        selectInputName="timeIn"
        selectFunctionsTitle="Function"
        selectFunctionsClassName="w-144"
        selectFunctionsName="functions"
      />
      {/* <KeyValueFieldAggregate
        fieldType="Text"
        name="second_value"
        className="w-full ml-8"
        value={aggregate.time_value}
        onChange={(e) =>
          setAggregate({
            ...aggregate,
            time_value: e.target.value.replace(/\D/g, ""),
          })
        }
      /> */}
    </div>
  );
}

export default memo(Aggregate);
