import { useState, useContext, useEffect, memo } from "react";
import { Context } from "../../Context";
import Header from "../../Widgets/Header";
import Table from "../../Widgets/Table";
import CustomTooltip from "../../Widgets/Tooltip";
import SidebarForm from "../lakehouse/SidebarForm";
import CommonField from "../../Widgets/CommonField";
import { filterDataBySearch } from "../../utils/utils";
import Tabs from "../../Widgets/Tabs";
import Status from "../../Widgets/Status";
import CustomSwitch from "../../Widgets/CustomSwitch";
import Deletepopup from "../../Widgets/Deletepopup";

function Groups() {
  const contextValues = useContext(Context);
  const [search, setSearch] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [data, setData] = useState({});
  const [userData, setuserData] = useState([]);
  const [editItem, setEditItem] = useState();
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState("users");
  const [editUser, setEditUser] = useState();
  const [rowsPerPage] = useState(16);
  const [pageUser, setPageUser] = useState(1);
  const [userPermissionData, setUserPermissionData] = useState([]);
  const [openDeletePopup, setDeletepopup] = useState(false);

  useEffect(() => {
    if (search !== null) {
      const timer = setTimeout(() => {
        setPageUser(1);
        if (activeTab === "users") {
          contextValues.getUserPermission({
            search,
            page: pageUser,
            rowsPerPage,
          });
        } else if (activeTab === "group") {
          contextValues.getUserPermission({ limit: -1 });
          contextValues.getGroup({ search, page, rowsPerPage });
        }
      }, 800);
      return () => clearTimeout(timer);
    }
  }, [search]);

  useEffect(() => {
    if (activeTab == "users") {
      contextValues.getUserPermission({ search, page: pageUser, rowsPerPage });
    }
    if (activeTab == "group") {
      contextValues.getUserPermission({ limit: -1 });
      contextValues.getGroup({ search, page, rowsPerPage });
    }
  }, [page, activeTab]);

  // useEffect(() => {
  //   if (contextValues?.activeTab !== undefined) {
  //     setActiveTab(contextValues?.activeTab);
  //   }
  // }, [contextValues?.activeTab]);

  const selectItemToEdit = (id) => {
    const selectedItem = contextValues.groupData?.data?.find((item) => item.id === id);
    const editData = {
      groupname: selectedItem?.groupname,
      description: selectedItem?.description,
      user_permission: selectedItem?.user_permissions?.data?.map((item) => ({
        value: `${item.id}`,
        label: `${item?.attributes?.username}`,
      })),
    };
    setData(editData);
    setEditItem(id);
  };

  const tableHead = [
    { id: 1, label: "Group Id", position: "left" },
    { id: 2, label: "Group Name", position: "left" },
    { id: 3, label: "Description", position: "center" },
    { id: 4, label: "Actions", position: "right" },
  ];

  const userHeader = [
    { id: 1, label: "UserName", position: "left" },
    { id: 2, label: "Email", position: "center" },
    { id: 3, label: "Permission", position: "center" },
    { id: 4, label: "Actions", position: "center" },
  ];

  const tabData = [
    { title: "Users", value: "users" },
    { title: "Group", value: "group" },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    if (activeTab === "group") {
      if (!editItem) {
        contextValues.createGroup(data);
      } else {
        contextValues.putGroup({
          groupname: data.groupname,
          description: data.description,
          user_permissions: data?.user_permission?.map((item) => item?.value),
          id: editItem,
        });
      }
      setEditItem(null);
      setOpenSidebar(false);
      setData({});
    } else if (activeTab === "users") {
      if (!editUser) {
        contextValues.createUserPermission(userPermissionData);
      } else {
        contextValues.putUserPermission({
          data: userPermissionData,
          id: editUser,
        });
      }
      setEditUser(null);
      setOpenSidebar(false);
      setUserPermissionData({});
    }
  };

  const handleChange = (event) => {
    const { name, type, value, checked } = event.target;
    if (name === "email") {
      userPermissionData[name] = value.toLowerCase();
    } else {
      userPermissionData[name] = value;
    }

    if (activeTab === "users") {
      setUserPermissionData((values) => ({
        ...values,
        [name]: type === "checkbox" ? checked : value,
      }));
    } else if (activeTab === "group") {
      setData((values) => ({
        ...values,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (contextValues?.userPermission) {
      setuserData(
        contextValues?.userPermission?.data?.map((item) => ({
          value: `${item.id}`,
          label: `${item.username}`,
        }))
      );
    }
  }, [contextValues?.userPermission]);

  const validate = () => {
    let isValidate = false;
    if (activeTab === "users") {
      if (Object.keys(userPermissionData).length !== 0) {
        if (!userPermissionData?.username || userPermissionData?.username.trim() === "") {
          isValidate = false;
        }
        if (
          userPermissionData.email == "" ||
          !userPermissionData.email ||
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userPermissionData.email)
        ) {
          isValidate = false;
        } else {
          isValidate = true;
        }
      }
    } else if (activeTab === "group") {
      if (Object.keys(data).length !== 0) {
        if (!data?.groupname || data?.groupname.trim() === "") {
          isValidate = false;
        } else if (/[A-Z]/.test(data?.groupname) || data?.groupname?.includes(" ") || data?.groupname?.includes("_")) {
          isValidate = false;
        } else if (!data?.user_permission || data?.user_permission == "") {
          isValidate = false;
        } else {
          isValidate = true;
        }
      }
    }
    return isValidate;
  };

  const handleEdit = (item) => {
    if (item) {
      if (activeTab === "users") {
        setEditUser(item?.id);
        setEditItem(item?.id);
        setUserPermissionData(item);
      }
    }
  };

  return (
    <div className="w-full h-full">
      <Header
        title="Users/Groups"
        createButtonText={activeTab == "users" ? "Create User" : "Add Group"}
        onButtonClick={() => {
          setOpenSidebar(true);
        }}
        searchPlaceholder="Search..."
        search={search}
        setSearch={setSearch}
      />
      {/* <div className="p-10"> */}
      <div className="flex justify-between">
        {openSidebar && (
          <SidebarForm
            title={activeTab === "group" ? (editItem ? "Update Group" : "Create Group") : editUser ? "Update User" : "Create User"}
            buttonClass="justify-end"
            disable={!validate()}
            onClose={() => {
              setOpenSidebar(false);
              setData({});
              setUserPermissionData({});
              setEditItem("");
              setEditUser("");
              setuserData([]);
            }}
            handleSubmit={handleSubmit}
            createButtonName={activeTab === "group" ? (editItem ? "Save" : "Create") : editUser ? "Save" : "Create"}
          >
            {activeTab == "users" ? (
              <>
                <CommonField
                  className="mb-8"
                  titleText="UserName"
                  name="username"
                  value={userPermissionData?.username}
                  onChange={handleChange}
                  error="field"
                />
                <CommonField
                  className="mb-8"
                  titleText="Email"
                  name="email"
                  value={userPermissionData?.email}
                  onChange={handleChange}
                  error="email"
                />
                <div className="flex justify-start items-center uppercase text-2xs mb-2 text-lightGrey">Permission</div>
                <CustomSwitch name="permission" checked={userPermissionData?.permission ?? false} onChange={handleChange} size="small" />
              </>
            ) : activeTab == "group" ? (
              <>
                <CommonField
                  className="mb-8"
                  titleText="Group Name"
                  name="groupname"
                  value={data.groupname || ""}
                  onChange={handleChange}
                  error="lowercase"
                />
                <CommonField
                  className="mb-3"
                  options={userData}
                  titleText="User"
                  value={data?.user_permission}
                  isMulti
                  onChange={(user_permission) => {
                    setData({ ...data, user_permission });
                  }}
                  error
                  selectfield
                />
                <CommonField
                  className="mb-8"
                  titleText="Description"
                  rows="4"
                  name="description"
                  onChange={handleChange}
                  value={data.description || ""}
                  textarea
                />
              </>
            ) : null}
          </SidebarForm>
        )}
      </div>
      <Tabs type="basic" tabs={tabData} setActiveTab={setActiveTab} activeTab={activeTab} className="bg-white h-40 flex items-end" />
      <div className="p-10">
        {activeTab == "users" ? (
          <>
            <div className="px-16 py-12  inline-block  flex items-center text-131 font-semibold text-333333">Users</div>
            <Table
              setPage={setPageUser}
              page={pageUser}
              tableHeader={userHeader}
              rowsPerPage={rowsPerPage}
              totalPost={contextValues?.userPermission?.meta?.total}
            >
              {contextValues?.userPermission?.data?.map((item, index) => {
                return (
                  <tr key={index} className="border-b w-full hover:bg-tableHoverColor last:border-0">
                    <td className="px-16 py-5 cursor-pointer text-left text-xs">{item?.username}</td>
                    <td className="px-16 py-5 cursor-pointer text-center text-xs">{item?.email}</td>
                    <td className="px-16 py-5 cursor-pointer text-center text-xs flex justify-center items-center">
                      <Status title={item?.permission ? "allowed" : "not_allowed"} />
                    </td>
                    <td className="px-16 py-5 text-center cursor-pointer text-xs">
                      <CustomTooltip direction="top" title="Edit">
                        <i
                          className="fa-solid fa-pen-to-square mx-4 font-normal cursor-pointer hover:text-blue-600"
                          role="presentation"
                          onClick={() => {
                            setOpenSidebar(true);
                            handleEdit(item);
                          }}
                        />
                      </CustomTooltip>
                      <Deletepopup
                        open={openDeletePopup.id == item?.id}
                        setOpen={setDeletepopup}
                        onDelete={() => {
                          contextValues.deleteUserPermission(openDeletePopup.id);
                          setDeletepopup({ open: false });
                        }}
                      >
                        <CustomTooltip direction="top" title="Delete">
                          <i
                            className="fa-solid fa-trash-can text-lightGrey font-normal cursor-pointer hover:text-blue-600"
                            role="presentation"
                            onClick={() => {
                              setDeletepopup({ open: true, id: item?.id });
                            }}
                          />
                        </CustomTooltip>
                      </Deletepopup>
                    </td>
                  </tr>
                );
              })}
            </Table>
          </>
        ) : (
          <>
            <div className="px-16 py-12  inline-block  flex items-center text-131 font-semibold text-333333">Group</div>
            <Table
              tableHeader={tableHead}
              bodyHight="max-h-[calc(100vh-14.4rem)]"
              setPage={setPage}
              page={page}
              rowsPerPage={rowsPerPage}
              totalPost={contextValues?.groupData?.meta?.total}
            >
              {filterDataBySearch(contextValues?.groupData?.data, search)?.map((item, index) => {
                return (
                  <tr key={index} className="border-b w-full hover:bg-gray-100 cursor-pointer">
                    <td className="px-16 py-5 text-xs text-left">{item.id}</td>
                    <td className="px-16 py-5 text-xs text-left justify-center">{item.groupname}</td>
                    <td className="px-16 py-5 text-xs text-center flex justify-center">{item.description}</td>
                    <td className="px-16 py-5 text-xs text-center">
                      <CustomTooltip direction="top" title="Edit">
                        <i
                          className="fa-solid fa-pen-to-square mx-4 font-normal text-lightGrey cursor-pointer hover:text-blue-600"
                          role="presentation"
                          onClick={() => {
                            setOpenSidebar(true);
                            selectItemToEdit(item.id);
                          }}
                        />
                      </CustomTooltip>
                      <Deletepopup
                        open={openDeletePopup.id == item?.id}
                        setOpen={setDeletepopup}
                        onDelete={() => {
                          contextValues.deleteGroup(openDeletePopup.id);
                          setDeletepopup({ open: false });
                        }}
                      >
                        <CustomTooltip direction="top" title="Delete">
                          <i
                            className="fa-solid fa-trash-can text-lightGrey font-normal cursor-pointer hover:text-blue-600"
                            role="presentation"
                            onClick={() => {
                              setDeletepopup({ open: true, id: item?.id });
                            }}
                          />
                        </CustomTooltip>
                      </Deletepopup>
                    </td>
                  </tr>
                );
              })}
            </Table>
          </>
        )}
      </div>
    </div>
  );
}

export default memo(Groups);
