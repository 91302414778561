import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import constants from "../constants";
import Button from "./Button";
import { Context } from "../Context";

const PageNotFound = ({ rediretText, rediectLink }) => {
  const navigate = useNavigate();
  const contextValues = useContext(Context);
  const authed = contextValues.checkAdminLoginStatus();
  const handleRedirect = () => {
    navigate(rediectLink ?? authed ? constants.route.dashboard : constants.route.login);
  };
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="ml-14 w-2/5">
        <h3 className="text-32 bg-primaryColor font-bold inline-block text-transparent bg-clip-text">We lost this page</h3>
        <p className="text-md bg-buttonGradient inline-block text-transparent bg-clip-text">
          We seasrched high and low but coudn't find what you're looking for. Let's find better place for you to go.
        </p>

        <Button className="mt-10" variant="contained" color="primaryColor" onClick={handleRedirect}>
          {rediretText ?? authed ? "Dashboard" : "Login"}
        </Button>
      </div>
      <img className="w-2/5" src={process.env.PUBLIC_URL + "/assets/page_not_found.svg"} alt="No Data..." />
    </div>
  );
};
export default PageNotFound;
