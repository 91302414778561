import React, { memo, useState } from "react";
import { Context } from "../Context";

const UploadButton = ({ className, color, imgName, name, fileUpload, accept }) => {
  const [fileName, setFileName] = useState("");
  const contextValues = React.useContext(Context);

  return (
    <div className={`flex items-center cursor-pointer ${className} `}>
      <div className={`flex w-fit justify-center items-center px-6 py-4 rounded-md relative border-${color || "blue"}-700 border`}>
        <i className={`fa-solid fa-arrow-up-from-bracket text-xs cursor-pointer text-${color || "blue"}-800`} />
        <div className={`ml-6 cursor-pointer text-${color || "blue"}-900`}>Upload File</div>
        <input
          className="absolute z-10 top-0 left-0 w-full h-full opacity-0 cursor-pointer"
          onChange={(e) => {
            contextValues.setUploadFile({ file: e.target.files[0], name, isupload: true });
            setFileName(e.target.files[0].name);
          }}
          type="file"
          accept={accept}
        />
      </div>
      {imgName && <div className="ml-6 text-[15px] text-red-600">{fileName == "" ? (fileUpload ? fileUpload[name]?.name : "") : fileName}</div>}
    </div>
  );
};

export default memo(UploadButton);
