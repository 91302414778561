import React from "react";
import moment from "moment";
import { Context } from "../../Context";
import ReportDetailsCard from "./ReportsDetailsCard";
import constants from "../../constants";

const ReportDetails = () => {
  const contextValues = React.useContext(Context);
  const [graphLakehousesData, setGraphLakehousesData] = React.useState([]);
  const [flowData, setFlowData] = React.useState([]);
  const [scheduledJobsData, setscheduledJobsData] = React.useState([]);
  const [lakehouseDate, setlakehouseDate] = React.useState();
  const [flowDate, setFlowDate] = React.useState();
  const [scheduleJobDate, setScheduleJobDate] = React.useState();

  React.useEffect(() => {
    contextValues.GetLakehouse({ lakehouseDate, sort: "asc" });
  }, [lakehouseDate]);

  React.useEffect(() => {
    contextValues.GetFlow({ flowDate, sort: "asc" });
  }, [flowDate]);

  React.useEffect(() => {
    contextValues.GetScheduledJobs({ scheduleJobDate, sort: "asc" });
  }, [scheduleJobDate]);

  React.useEffect(() => {
    if (contextValues?.graphLakehousesData?.data) {
      const customData = contextValues?.graphLakehousesData?.data.map((item) => ({
        ...item,
        createdAt: moment(item.createdAt).format("DD/MM/YYYY"),
        total: contextValues?.graphLakehousesData?.data?.filter(
          (i) => item?.status == i?.status && moment(i.createdAt).format("DD/MM/YYYY") == moment(item.createdAt).format("DD/MM/YYYY")
        )?.length,
      }));
      const uniqueCombos = {};
      const filterData = customData.filter((item) => {
        const { status, createdAt, total } = item;
        const comboKey = `${status}-${createdAt}-${total}`;
        if (uniqueCombos[comboKey]) {
          delete item.total;
          return false;
        }
        uniqueCombos[comboKey] = true;
        return true;
      });
      setGraphLakehousesData(filterData);
    }
  }, [contextValues?.graphLakehousesData]);
  React.useEffect(() => {
    if (contextValues?.FlowData?.data) {
      const customData = contextValues?.FlowData?.data?.map((item) => ({
        ...item,
        createdAt: moment(item.attributes?.createdAt).format("DD/MM/YYYY"),
        status: item?.attributes?.status,
        total: contextValues?.FlowData?.data?.filter(
          (i) =>
            item?.attributes?.status == i?.attributes?.status &&
            moment(i?.attributes?.createdAt).format("DD/MM/YYYY") == moment(item?.attributes?.createdAt).format("DD/MM/YYYY")
        )?.length,
      }));
      const uniqueCombos = {};
      const filterData = customData.filter((item) => {
        const { status, createdAt, total } = item;
        const comboKey = `${status}-${createdAt}-${total}`;
        if (uniqueCombos[comboKey]) {
          delete item.total;
          return false;
        }
        uniqueCombos[comboKey] = true;
        return true;
      });
      setFlowData(filterData);
    }
  }, [contextValues?.FlowData]);

  React.useEffect(() => {
    if (contextValues?.ScheduledJobData?.data) {
      const customData = contextValues?.ScheduledJobData?.data.map((item) => ({
        ...item,
        createdAt: moment(item.createdAt).format("DD/MM/YYYY"),
        status: item?.status === false ? "Stopped" : "Started",
        total: contextValues?.ScheduledJobData?.data?.filter(
          (i) => item?.status == i?.status && moment(i.createdAt).format("DD/MM/YYYY") == moment(item.createdAt).format("DD/MM/YYYY")
        )?.length,
      }));
      const uniqueCombos = {};
      const filterData = customData.filter((item) => {
        const { status, createdAt, total } = item;
        const comboKey = `${status}-${createdAt}-${total}`;
        if (uniqueCombos[comboKey]) {
          delete item.total;
          return false;
        }
        uniqueCombos[comboKey] = true;
        return true;
      });
      setscheduledJobsData(filterData);
    }
  }, [contextValues?.ScheduledJobData]);
  return (
    <div className="w-full h-full">
      <div className="grid grid-cols-2 gap-16 mb-14 w-full">
        <ReportDetailsCard
          data={graphLakehousesData}
          title="Lakehouses"
          countHeading="Total Lakehouses"
          setDate={setlakehouseDate}
          viewAllLink={constants.route.lakehouse}
        />
        <ReportDetailsCard
          data={flowData}
          title="Flows"
          countHeading="Total Flows"
          viewAllLink={constants.route.lackhouseFlow}
          setDate={setFlowDate}
        />
        <ReportDetailsCard
          data={scheduledJobsData}
          title="Scheduled Jobs"
          countHeading="Total Scheduled Jobs"
          viewAllLink={constants.route.scheduledJobs}
          setDate={setScheduleJobDate}
        />
      </div>
    </div>
  );
};
export default ReportDetails;
