import React, { memo, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "../../Widgets/Button";
import Modal from "../../Widgets/Modal";
import { Context } from "../../Context";
import Header from "../../Widgets/Header";
import { dateFormat } from "../../utils/utils";
import Postgres from "../configureField/Postgres";
import CustomTooltip from "../../Widgets/Tooltip";

function PostgreSQL() {
  const contextValues = useContext(Context);
  const [showModel, setShowModel] = useState();
  const [fieldValue, setFieldValue] = useState({});
  const [search, setSearch] = useState();
  const [editItem, setEditItem] = useState();
  const USER = window.sessionStorage.getItem("USER");
  const ParseUser = JSON.parse(USER);
  const location = useLocation();

  React.useEffect(() => {
    contextValues.getPostgres();
  }, []);

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    setFieldValue((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!editItem) {
      contextValues.createPostgres({
        data: fieldValue,
        users_permissions_user: ParseUser.id,
        name: location?.pathname?.split("/")?.[2],
      });
    } else {
      contextValues.putPostgres({
        id: editItem,
        name: location?.pathname?.split("/")?.[2],
        data: fieldValue,
      });
    }
    setShowModel(false);
  };

  const validate = () => {
    // let isValidate = true;
    // if (selectedName == "PostgreSQL") {
    //   if (fieldValue.url == "" || !fieldValue.url) {
    //     isValidate = false;
    //   } else if (fieldValue.config_name == "" || !fieldValue.config_name) {
    //     isValidate = false;
    //   } else if (fieldValue.connect === "host") {
    //     if (fieldValue.host == "" || !fieldValue.host) {
    //       isValidate = false;
    //     } else if (fieldValue.port == "" || !fieldValue.port) {
    //       isValidate = false;
    //     } else if (fieldValue.database == "" || !fieldValue.database) {
    //       isValidate = false;
    //     }
    //   }
    // }
    return true;
  };

  const selectItemToEdit = (id) => {
    setShowModel(true);
    const selectedItem = contextValues.postgressData?.data.filter((item) => item.id === id);
    setFieldValue(selectedItem[0]?.attributes?.properties);
    setEditItem(id);
  };

  const teamHead = [
    { id: 1, label: "Id", position: "left" },
    { id: 2, label: "Created At", position: "center" },
    { id: 3, label: "Updated At", position: "center" },
    { id: 4, label: "Name", position: "center" },
    { id: 5, label: "Host", position: "center" },
    { id: 6, label: "Port", position: "center" },
    { id: 7, label: "Action", position: "center" },
  ];

  return (
    <div className="w-full h-full">
      <Header
        title="PostgreSQL"
        createButtonText="Create postgres"
        onButtonClick={() => {
          setFieldValue();
          setShowModel(true);
          setEditItem("");
        }}
        searchPlaceholder="Search..."
        search={search}
        setSearch={setSearch}
      />
      <div className="p-10">
        <div className="bg-indigo-500 text-white font-medium w-full grid grid-cols-7 rounded-t-lg">
          {teamHead.map((item) => (
            <div className={`p-8 text-${item?.position}`} key={item.id}>
              {item.label}
            </div>
          ))}
        </div>
        {contextValues?.postgressData?.data
          ?.filter((val) => {
            return !search ? val : val.attributes?.config_name.toLowerCase().includes(search.toLowerCase());
          })
          ?.map((item) => {
            return (
              <div key={item?.id} className="border-b w-full grid grid-cols-7 hover:bg-indigo-50">
                <div className="p-6 cursor-pointer" role="presentation">
                  {item?.id}
                </div>
                <div className="p-6 text-center cursor-pointer" role="presentation">
                  {dateFormat(item?.attributes?.publishedAt)}
                </div>
                <div className="p-6 text-center cursor-pointer" role="presentation">
                  {dateFormat(item?.attributes?.updatedAt)}
                </div>
                <div className="p-6 text-center cursor-pointer" role="presentation">
                  {item?.attributes?.properties?.config_name}
                </div>
                <div className="p-6 text-center cursor-pointer" role="presentation">
                  {item?.attributes?.properties?.host}
                </div>
                <div className="p-6 text-center cursor-pointer" role="presentation">
                  {item?.attributes?.properties?.port}
                </div>
                <div className="p-6 text-center">
                  <div className="">
                    <CustomTooltip direction="bottom" title="Edit">
                      <i
                        className="fa-solid fa-pen-to-square mx-8 font-normal cursor-pointer hover:text-blue-600 z-999"
                        role="presentation"
                        onClick={() => {
                          selectItemToEdit(item.id);
                        }}
                      />
                    </CustomTooltip>
                    <CustomTooltip direction="bottom" title="Delete">
                      <i
                        className="fa-solid fa-trash-can mx-8 font-normal cursor-pointer hover:text-blue-600"
                        role="presentation"
                        onClick={() => {
                          contextValues.deletePostgres(item.id);
                        }}
                      />
                    </CustomTooltip>
                  </div>
                </div>
              </div>
            );
          })}
        <Modal showModel={showModel} setShowModel={setShowModel}>
          <form onSubmit={handleSubmit} className="flex flex-col w-full justify-center items-center">
            <div className="flex w-full justify-between items-center pt-6 pb-12 border-b">
              <div className="text-sm">{editItem ? "Update Postgres" : "Create Postgres"}</div>
            </div>
            <Postgres fieldValue={fieldValue} setFieldValue={setFieldValue} handleChange={handleChange} />
            <div className="flex justify-end items-center w-full mt-40">
              <Button className="mr-12" color="indigo" variant="outline" onClick={() => setShowModel(false)}>
                Cancel
              </Button>
              <Button color="indigo" variant="contained" disable={!validate()} submit>
                {editItem ? "Update" : "Create"}
              </Button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
}

export default memo(PostgreSQL);
