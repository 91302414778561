import React, { useState, memo, useContext } from "react";
import { cloneDeep, mapValues } from "lodash";

import { FlowChart } from "@mrblenny/react-flow-chart";
import * as actions from "@mrblenny/react-flow-chart/src/container/actions";

import { useParams, useNavigate } from "react-router-dom";
import { Context } from "../../Context";
import * as utils from "../../utils/utils";
import DraggableCard from "../../Widgets/DraggableCard";
import DraggedCard from "../../Widgets/DraggedCard";
import Input from "../../Widgets/Input";
import Confugure from "../flowConfigure/Confugure";
import Modal from "../../Widgets/Modal";
import CustomTooltip from "../../Widgets/Tooltip";

function Flowchart() {
  const contextValues = useContext(Context);
  const INITIAL_FLOW = {
    offset: { x: 0, y: 0 },
    flow_config: {},
    nodes: {},
    links: {},
    selected: {},
    hovered: {},
  };
  const params = useParams();
  const [isConfigure, setIsConfigure] = useState(false);
  const [search, setSearch] = useState();
  const [chart, setChart] = useState(cloneDeep(INITIAL_FLOW));
  const [configureData, setConfigureData] = useState();
  const [flowHeader, setFlowHeader] = useState("");
  const [openComponentBar, setOpenComponentBar] = useState(true);

  React.useEffect(() => {
    contextValues.GetFlow();
    contextValues.GetComponent(params.id);
  }, []);

  React.useEffect(() => {
    if (contextValues.FlowData !== undefined || null) {
      const filterData = contextValues.FlowData?.data.filter((item) => item.id == params.id);
      setFlowHeader(filterData[0]?.attributes?.name);
      if (filterData[0].attributes.chart !== null) {
        setChart(cloneDeep(filterData[0].attributes.chart));
      }
    }
  }, [contextValues.FlowData?.data]);
  const getChart = () => {
    return chart != null ? chart : cloneDeep(INITIAL_FLOW);
  };

  const stateActions = mapValues(actions, (func) => (...args) => {
    let chartData = getChart();
    chartData = {
      ...chartData,
      ...func(...args)(chartData),
    };
    setChart(chartData);
  });

  const deleteNode = (id) => {
    if (contextValues.configCloseId == id) {
      setIsConfigure(false);
    }
    const updatechart = chart;
    const nodes = {};
    for (const k of Object.keys(chart.nodes)) {
      for (const l of Object.keys(chart.links)) {
        if (chart.links[l].to.nodeId === id || chart.links[l].from.nodeId === id) {
          delete chart.links[l];
        }
      }
      if (k === id) {
        for (const i of contextValues.componentData) {
          if (chart.nodes[k].properties.name == i.attributes.name) {
            contextValues.deleteComponent(i.attributes.id);
          }
        }
        delete chart.nodes[k];
      } else {
        nodes[k] = chart.nodes[k];
      }
    }
    setChart(updatechart);
    contextValues.putFlowChart({ chart, id: params.id });
  };
  const NodeInnerCustom = ({ node }) => <DraggedCard setIsConfigure={setIsConfigure} setConfigureData={setConfigureData} node={node} chart={chart} />;

  const PortCustom = () => (
    <div className="flex content-center items-center cursor-pointer rounded-full w-9 h-9 bg-antiflashwhite border-gray-400 border-2" />
  );

  const NodeCustom = React.forwardRef(({ node, children, ...otherProps }, ref) => {
    delete otherProps.isSelected;
    return (
      <div
        ref={ref}
        {...otherProps}
        className={`h-40 w-40 flex justify-center items-center shadow-xl rounded bg-white  absolute `}
        id="draggable"
        style={{
          ...(otherProps.style ? otherProps.style : {}),
        }}
      >
        <div className="absolute pl-40 mb-36 z-999">
          <div className="z-9999 cursor-pointer">
            <i
              className="fa fa-times text-[10px] text-red-600 border-1  border-red-600 rounded-full p-2 h-10 w-10 flex justify-center items-center"
              aria-hidden="true"
              onClick={() => deleteNode(node.id)}
            />
          </div>
        </div>
        {children}
      </div>
    );
  });

  const Link = (props) => {
    const { startPos, endPos, link, isSelected, isHovered } = props;
    const element = document.getElementById("draggable");
    if (element !== null) {
      const { offsetHeight } = element;
      const { offsetWidth } = element;
      startPos.x += offsetWidth / 2;
      startPos.y += offsetHeight / (props.link?.from?.portId === "port1" ? 4 : props.link?.from?.portId === "port3" ? 6 : 3);
      if (props.link.to.nodeId == undefined) {
        endPos.y += offsetHeight / 22;
      } else {
        endPos.y += offsetHeight / 4;
      }
    }
    let points = [];
    const c1 = utils.copyDict(startPos);
    const c2 = utils.copyDict(endPos);
    const sign = c2.y > c1.y ? 1 : -1;

    if (startPos.x > endPos.x) {
      const xDiff = 10 + Math.min(10, 0.1 * Math.abs(c1.x - c2.x));
      const yDiff = 15;
      const yCorrection = sign * (c2.y - c1.y) < 200 ? 7 : 0;
      const a1 = utils.copyDict(c1);
      const a2 = { x: a1.x + xDiff, y: a1.y };
      const a3 = { x: a2.x + xDiff, y: a2.y + sign * yCorrection };
      const a4 = { x: a3.x, y: a2.y + sign * yDiff };
      const a5 = { x: a4.x, y: a4.y + sign * yDiff };
      const a6 = { x: (c1.x + c2.x) / 2, y: (c1.y + c2.y) / 2 };
      const b1 = utils.copyDict(c2);
      const b2 = { x: b1.x - xDiff, y: b1.y };
      const b3 = { x: b2.x - xDiff, y: b2.y - sign * yDiff };
      const b4 = {
        x: b3.x,
        y: b3.y - sign * yDiff + sign * yCorrection,
      };
      points = [a1, a2, a3, a4, a5, a6, b4, b3, b2, b1];
    } else {
      const xOffset = Math.min(75, 0.6 * Math.abs(c1.x - c2.x) + 0.4 * Math.abs(c1.y - c2.y));
      const a1 = utils.copyDict(c1);
      const a2 = { x: a1.x + xOffset, y: a1.y };
      const b1 = utils.copyDict(c2);
      const b2 = { x: b1.x - xOffset, y: b1.y };
      points = [a1, a2, b2, b1];
    }

    return (
      <svg
        style={{
          overflow: "visible",
          position: "absolute",
          cursor: "pointer",
          left: "0px",
          right: "0px",
        }}
        onClick={(e) => {
          stateActions.onLinkClick({ linkId: link.id });
          e.stopPropagation();
        }}
        onMouseEnter={(e) => {
          stateActions.onLinkMouseEnter({ linkId: link.id });
          e.stopPropagation();
        }}
        onMouseLeave={(e) => {
          stateActions.onLinkMouseLeave({ linkId: link.id });
          e.stopPropagation();
        }}
      >
        {points.length === 10 ? (
          <path
            d={`M ${points[0].x} ${points[0].y} C ${points[1].x} ${points[1].y} ${points[2].x} ${points[2].y} ${points[3].x} ${points[3].y}
              S ${points[4].x} ${points[4].y} ${points[5].x} ${points[5].y}
              S ${points[6].x} ${points[6].y} ${points[7].x} ${points[7].y}
              S ${points[8].x} ${points[8].y} ${points[9].x} ${points[9].y}
            `}
            stroke="cornflowerblue"
            strokeWidth="3"
            fill="none"
          />
        ) : points.length === 4 ? (
          <path
            d={`M ${points[0].x} ${points[0].y} C ${points[1].x} ${points[1].y} ${points[2].x} ${points[2].y} ${points[3].x} ${points[3].y}
              `}
            stroke="cornflowerblue"
            strokeWidth="3"
            fill="none"
          />
        ) : null}
        {isSelected || isHovered
          ? (points.length === 10 && (
              <path
                d={`M ${points[0].x} ${points[0].y} C ${points[1].x} ${points[1].y} ${points[2].x} ${points[2].y} ${points[3].x} ${points[3].y}
              S ${points[4].x} ${points[4].y} ${points[5].x} ${points[5].y}
              S ${points[6].x} ${points[6].y} ${points[7].x} ${points[7].y}
              S ${points[8].x} ${points[8].y} ${points[9].x} ${points[9].y}
            `}
                stroke="cornflowerblue"
                opacity="0.15"
                strokeWidth="3"
                fill="none"
              />
            )) ||
            (points.length === 4 && (
              <path
                d={`M ${points[0].x} ${points[0].y} C ${points[1].x} ${points[1].y} ${points[2].x} ${points[2].y} ${points[3].x} ${points[3].y}
            `}
                stroke="cornflowerblue"
                opacity="0.15"
                strokeWidth="3"
                fill="none"
              />
            )) ||
            null
          : (points.length === 10 && (
              <path
                d={`M ${points[0].x} ${points[0].y} C ${points[1].x} ${points[1].y} ${points[2].x} ${points[2].y} ${points[3].x} ${points[3].y}
              S ${points[4].x} ${points[4].y} ${points[5].x} ${points[5].y}
              S ${points[6].x} ${points[6].y} ${points[7].x} ${points[7].y}
              S ${points[8].x} ${points[8].y} ${points[9].x} ${points[9].y}
            `}
                stroke="cornflowerblue"
                opacity="0.001"
                strokeWidth="3"
                fill="none"
              />
            )) ||
            (points.length === 4 && (
              <path
                d={`M ${points[0].x} ${points[0].y} C ${points[1].x} ${points[1].y} ${points[2].x} ${points[2].y} ${points[3].x} ${points[3].y}
            `}
                stroke="cornflowerblue"
                opacity="0.001"
                strokeWidth="3"
                fill="none"
              />
            )) ||
            null}
      </svg>
    );
  };

  const draggableCardItems = [
    {
      name: "Aggregate",
      icon: "https://www.svgrepo.com/show/448924/aggregate.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "Conditional Logic",
      icon: "https://www.svgrepo.com/show/21117/robot.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "Facial Recognition",
      icon: "https://www.svgrepo.com/show/445747/facial-recognition.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "Gstream Sink",
      icon: "https://www.svgrepo.com/show/130434/chart.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "HTTP Server",
      icon: "https://www.svgrepo.com/show/221325/http.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "HTTP Client",
      icon: "https://www.svgrepo.com/show/224767/http.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "Join streams",
      icon: "https://www.svgrepo.com/show/7371/data-flow-chart.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "Kafka Producer",
      icon: "https://www.svgrepo.com/show/329947/apachekafka.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "Kafka Consumer",
      icon: "https://www.svgrepo.com/show/329947/apachekafka.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "Postgres Consumer",
      icon: "https://www.svgrepo.com/show/342129/postgresql.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "Postgres Producer",
      icon: "https://www.svgrepo.com/show/443342/brand-postgresql.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "Qr Code Detection",
      icon: "https://www.svgrepo.com/show/379577/qr-code-scan.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "Send SMS",
      icon: "https://www.svgrepo.com/show/384027/chat-sms.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "Send Email",
      icon: "https://www.svgrepo.com/show/14478/email.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "Slack Integration",
      icon: "https://www.svgrepo.com/show/332549/slack.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "SNMP Client",
      icon: "https://www.svgrepo.com/show/208358/manager-user.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "Tensorflow",
      icon: "https://www.svgrepo.com/show/342290/tensorflow.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "Transformation",
      icon: "https://www.svgrepo.com/show/299191/data-transformation.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "Object Storage",
      icon: "https://www.svgrepo.com/show/340754/object-storage.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
    {
      name: "People Counter",
      icon: "https://www.svgrepo.com/show/504008/user-people-account.svg",
      ports: {
        port1: { id: "port1", type: "right" },
        port2: { id: "port2", type: "left" },
      },
    },
  ];

  const navigate = useNavigate();
  const handlebackbtn = () => {
    navigate(-1);
  };

  return (
    <div className="w-full h-screen flex">
      <div className="flex flex-col overflow-hidden w-full">
        <div className="flex justify-between items-center py-6 px-8 border-b bg-gray-100">
          <div className="text-[16px] text-gray-900 space-x-1 flex justify-between items-center align-center">
            <button type="button" onClick={() => handlebackbtn()}>
              <i className="fa-solid fa-angle-left flex-row pr-4 h-25">{}</i>
            </button>
            <div className="pb-1.1">{flowHeader}</div>
          </div>
          {!openComponentBar && (
            <CustomTooltip direction="left" title="Components">
              <i className="fa-solid fa-bars text-md cursor-pointer " role="presentation" onClick={() => setOpenComponentBar(true)} />
            </CustomTooltip>
          )}
        </div>
        <FlowChart
          chart={chart}
          callbacks={stateActions}
          config={{
            validateLink: (props) => {
              const egresslink = [];
              const ingresslink = [];
              let egressName;
              let ingressName;
              for (const i of Object.keys(chart.links)) {
                if (chart.links[i].from.nodeId == props.fromNodeId) {
                  if (chart.links[i].to.nodeId !== undefined) {
                    egresslink.push({
                      from: chart.links[i].from.nodeId,
                      to: chart.links[i].to.nodeId,
                    });
                  }
                }
                if (chart.links[i].to.nodeId == props.toNodeId) {
                  ingresslink.push({
                    from: chart.links[i].from.nodeId,
                    to: chart.links[i].to.nodeId,
                  });
                }
              }
              egresslink.push({ from: props.fromNodeId, to: props.toNodeId });
              ingresslink.push({ from: props.fromNodeId, to: props.toNodeId });
              for (const i of Object.keys(chart.nodes)) {
                if (chart.nodes[i].id == props.fromNodeId) {
                  egressName = chart.nodes[i].properties.name;
                }
                if (chart.nodes[i].id == props.toNodeId) {
                  ingressName = chart.nodes[i].properties.name;
                }
              }
              const fromData = contextValues.componentData.filter((data) => data.attributes.component_id?.includes(props.fromNodeId));
              const toData = contextValues.componentData.filter((data) => data.attributes.component_id?.includes(props.toNodeId));
              contextValues.putComponent({
                name: egressName,
                egress: egresslink,
                flow: params.id,
                componentId: fromData[0]?.id,
              });
              contextValues.putComponent({
                name: ingressName,
                ingress: ingresslink,
                id: params.id,
                componentId: toData[0]?.id,
              });
              contextValues.putFlowChart({ chart, id: params.id });
              return true;
            },
          }}
          Components={{
            NodeInner: NodeInnerCustom,
            Node: NodeCustom,
            Port: PortCustom,
            Link,
          }}
        />
        <Modal showModel={isConfigure} setShowModel={setIsConfigure}>
          <Confugure
            setIsConfigure={setIsConfigure}
            isConfigure={isConfigure}
            chart={chart}
            configureData={configureData}
            node={Object.keys(chart?.selected).length > 0 && chart?.selected?.type === "node" ? chart?.nodes[chart?.selected?.id] : null}
          />
        </Modal>
      </div>
      {openComponentBar && (
        <div className="flex flex-col w-1/5 p-10 bg-grey-100">
          <div className="flex justify-between items-center pb-10">
            <div className="text-md truncate">Components</div>
            <i className="fa-solid fa-xmark text-md cursor-pointer" role="presentation" onClick={() => setOpenComponentBar(false)} />
          </div>
          <Input value={search} onChange={(e) => setSearch(e.target.value)} placeholder="search..." search className="w-full" />

          <div className="grid grid-cols-3 gap-6 py-12">
            {draggableCardItems
              ?.filter((val) => {
                return !search ? val : val.name.toLowerCase().includes(search.toLowerCase());
              })
              ?.map((cardItem, index) => (
                <DraggableCard
                  key={index}
                  type={cardItem.type}
                  properties={{ name: cardItem.name, icon: cardItem.icon }}
                  ports={cardItem.ports}
                  chart={chart}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(Flowchart);
