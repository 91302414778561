import React, { useState } from "react";
import { Context } from "../Context";

function MultipleFileUpload({ className, color, fieldValue, setFieldValue, name, ...filds }) {
  const [file, setFile] = useState([]);
  const contextValues = React.useContext(Context);
  const handleFileChange = (e) => {
    contextValues.setUploadFile({
      ...contextValues.uploadFile,
      isMultiUpload: true,
    });
    const ImagesArray = Object.entries(e.target.files).map((item) => item[1]);
    setFile([...file, ...ImagesArray]);
  };

  React.useEffect(() => {
    contextValues.setUploadFile({ file, name, isMultiUpload: true });
  }, [file]);

  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  }

  function deleteFieldValue(e) {
    const s = fieldValue[name]?.filter((item, index) => index !== e);
    setFieldValue({ ...fieldValue, [name]: s });
  }

  return (
    <div className={`items-center ${className} `}>
      <div className="flex items-center">
        <span className="text-xs text-lightGrey ">Known Images</span>
        <div className={`flex w-fit justify-center items-center px-6 py-4 ml-8 rounded-md relative border-${color || "blue"}-700 border`}>
          <i className={`fa-solid fa-arrow-up-from-bracket text-sm cursor-pointer text-${color || "blue"}-800`} />
          <div className={`ml-6 cursor-pointer text-${color || "blue"}-900`}>Upload File</div>
          <input
            className="absolute z-10 top-0 left-0 w-full h-full opacity-0 cursor-pointer"
            type="file"
            onChange={handleFileChange}
            {...filds}
            multiple
          />
        </div>
      </div>
      <ul className="mt-8 max-h-120 w-full overflow-y-auto">
        {file?.map((item, i) => (
          <li className="flex justify-between items-center border border-gray-400 mx-2 p-6 rounded-md mb-2" key={i}>
            <span className="truncate w-fit">{item.name}</span>
            <i
              className="fa-solid fa-trash-can cursor-pointer text-gray-600 hover:text-red-600 mx-6"
              aria-hidden="true"
              onClick={() => deleteFile(i)}
            />
          </li>
        ))}
        {fieldValue[name]?.map((item, i) => (
          <li className="flex justify-between items-center border border-gray-400 mx-2 p-6 rounded-md mb-2" key={i}>
            <span className="truncate w-fit">{item.name}</span>
            <i
              className="fa-solid fa-trash-can cursor-pointer text-gray-600 hover:text-red-600 mx-6"
              aria-hidden="true"
              onClick={() => deleteFieldValue(i)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MultipleFileUpload;
