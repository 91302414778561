import React, { useState, useContext } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import CustomTooltip from "../Widgets/Tooltip";
import Header from "../Widgets/Header";
import CommonField from "../Widgets/CommonField";
import { dateFormat } from "../utils/utils";
import SidebarForm from "../Components/lakehouse/SidebarForm";
import { Context } from "../Context";
import Status from "../Widgets/Status";
import Table from "../Widgets/Table";
import Deletepopup from "../Widgets/Deletepopup";
import constants from "../constants";

function FlowTable() {
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(18);
  const contextValues = useContext(Context);
  const [search, setSearch] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openDeletePopup, setDeletepopup] = useState(false);
  const [flowData, setFlowData] = useState({});
  const navigate = useNavigate();
  const teamHead = [
    { id: 1, label: "Name", position: "left" },
    { id: 2, label: "Description", position: "center" },
    { id: 4, label: "Created on", position: "center" },
    { id: 5, label: "Status", position: "center" },
    { id: 6, label: "Actions", position: "center" },
  ];
  const validate = () => {
    let isValidate = false;
    if (Object.keys(flowData).length !== 0) {
      if (!flowData?.name || flowData?.name == "") {
        isValidate = false;
      } else {
        isValidate = true;
      }
    }
    return isValidate;
  };

  React.useEffect(() => {
    contextValues.GetFlow({ search, page, rowsPerPage });
  }, [page]);

  React.useEffect(() => {
    if (search !== null) {
      const timer = setTimeout(() => {
        setPage(1);
        contextValues.GetFlow({ search, page, rowsPerPage });
      }, 800);
      return () => clearTimeout(timer);
    }
  }, [search]);

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    setFlowData((values) => ({
      ...values,
      [name]: value,
      createdAt: moment().format(),
      status: "draft",
    }));
  };

  const handleFlow = (id) => {
    navigate(`${constants.route.flowCreation}/${id}`);
    // navigate(`${constants.route.flowCreation_canvas}/${id}`);
  };

  const handleSubmit = () => {
    contextValues.createFlow(flowData);
    setOpenSidebar(false);
    setFlowData({});
  };

  return (
    <div className="w-full h-full">
      <Header
        title="Flows"
        createButtonText="Create Flow"
        onButtonClick={() => setOpenSidebar(true)}
        searchPlaceholder="Search..."
        search={search}
        setSearch={setSearch}
      />
      {openSidebar && (
        <SidebarForm
          title="Create Flow"
          disable={!validate()}
          onClose={() => {
            setOpenSidebar(false);
            setFlowData({});
          }}
          handleSubmit={handleSubmit}
        >
          <CommonField className="mb-8" titleText="Flow Name" name="name" value={flowData?.name || ""} onChange={handleChange} error="field" />
          <CommonField
            className="mb-8"
            titleText="Description"
            rows="4"
            name="description"
            onChange={handleChange}
            value={flowData.description}
            textarea
          />
        </SidebarForm>
      )}

      <div className="p-10">
        <Table
          // subTitle="Enter the editing mode to create and edit your desired flow configurations."
          setPage={setPage}
          tableHeader={teamHead}
          bodyHight="max-h-[calc(100vh-14.4rem)]"
          page={page}
          rowsPerPage={rowsPerPage}
          totalPost={contextValues.FlowData?.meta?.pagination?.total}
        >
          {contextValues.FlowData?.data?.map((item, index) => {
            return (
              <tr key={index} className="border-b w-full hover:bg-tableHoverColor last:border-0">
                <td className="px-16 py-8 cursor-pointer text-xs">{item?.attributes?.name}</td>
                <td className="px-16 py-8 text-center cursor-pointer text-xs">{item?.attributes?.description}</td>
                <td className="px-16 py-8 text-center cursor-pointer text-xs">{dateFormat(item?.attributes?.createdAt)}</td>
                <td className="px-16 py-5 text-center cursor-pointer flex justify-center text-xs">
                  <Status title={item?.attributes?.status} />
                </td>
                <td className="px-16 py-8 text-center cursor-pointer text-xs">
                  <CustomTooltip direction="top" title="Edit">
                    <i
                      className="fa-solid fa-pen-to-square mx-4 font-normal text-lightGrey cursor-pointer hover:text-blue-600 z-99"
                      role="presentation"
                      onClick={() => handleFlow(item?.id)}
                      // onClick={() => navigate(constants.route.flowCreation_canvas)}
                    />
                  </CustomTooltip>
                  <Deletepopup
                    open={openDeletePopup.id == item?.id}
                    setOpen={setDeletepopup}
                    onDelete={() => {
                      contextValues.deleteFlow(openDeletePopup.id);
                      setDeletepopup({ open: false });
                    }}
                  >
                    <CustomTooltip direction="top" title="Delete">
                      <i
                        className="fa-solid fa-trash-can mx-4 text-lightGrey font-normal cursor-pointer hover:text-blue-600"
                        role="presentation"
                        onClick={() => {
                          setDeletepopup({ open: true, id: item?.id });
                          // contextValues.deleteFlow(item?.id);
                        }}
                      />
                    </CustomTooltip>
                  </Deletepopup>
                </td>
              </tr>
            );
          })}
        </Table>
      </div>
    </div>
  );
}

export default FlowTable;
