import { useEffect, useState, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Popconfirm, message } from "antd";
import Input from "../../Widgets/Input";
import Table from "../../Widgets/Table";
import Tabs from "../../Widgets/Tabs";
import Status from "../../Widgets/Status";
import { Context } from "../../Context";
import Lineage from "../DataCatalog/Lineage";
import Header from "../../Widgets/Header";
import { filterDataBySearch } from "../../utils/utils";
// import constants from "../../constants";

const CatalogOverview = () => {
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState("DATA_ENTITY_GROUP");
  const [activeTab, setActiveTab] = useState();
  const [catalogData, setCatalogData] = useState();
  const contextValues = useContext(Context);
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const fieldHead = [
    { id: 1, label: "ID", position: "left" },
    { id: 2, label: "Name", position: "center" },
    { id: 3, label: "Required", position: "center" },
    { id: 4, label: "Type", position: "center" },
    { id: 5, label: "Doc", position: "center" },
  ];

  useEffect(() => {
    const getCatalogsData = async () => {
      const response = await contextValues.getCatalogsData({
        catalog_name: params?.id,
        table_namespace: queryParams.get("table_namespace"),
        table_name: queryParams.get("table_name"),
      });
      setCatalogData(response?.data?.data[0]);
    };
    getCatalogsData();
  }, []);

  const catalogOverviewHead = [
    { id: 1, label: "Name", position: "left" },
    { id: 2, label: "Owner", position: "center" },
    { id: 3, label: "Created at", position: "center" },
    { id: 4, label: "Updated at", position: "center" },
  ];

  const tabData = [
    { title: "Overview", value: "overview" },
    { title: "Lineage", value: "lineage" },
  ];

  useEffect(() => {
    setStatus("DATA_SET");
  }, [status]);

  return (
    <div>
      <Header title={catalogData?.catalog_name} />
      <div className="p-10">
        <div className="relative w-full h-full bg-white p-10">
          <Tabs type="basic" tabs={tabData} setActiveTab={setActiveTab} activeTab={activeTab} className="border-b mb-10 cursor-pointer" />

          {activeTab === "overview" ? (
            <div className="flex">
              <div className="w-2/3 flex-col">
                <div className="p-10 mb-8 rounded-md border ">
                  {status == "DATA_ENTITY_GROUP" && (
                    <>
                      <div className="flex justify-between items-center">
                        <Input
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search..."
                          setSearch={setSearch}
                          search
                          className="mr-8 !w-fit "
                        />
                        <div className="text-7 font-normal">0 entities,0 upper groups</div>
                      </div>
                      <Table tableHeader={catalogOverviewHead} />
                    </>
                  )}
                  {status == "DATA_SET" && (
                    <>
                      <div>
                        {/* <div className="bg-ffe5e6 py-2 px-4 rounded w-fit mb-10">Dataset</div> */}
                        <div className="grid grid-cols-3">
                          {catalogData?.table_namespace && (
                            <div>
                              <div className="font-bold">Namespace</div>
                              <div className="flex items-center">
                                <i className="fa-solid fa-bars text-0a58ff mr-4" />
                                <div>{catalogData?.table_namespace ?? "-"}</div>
                              </div>
                            </div>
                          )}
                          {catalogData?.table_name && (
                            <div>
                              <div className="font-bold">Table</div>
                              <div className="flex items-center">
                                <i className="fa-solid fa-bars text-0a58ff mr-4" />
                                <div>{catalogData?.table_name ?? "-"}</div>
                              </div>
                            </div>
                          )}
                          {catalogData?.metadata?.schema?.fields?.length && (
                            <div>
                              <div className="font-bold">Columns</div>
                              <div className="flex items-center">
                                <i className="fa-solid fa-bars fa-rotate-90 text-0a58ff mr-4" />
                                <div>{catalogData?.metadata?.schema?.fields?.length ?? 0}</div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {status == "DATA_TRANSFORMER" && (
                    <>
                      <div>
                        <div className="bg-eae5ff py-2 px-4 rounded w-fit mb-10">Transformer</div>
                        <div className="grid grid-cols-2">
                          <div>
                            <div className="flex items-center mb-4">
                              <i className=" fa-solid fa-arrow-down text-0a58ff mr-4" />
                              <div className="font-bold">1 sources</div>
                            </div>
                            <a className="text-0a58ff">kds_clickstream</a>
                          </div>
                          <div>
                            <div className="flex items-center mb-4">
                              <i className=" fa-solid fa-arrow-down fa-rotate-180 text-0a58ff mr-4" />
                              <div className="font-bold">1 targets</div>
                            </div>
                            <a className="text-0a58ff">data_lake</a>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {status == "DATA_QUALITY_TEST" && (
                    <>
                      <div>
                        <div className="bg-fff6e5 py-2 px-4 rounded w-fit mb-10">Quality Test</div>
                        <div className="grid grid-cols-2">
                          <div>
                            <div className="flex items-center mb-4">
                              <div className="font-bold">1 dataset</div>
                            </div>
                            <a className="text-0a58ff">sales_denorm</a>
                          </div>
                          <div>
                            <div className="font-bold mb-4">Last execution</div>
                            <div className="grid grid-cols-2 w-3/4 mb-4">
                              <div className="space-y-4">
                                <div>Status</div>
                                <div>Date</div>
                                <div>Duration</div>
                              </div>
                              <div className="space-y-4">
                                <Status title="skipped" />
                                <div>30 Oct 2021, 03:27</div>
                                <div>13 seconds</div>
                              </div>
                            </div>
                            <Status title="History" />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="py-7 px-10 border rounded-md flex flex-col mb-10">
                  <div className="text-333333 text-11 leading-6 font-bold">Metadata</div>
                  {catalogData?.metadata?.error ? (
                    <div className="text-7 font-normal text-lightGrey">Not created</div>
                  ) : (
                    <table>
                      <tbody>
                        {catalogData?.metadata?.["format-version"] ? (
                          <tr>
                            <td className="w-1/3 text-lightGrey">Format Version</td>
                            <td>{catalogData?.metadata?.["format-version"] ?? "-"}</td>
                          </tr>
                        ) : null}
                        {catalogData?.metadata?.["last-column-id"] ? (
                          <tr>
                            <td className="w-1/3 text-lightGrey">Last Column ID</td>
                            <td>{catalogData?.metadata?.["last-column-id"] ?? "-"}</td>
                          </tr>
                        ) : null}
                        {catalogData?.metadata?.["last-updated-ms"] ? (
                          <tr>
                            <td className="w-1/3 text-lightGrey">Last Updated MS</td>
                            <td>{catalogData?.metadata?.["last-updated-ms"] ?? "-"}</td>
                          </tr>
                        ) : null}
                        {catalogData?.metadata?.["current-schema-id"] ? (
                          <tr>
                            <td className="w-1/3 text-lightGrey">Current Schema ID</td>
                            <td>{catalogData?.metadata?.["current-schema-id"] ?? "-"}</td>
                          </tr>
                        ) : null}
                        {catalogData?.metadata?.["default-spec-id"] ? (
                          <tr>
                            <td className="w-1/3 text-lightGrey">Default Spec ID</td>
                            <td>{catalogData?.metadata?.["default-spec-id"] ?? "-"}</td>
                          </tr>
                        ) : null}
                        {catalogData?.metadata?.["last-partition-id"] ? (
                          <tr>
                            <td className="w-1/3 text-lightGrey">Last Partition ID</td>
                            <td>{catalogData?.metadata?.["last-partition-id"] ?? "-"}</td>
                          </tr>
                        ) : null}
                        {catalogData?.metadata?.["default-sort-order-id"] ? (
                          <tr>
                            <td className="w-1/3 text-lightGrey">Default Sort Order ID</td>
                            <td>{catalogData?.metadata?.["default-sort-order-id"] ?? "-"}</td>
                          </tr>
                        ) : null}
                        {catalogData?.metadata?.["default-sort-order-id"] ? (
                          <tr>
                            <td className="w-1/3 text-lightGrey">Default Sort Order ID</td>
                            <td>{catalogData?.metadata?.["default-sort-order-id"] ?? "-"}</td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  )}
                </div>
                <div className="py-7 px-10 border rounded-md flex flex-col mb-10">
                  <div className="flex justify-between items-center">
                    <div className="text-333333 text-11 leading-6 font-bold">Fields</div>
                    <Input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search..."
                      setSearch={setSearch}
                      search
                      className="mr-8 !w-fit"
                    />
                  </div>
                  <Table tableHeader={fieldHead} bodyHight="max-h-[calc(100vh-14.4rem)]" totalPost={contextValues?.lakehouseData?.meta?.total}>
                    {filterDataBySearch(catalogData?.metadata?.schema?.fields, search)?.map((item, index) => {
                      return (
                        <tr key={index} className="border-b w-full hover:bg-tableHoverColor last:border-0">
                          <td className="px-16 py-5 cursor-pointer text-xs">{item?.id}</td>
                          <td className="px-16 py-5 text-center cursor-pointer justify-center text-xs">{item?.name ?? "-"}</td>
                          <td className="px-16 py-5 text-center cursor-pointer justify-center text-xs">
                            {item?.required == true ? "True" : "False"}
                          </td>
                          <td className="px-16 py-5 text-center cursor-pointer justify-center text-xs">{item?.type ?? "-"}</td>
                          <td className="px-16 py-5 text-center cursor-pointer justify-center text-xs">{item?.doc ?? "-"}</td>
                        </tr>
                      );
                    })}
                  </Table>
                </div>
              </div>
              <div className="flex-col w-1/3 ml-10">
                <div className="py-7 px-10 border rounded-md mb-10">
                  <div className="flex justify-between items-center text-7 font-normal mb-2 cursor-pointer text-lightGrey">
                    Metadata location
                    <Popconfirm
                      open={false}
                      onOpenChange={() => {
                        message.success("copied!");
                      }}
                    >
                      <div role="presentation" onClick={() => navigator.clipboard.writeText(catalogData?.metadata_location)} className="text-0a58ff ">
                        <i className="fa-solid fa-copy" />
                        <span className="ml-4">Copy</span>
                      </div>
                    </Popconfirm>
                  </div>
                  <Input disabled value={catalogData?.metadata_location} className=" w-full " />
                  {catalogData?.metadata?.["table-uuid"] ? (
                    <>
                      <div className="mt-10 text-10 text-lightGrey">Table UUID</div>
                      <div className="text-7 font-normal">{catalogData?.metadata?.["table-uuid"] ?? "-"}</div>
                    </>
                  ) : null}
                  {catalogData?.metadata?.properties?.owner ? (
                    <>
                      <div className="mt-10 text-10 text-lightGrey">Owner</div>
                      <div className="text-7 font-normal">{catalogData?.metadata?.properties?.owner ?? "Not created"}</div>
                    </>
                  ) : null}
                </div>
                <div className="py-7 px-10 border rounded-md flex flex-col mb-10">
                  <div className="text-333333 text-11 leading-6 font-bold">Snapshot</div>
                  {catalogData?.metadata?.error ? (
                    <div className="text-7 font-normal text-lightGrey">Not created</div>
                  ) : (
                    <table>
                      <tbody>
                        {catalogData?.metadata?.refs?.main?.["snapshot-id"] ? (
                          <tr>
                            <td className="w-1/3 text-lightGrey">Snapshot ID</td>
                            <td>{catalogData?.metadata?.refs?.main?.["snapshot-id"] ?? "-"}</td>
                          </tr>
                        ) : null}
                        {catalogData?.metadata?.refs?.main?.type ? (
                          <tr>
                            <td className="w-1/3 text-lightGrey">Type</td>
                            <td>{catalogData?.metadata?.refs?.main?.type ?? "-"}</td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          ) : activeTab === "lineage" ? (
            <Lineage tableData={catalogData} height="76.8vh" width="100%" />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CatalogOverview;
