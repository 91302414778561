import { BrowserRouter } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import Body from "./Body";
import Router from "./Router";
import { Provider } from "./Context";
import Loading from "./Widgets/Loading";
import "antd/dist/antd.css";

const App = () => {
  return (
    <BrowserRouter>
      <Provider>
        <Body>
          <Loading />
          <CssBaseline />
          <Router />
        </Body>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
