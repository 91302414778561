import { memo, useContext, useEffect, useState } from "react";
import CommonField from "../../Widgets/CommonField";
import { Context } from "../../Context";

function Iceberg({ iceberg, setIceberg }) {
  const [optionData, setOptionData] = useState();
  const [catalog, setCatalog] = useState();
  const [namespaceOptionData, setNamespaceOptionData] = useState();
  const [namespace, setNamespace] = useState();
  const [tableOptionData, setTableOptionData] = useState();
  const [table, setTable] = useState();
  const contextValues = useContext(Context);

  useEffect(() => {
    if (iceberg?.namespace?.value) {
      contextValues.customNamespace(`catalog_name=${iceberg?.namespace?.value}`);
    }
    if (iceberg?.table) {
      contextValues.GetTables(`table_namespace=${iceberg?.namespace?.value}`);
      contextValues.GetTables(iceberg?.namespace?.value);
    }
    // contextValues.GetLakehouse({ limit: -1 });
    contextValues.getAllCatalogs({ limit: -1 });
  }, []);

  useEffect(() => {
    if (contextValues?.getCatalogs) {
      const filterData = contextValues?.getCatalogs?.map((item) => ({
        value: `${item.catalog_name}`,
        label: `${item.catalog_name}`,
      }));
      setOptionData(filterData);
      if (iceberg?.catalog) {
        const filterDropDown = contextValues?.getCatalogs?.find((item) => item?.catalog_name == iceberg?.catalog);
        setCatalog({
          value: filterDropDown?.catalog_name,
          label: filterDropDown?.catalog_name,
        });
      }
    }
  }, [contextValues?.getCatalogs]);

  useEffect(() => {
    const filterData = contextValues?.tablesData?.map((item) => ({
      value: `${item?.table_name}`,
      label: `${item?.table_name}`,
    }));
    setTableOptionData(filterData);
    if (iceberg?.table && contextValues?.tablesData) {
      const filterDropDown = contextValues?.tablesData?.find((item) => item?.table_name == iceberg?.table);
      setTable({
        value: filterDropDown?.table_name,
        label: filterDropDown?.table_name,
      });
    }
  }, [contextValues?.tablesData]);

  useEffect(() => {
    const filterData = contextValues?.getCustomNamespace?.map((item) => ({
      value: `${item.table_namespace}`,
      label: `${item.table_namespace}`,
    }));
    setNamespaceOptionData(filterData);
    if (iceberg?.namespace && contextValues?.getCustomNamespace) {
      const filterDropDown = contextValues?.getCustomNamespace?.find((item) => item?.table_namespace == iceberg?.namespace?.value);
      setNamespace({
        value: filterDropDown?.table_namespace,
        label: filterDropDown?.table_namespace,
      });
    }
  }, [contextValues?.getCustomNamespace]);

  return (
    <div className="grid gap-8 w-full h-fit">
      <CommonField
        titleText="Catalog"
        value={catalog}
        options={optionData}
        onChange={(e) => {
          setCatalog(e);
          setIceberg({
            ...iceberg,
            catalog: e?.value,
          });
          setTable();
          setNamespace();
          setTableOptionData();
          setNamespaceOptionData();
          contextValues.customNamespace(`catalog_name=${e?.value}`);
        }}
        error
        selectfield
      />
      <CommonField
        titleText="Namespace"
        value={namespace}
        options={namespaceOptionData}
        onChange={(selecte_namespace) => {
          contextValues.GetTables(`table_namespace=${selecte_namespace?.value}`);
          setNamespace(selecte_namespace);
          setIceberg({ ...iceberg, namespace: selecte_namespace });
        }}
        error
        selectfield
      />
      <CommonField
        titleText="Table"
        value={table}
        options={tableOptionData}
        onChange={(e) => {
          setTable(e);
          setIceberg({ ...iceberg, table: e.value });
        }}
        error
        selectfield
      />
    </div>
  );
}

export default memo(Iceberg);
