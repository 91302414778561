import { useContext } from "react";
import { Context } from "../Context";

const Loading = () => {
  const contextValues = useContext(Context);
  if (contextValues?.loading)
    return (
      <div
        className={`flex items-center justify-center h-screen ${
          window.location.href.includes("flow-creation") ? `w-full` : `w-[calc(100%-14rem)]`
        } absolute top-0 bg-white bg-opacity-5 backdrop-blur-sm z-9999`}
      >
        <div className="animate-spin rounded-full h-28 w-28 border-t-4 border-r-4 border-blue-500" />
      </div>
    );
};

export default Loading;
