import { useState } from "react";
import { Menu } from "@mui/material";
import { MenuItem } from "@material-ui/core";

const CustomMenu = ({ menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="w-full flex justify-center items-center">
        <i
          className="fa-solid fa-ellipsis-vertical text-sm text-lightGrey h-16 w-16 flex items-center justify-center hover:text-blue-500 hover:bg-blue-50 hover:bg-opacity-30 rounded-full cursor-pointer"
          role="presentation"
          onClick={handleClick}
        />
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {menuItems.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                option.onclick();
                handleClose();
              }}
            >
              <i className={`${option.icon} mr-6 text-xs `} />
              <div className="font-normal text-xs">{option.title}</div>
            </MenuItem>
          ))}
        </Menu>
      </div>
    </>
  );
};

export default CustomMenu;
